// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo } from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { colors } from '../../../lib/designSystem';
import { isGroupVisible } from '../../../lib/entityGroupUtils';
import { isFarfield } from '../../../lib/farfieldUtils';
import { isGeomHealthId } from '../../../lib/geometryHealthUtils';
import { getAllAttachedSurfaceIds } from '../../../lib/motionDataUtils';
import { formatDescendantCount } from '../../../lib/simulationTree/utils';
import {
  deleteTreeNodeMenuItem,
  groupTreeNodeMenuItem,
  ungroupTreeNodeMenuItem,
} from '../../../lib/treeUtils';
import { useEntityGroupData, useNumDescendants } from '../../../recoil/entityGroupState';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useLcVisReadyValue } from '../../../recoil/lcvis/lcvisReadyState';
import { useLcvisVisibilityMapValue } from '../../../recoil/lcvis/lcvisVisibilityMap';
import { useShowRowChildrenCountValue } from '../../../recoil/simulationTree/showRowChildrenCount';
import { useToggleVisibility } from '../../../recoil/vis/useToggleVisibility';
import { useSimulationParam } from '../../../state/external/project/simulation/param';
import { useIsGeometryView } from '../../../state/internal/global/currentView';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { useTagsInteractiveGeometry } from '../../hooks/useInteractiveGeometry';
import { useNodeDeletion } from '../../hooks/useNodeDeletion';
import { useNodeGrouping } from '../../hooks/useNodeGrouping';
import { useNodeRenaming } from '../../hooks/useNodeRenaming';
import { ContextMenuSection, IconSpec, TreeRow } from '../TreeRow';

const PRIMARY_ICON: IconSpec = { name: 'group' };

// A row displaying information about a surface group.
export const SurfaceGroupTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;
  const { id: nodeId } = node;

  // == Context
  const { viewState, visibilityMap } = useParaviewContext();
  const { projectId, workflowId, jobId } = useProjectContext();
  const { selectedNodeIds } = useSelectionContext();

  // == Recoil
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisReady = useLcVisReadyValue();
  const visibilityV2 = useLcvisVisibilityMapValue({ projectId, workflowId, jobId });
  const showRowChildren = useShowRowChildrenCountValue();
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const isGeometryView = useIsGeometryView();

  // == Hooks
  const renaming = useNodeRenaming(node);
  const { canDelete, deleteSurfaceGroupNode, postDeleteNodeIds } = useNodeDeletion();
  const {
    canGroup,
    canUngroup,
    groupEntities,
    ungroupEntities,
    groupableNodes,
  } = useNodeGrouping();
  const { removeTag, isRemoveTagDisabled } = useTagsInteractiveGeometry();

  const isVisible = (
    lcvisEnabled ?
      isGroupVisible(visibilityV2, entityGroupData.groupMap, nodeId) :
      isGroupVisible(visibilityMap, entityGroupData.groupMap, nodeId)
  );

  const surfacesWithMotion = getAllAttachedSurfaceIds(simParam, { motion: 'moving' });

  const toggleIds = useMemo(() => {
    const isSelected = selectedNodeIds.includes(nodeId);
    // Toggle all the selected IDs, if this ID is selected.
    const ids = (isSelected ? selectedNodeIds : [nodeId]);

    // Vis knows nothing about geometry health ids, but those ids are included as part of the
    // selection since both the heath check row and the sim tree row are selected.
    return ids.reduce((result, id) => {
      if (!isGeomHealthId(id)) {
        result.add(id);
      }
      return result;
    }, new Set<string>());
  }, [nodeId, selectedNodeIds]);
  const toggleVis = useToggleVisibility(toggleIds, isVisible);

  const visibilityControl = {
    show: isVisible,
    disabled: lcvisEnabled ? !lcvisReady : !viewState,
    isHovered: (rowHovered: boolean) => rowHovered,
    toggle: toggleVis,
  };

  const numDescendants = useNumDescendants(projectId, workflowId, jobId, nodeId);
  const descendantsLabel = showRowChildren ? formatDescendantCount(numDescendants) : '';

  const auxIcons = useMemo<IconSpec[]>(() => {
    // Find all the leaf surfaces in the group

    // Check for nodeId in the map, because there's a race condition where this component re-renders
    // after the group is removed from the entity map but before the group node is removed from the
    // control panel.
    const { groupMap } = entityGroupData;
    if (groupMap.has(nodeId)) {
      const group = groupMap.get(nodeId);
      const groupSurfaces = groupMap.findDescendants(group.id);

      // Determine if some member surfaces are attached and some are unattached
      const someIn = groupSurfaces.some((surface) => surfacesWithMotion.has(surface));
      const someOut = groupSurfaces.some((surface) => !surfacesWithMotion.has(surface));
      if (someIn) {
        return [
          {
            name: 'rotatingDots',
            color: colors.citronGreen600,
            opacity: someOut ? 0.5 : 1,
          },
        ];
      }
    }
    return [];
  }, [entityGroupData, nodeId, surfacesWithMotion]);

  const deleteRow = useCallback(() => {
    if (deleteSurfaceGroupNode(node.id)) {
      postDeleteNodeIds([node.id]);
    }
  }, [deleteSurfaceGroupNode, node.id, postDeleteNodeIds]);

  const getExtraContextMenuItems = useCallback(() => {
    const sections: ContextMenuSection[] = [];
    if (isGeometryView) {
      if (!isRemoveTagDisabled) {
        const deleteTagRow = async () => {
          const isSelected = selectedNodeIds.includes(nodeId);
          const ids = (isSelected ? selectedNodeIds : [nodeId]);
          await removeTag(ids);
        };
        const deleteItem = deleteTreeNodeMenuItem(deleteTagRow, isRemoveTagDisabled);
        sections.push({ section: 'crud', menuItems: [deleteItem] });
      }
      return sections;
    }

    if (isFarfield(node.id)) {
      const disabled = !canDelete(node.type, node.id);
      const deleteItem = deleteTreeNodeMenuItem(deleteRow, disabled);
      sections.push({ section: 'crud', menuItems: [deleteItem] });
    }

    const nodeIdsToGroup = groupableNodes(node.id);
    if (canGroup(nodeIdsToGroup, entityGroupData)) {
      const groupItem = groupTreeNodeMenuItem(() => groupEntities(nodeIdsToGroup));
      sections.push({ section: 'grouping', menuItems: [groupItem] });
    }

    if (canUngroup(node, entityGroupData)) {
      const ungroupItem = ungroupTreeNodeMenuItem(() => ungroupEntities(node.id));
      sections.push({ section: 'grouping', menuItems: [ungroupItem] });
    }

    return sections;
  }, [
    canDelete,
    canGroup,
    canUngroup,
    deleteRow,
    entityGroupData,
    groupEntities,
    groupableNodes,
    node,
    ungroupEntities,
    removeTag,
    isRemoveTagDisabled,
    isGeometryView,
    selectedNodeIds,
    nodeId,
  ]);

  return (
    <TreeRow
      {...props}
      auxIcons={auxIcons}
      canMultiSelect
      getExtraContextMenuItems={getExtraContextMenuItems}
      label={node.name}
      primaryIcon={PRIMARY_ICON}
      propertiesControl={!isGeometryView}
      renaming={renaming}
      sublabel={descendantsLabel || undefined}
      visibility={visibilityControl}
    />
  );
};
