// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { deepEqual } from 'fast-equals';
import { atom, useAtom, useSetAtom } from 'jotai';
import { atomFamily, atomWithStorage, createJSONStorage } from 'jotai/utils';

import { ColumnId, ColumnOps, RowId, SortDefinition } from '../../../lib/componentTypes/table';

// #region: Types

export type ColumnOpsRecord = Record<ColumnId, ColumnOps>;

export type TableState = {
  /** Operational state for columns */
  columns: ColumnOpsRecord;
  /** Sorting definition */
  sorting?: SortDefinition;
  pageSize?: number;
}

// #region: Helper Functions

export function defaultTableState(): TableState {
  return { columns: {} };
}

function getTableStorageKey(name: string) {
  return `data-table-state-${name}`;
}

function getPageStorageKey(name: string) {
  return `data-table-page-${name}`;
}

// #region: Atoms

/**
 * @param name a unique name that identifies the table
 */
const dataTableState = atomFamily((name: string) => {
  const storageKey = getTableStorageKey(name);
  return atomWithStorage(
    storageKey,
    defaultTableState(),
    createJSONStorage<TableState>(() => sessionStorage),
    { getOnInit: true },
  );
}, deepEqual);

/**
 * An atom for the currently selected row IDs of a table
 *
 * @param name the unique name of the table
 */
const dataTableSelectedRowsState = atomFamily(
  (name: string) => atom(new Set<RowId>()),
  deepEqual,
);

/**
 * At atom for the last opened page for a particular table
 *
 * @param name the unique name of the table
 */
const dataTablePageState = atomFamily((name: string) => {
  const storageKey = getPageStorageKey(name);
  return atomWithStorage(
    storageKey,
    0,
    createJSONStorage<number>(() => sessionStorage),
    { getOnInit: true },
  );
}, deepEqual);

// #region: Hooks

export function useDataTable(name: string) {
  return useAtom(dataTableState(name));
}

export function useDataTableSelectedRows(name: string) {
  return useAtom(dataTableSelectedRowsState(name));
}

export function useSetDataTableSelectedRows(name: string) {
  return useSetAtom(dataTableSelectedRowsState(name));
}

export function useDataTablePage(name: string) {
  return useAtom(dataTablePageState(name));
}
