// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode } from 'react';

import cx from 'classnames';

import { SvgIconSpec } from '../../../lib/componentTypes/svgIcon';
import { ActionButton, ActionKind } from '../../Button/ActionButton';
import { IconButton } from '../../Button/IconButton';
import Tooltip from '../../Tooltip';
import { Modal } from '../../layout/Modal';
import { CaretDownIcon } from '../../svg/CaretDownIcon';
import { XIcon } from '../../svg/XIcon';
import Collapsible from '../../transition/Collapsible';
import { ProgressBar } from '../../visual/ProgressBar';

import './Dialog.scss';

const DEFAULT_DIALOG_WIDTH = '600px';

export interface ButtonAction {
  label?: string;
  disabled?: boolean;
  kind?: ActionKind;
  icon?: SvgIconSpec;
  help?: string;
  name?: string;
}

export interface Progress {
  visible: boolean;
  message?: string;
  progress: null | number;
}

export interface ExtraButtonAction extends ButtonAction {
  key: string;
  label: string;
  onClick: () => void;
}

// Setting controlState to 'disabled' causes all controls (buttons) to be disabled.  Setting
// controlState to 'working' also disables all controls, but it also shows a spinner in the
// continue button. 'working cancelable' is the same as 'working', but the cancel buttons are
// enabled.
export type ControlState = 'disabled' | 'working' | 'working cancelable' | 'normal';

type CollapsibleSettings = {
  collapsed: boolean;
  onToggle: (collapsed: boolean) => void;
}

export interface DialogProps {
  open: boolean;
  onClose: () => void;
  onContinue?: () => void | Promise<void>;
  title: string;
  subtitle?: ReactNode;
  children: ReactNode;
  // Optional footer between the children and the action buttons that is visible even with scroll
  footer?: ReactNode;
  compact?: boolean;
  destructive?: boolean;
  controlState?: ControlState;
  cancelButton?: ButtonAction;
  continueButton?: ButtonAction;
  secondaryButtons?: ExtraButtonAction[];
  tertiaryButtons?: ExtraButtonAction[];
  progress?: Progress;
  modal?: boolean;
  draggable?: boolean;
  dragging?: boolean;
  onFrameMousedown?: (event: React.MouseEvent) => void;
  symbol?: ReactNode;
  width?: string;
  refSubmit?: React.RefObject<HTMLButtonElement>;
  // If collapsible is true, the dialog will be collapsible and have a caret icon in the header.
  collapsible?: CollapsibleSettings
}

const CollapsibleWrapper = (props: {
  collapsible?: CollapsibleSettings;
  children: ReactNode,
}) => (
  props.collapsible ? (
    <Collapsible collapsed={props.collapsible.collapsed} transitionPeriod={150}>
      {props.children}
    </Collapsible>
  ) :
    <>{props.children}</>
);

export const Dialog = (props: DialogProps) => {
  // Props
  const {
    cancelButton,
    continueButton,
    controlState,
    compact,
    destructive,
    draggable,
    dragging,
    footer,
    secondaryButtons,
    modal,
    onClose,
    onContinue,
    onFrameMousedown,
    open,
    progress,
    subtitle,
    symbol,
    tertiaryButtons,
    title,
    width = DEFAULT_DIALOG_WIDTH,
    collapsible,
  } = props;

  const { collapsed, onToggle } = collapsible ?? {};

  const isWorking = controlState === 'working' || controlState === 'working cancelable';
  const isDisabled = isWorking || controlState === 'disabled';
  const isCancelable = (
    !controlState ||
    controlState === 'normal' ||
    controlState === 'working cancelable'
  );

  if (!open) {
    return null;
  }

  const content = (
    <div
      className={cx('dialogBase', { modal, compact })}
      role="dialog"
      style={{ width }}>
      <div
        aria-label={title}
        className={cx('dialogBody', { draggable, dragging, compact })}
        onMouseDown={(event) => {
          const target = event.target as HTMLElement;
          // Allows dragging only in the header and actions bar area of the dialog.
          if (target.classList.contains('draggable')) {
            onFrameMousedown?.(event);
          }
        }}
        role="presentation">
        {symbol && (
          <div className="dialogSymbol">{symbol}</div>
        )}
        <form
          onSubmit={async (event) => {
            event.preventDefault();
            await onContinue?.();
          }}>
          <div className={cx('dialogHeader', { compact, draggable, collapsed })}>
            <div className={cx('headerBar', { draggable })}>
              {collapsible && (
                <div
                  className={cx('toggle', { collapsed })}
                  onClick={() => onToggle?.(!collapsed)}
                  role="presentation">
                  <CaretDownIcon maxWidth={6} />
                </div>
              )}
              <h4 className={cx('headerTitle', { compact, draggable })}>{title}</h4>
              <div className={cx('headerControl', { draggable })}>
                <IconButton
                  disabled={!isCancelable}
                  onClick={onClose}>
                  <XIcon maxHeight={10} />
                </IconButton>
              </div>
            </div>
            {subtitle && (
              <h5 className="headerSubtitle">{subtitle}</h5>
            )}
          </div>
          <CollapsibleWrapper collapsible={collapsible}>
            <div
              className={cx('dialogContent', { compact })}
              // This prevents dialog content from triggering drag motion. Allowing mousedown drag
              // for inputs can cause complications.
              role="presentation">
              {props.children}
            </div>
          </CollapsibleWrapper>
          {footer && <div className={cx('dialogFooter', { compact })}>{footer}</div>}
          {!compact && (
            <div
              className={cx('dialogActions', { draggable, dragging, compact, hasFooter: footer })}>
              {tertiaryButtons?.map((button) => (
                <ActionButton
                  disabled={isDisabled || (button.disabled ?? false)}
                  key={button.key}
                  kind={button.kind || 'minimal'}
                  name={button.name}
                  onClick={button.onClick}
                  size={compact ? 'small' : 'medium'}
                  startIcon={button.icon}
                  title={button.help || ''}>
                  {button.label || ''}
                </ActionButton>
              ))}
              {tertiaryButtons && (
                <div className="dialogButtonsGap" />
              )}
              {cancelButton && (
                <ActionButton
                  disabled={!isCancelable || (cancelButton.disabled ?? false)}
                  kind={cancelButton.kind || 'cancel'}
                  name={cancelButton.name}
                  onClick={onClose}
                  size={compact ? 'small' : 'medium'}
                  startIcon={cancelButton.icon}
                  title={cancelButton.help || ''}>
                  {cancelButton.label || 'Cancel'}
                </ActionButton>
              )}
              {secondaryButtons?.map((button) => (
                <ActionButton
                  disabled={isDisabled || (button.disabled ?? false)}
                  key={button.key}
                  kind={button.kind || 'secondary'}
                  name={button.name}
                  onClick={button.onClick}
                  size={compact ? 'small' : 'medium'}
                  startIcon={button.icon}
                  title={button.help || ''}>
                  {button.label || ''}
                </ActionButton>
              ))}
              {continueButton && (
                <ActionButton
                  disabled={isDisabled || (continueButton.disabled ?? false)}
                  kind={continueButton.kind || (destructive ? 'destructive' : 'primary')}
                  name={continueButton.name}
                  ref={props.refSubmit}
                  showSpinner={isWorking}
                  size={compact ? 'small' : 'medium'}
                  startIcon={continueButton.icon}
                  title={continueButton.help || ''}
                  type="submit">
                  {continueButton.label || 'Continue'}
                </ActionButton>
              )}
            </div>
          )}
        </form>
      </div>
      <Collapsible collapsed={!progress?.visible}>
        <div className="dialogProgress">
          <Tooltip placement="top-start" title={progress?.message || ''}>
            <div className="progressMessage">
              {progress?.message}
            </div>
          </Tooltip>
          <div className="progressBar">
            <ProgressBar height={6} progress={progress?.progress ?? null} />
          </div>
        </div>
      </Collapsible>
    </div>
  );

  if (modal) {
    return (
      <Modal
        onClickBackDrop={() => {
          !isDisabled && onClose();
        }}
        onClose={onClose}>
        {content}
      </Modal>
    );
  }

  return content;
};
