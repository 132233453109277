// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { forwardRef, useRef, useState } from 'react';

import { LCVType } from '@luminarycloudinternal/lcvis';

import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { colors, hexToRgbList, rgbListToHex } from '../../lib/designSystem';
import { getLiveLcvObjects } from '../../lib/lcvis/classes/base/LcvObject';
import { LcvFilter } from '../../lib/lcvis/classes/filters/LcvFilter';
import { Vector3 } from '../../proto/base/base_pb';
import { useShowLCVisSettingsDialog } from '../../state/external/lcvis/showLCVisSettingsDialog';
import { ActionButton } from '../Button/ActionButton';
import Dropdown from '../Dropdown';
import { NumberInput } from '../Form/NumberInput';
import { TextInput } from '../Form/TextInput';
import { Vector3Input } from '../Form/Vector3Input';
import { useDraggable } from '../Paraview/draggable';
import Divider from '../Theme/Divider';
import { Flex } from '../visual/Flex';

import { Dialog } from './Base';

const LCVIS_SETTINGS_DIALOG_ID = 'lcvis-settings-dialog';

interface LCVisSettingsDialogProps {
  onClose?: () => void;
}

const LCVisSettingsDialog = forwardRef((props: LCVisSettingsDialogProps, ref) => {
  const overlayRef = ref as React.RefObject<HTMLDivElement>;
  const { onClose } = props;
  const [open, setOpen] = useShowLCVisSettingsDialog();

  const [selectedType, setSelectedType] = useState<LCVType | string>(LCVType.kLCVDataTypeUnknown);

  const { initDrag, draggedPositions, dragPositionToStyle, currentDragId } = useDraggable();
  const dialogNode = useRef<HTMLDivElement>(null);

  const [paramName, setParamName] = useState<string>('');
  const [paramType, setParamType] = useState<LCVType>(LCVType.kLCVDataTypeInt);
  const [paramValueNumber, setParamValueNumber] = useState<number>(0);
  const [paramValueString, setParamValueString] = useState<string>('');
  const [paramValueVec3, setParamValueVec3] = useState<[number, number, number]>([0, 0, 0]);
  const [paramValueVec4, setParamValueVec4] = useState<[number, number, number, number]>(
    [0, 0, 0, 0],
  );
  const [surfaceLineColor, setSurfaceLineColor] = useState<[number, number, number]>(
    [0, 0, 0],
  );

  if (!open) {
    return <></>;
  }

  const isDragging = currentDragId === LCVIS_SETTINGS_DIALOG_ID;

  const onMouseDown = (event: any) => {
    if (overlayRef.current && dialogNode.current) {
      initDrag(
        LCVIS_SETTINGS_DIALOG_ID,
        event,
        dialogNode.current,
        overlayRef.current,
      );
    }
  };

  // Call this every render because the objects aren't connected to the React state. It makes
  // the dialog less performant but it's fine for our use cases.
  const objects = getLiveLcvObjects();

  const uniqueObjectTypes = (() => {
    const types = new Set<LCVType | string>();
    objects.forEach((obj) => types.add(obj.objectType));
    types.add('separator');
    types.add('header');
    objects.forEach((obj) => types.add(obj.constructor.name));
    return [...types];
  })();

  const numObjectsOfType: Map<LCVType | string, number> = objects.reduce((acc, obj) => {
    acc.set(obj.objectType, (acc.get(obj.objectType) || 0) + 1);
    acc.set(obj.constructor.name, (acc.get(obj.constructor.name) || 0) + 1);
    return acc;
  }, new Map());

  const combinedMenuItems: CommonMenuItem[] = uniqueObjectTypes.map((type, index) => {
    if (type === 'separator') {
      return {
        separator: true,
      };
    } if (type === 'header') {
      return {
        title: 'Class wrappers',
      };
    }
    const chooseByClass = typeof type === 'string';
    const formattedType = chooseByClass ? type : LCVType[type as LCVType];
    const numObjects = numObjectsOfType.get(type) ?? 0;
    return {
      label: `${formattedType} (${numObjects})`,
      selected: selectedType === type,
      onClick: () => {
        setSelectedType(type);
      },
    };
  });
  combinedMenuItems.unshift({
    title: 'Object types',
  });

  const objectTypeDropdown = (
    <Dropdown
      menuItems={combinedMenuItems}
      toggle={(
        <ActionButton kind="minimal" size="small">
          {typeof selectedType === 'string' ? selectedType : LCVType[selectedType]}&nbsp;
          ({numObjectsOfType.get(selectedType) ?? 0})
        </ActionButton>
    )}
    />
  );

  const valueInput = (() => {
    switch (paramType) {
      case LCVType.kLCVDataTypeFloat:
      case LCVType.kLCVDataTypeInt:
      case LCVType.kLCVDataTypeUint:
        return (
          <NumberInput
            onCommit={(value) => setParamValueNumber(Number(value))}
            placeholder="param value"
            size="small"
            value={paramValueNumber}
          />
        );
      case LCVType.kLCVDataTypeString:
        return (
          <TextInput
            onCommit={(value) => setParamValueString(value)}
            placeholder="param value"
            size="small"
            value={paramValueString}
          />
        );
      case LCVType.kLCVDataTypeFloat3:
      case LCVType.kLCVDataTypeInt3:
      case LCVType.kLCVDataTypeUint3:
        return (
          <Vector3Input
            onCommit={(value) => setParamValueVec3([value.x, value.y, value.z])}
            value={new Vector3(
              { x: paramValueVec3[0], y: paramValueVec3[1], z: paramValueVec3[2] },
            )}
          />
        );
      case LCVType.kLCVDataTypeFloat4:
      case LCVType.kLCVDataTypeInt4:
      case LCVType.kLCVDataTypeUint4:
        return (
          <>
            <Vector3Input
              onCommit={(value) => setParamValueVec4(
                (prev) => [value.x, value.y, value.z, prev[3]],
              )}
              value={new Vector3(
                { x: paramValueVec4[0], y: paramValueVec4[1], z: paramValueVec4[2] },
              )}
            />
            <NumberInput
              onCommit={(value) => setParamValueVec4(
                (prev) => [prev[0], prev[1], prev[2], Number(value)],
              )}
              size="small"
              value={paramValueVec4[3]}
            />
          </>
        );
      default:
        return <>Inputting {LCVType[paramType]} is not yet supported</>;
    }
  })();

  const applyParam = () => {
    const chooseByClass = typeof selectedType === 'string';
    const selectedObjects = objects.filter((obj) => {
      if (chooseByClass) {
        return obj.constructor.name === selectedType;
      }
      return obj.objectType === selectedType;
    });
    selectedObjects.forEach((obj) => {
      obj.setParam(paramName, paramType, (() => {
        switch (paramType) {
          case LCVType.kLCVDataTypeFloat:
          case LCVType.kLCVDataTypeInt:
          case LCVType.kLCVDataTypeUint:
            return paramValueNumber;
          case LCVType.kLCVDataTypeString:
            return paramValueString;
          case LCVType.kLCVDataTypeFloat3:
          case LCVType.kLCVDataTypeInt3:
          case LCVType.kLCVDataTypeUint3:
            return paramValueVec3;
          case LCVType.kLCVDataTypeFloat4:
          case LCVType.kLCVDataTypeInt4:
          case LCVType.kLCVDataTypeUint4:
            return paramValueVec4;
          default:
            return null;
        }
      })());
    });
  };

  const disableApply = (
    selectedType === LCVType.kLCVDataTypeUnknown ||
    !selectedType ||
    !paramName
  );

  const applyLineColor = () => {
    objects.forEach((obj) => {
      if (obj instanceof LcvFilter) {
        obj.setAllSurfaceLinesColor(surfaceLineColor);
      }
    });
  };

  return (
    <div
      ref={dialogNode}
      style={{
        position: 'absolute',
        zIndex: 1000,
        ...dragPositionToStyle(draggedPositions[LCVIS_SETTINGS_DIALOG_ID]),
      }}>
      <Dialog
        compact
        draggable={!!onMouseDown}
        dragging={isDragging}
        onClose={() => {
          setOpen(false);
          onClose?.();
        }}
        onFrameMousedown={onMouseDown}
        open={open}
        title="LCVis Settings">
        <div>
          <Flex flexDirection="column" gap={8}>
            <Flex alignItems="center" flexDirection="row" gap={8}>
              Change surface line color:
              <input
                onChange={(ev) => {
                  const value = ev.target.value;
                  const rgb = hexToRgbList(value);
                  setSurfaceLineColor(rgb);
                }}
                type="color"
                value={rgbListToHex(surfaceLineColor)}
              />
              <div style={{ width: '100px' }}>
                {rgbListToHex(surfaceLineColor)}
              </div>
              <div style={{ width: '120px' }}>
                <ActionButton compact onClick={applyLineColor}>
                  Apply
                </ActionButton>
              </div>
            </Flex>
            <Divider />
            <Flex alignItems="center" flexDirection="row" gap={8}>
              Object type or class:
              {objectTypeDropdown}
            </Flex>
            Set a param on all items in the above selected class or objectType:
            <Flex alignItems="center" flexDirection="row" gap={8}>
              <TextInput
                onCommit={(name) => setParamName(name)}
                placeholder="param name"
                size="small"
                value={paramName}
              />
              <Dropdown
                menuItems={Object.values(LCVType)
                  .filter((type) => typeof type === 'number')
                  .map((type) => ({
                    label: LCVType[type as LCVType],
                    selected: paramType === type,
                    onClick: () => setParamType(type as LCVType),
                  }))}
                toggle={(
                  <ActionButton kind="minimal" size="small">
                    {LCVType[paramType]}
                  </ActionButton>
              )}
              />
              {valueInput}
            </Flex>
            <div style={{ width: '120px' }}>
              <ActionButton disabled={disableApply} onClick={applyParam}>
                Apply
              </ActionButton>
            </div>
            {!disableApply && (
              <span style={{ color: colors.red500 }}>
                Warning: this will apply the param to all objects of the selected type.
                Make sure you know what you&apos;re doing.
                State will not be saved and only applies to LCVis, not the whole UI. You can
                undo by refreshing the page.
              </span>
            )}
          </Flex>
        </div>
      </Dialog>
    </div>
  );
});

export default LCVisSettingsDialog;
