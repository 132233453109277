// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect } from 'react';

import { ConnectError } from '@connectrpc/connect';
import { atom, useAtom, useAtomValue } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { atomFamily as atomFamilyRecoil } from 'recoil';

import { hasSupportAclEntry } from '../lib/projectShareUtils';
import { isProjectAccessDeniedError, isProjectDeletedError } from '../lib/projectUtilsErrors';
import * as rpc from '../lib/rpc';
import { addRpcError } from '../lib/transientNotification';
import * as frontendpb from '../proto/frontend/frontend_pb';
import { ProjectDeniedStatus, useSetProjectDeniedDialog } from '../state/external/project/sharing';

const rpcPool = new rpc.StreamingRpcPool<
  frontendpb.GetProjectRequest,
  frontendpb.GetProjectReply
>('GetProject', rpc.client.getProject);

// Cache of project state reported by GetProject RPC.  The key is the project ID.
export const projectMetadataState = atomFamily(
  (projectId: string) => atom<frontendpb.GetProjectReply | null>(null),
);

// An alternative Recoil state for backward compatibily. Soon to be removed.
export const projectMetadataMapState_DEPRECATED = atomFamilyRecoil<
  frontendpb.GetProjectReply | null,
  string
>({
  key: 'projectMetadataMap',
  default: null,
});

export function useProjectMetadataValue(projectId: string) {
  return useAtomValue(projectMetadataState(projectId));
}

// Start polling for new project status. It returns the most uptodate project
// metadata, or null if it hasn't been fetched yet.  This function must be
// called inside a component.
export default function useProjectMetadata(
  projectId: string,
): frontendpb.GetProjectReply | null {
  const [projectMetadata, setProjectMetadata] = useAtom(projectMetadataState(projectId));
  const setProjectDeniedDialog = useSetProjectDeniedDialog();

  useEffect(() => {
    if (projectId) {
      const cancelRpc = rpcPool.start(
        projectId,
        () => new frontendpb.GetProjectRequest({ projectId }),
        setProjectMetadata,
        (err: ConnectError) => {
          if (isProjectAccessDeniedError(err.message)) {
            setProjectDeniedDialog({ open: true, type: ProjectDeniedStatus.NO_ACCESS, projectId });
          } else if (isProjectDeletedError(err.message)) {
            setProjectDeniedDialog({ open: true, type: ProjectDeniedStatus.DELETED, projectId });
          } else {
            addRpcError('Failed to get project', err);
          }
        },
      );
      return cancelRpc;
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);
  return projectMetadata;
}

export const projectMetadataHasSupportAclEntry = (
  projectMetadata: frontendpb.GetProjectReply | null,
) => hasSupportAclEntry(projectMetadata?.summary?.acl ?? []);

export const useIsProjectSharedWithSupport = (projectId: string) => (
  projectMetadataHasSupportAclEntry(useProjectMetadata(projectId))
);
