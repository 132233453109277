// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import backgroundImage from '../../../assets/images/auth-background-waves.jpg';
import { HOME_URL } from '../../../lib/constants';
import { colors } from '../../../lib/designSystem';
import { routes } from '../../../lib/navigation';
import { ActionButton } from '../../Button/ActionButton';
import suspenseWidget from '../../SuspenseWidget';
import { createStyles, makeStyles } from '../../Theme';
import { GoogleIcon } from '../../svg/GoogleIcon';
import { LcLogoIcon } from '../../svg/LcLogoIcon';

const PADDING_TOP = 'max(15vh, 116px)';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      fontSize: '14px',
      backgroundColor: colors.neutral150,
      color: colors.highEmphasisText,
      minWidth: '1024px',
      overflowY: 'auto',
    },
    left: {
      flex: '1 1 0',
      paddingTop: PADDING_TOP,
      position: 'relative',
    },
    logoLoginLink: {
      position: 'absolute',
      left: '24px',
      top: '24px',
      color: colors.mediumEmphasisText,

      '&:hover, &:active': {
        color: 'white',
      },
    },
    leftContainer: {
      width: '400px',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingBottom: '24px',
      display: 'flex',
      flexDirection: 'column',
      gap: '32px',
    },
    right: {
      width: '50%',
      paddingTop: PADDING_TOP,
      backgroundColor: colors.neutral100,
      backgroundImage: `url(${backgroundImage})`,
      backgroundPosition: '0 center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    headings: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
    title: {
      fontSize: '24px',
      lineHeight: '32px',
      margin: '0px',
    },
    loading: {
      height: `calc(100% - ${PADDING_TOP})`,
    },
    secretSection: {
      border: `1px solid ${colors.neutral350}`,
      borderRadius: '8px',
      background: colors.neutral200,
      fontWeight: 600,
      display: 'flex',
      flexDirection: 'column',
      padding: '12px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      alignSelf: 'stretch',
      textAlign: 'center',
    },
    code: {
      color: colors.neutral900,
      textAlign: 'center',

      fontSize: '16px',
      lineHeight: 1.5,
    },
    or: {
      // We don't need any height for the "OR", bc we rely on leftContainer's gap for the spacing
      height: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      color: colors.neutral650,
      fontSize: '14px',

      '&:before, &:after': {
        content: '""',
        display: 'block',
        height: '1px',
        width: '181px',
        background: colors.surfaceLight1,
        position: 'absolute',
        top: '50%',
      },

      '&:before': {
        left: '0',
      },

      '&:after': {
        right: '0',
      },
    },
    alternative: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
  }),
  { name: 'AuthPageLayout' },
);

export enum AuthPageState {
  INITIALIZING,
  INVALID_LINK,
  FORM,
  DONE,
}

// export the styles so they can be used in all the pages from the login flow
export const useAuthPageLayoutStyles = useStyles;

export interface AuthPageLayoutProps {
  // Main title for the page
  title?: ReactNode;
  // The text between the title and the form/actions
  subtitle?: ReactNode;
  // The main content. Use this ONLY if the content doesn't fit in any of the other props.
  children?: ReactNode;
  // If true, the page will have a "Go Back" button after the main content
  back?: boolean;
  // If true, the page will have a "Back to Log In" button after the main content
  backToLogin?: boolean
  // If set, there will be an alternative "Continue with X" button below the form and below the Back
  alternativeOption?: {
    label: string;
    onClick?: () => void;
    route?: string;
    isGoogle?: boolean;
    description?: React.ReactNode;
  }
  // A footer which is shown below everything.
  footer?: ReactNode;
  // Whether to include the right presentational-only section. Defaults to true.
  rightSection?: boolean;
  // Shows the suspense widget instead of any children and buttons
  loading?: boolean;
}

// This is the layout that will be used by all pages in the new login flow.
// The page is divided in half.
// The left side shows the actual page with the form and controls.
// The right side will show some generic Luminary text and images.
export const AuthPageLayout = (props: AuthPageLayoutProps) => {
  const {
    back,
    backToLogin,
    title,
    subtitle,
    alternativeOption,
    footer,
    rightSection = true,
    loading,
  } = props;

  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <Link className={classes.logoLoginLink} data-location="login-link" to={HOME_URL}>
          <LcLogoIcon maxHeight={28} />
        </Link>

        {loading ? (
          <div className={classes.loading}>{suspenseWidget}</div>
        ) : (
          <>
            <div className={classes.leftContainer}>
              <div className={classes.headings}>
                <h1 className={classes.title}>{title}</h1>
                {subtitle && <div>{subtitle}</div>}
              </div>
              {props.children}

              {back && (
                <ActionButton
                  asBlock
                  kind="alternative"
                  onClick={() => {
                    navigate(-1);
                  }}
                  type="button">
                  Go Back
                </ActionButton>
              )}

              {backToLogin && (
                <ActionButton
                  asBlock
                  kind="alternative"
                  onClick={() => navigate(routes.login, { replace: true })}
                  type="button">
                  Back to Log In
                </ActionButton>
              )}

              {alternativeOption && (
                <>
                  <div className={classes.or}>or</div>
                  <div className={classes.alternative}>
                    <ActionButton
                      asBlock
                      kind="alternative"
                      onClick={() => {
                        alternativeOption.route && navigate(alternativeOption.route);
                        alternativeOption.onClick?.();
                      }}
                      type="button">
                      {alternativeOption.isGoogle && <GoogleIcon />}
                      {alternativeOption.label}
                    </ActionButton>
                    {alternativeOption.description}
                  </div>
                </>
              )}

              {footer && (
                // Wrap the footer items in a single div as they would be separated by the 32px gap
                <div>{footer}</div>
              )}
            </div>
          </>
        )}
      </div>

      {rightSection && <div className={classes.right} />}
    </div>
  );
};
