// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

/**
 * Configure the geometry for a project.
 */
import React, { Suspense, useCallback, useEffect, useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import ProjectStateSync from '../components/RecoilSync/ProjectState';
import suspenseWidget from '../components/SuspenseWidget';
import Project from '../components/context/ProjectContext';
import { MainPageLayout } from '../components/layout/page/Main';
import { CurrentView } from '../lib/componentTypes/context';
import { geometryIdLink } from '../lib/navigation';
import * as rpc from '../lib/rpc';
import { ListenerEvent, useEventListener } from '../lib/useEventListener';
import { debounce } from '../lib/utils';
import { useGeometryList } from '../recoil/geometry/geometryListState';
import { useGeometryServerStatus } from '../recoil/geometry/geometryServerStatus';
import useProjectMetadata from '../recoil/useProjectMetadata';
import { useSetCurrentView } from '../state/internal/global/currentView';

import PageBody from './PageBody';

async function invalidOnRunSimulation() {
  throw Error('onRunSimulation: shall not be called');
}

const GeometryPage = () => {
  const params = useParams();
  const projectId = params.projectId || '';
  const geometryId = params.geometryId || '';
  const projectMetadata = useProjectMetadata(projectId);
  const setCurrentView = useSetCurrentView();
  const geometryList = useGeometryList(projectId);
  const [geometryServerStatus] = useGeometryServerStatus(geometryId);
  const navigate = useNavigate();

  const projectName = projectMetadata ?
    projectMetadata.summary!.name :
    projectId;

  useEffect(() => {
    setCurrentView(CurrentView.GEOMETRY);
  }, [setCurrentView]);

  const keepAliveDebounce = useMemo(() => debounce(() => {
    if (!geometryId) {
      return;
    }
    rpc.clientGeometry!.keepAlive({ geometryId }).catch(
      (error: Error) => console.error(`Error keeping alive ${error}`),
    );
  }, 1500), [geometryId]);
  const keepAliveDebounceCb = useCallback((_: ListenerEvent) => {
    if (geometryServerStatus !== 'disconnected') {
      keepAliveDebounce();
    }
  }, [geometryServerStatus, keepAliveDebounce]);
  useEventListener('click', keepAliveDebounceCb);
  useEventListener('keydown', keepAliveDebounceCb);
  useEventListener('keypress', keepAliveDebounceCb);
  useEventListener('scroll', keepAliveDebounceCb);

  // If there is a geometryId within the project we navigate directly to it. This is to avoid users
  // seeing more than one geometry in their project. The backend supports it but we don't want to
  // expose such a functionality to the users.
  useEffect(() => {
    if (!geometryId && geometryList?.geometries.length) {
      navigate(geometryIdLink(projectId, geometryList.geometries[0].id));
    }
  }, [geometryId, geometryList, navigate, projectId]);

  return (
    <MainPageLayout projectId={projectId} title={projectName}>
      <Suspense fallback={suspenseWidget}>
        <ProjectStateSync geometryId={geometryId} projectId={projectId}>
          <Project
            geometryId={geometryId}
            projectId={projectId}
            selectedJobId=""
            workflowId="">
            <PageBody
              isExploration={false}
              onRunSimulation={invalidOnRunSimulation}
            />
          </Project>
        </ProjectStateSync>
      </Suspense>
    </MainPageLayout>
  );
};
export default GeometryPage;
