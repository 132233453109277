// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { SimulationRowProps } from '../../../../lib/componentTypes/simulationTree';
import { getTypeIconName } from '../../../../lib/physicsUtils';
import { NodeType } from '../../../../lib/simulationTree/node';
import { useNodeRenaming } from '../../../hooks/useNodeRenaming';
import { usePhysicsNode } from '../../../hooks/usePhysicsNode';
import { IconSpec, TreeRow } from '../../TreeRow';

const PRIMARY_ICON: IconSpec = { name: getTypeIconName('heat') };

export const PhysicsHeatTreeRow = (props: SimulationRowProps) => {
  const { node } = props;
  const { getExtraContextMenuItems } = usePhysicsNode(node.id, NodeType.PHYSICS_HEAT);
  const renaming = useNodeRenaming(node);

  return (
    <TreeRow
      {...props}
      getExtraContextMenuItems={getExtraContextMenuItems}
      primaryIcon={PRIMARY_ICON}
      renaming={renaming}
    />
  );
};
