// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useState } from 'react';

import { ParamGroupName, paramGroupDesc } from '../../../../SimulationParamDescriptor';
import assert from '../../../../lib/assert';
import { useHeatPhysicsPresets } from '../../../../lib/physicsHeatControlsPresets';
import { parsePhysicsIdFromSubId } from '../../../../lib/physicsUtils';
import { useHeatPhysics } from '../../../../model/hooks/useHeatPhysics';
import * as simulationpb from '../../../../proto/client/simulation_pb';
import { useSimulationParamScope } from '../../../../state/external/project/simulation/paramScope';
import { ActionButton } from '../../../Button/ActionButton';
import { DataSelect } from '../../../Form/DataSelect';
import LabeledInput from '../../../Form/LabeledInput';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import { ParamForm } from '../../../ParamForm';
import Divider from '../../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../../Theme/commonStyles';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { AdvancedHeatPhysicsDialog } from '../../../dialog/AdvancedHeatPhysics';
import PropertiesSection from '../../PropertiesSection';

const paramAdjointControls = paramGroupDesc[ParamGroupName.AdjointControlsFluid];

export const PhysicsHeatPropPanel = () => {
  // Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected heat physics row');
  const physicsId = parsePhysicsIdFromSubId(node.id);

  // Recoil
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);

  // State
  const [advancedIsOpen, setAdvancedIsOpen] = useState(false);

  // Hooks
  const {
    physics,
    getParamScope,

    spatialDiscretizationPreset,
    setSpatialDiscretizationPreset,
    solutionControlsPreset,
    setSolutionControlsPreset,

    floatType,
    adjointSolutionControls,
    setAdjointSolutionControls,

    disablePresetsReason,
  } = useHeatPhysics(projectId, workflowId, jobId, readOnly, physicsId);
  const propClasses = useCommonTreePropsStyles();

  const {
    spatialDiscOptions,
    solnControlOptions,
  } = useHeatPhysicsPresets(spatialDiscretizationPreset, solutionControlsPreset, false);

  if (!physics || !adjointSolutionControls) {
    return null;
  }

  const physicsScope = getParamScope(paramScope);

  const showAdjoint = !!floatType && floatType === simulationpb.FloatType.ADA1D;

  return (
    <div className={propClasses.properties}>
      <PropertiesSection>
        <CollapsibleNodePanel
          headerRight={(
            <ActionButton
              asBlock
              kind="minimal"
              onClick={() => setAdvancedIsOpen(true)}
              size="small">
              {readOnly ? 'Inspect' : 'Customize'}
            </ActionButton>
          )}
          heading="Advanced Solver Settings"
          nodeId={node.id}
          panelName="advanced">
          <LabeledInput
            label="Spatial Discretization">
            <DataSelect
              asBlock
              disabled={readOnly || !!disablePresetsReason}
              onChange={setSpatialDiscretizationPreset}
              options={spatialDiscOptions}
              size="small"
              tooltip={disablePresetsReason}
            />
          </LabeledInput>
          <LabeledInput
            label="Solution Controls">
            <DataSelect
              asBlock
              disabled={readOnly || !!disablePresetsReason}
              onChange={setSolutionControlsPreset}
              options={solnControlOptions}
              size="small"
              tooltip={disablePresetsReason}
            />
          </LabeledInput>
        </CollapsibleNodePanel>
        <AdvancedHeatPhysicsDialog
          onClose={() => setAdvancedIsOpen(false)}
          open={advancedIsOpen}
          physicsId={physicsId}
        />
      </PropertiesSection>
      {showAdjoint && (
        <>
          <Divider />
          <PropertiesSection>
            <CollapsibleNodePanel
              heading="Adjoint Solver Settings"
              nodeId={node.id}
              panelName="advanced_adjoint">
              <ParamForm<simulationpb.AdjointControlsHeat>
                group={paramAdjointControls}
                onUpdate={setAdjointSolutionControls}
                paramScope={physicsScope}
                proto={adjointSolutionControls}
                readOnly={readOnly}
              />
            </CollapsibleNodePanel>
          </PropertiesSection>
        </>
      )}
    </div>
  );
};
