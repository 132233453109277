// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useState } from 'react';

import { isGeometryTab } from '../../lib/navigation';
import { useIsGeometryPending } from '../../recoil/pendingWorkOrders';
import { ActionButton } from '../Button/ActionButton';

import { Preset } from './MeshImportDialog';
import { MeshImportDialogCommon } from './MeshImportDialogCommon';

export interface MeshUploadControlProps {
  // Project ID
  projectId: string;
  // Set to true to disable the control
  disabled: boolean;
  // An optional callback for when the dialog is opened or closed
  onToggleDialog?: (open: boolean) => void;
  preset?: Preset;
}

const MeshUploadControl = (props: MeshUploadControlProps) => {
  const { onToggleDialog, preset, projectId, disabled } = props;

  // We need to use the window.location.pathname here and match it against the geometry url, rather
  // than using useCurrentView, because currentView is not immediately updated when the user
  // switches from the geometry view to the setup view.
  const isGeometryView = isGeometryTab();
  const isGeometryPending = useIsGeometryPending(projectId);
  const defaultOpenState = isGeometryView || isGeometryPending;

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(defaultOpenState);
  const [filePresets, setFilePresets] = useState<Preset | undefined>(preset);

  useEffect(() => {
    setFilePresets(preset);
    if (preset) {
      setIsDialogOpen(true);
    }
  }, [preset]);

  useEffect(() => {
    onToggleDialog?.(isDialogOpen);
  }, [isDialogOpen, onToggleDialog]);

  return (
    <>
      <MeshImportDialogCommon
        onClose={() => setIsDialogOpen(false)}
        open={isDialogOpen}
        preset={filePresets}
      />
      <ActionButton
        disabled={disabled || isDialogOpen}
        kind="primary"
        name="pmucButton"
        onClick={() => {
          setFilePresets(undefined);
          setIsDialogOpen(true);
        }}>
        Browse files
      </ActionButton>
    </>
  );
};

export default MeshUploadControl;
