// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import * as flags from '../../../../../flags';
import { newProto, rotate } from '../../../../../lib/Vector';
import { vec3ToAdVec3 } from '../../../../../lib/adUtils';
import { isOversetBoundaryCondition } from '../../../../../lib/boundaryConditionUtils';
import { reconcileMeshingSettings } from '../../../../../lib/mesh';
import {
  extractCoordinatesFromTransforms,
  findBodyFrame,
} from '../../../../../lib/motionDataUtils';
import { getFluid } from '../../../../../lib/physicsUtils';
import { resetRegionIds } from '../../../../../lib/refinementRegionUtils';
import * as rpc from '../../../../../lib/rpc';
import * as frontendpb from '../../../../../proto/frontend/frontend_pb';
import * as meshgenerationpb from '../../../../../proto/meshgeneration/meshgeneration_pb';
import { useFrontendMenuState } from '../../../../../recoil/frontendMenuState';
import { useMeshUrlState } from '../../../../../recoil/meshState';
import { useSetPendingWorkOrders } from '../../../../../recoil/pendingWorkOrders';
import { useSelectedGeometry } from '../../../../../recoil/selectedGeometry';
import { useCadMetadata } from '../../../../../recoil/useCadMetadata';
import { useCadModifier } from '../../../../../recoil/useCadModifier';
import { useEnabledExperiments } from '../../../../../recoil/useExperimentConfig';
import useMeshGeneration from '../../../../../recoil/useMeshGeneration';
import { useMeshName } from '../../../../../recoil/useMeshName';
import { useMeshReadOnly } from '../../../../../recoil/useMeshReadOnly';
import { useMeshSurfaces } from '../../../../../recoil/useMeshSurfaces';
import useMeshMultiPart from '../../../../../recoil/useMeshingMultiPart';
import { useSimulationParam } from '../../../../../state/external/project/simulation/param';
import { pushConfirmation, useSetConfirmations } from '../../../../../state/internal/dialog/confirmations';
import { ActionButton } from '../../../../Button/ActionButton';
import { ActionLink } from '../../../../Button/ActionLink';
import { useProjectContext } from '../../../../context/ProjectContext';
import { LoadingEllipsis } from '../../../../visual/LoadingEllipsis';

const GET_MESH = frontendpb.WorkOrderType.GET_MESH;

// The button for generating a mesh.
export const GenerateMeshButton = (props: { disabled: boolean; lmaVariant?: boolean }) => {
  // == Props
  const { disabled, lmaVariant = false } = props;

  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const [cadModifier] = useCadModifier(projectId);
  const [frontendMenuState] = useFrontendMenuState(projectId, '', '');
  const setPendingWorkOrders = useSetPendingWorkOrders(projectId);
  const [cadMetadata] = useCadMetadata(projectId);
  const meshGeneration = useMeshGeneration(projectId, '');
  const inputSettings = useMeshMultiPart(projectId, workflowId, jobId);
  const [meshUrlState] = useMeshUrlState(projectId);
  const meshSurfaces = useMeshSurfaces(projectId);
  const meshName = useMeshName(projectId);
  const meshReadOnly = useMeshReadOnly(projectId);
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const experimentConfig = useEnabledExperiments();
  const setConfirmStack = useSetConfirmations();
  const [selectedGeometry] = useSelectedGeometry(projectId);

  // == Data
  const bodyFrame = findBodyFrame(simParam);

  // TODO(LC-6917): remove when Parasolid meshing is enabled for all
  const allowParasolid = experimentConfig.includes(flags.parasolidMeshing);
  const lcsurfaceTessellation = experimentConfig.includes(flags.lcsurfaceTessellation);

  const buttonText = lmaVariant ? 'Generate Minimal Mesh' : 'Generate Mesh';
  const buttonTextGenerating = <span>Generating Mesh<LoadingEllipsis /></span>;

  const generateMesh = async () => {
    if (!inputSettings || !meshGeneration) {
      return;
    }
    const meshMultiPart = reconcileMeshingSettings(
      inputSettings,
      simParam,
      cadMetadata,
      meshSurfaces,
    );

    if (meshGeneration.addRefinement) {
      // if refinement boxes are enabled, compute body x- and y-axis from Body Frame and pass into
      // meshGeneration message
      if (bodyFrame) {
        const {
          orientation,
        } = extractCoordinatesFromTransforms(bodyFrame.frameTransforms);
        const xHat = newProto(1, 0, 0);
        const yHat = newProto(0, 1, 0);
        meshGeneration.bodyXAxis = vec3ToAdVec3(rotate(orientation, xHat));
        meshGeneration.bodyYAxis = vec3ToAdVec3(rotate(orientation, yHat));
      } else {
        throw Error('Mesh refinement requires Body Frame to be defined.');
      }
    }
    // Mesh generation needs information about the overset boundary conditions.
    // Copy that information from the boundary conditions into the mesh generation params.
    meshMultiPart.oversetParams = [];
    simParam.physics.forEach((physics) => {
      getFluid(physics)?.boundaryConditionsFluid.forEach((boundCond) => {
        if (isOversetBoundaryCondition(boundCond)) {
          meshMultiPart.oversetParams.push(
            (new meshgenerationpb.MeshingMultiPart_OversetParams({
              surfaces: boundCond.surfaces,
            })),
          );
        }
      });
    });

    const workOrder = new frontendpb.PendingWorkOrder({
      typ: {
        case: 'getMesh',
        value: {
          projectId,
          geometryId: selectedGeometry.geometryId,
          geometryVersionId: selectedGeometry.geometryVersionId,
          userGeo: {
            url: meshUrlState.url,
            scaling: frontendMenuState.meshScaling,
            allowParasolid,
            lcsurfaceTessellation,
          },
          userGeoMod: cadModifier ?? undefined,
          meshingParams: meshGeneration,
          meshingMultiplePart: resetRegionIds(meshMultiPart),
          name: meshName,
        },
      },
    });

    const req = new frontendpb.CheckGetMeshDependenciesRequest({
      request: workOrder.typ.value as frontendpb.GetMeshRequest,
    });
    const reply = await rpc.callRetry(
      'CheckGetMeshDependencies',
      rpc.client.checkGetMeshDependencies,
      req,
    );

    const execute = () => {
      setPendingWorkOrders((pendingOrders: frontendpb.PendingWorkOrders) => {
        const newPendingOrders = pendingOrders.clone();
        const workOrdersMap = newPendingOrders.workOrders;

        if (workOrdersMap[GET_MESH]) {
          return newPendingOrders;
        }
        workOrdersMap[GET_MESH] = workOrder;
        return newPendingOrders;
      });
    };

    if (!reply.allFound) {
      const warnMsg = (
        <div>
          Generating this mesh may corrupt your project state. If this project was
          created or copied from a project created after May 16, 2024, you can
          ignore this message. As a workaround, please create a new project (not a
          copy), upload the CAD file, and generate new meshes.
          <br /> <br />
          We are actively working to address this issue. Please reach out to
          <ActionLink
            href="mailto:support@luminarycloud.com">support@luminarycloud.com
          </ActionLink> for assistance.
          <br /> <br />
          Are you sure you want to
          continue?
        </div>
      );
      pushConfirmation(setConfirmStack, {
        continueLabel: 'Generate Mesh',
        destructive: true,
        onContinue: () => {
          execute();
        },
        children: warnMsg,
        title: 'Warning',
      });
    } else {
      execute();
    }
  };
  return (
    <ActionButton asBlock disabled={disabled} onClick={generateMesh} size="small">
      {meshReadOnly ? buttonTextGenerating : buttonText}
    </ActionButton>
  );
};
