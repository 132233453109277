// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement } from 'react';

import * as ProtoDescriptor from '../../../ProtoDescriptor';
import { ParamGroupName, ParamName, paramDesc, paramGroupDesc } from '../../../SimulationParamDescriptor';
import { referenceValues } from '../../../flags';
import { findFarfield } from '../../../lib/boundaryConditionUtils';
import { FaultInfo } from '../../../lib/inputValidationUtils';
import { setParamValue } from '../../../lib/paramCallback';
import { DEFAULT_OUTPUT_NODES } from '../../../lib/paramDefaults/outputNodesState';
import {
  getReferenceArea,
  getReferenceLength,
  getReferencePressure,
  getReferenceTemperature,
  getReferenceVelocity,
  validReferenceValueSelection,
} from '../../../lib/referenceValueUtils';
import { getBoundaryCondName } from '../../../lib/simulationTree/utils';
import { AdFloatType } from '../../../proto/base/base_pb';
import * as simulationpb from '../../../proto/client/simulation_pb';
import { OutputNodes } from '../../../proto/frontend/output/output_pb';
import { ReferenceValueType } from '../../../proto/output/reference_values_pb';
import { QuantityType } from '../../../proto/quantity/quantity_pb';
import { useGeometryTags } from '../../../recoil/geometry/geometryTagsState';
import { useOutputNodes } from '../../../recoil/outputNodes';
import { useEnabledExperiments } from '../../../recoil/useExperimentConfig';
import { useMeshReadyState } from '../../../recoil/useMeshReadyState';
import { useStaticVolumes } from '../../../recoil/volumes';
import { useSimulationBoundaryNames } from '../../../state/external/project/simulation/param/boundaryNames';
import { useSimulationParamScope } from '../../../state/external/project/simulation/paramScope';
import { IconButton } from '../../Button/IconButton';
import Form from '../../Form';
import { DataSelect } from '../../Form/DataSelect';
import LabeledInput from '../../Form/LabeledInput';
import { ValidAdNumberInput } from '../../Form/ValidatedInputs/ValidNumberInput';
import { CollapsibleNodePanel } from '../../Panel/CollapsibleNodePanel';
import { ParamForm } from '../../ParamForm';
import QuantityAdornment from '../../QuantityAdornment';
import Tooltip from '../../Tooltip';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { useSimulationConfig } from '../../hooks/useSimulationConfig';
import { SectionMessage } from '../../notification/SectionMessage';
import { ResetIcon } from '../../svg/ResetIcon';
import NodeLink from '../NodeLink';
import PropertiesSection from '../PropertiesSection';

const {
  REFERENCE_PRESCRIBE_VALUES,
  REFERENCE_FARFIELD_VALUES,
} = ReferenceValueType;

const InvalidSelectionMessage = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      justifyContent: 'center',
      marginTop: '10px',
    }}>
    <SectionMessage level="warning" message="No Far-field boundary found." />
  </div>
);

const RefTypeNodeLink = (
  { outputNodes, param }: { outputNodes: OutputNodes, param: simulationpb.SimulationParam },
) => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const bcNames = useSimulationBoundaryNames(projectId, workflowId, jobId);

  switch (outputNodes.referenceValues?.referenceValueType) {
    case REFERENCE_FARFIELD_VALUES: {
      const farfield = findFarfield(param);
      if (farfield) {
        return (
          <LabeledInput label="">
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <NodeLink
                asBlock
                nodeIds={[farfield.boundaryConditionName]}
                text={getBoundaryCondName(bcNames, farfield)}
              />
            </div>
          </LabeledInput>
        );
      }
      break;
    }
    default: // None
  }
  return <></>;
};

// A panel for showing the reference values for a simulation under the Outputs node.
export const ReferenceValuePropPanel = () => {
  // == Contexts
  const { selectedNode } = useSelectionContext();
  const { projectId, workflowId, jobId, readOnly: disable } = useProjectContext();

  // == Hooks
  const { simParam, saveParam } = useSimulationConfig();

  // == Recoil
  const experimentConfig = useEnabledExperiments();
  const meshReadyState = useMeshReadyState(projectId, workflowId, jobId);
  const [outputNodes, setOutputNodes] = useOutputNodes(projectId, workflowId, jobId);
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId);
  const staticVolumes = useStaticVolumes(projectId);

  // == Data
  const validSelection = validReferenceValueSelection(outputNodes, simParam);
  const refValType = outputNodes.referenceValues?.referenceValueType;

  if (!selectedNode) {
    throw Error('Outputs Prop Panel should only display when a node is selected.');
  }
  const createRefValsSelection = (): ReactElement => (
    <LabeledInput
      help="Sync reference values with far field or prescribe custom values."
      key="available-values"
      label="Available Values">
      <DataSelect
        asBlock
        disabled={!meshReadyState}
        faultType={!validSelection ? 'warning' : undefined}
        onChange={(value) => {
          setOutputNodes((oldNodes) => {
            const newNodes = oldNodes.clone();
            newNodes.referenceValues!.referenceValueType = value;
            return newNodes;
          });
        }}
        options={[
          {
            options: [{
              name: 'Far-field',
              value: REFERENCE_FARFIELD_VALUES,
              selected: refValType === REFERENCE_FARFIELD_VALUES,
              disabled: !findFarfield(simParam),
              disabledReason: 'This setup does not have a Far-field boundary.',
            }],
          },
          {
            name: 'Custom',
            value: REFERENCE_PRESCRIBE_VALUES,
            selected: refValType === REFERENCE_PRESCRIBE_VALUES,
          },
        ]}
        size="small"
      />
    </LabeledInput>
  );

  const getReferenceValue = (descriptor: ProtoDescriptor.RealParam): {
    value: AdFloatType,
    readOnly: boolean
  } => {
    switch (descriptor.pascalCaseName) {
      case 'AreaRef': return getReferenceArea(outputNodes);
      case 'LengthRef': return getReferenceLength(outputNodes);
      case 'PRef': return getReferencePressure(outputNodes, simParam);
      case 'TRef': return getReferenceTemperature(outputNodes, simParam);
      case 'VRef': return getReferenceVelocity(outputNodes, simParam, geometryTags, staticVolumes);
      default: throw Error('Unrecognized reference value param description');
    }
  };

  const resetButton = (
    <Tooltip title="Reset custom values to default">
      <span>
        <IconButton
          aria-label="reset"
          disabled={!meshReadyState || refValType !== REFERENCE_PRESCRIBE_VALUES}
          onClick={() => setOutputNodes((oldNodes) => {
            const newNodes = DEFAULT_OUTPUT_NODES.clone();
            newNodes.nodes = oldNodes.nodes;
            return newNodes;
          })}>
          <ResetIcon maxHeight={13} />
        </IconButton>
      </span>
    </Tooltip>
  );

  const createRefValueInput = (
    descriptor: ProtoDescriptor.RealParam,
  ) => {
    const { value, readOnly } = getReferenceValue(descriptor);
    const validate = (newVal: number) => {
      const error = ProtoDescriptor.checkBounds(
        { openBound: true, minVal: 0, ...descriptor },
        newVal,
      );
      if (error) {
        return { type: 'error', message: error } as FaultInfo;
      }
      return undefined;
    };
    return (
      <Form.LabeledInput help={descriptor.help} label={descriptor.text}>
        <ValidAdNumberInput
          asBlock
          disabled={!meshReadyState || readOnly}
          endAdornment={<QuantityAdornment quantity={descriptor.quantityType} />}
          name={descriptor.pascalCaseName}
          onCommit={(val) => setOutputNodes((oldNodes) => {
            const newNodes = oldNodes.clone();
            if (newNodes.referenceValues) {
              setParamValue(newNodes.referenceValues, descriptor, val);
            }
            return newNodes;
          })}
          size="small"
          validate={validate}
          value={value}
        />
      </Form.LabeledInput>
    );
  };
  const paramGroup = paramGroupDesc[ParamGroupName.ReferenceValues];
  return (
    <PropertiesSection key={paramGroup.text}>
      {/* Use the new way of storing reference values if the message is defined (it will defined
       for all projects that are created after the message was added). For old projects use the
        reference values in the params. */ }
      {experimentConfig.includes(referenceValues) && outputNodes.useRefValues ? (
        <CollapsibleNodePanel
          headerRight={resetButton}
          heading="Reference Values For Coefficients"
          help={'Used exclusively for non dimensional outputs, these values do not interact with ' +
          'physics inputs such as boundary conditions).'}
          nodeId={selectedNode.id}
          panelName="reference_values">
          {createRefValueInput({
            type: ProtoDescriptor.ParamType.REAL,
            camelCaseName: 'areaRef',
            pascalCaseName: 'AreaRef',
            help: paramDesc[ParamName.AreaRef].help,
            text: paramDesc[ParamName.AreaRef].text,
            name: 'area',
            quantityType: QuantityType.AREA,
            parentGroups: [],
          })}
          {createRefValueInput({
            type: ProtoDescriptor.ParamType.REAL,
            camelCaseName: 'lengthRef',
            pascalCaseName: 'LengthRef',
            help: paramDesc[ParamName.LengthRef].help,
            text: paramDesc[ParamName.LengthRef].text,
            name: 'length',
            quantityType: QuantityType.LENGTH,
            parentGroups: [],
          })}
          {createRefValsSelection()}
          {validSelection && (
            <>
              <RefTypeNodeLink outputNodes={outputNodes} param={simParam} />
              {createRefValueInput({
                type: ProtoDescriptor.ParamType.REAL,
                camelCaseName: 'pRef',
                pascalCaseName: 'PRef',
                help: paramDesc[ParamName.PRef].help,
                text: paramDesc[ParamName.PRef].text,
                name: 'pressure',
                quantityType: QuantityType.PRESSURE,
                parentGroups: [],
              })}
              {createRefValueInput({
                type: ProtoDescriptor.ParamType.REAL,
                camelCaseName: 'tRef',
                pascalCaseName: 'TRef',
                help: paramDesc[ParamName.TRef].help,
                text: paramDesc[ParamName.TRef].text,
                name: 'temperature',
                quantityType: QuantityType.TEMPERATURE,
                parentGroups: [],
              })}
              {createRefValueInput({
                type: ProtoDescriptor.ParamType.REAL,
                camelCaseName: 'vRef',
                pascalCaseName: 'VRef',
                help: paramDesc[ParamName.VRef].help,
                text: paramDesc[ParamName.VRef].text,
                name: 'velocity',
                quantityType: QuantityType.VELOCITY,
                parentGroups: [],
              })}
            </>
          )}
          {!validSelection && <InvalidSelectionMessage />}
        </CollapsibleNodePanel>
      ) : (
        <ParamForm<simulationpb.ReferenceValues>
          group={paramGroup}
          key={paramGroup.name}
          onUpdate={(newRefVals) => saveParam((newParam) => {
            newParam.referenceValues = newRefVals;
          })}
          paramScope={paramScope}
          proto={simParam.referenceValues!}
          readOnly={!meshReadyState || disable}
        />
      )}
    </PropertiesSection>
  );
};
