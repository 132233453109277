// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import * as simulationpb from '../../proto/client/simulation_pb';
import * as workflowpb from '../../proto/workflow/workflow_pb';

export enum CurrentView {
  SETUP = 0,
  ANALYSIS = 1,
  RESULTS = 2,
  GEOMETRY = 3,
}

export type ProjectContextType = {
  projectId: string;
  workflowId: string;
  jobId: string;
  readOnly: boolean;
  geometryId: string;
  // Called to update the simulation parameters.
  onParamUpdate: (newParam: simulationpb.SimulationParam) => void;
  asyncOnParamUpdate: (newParam: simulationpb.SimulationParam) => Promise<void>;
  // Called to update the workflow config.
  onNewWorkflowConfig: (newConfig: workflowpb.Config) => void;
  copyToSetup: () => Promise<void>;
};
