// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { LCVError, LCVSelectionType } from '@luminarycloudinternal/lcvis';
import { SetterOrUpdater, useRecoilCallback } from 'recoil';

import GroupMap from '../../lib/GroupMap';
import { EntityGroupMap } from '../../lib/entityGroupMap';
import { applyFullVisibilityMap } from '../../lib/lcvis/api';
import { LcvDisplay } from '../../lib/lcvis/classes/LcvDisplay';
import { LcvisMeasureCallback } from '../../lib/lcvis/classes/widgets/LcvMeasureWidget';
import { LcvisProbeCallback } from '../../lib/lcvis/classes/widgets/LcvProbeWidget';
import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { mountLcVisListeners } from '../../lib/lcvis/handler/handlerUtils';
import { LcvisCameraStateType } from '../../lib/lcvis/types';
import { NodeTableType } from '../../lib/nodeTableUtil';
import { actuatorDiskParamFromParticleGroup, getProbePoints } from '../../lib/particleGroupUtils';
import { RecoilProjectKey } from '../../lib/persist';
import { SelectionAction, VOLUME_TABLES, allowedSelection } from '../../lib/selectionUtils';
import { simAnnotationMonitorPoint } from '../../lib/simAnnotationUtils';
import { debounce } from '../../lib/utils';
import { EditState, applyVisibilityToNode } from '../../lib/visUtils';
import * as simulationpb from '../../proto/client/simulation_pb';
import { UrlType } from '../../proto/projectstate/projectstate_pb';
import { ActuatorDiskParam, TreeNode } from '../../pvproto/ParaviewRpc';
import { useEntityGroupMap } from '../../recoil/entityGroupState';
import { useAllowEventListener } from '../../recoil/eventListenerState/useAllowEventListener';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useSetLcvisCameraState } from '../../recoil/lcvis/lcvisCameraState';
import { useUpdateClipHide } from '../../recoil/lcvis/lcvisClipHide';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useSetLcvisFilterStatus } from '../../recoil/lcvis/lcvisFilterStatus';
import { useHoverInVisCallback } from '../../recoil/lcvis/lcvisHoveredId';
import { lcvisMeasureState } from '../../recoil/lcvis/lcvisMeasureState';
import { useSetLcvisMenuSettings } from '../../recoil/lcvis/lcvisMenuSettings';
import { lcvisProbeState, useLcvisProbeValue } from '../../recoil/lcvis/lcvisProbeState';
import { useLcVisReadyValue } from '../../recoil/lcvis/lcvisReadyState';
import { useApplyInitialLCVisSettings } from '../../recoil/lcvis/lcvisSettings';
import { useLcvisVisibilityMapValue } from '../../recoil/lcvis/lcvisVisibilityMap';
import { useResetTransparency } from '../../recoil/lcvis/transparencySettings';
import { useRemoveOverlays } from '../../recoil/lcvis/useRemoveOverlays';
import { useMeshUrlState } from '../../recoil/meshState';
import { useEditState, useEditStateValue } from '../../recoil/paraviewState';
import { useIsGeometryPending } from '../../recoil/pendingWorkOrders';
import { useEntitySelectionValue } from '../../recoil/selectionOptions';
import { useSetSelectedVisualizerEntities, useSimulationTreeSubselect } from '../../recoil/simulationTreeSubselect';
import { useRefinementRegionsLcvEffect } from '../../recoil/useRefinementRegions';
import { useActiveVisUrlValue } from '../../recoil/vis/activeVisUrl';
import { useBackgroundColorLcvEffect } from '../../recoil/vis/backgroundColor';
import { useFilterState, useFilterStateValue } from '../../recoil/vis/filterState';
import { useStaticVolumes } from '../../recoil/volumes';
import { useSimulationParam } from '../../state/external/project/simulation/param';
import { useIsGeometryView } from '../../state/internal/global/currentView';
import { useAutoSelectSurfacesValue } from '../../state/internal/tree/autoSelectSurfaces';
import { useProjectContext } from '../context/ProjectContext';
import { ProvidedSelectionContext, useSelectionContext } from '../context/SelectionManager';
import { useSubselectControl } from '../treePanel/NodeSubselect/control';

export interface LcVisEventHandlerProps {
  className: string,
}

// The interval of time in milliseconds in which two clicks are considered a
// double click.
const DOUBLE_CLICK_INTERVAL = 250;

/**
 * Add a selection callback to the lcvis display. When the user clicks a surface, that surface
 * will be selected. If the user double clicks the surface, its parent group is selected.
 * The selection callback also handles selecting surfaces when they're
 * selected by the box select widget.
 * This hook should only be called by LcVisEventHandler.
 */
const useSelectionCallback = (
  lcvisReady: boolean,
  divRef: React.RefObject<HTMLDivElement | null>,
  entityGroupMap: EntityGroupMap,
  selectionContext: ProvidedSelectionContext,
) => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();
  const {
    selectedNode,
    setScrollTo,
    activeNodeTable,
    modifySelection,
    setNodeTableWarning,
    isTreeModal,
  } = selectionContext;

  // == Recoil
  const staticVolumes = useStaticVolumes(projectId);
  const treeSubselect = useSimulationTreeSubselect();
  const setSelectedVisualizerEntities = useSetSelectedVisualizerEntities();
  const entitySelectionType = useEntitySelectionValue(projectId);
  const autoSelectSurfaces = useAutoSelectSurfacesValue(projectId);
  const { endSubselect } = useSubselectControl();
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const geometryTags = useGeometryTags(projectId);

  // Use these 2 variables to determine if the user double clicked on a surface.
  const prevSurface = useRef('');
  const lastClickedTime = useRef(0);
  const editState = useEditStateValue();
  const isEditing = editState !== null;

  /** If the user double clicked on a surface, update newSelection to select its parent group. */
  const maybeHandleDoubleClick = useCallback((newSelection: string[]) => {
    const now = Date.now();
    const initialSelection = newSelection[0];

    const doubleClicked = (
      !treeSubselect.active &&
      newSelection.length === 1 &&
      (!prevSurface.current || prevSurface.current === newSelection[0]) &&
      now - lastClickedTime.current < DOUBLE_CLICK_INTERVAL
    );
    // if a user clicks twice on the same surface within DOUBLE_CLICK_INTERVAL, select its parent.
    if (doubleClicked) {
      if (!entityGroupMap.has(newSelection[0])) {
        return false;
      }
      const current = entityGroupMap.get(newSelection[0]);
      const parentId = current.parentId;
      if (parentId && !GroupMap.isRoot(parentId)) {
        newSelection[0] = parentId;
      }
      prevSurface.current = parentId ?? '';
    } else {
      prevSurface.current = initialSelection;
    }
    lastClickedTime.current = now;
    return doubleClicked;
  }, [entityGroupMap, treeSubselect.active]);

  /** The callback to be invoked whenever the LCVis selection widget's selection changes. */
  const selectionCallback = useCallback((
    selection: number[],
    display: LcvDisplay,
    // a message returned by the selection callback. This includes the modifier keys which were
    // pressed when the selection event was triggered, e.g. 'selection control'
    ctrlKey: boolean,
    selectionType: LCVSelectionType,
  ) => {
    const { workspace, simAnnotationHandler } = display;
    if (!workspace || !simAnnotationHandler || isEditing) {
      return;
    }
    // The selection callback gives us an array of object and primtivie index pairs.
    // The object ID identifies the API object (a workspace, monitor point, etc.) and
    // the primitive ID optionally identifies the item within that object (e.g, surface ID).
    // Right now we just filter the selection down to the surface IDs for the workspace,
    // but later we can handle selecting other objects too
    const newSelection: string[] = [];
    for (let i = 0; i < selection.length / 2; i += 1) {
      const objectId = selection[i * 2];
      const primitiveIndex = selection[i * 2 + 1];
      if (workspace.hasFilter(objectId)) {
        newSelection.push(workspace.getIdFromIndex(objectId, primitiveIndex));
      } else if (simAnnotationHandler.hasObjectId(objectId)) {
        newSelection.push(simAnnotationHandler.getIdFromSelection(objectId, primitiveIndex));
      }
    }
    // Use the workspace indexNameMap to convert to surface ids.
    // detect if the user double clicked a surface. If they did, this mutates newSelection.
    const doubleClicked = maybeHandleDoubleClick(newSelection);
    if (newSelection.length === 1) {
      if (VOLUME_TABLES.includes(activeNodeTable.type)) {
        // VOLUME_TABLES expect volumes. Convert from the surface to its volume.
        const staticVolume = staticVolumes.find((item) => item.bounds.has(newSelection[0]));
        newSelection[0] = staticVolume?.id ?? '';
      }
      if (!treeSubselect.active) {
        const warning = allowedSelection(
          newSelection[0],
          selectedNode?.id,
          activeNodeTable.type,
          simParam,
          entityGroupMap,
          geometryTags,
          staticVolumes,
          autoSelectSurfaces,
        );
        if (warning) {
          setNodeTableWarning(warning);
          return;
        }
      }
    }
    // If the user clicked on empty space in the viewer, blur the active element.
    if (!newSelection.length && document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    if (treeSubselect.active) {
      // If we are in NodeSubselect selection mode and we click on the empty space in the viewer,
      // we should cancel the NodeSubselect's selection and restore the previously opened node.
      if (!selection.length) {
        endSubselect(treeSubselect.referenceNodeIds);
      } else {
        setSelectedVisualizerEntities({ ids: newSelection });
      }
    } else {
      const hasActiveNodeTable = activeNodeTable.type !== NodeTableType.NONE;
      // If we are in NodeTable selection mode and we click on the empty space in the viewer,
      // we should cancel the NodeTable's selection.
      if (hasActiveNodeTable && !selection.length) {
        modifySelection({
          action: SelectionAction.HIGHLIGHT_CURRENT,
          nodeTableOverride: { type: NodeTableType.NONE },
          updateActiveNodeTable: true,
        });
      } else {
        // default selection action is to overwrite.
        let action = SelectionAction.OVERWRITE;
        if (hasActiveNodeTable) {
          if (doubleClicked) {
            // if the user double-clicked, we don't want to toggle off the previous selection (a
            // single surface), since that is still part of the new group we select.
            action = SelectionAction.ADD;
          } else {
            action = SelectionAction.TOGGLE;
          }
        } else if (ctrlKey) {
          action = SelectionAction.TOGGLE;
        }

        modifySelection({
          action,
          modificationIds: newSelection,
          nodeTableOverride: activeNodeTable,
          selectionSource: 'vis',
        });
        requestAnimationFrame(() => {
          // if the entitySelectionType is 'volume', then modifySelection will select the volume
          // instead of the surface. So we should scroll to that instead.
          if (entitySelectionType === 'volume' && !isTreeModal) {
            const volumeToSelect = staticVolumes.find(
              (volume) => volume.bounds.has(newSelection[0]),
            );
            if (volumeToSelect) {
              setScrollTo({ node: volumeToSelect.id, fast: true });
            }
          } else {
            setScrollTo({ node: newSelection[0], fast: true });
          }
        });
      }
    }
  }, [
    activeNodeTable,
    autoSelectSurfaces,
    endSubselect,
    geometryTags,
    entityGroupMap,
    maybeHandleDoubleClick,
    modifySelection,
    selectedNode,
    setNodeTableWarning,
    setScrollTo,
    setSelectedVisualizerEntities,
    simParam,
    staticVolumes,
    treeSubselect.active,
    treeSubselect.referenceNodeIds,
    isEditing,
    entitySelectionType,
    isTreeModal,
  ]);

  // Replace the selection callback whenever its dependencies change to avoid stale closures.
  useEffect(() => {
    if (!divRef.current || !lcvisReady) {
      return;
    }
    const setSelectionCallback = (display: LcvDisplay) => {
      const selectionWidget = display.widgets.selectionWidget;
      const workspace = display.workspace;
      if (!selectionWidget || !workspace) {
        return;
      }
      selectionWidget.setSelectionCallback(
        (selection: number[], controlKey: boolean, selectionType: LCVSelectionType) => {
          selectionCallback(selection, display, controlKey, selectionType);
        },
      );
    };
    lcvHandler.queueDisplayFunction('set selection callback', setSelectionCallback);
  }, [lcvisReady, selectionCallback, divRef]);
};

/**
 * Attach a callback to the camera. When the arcball camera position changes, update it in recoil.
 * We can't move this solely to recoil because it depends on the canvas as well. When the canvas
 * changes, we need to add the callback again.
 */
const useCameraCallback = (
  setCameraState: SetterOrUpdater<LcvisCameraStateType>,
) => {
  useEffect(() => {
    lcvHandler.queueDisplayFunction(
      'addLcvisCameraCallback',
      (display) => {
        display.widgets.arcballWidget?.setCallback(
          debounce((newVal: LcvisCameraStateType) => {
            setCameraState(newVal);
          }, 100),
        );
      },
    );
  }, [setCameraState]);
};

/**
 * Attaches a callback to the lcvis probe widget to change the probe recoil state when it updates.
 * */
const useProbeCallback = () => {
  const probeCallback: LcvisProbeCallback = useRecoilCallback(({ set }) => (
    probeCoords: [number, number, number],
    objectId: string,
    message?: string,
  ) => {
    // If the callback was called as a result of a mouse release, place a probe point.
    const mouseRelease = message?.includes('mouse_release');
    set(
      lcvisProbeState,
      ((prevState) => {
        // Only if we haven't yet placed the probe point, or, if we've placed the probe point and
        // clicked somewhere new, update the recoil state.
        if (!prevState.placedProbe || mouseRelease) {
          return ({
            ...prevState,
            coordinates: probeCoords,
            probedId: objectId,
            // place the probe point if a mouse release triggered the probe update and
            // a valid object was probed.
            placedProbe: !!mouseRelease && !!objectId,
          });
        }
        return prevState;
      }),
    );
  }, []);

  useEffect(() => {
    lcvHandler.queueDisplayFunction('set probe callback', (display) => {
      display.widgets.probeWidget?.setCallback(probeCallback, display);
    });
  }, [probeCallback]);
};

/**
 * Attaches a callback to the lcvis measure widget to change the recoil state when it updates.
 * */
const useMeasureCallback = () => {
  const measureCallback: LcvisMeasureCallback = useRecoilCallback(({ set }) => (
    length: number,
    mousePos: [number, number],
    pointToUpdate: number,
    startPoint: [number, number, number],
    endPoint: [number, number, number],
  ) => {
    set(
      lcvisMeasureState,
      ((prevState) => {
        // Only if we haven't yet placed the probe point, or, if we've placed the probe point and
        // clicked somewhere new, update the recoil state.
        if (prevState.length !== length || prevState.pointToUdpdate !== pointToUpdate) {
          return ({
            ...prevState,
            length,
            pointToUdpdate: pointToUpdate,
            mousePos,
            startPoint,
            endPoint,
          });
        }
        return prevState;
      }),
    );
  }, []);

  useEffect(() => {
    lcvHandler.queueDisplayFunction('set measure callback', (display) => {
      display.widgets.measureWidget?.setCallback(measureCallback, display);
    });
  }, [measureCallback]);
};

/** When the effect handler mounts, apply the initial filter state to LCVis. */
const useInitializeFilterState = (
  lcvisReady: boolean,
  filterState: TreeNode,
  setFilterState: SetterOrUpdater<TreeNode>,
) => {
  const initialFilterState = useRef(filterState);
  useEffect(() => {
    if (lcvisReady) {
      lcvHandler.display?.filterHandler?.applyInitialState(initialFilterState.current);
      const maybeUpdatedState = lcvHandler.display?.filterHandler?.maybeUpdateVisibility(
        initialFilterState.current,
      );
      // set the filter state to the result of calling maybeUpdateVisibility, since
      // lcvis may only set a subset of the nodes to actually be visible.
      setFilterState((prevState) => maybeUpdatedState ?? prevState);
    }
  }, [lcvisReady, setFilterState]);
};

/** Sync the recoil editState with the LCVis edit state and visible filter widgets. */
const useSyncEditState = (
  editState: EditState | null,
  setEditState: SetterOrUpdater<EditState | null>,
  lcvisReady: boolean,
  filterState: TreeNode,
  setFilterState: SetterOrUpdater<TreeNode>,
  isMesh: boolean,
) => {
  const prevEditState = useRef(editState);
  // Whenever the editState changes, update the filterHandler.
  useEffect(() => {
    if (lcvisReady) {
      if (prevEditState.current === null && editState !== null) {
        // if we're here, we just started editing a filter,
        // so we should make that filter's parent visible in the tree.
        const newRoot = applyVisibilityToNode(
          filterState,
          editState.nodeId,
          ['Clip', 'Slice'].includes(editState.param.typ),
          true,
          isMesh,
        );
        // Update the filterState in both LCVis and Recoil.
        const newState = lcvHandler.display?.filterHandler?.maybeUpdateVisibility(newRoot);
        setFilterState((prevState) => newState ?? prevState);
      }
      // update the editState in LCVis whenever the recoil editState changes.
      lcvHandler.display?.filterHandler?.updateEdit(editState, setEditState);
    }
    prevEditState.current = editState;
  }, [editState, setEditState, lcvisReady, filterState, setFilterState, isMesh]);
};

/**
 * When the simulation annotations (e.g. points, disks) from the simulation param change,
 * update LCVis.
 */
const useSimulationAnnotations = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const simParam = useSimulationParam(projectId, workflowId, jobId);

  const actuatorDisks = useMemo(() => {
    const diskList: ActuatorDiskParam[] = [];
    const particleGroups = simParam.particleGroup;
    // Actuator disks are listed individually, so loop through
    // the particle groups and add them as we find them.
    particleGroups.forEach((param: simulationpb.ParticleGroup) => {
      if (param.particleGroupType === simulationpb.ParticleGroupType.ACTUATOR_DISK) {
        const annotationParam = actuatorDiskParamFromParticleGroup(param);
        diskList.push(annotationParam);
      }
    });
    return diskList;
  }, [simParam]);

  const monitorPoints = useMemo(() => {
    const probePoints = getProbePoints(simParam);
    return probePoints.map((point) => simAnnotationMonitorPoint(point));
  }, [simParam]);

  useEffect(() => {
    lcvHandler.queueDisplayFunction('update sim annotations', (display) => {
      display.simAnnotationHandler?.updateAnnotations(monitorPoints, actuatorDisks);
    });
  }, [actuatorDisks, monitorPoints]);
};

/** Add KeyboardEvent and MouseEvent listeners which forward the events to LCVis. */
const useLCVisEventListeners = (divRef: React.RefObject<HTMLDivElement | null>) => {
  const lcvisReady = useLcVisReadyValue();

  // ignore keyboard events if an input or modal is active
  const allowKeyListeners = useAllowEventListener();
  // pass a ref to the mountLcVisListeners function so that we can update the ref.current value
  // without needing to remove and add new event listeners anytime allowKeyListeners changes.
  const keyListenersRef = useRef(allowKeyListeners);

  useEffect(() => {
    keyListenersRef.current = allowKeyListeners;
  }, [allowKeyListeners]);

  // Attach event listeners.
  useEffect(() => {
    if (!divRef.current || !lcvisReady) {
      return () => { };
    }
    const listeners = mountLcVisListeners(divRef.current, keyListenersRef);
    return async () => (await listeners).remove();
  }, [lcvisReady, divRef]);
};

/**
 * Switch between showing the geometry and mesh views in LCVis.
 */
const useSwitchViews = (key: RecoilProjectKey) => {
  const { projectId, workflowId, jobId } = key;
  const lcvisReady = useLcVisReadyValue();
  const [meshUrlState] = useMeshUrlState(projectId);
  const prevView = useRef(meshUrlState.activeType);
  const visibilityMap = useLcvisVisibilityMapValue({ projectId, workflowId, jobId });
  const visMap = useRef(visibilityMap);
  const activeVisUrl = useActiveVisUrlValue({ projectId, workflowId, jobId });
  const isMesh = meshUrlState.activeType === UrlType.MESH;

  useEffect(() => {
    visMap.current = visibilityMap;
  }, [visibilityMap]);

  useEffect(() => {
    if (!lcvisReady || prevView.current === meshUrlState.activeType) {
      return;
    }
    const switchViews = async () => {
      if (meshUrlState.activeType === UrlType.GEOMETRY) {
        await lcvHandler.display?.workspace?.showGeometry(activeVisUrl);
      } else {
        await lcvHandler.display?.workspace?.showMesh(activeVisUrl);
      }
      applyFullVisibilityMap(visMap.current);
      prevView.current = meshUrlState.activeType;
    };

    switchViews().catch((err) => {
      throw err;
    });
  }, [meshUrlState, lcvisReady, activeVisUrl]);

  useEffect(() => {
    if (isMesh) {
      lcvHandler.queueDisplayFunction('disable filter widgets', (display) => {
        display.filterHandler?.setDisabled(true);
      });
    } else {
      lcvHandler.queueDisplayFunction('enable filter widgets', (display) => {
        display.filterHandler?.setDisabled(false);
      });
    }
  }, [isMesh]);
};

/**
 * Whenever the workspace calls executeWorkspace, we may get inconsistency between the new surfaces
 * that are visible in LCVis and the visibility map in recoil. This hook adds a callback to the
 * workspace to reapply the visibility map whenever executeWorkspace is called.
 *
 * We use a ref to store the visibility map so that we can access the most recent visibility map
 * without needing to update the callback every time the visibility map changes.
 * */
const useApplyVisOnExecuteWorkspace = (projectId: string, workflowId: string, jobId: string) => {
  const lcvisVisibilityMap = useLcvisVisibilityMapValue({ projectId, workflowId, jobId });
  const mapRef = useRef(lcvisVisibilityMap);

  useEffect(() => {
    mapRef.current = lcvisVisibilityMap;
  }, [lcvisVisibilityMap]);

  useEffect(() => {
    lcvHandler.queueDisplayFunction('set visibility map callback', (display) => {
      display.workspace?.addOnExecuteWorkspaceCallback('reapply visibility map', () => {
        applyFullVisibilityMap(mapRef.current);
      });
    });
  }, []);
};

const useUpdateMeshUrl = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const activeUrl = useActiveVisUrlValue({ projectId, workflowId, jobId });
  const isGeometryPending = useIsGeometryPending(projectId);
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const isGeometryView = useIsGeometryView();
  const lcvisReady = useLcVisReadyValue();

  // Update the mesh url when the mesh URL may have changed, including when a a far field is added
  // or removed. It will also be run in other circustances such as before/after CheckGeometry, but
  // it will amount to no effects, since inside the workspace we check if the new meshURL is the
  // same as the old one, and if it is, we do nothing.
  useEffect(() => {
    // We are trying to avoid the WorkOrderManager in the geometry tab.
    if (isGeometryView) {
      return;
    }
    if (!isGeometryPending && lcvisEnabled && lcvisReady) {
      lcvHandler.queueDisplayFunction('update mesh url', (display) => {
        display.workspace?.updateMeshURL(activeUrl)
          .then(() => {
            display.widgets.arcballWidget?.resetCamera();
          }).catch(
            (error) => console.warn(error),
          );
      });
    }
  }, [isGeometryPending, activeUrl, lcvisEnabled, isGeometryView, lcvisReady]);
};

/**
 * When viewing a mesh, sync the analysis filters with recoil
 */
const useSyncMeshFilters = (key: RecoilProjectKey, isMesh: boolean) => {
  const filterState = useFilterStateValue(key);
  const setFilterStatus = useSetLcvisFilterStatus();
  useEffect(() => {
    if (isMesh) {
      lcvHandler.queueDisplayFunction('apply filters', (display) => {
        display.workspace?.applyFilterState(filterState, setFilterStatus).catch((err) => {
          if (err === LCVError.kLCVErrorWorkspaceCanceled) {
            // If the workspace was cancelled, we don't need to throw an error.
            return;
          }
          throw err;
        });
      });
    } else {
      lcvHandler.queueDisplayFunction('clear filters', (display) => {
        display.workspace?.applyFilterState(null, setFilterStatus).catch((err) => {
          if (err === LCVError.kLCVErrorWorkspaceCanceled) {
            // If the workspace was cancelled, we don't need to throw an error because the user
            // likely cancelled the workspace.
            return;
          }
          throw err;
        });
      });
    }
  }, [filterState, isMesh, setFilterStatus]);
};

/** An overlay which handles events and passes them to lcvis, e.g. panning, zoom, rotation */
export const LcVisEventHandler = (props: LcVisEventHandlerProps) => {
  const divRef = useRef<HTMLDivElement>(null);
  const lcvisReady = useLcVisReadyValue();
  const projectContext = useProjectContext();
  const { projectId, workflowId, jobId } = projectContext;
  const recoilKey = { projectId, workflowId, jobId };
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const setCameraState = useSetLcvisCameraState(recoilKey);
  const selectionContext = useSelectionContext();
  const setLcvisContextMenu = useSetLcvisMenuSettings();
  const removeOverlays = useRemoveOverlays(recoilKey);
  const [filterState, setFilterState] = useFilterState(recoilKey);
  const [editState, setEditState] = useEditState();
  const lcvisProbe = useLcvisProbeValue();
  const [meshUrlState] = useMeshUrlState(projectId);
  const isMesh = meshUrlState.activeType === UrlType.MESH;

  // Add mouse and keyboard event listeners to forward events to LCVis
  useLCVisEventListeners(divRef);

  // Attach resize observer.
  useEffect(() => {
    if (!divRef.current || !lcvisReady) {
      return () => { };
    }
    const onResize: ResizeObserverCallback = debounce(async (entries: ResizeObserverEntry[]) => {
      const entry = entries[0];
      if (!entry.contentRect.height || !entry.contentRect.width) {
        // firing an lcv.displayResizeEven in LCVis when the canvas has a height or width of 0
        // throws an error. So we shouldn't call the resize function unless the canvas has
        // valid dimensions.
        return;
      }
      const display = await lcvHandler.getDisplay();
      display?.resize();
    }, 50);
    const observer = new ResizeObserver(onResize);
    observer.observe(divRef.current);
    return () => observer.disconnect();
  }, [lcvisReady]);

  // Register selection callback for clicking elements in lcvis and using box select.
  useSelectionCallback(lcvisReady, divRef, entityGroupMap, selectionContext);

  // Attach a callback to the arcball camera widget so that updates in lcvis are sent to recoil.
  useCameraCallback(setCameraState);

  // Keep refinement regions in the UI in sync with the LCVis refinement region annotations
  useRefinementRegionsLcvEffect(recoilKey, lcvisReady);

  // Make sure the LCVis background color gets set.
  useBackgroundColorLcvEffect(recoilKey, lcvisReady);

  // Attach a callback to the probe widget so that updates in lcvis are sent to recoil.
  useProbeCallback();

  // Attach a callback to the measure widget so that updates in lcvis are sent to recoil.
  useMeasureCallback();

  // When we remove the lcvis canvas, deactivate any outstanding overlays.
  useEffect(() => () => {
    removeOverlays().catch((err) => {
      throw err;
    });
  }, [removeOverlays]);

  // Initialize the lcvis visualization filters.
  useInitializeFilterState(lcvisReady, filterState, setFilterState);

  // Keep the editState in LCVis in sync with the recoil editState.
  useSyncEditState(editState, setEditState, lcvisReady, filterState, setFilterState, isMesh);

  // Sync the simulation annotations from the simulation/solver param.
  useSimulationAnnotations();

  // Apply the LCVis settings from the kvstore.
  useApplyInitialLCVisSettings();

  // On mouseover, update recoil with the id of the hovered surface.
  useHoverInVisCallback();

  // Apply the visibility map whenever executeWorkspace is called.
  useApplyVisOnExecuteWorkspace(projectId, workflowId, jobId);

  // Switch between geometry and mesh views in LCVis.
  useSwitchViews(recoilKey);

  useSyncMeshFilters(recoilKey, isMesh);

  useUpdateMeshUrl();

  // Reset the transparency when the mesh or current view is switched.
  useResetTransparency();

  // Update the clipHide state on mount
  useUpdateClipHide();

  return (
    <div
      className={props.className}
      data-locator="lcvisEventHandler"
      onContextMenu={async (event) => {
        event.preventDefault();
        if (event.ctrlKey) {
          // ctrl + click with a touchpad sometimes triggers a context
          // menu. But in lcvis it pans the camera, so do nothing here if ctrl is pressed.
          return;
        }
        const display = await lcvHandler.getDisplay();
        const clickedId = display?.getHoveredId() ?? '';
        setLcvisContextMenu({
          clickedId,
          menuOpen: true,
          transform: {
            top: event.nativeEvent.offsetY,
            left: event.nativeEvent.offsetX,
          },
        });
      }}
      ref={divRef}
      role="presentation"
      style={{
        cursor: lcvisProbe.active ? 'crosshair' : 'auto',
      }}
    />
  );
};
