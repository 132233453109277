// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { AddCameraButton } from '../AddCameraButton';
import { IconSpec, TreeRow } from '../TreeRow';

const PRIMARY_ICON: IconSpec = { name: 'camera' };

export const CameraContainerTreeRow = (props: SimulationRowProps) => (
  <TreeRow
    {...props}
    addControl={<AddCameraButton />}
    primaryIcon={PRIMARY_ICON}
  />
);
