// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { ActionLink } from '../../components/Button/ActionLink';
import AuthForm from '../../components/Form/AuthForm';
import { AuthPageLayout } from '../../components/layout/page/AuthPageLayout';
import { SectionMessage } from '../../components/notification/SectionMessage';
import { Flex } from '../../components/visual/Flex';
import { handleAuthenticatedV2, setTempEmail, webAuth } from '../../lib/AuthV2';
import { authDomain } from '../../lib/RuntimeParams';
import { useHandleMfaRequired } from '../../lib/auth/useHandleMfaRequired';
import { SIGN_UP_URL } from '../../lib/constants';
import { locationOriginRoute, routes } from '../../lib/navigation';
import { Logger } from '../../lib/observability/logs';

type LoginLocationState = {
  error?: string,
  message?: string,
}

const logger = new Logger('login');

const LoginPage = () => {
  // Hooks
  const locationState = useLocation().state as LoginLocationState;
  const handleMfaRequired = useHandleMfaRequired();

  // State
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (data: Record<string, string>) => {
    setError('');
    setSubmitting(true);

    webAuth.client.login({
      username: data.email,
      password: data.password,
      realm: 'Username-Password-Authentication',
      scope: 'openid profile email address phone offline_access',
    }, async (err, jwt) => {
      if (err) {
        if (err?.code === 'mfa_required') {
          await handleMfaRequired(err);
        } else {
          logger.error(`Login for ${data.email} failed: `, err);
          setError(err?.description || err?.code || err?.name || 'Login failed');
          setSubmitting(false);
        }
      } else {
        // Todo: Force MFA for prod. LC-14776
        handleAuthenticatedV2(jwt.idToken).then(() => {
          // Do nothing. The auth subscribers will update the auth state and the hooks listening to
          // the state will do the navigation. Do not set submitting to true because there's a small
          // delay before the redirection kicks in and it's best to show the loading in the meantime
        }).catch((authError) => {
          setError(authError.message || 'Authentication failed');
          setSubmitting(false);
        });
      }
    });
  };

  useEffect(() => {
    if (locationState?.error) {
      setError(locationState.error);
    }
  }, [locationState]);

  return (
    <AuthPageLayout
      alternativeOption={{
        isGoogle: true,
        label: 'Sign In with Google',
        onClick: () => {
          webAuth.authorize({
            connection: 'google-oauth2',
            responseType: 'code',
            state: '',
            connection_scope: 'email',
            scope: 'openid profile email enroll read:authenticators offline_access',
            approvalPrompt: 'force',
            redirectUri: locationOriginRoute(routes.googleAuthCallback),
            audience: `https://${authDomain}/mfa/`,
          });
        },
      }}
      footer={(
        <Flex alignItems="center">
          Interested and want more information?&nbsp;
          <ActionLink href={SIGN_UP_URL}>Get in touch</ActionLink>.
        </Flex>
      )}
      subtitle=""
      title="Login">
      {locationState?.message && (
        <SectionMessage icon="warning" level="neutral" message={locationState.message} />
      )}
      <AuthForm
        fields={[
          {
            asBlock: true,
            autofocus: true,
            label: 'Email',
            disabled: submitting,
            name: 'email',
            placeholder: 'Enter your email',
            required: true,
          },
          {
            asBlock: true,
            label: 'Password',
            disabled: submitting,
            name: 'password',
            placeholder: 'Enter your password',
            required: true,
            type: 'password',
            helper: (
              <ActionLink href={routes.forgotPassword}>Forgot password?</ActionLink>
            ),
          },
        ]}
        globalError={error}
        onChange={(data) => {
          // Save the email, so we can reuse it in the forgotten password page if we go there and
          // for the SMS-based MFA (to avoid sending multiple messages to the same user).
          setTempEmail(data.email);
        }}
        onSubmit={handleSubmit}
        submit={{
          disabled: submitting,
          showSpinner: submitting,
          label: 'Login',
        }}
      />
    </AuthPageLayout>
  );
};

export default LoginPage;
