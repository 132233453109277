// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

// AboutDialog shows a dialog box that contains information about the backend
// server.

import React from 'react';

import { kLCVisVersion } from '@luminarycloudinternal/lcvis';

import * as RuntimeParams from '../../lib/RuntimeParams';
import { EMPTY_VALUE } from '../../lib/constants';
import { colors } from '../../lib/designSystem';
import { SESSION_ID } from '../../lib/observability/constants';
import { useExperimentConfig } from '../../recoil/useExperimentConfig';
import useServerInfo from '../../recoil/useServerInfo';
import { createStyles, makeStyles } from '../Theme';

import { Dialog } from './Base';

const useStyles = makeStyles(
  () => createStyles({
    heading: {
      fontWeight: 600,
      fontSize: '1rem',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'auto minmax(0, 1fr)',
      overflow: 'hidden',
      padding: '8px 0 0',
      gap: '16px',
    },
    field: {
      whiteSpace: 'nowrap',
      fontWeight: 600,
      fontVariant: 'small-caps',
      color: colors.highEmphasisText,
    },
    value: {
      overflowWrap: 'anywhere',
      color: colors.lowEmphasisText,
    },
  }),
  { name: 'AboutDialog' },
);

interface RowProps {
  field: string;
  value: string
}

const Row = (props: RowProps) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.field}>{props.field}</div>
      <div className={classes.value}>{props.value || EMPTY_VALUE}</div>
    </>
  );
};

interface AboutDialogProps {
  open: boolean;
  onClose: () => void;
}

export const AboutDialog = (props: AboutDialogProps) => {
  const serverInfo = useServerInfo();
  const localVersion = RuntimeParams.pageVersion;
  const experimentConfigVersion = useExperimentConfig()?.version || '';

  const classes = useStyles();

  return (
    <Dialog
      cancelButton={{ label: 'Close' }}
      modal
      onClose={props.onClose}
      open={props.open}
      title="Luminary Platform"
      width="700px">
      {!serverInfo ?
        '... reading server info' : (
          <section>
            <div className={classes.heading}>Component versions</div>
            <div className={classes.grid}>
              <Row field="git commit" value={serverInfo.jobmasterGitCommit} />
              <Row
                field="pageversion"
                value={`${localVersion} (remote: ${serverInfo.pageVersion})`}
              />
              <Row field="jobmaster" value={serverInfo.jobmasterImage} />
              <Row field="experiment config" value={experimentConfigVersion} />
              <Row field="luminary3d version" value={kLCVisVersion} />
              <Row field="session id" value={SESSION_ID} />
            </div>
          </section>
        )}
    </Dialog>
  );
};
