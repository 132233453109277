// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import { atomFamily, selectorFamily, useRecoilState, useRecoilValue, waitForAll } from 'recoil';

import { fromBigInt } from '../lib/number';
import { JobStatusType } from '../proto/base/base_pb';

import { jobsState } from './jobState';
import { workflowState } from './workflowState';

// Currently selected iteration
type IterationStatus = {
  selectedIteration: number,
  // if autoUpdate is enabled, selectedIteration will be updated
  // whenever a new solution is written
  autoUpdate: boolean
}
const DEFAULT_ITERATION_STATUS: IterationStatus = {
  selectedIteration: -1, autoUpdate: true,
};

type RecoilKey = {
  projectId: string,
  workflowId: string,
  jobId: string,
}

const iterationState = atomFamily<IterationStatus, RecoilKey>({
  key: 'iterationState',
  default: DEFAULT_ITERATION_STATUS,
});

export const selectedIterationState = selectorFamily<number, RecoilKey>({
  key: 'selectedIteration',
  get: (key: RecoilKey) => async ({ get }) => {
    const iterationStatus = get(iterationState(key));
    // If autoUpdate is enabled and the job has completed and it is steady-state,
    // return the iteration of the most recently written solution.
    // Otherwise we return the iteration of the first solution (if autoUpdate true) or
    // whatever is currently stored.
    if (iterationStatus.autoUpdate) {
      const [workflow, solution] = get(waitForAll([
        workflowState({ projectId: key.projectId, workflowId: key.workflowId }),
        jobsState(key.jobId),
      ]));
      if (!solution || !workflow) {
        return DEFAULT_ITERATION_STATUS.selectedIteration;
      }
      const completed = workflow?.status?.typ === JobStatusType.Completed;
      const solutions = solution.solutions;
      // Filter solutions that have a solution file
      if (solutions.length > 0) {
        // If the job has completed, return iteration of most recent solution
        if (completed) {
          return fromBigInt(solutions[solutions.length - 1].iter);
        }
        // Otherwise, return the iteration of the first solution
        return fromBigInt(solutions[0].iter);
      }
    }
    return iterationStatus.selectedIteration;
  },
  set: (key: RecoilKey) => ({ set }, newValue) => {
    if (typeof newValue === 'number') {
      // Set the new value of the iteration and disable autoUpdate
      set(iterationState(key), { selectedIteration: newValue, autoUpdate: false });
    }
  },
});

// Return recoil state storing the currently selected iteration. By default the selected iteration
// will be updated automatically unless the state was manually modified using the recoil setter.
const useSelectedIterationState = (projectId: string, workflowId: string, jobId: string) => (
  useRecoilState(selectedIterationState({ projectId, workflowId, jobId }))
);
export const useSelectedIterationValue = (projectId: string, workflowId: string, jobId: string) => (
  useRecoilValue(selectedIterationState({ projectId, workflowId, jobId }))
);

export default useSelectedIterationState;
