// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useMemo } from 'react';

import { getWallSurfaces } from '../../lib/boundaryConditionUtils';
import { expandGroups } from '../../lib/entityGroupUtils';
import { subtractSet } from '../../lib/lang';
import { attachSurfaceToFrameError, attachVolumeToFrameError } from '../../lib/motionDataUtils';
import { NodeTableType } from '../../lib/nodeTableUtil';
import { findOutputNodeById } from '../../lib/outputNodeUtils';
import { getPorousInterfaces, getPorousSurfaces } from '../../lib/porousModelUtils';
import { NodeType, SimulationTreeNode } from '../../lib/simulationTree/node';
import { useEntityGroupData } from '../../recoil/entityGroupState';
import { useOutputNodes } from '../../recoil/outputNodes';
import { useActiveNodeTableValue } from '../../recoil/useActiveNodeTable';
import { useStaticVolumes } from '../../recoil/volumes';
import { useSimulationParam } from '../../state/external/project/simulation/param';
import { useAutoSelectSurfacesValue } from '../../state/internal/tree/autoSelectSurfaces';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';

// Nodes that should also be disabled during NodeTable selection mode
const nodeTypesToDisable = [NodeType.GEOMETRY, NodeType.POINT_CONTAINER];

/**
 * When the NodeTable is in edit mode, the hook can be used to disable a particular TreeRow node
 * type and optionally, to provide a reason why it's disabled (the reason will appear as a Tooltip).
 */
export const useNodeTableTreeRowError = (node: SimulationTreeNode): {
  disabled: boolean,
  disabledReason?: string,
} => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();
  const { selectedNode } = useSelectionContext();

  // == Recoil
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const activeNodeTable = useActiveNodeTableValue();
  const autoSelectSurfaces = useAutoSelectSurfacesValue(projectId);
  const staticVolumes = useStaticVolumes(projectId);
  const [outputNodes] = useOutputNodes(projectId, '', '');
  const { leafMap, groupMap: entityGroupMap } = useEntityGroupData(projectId, workflowId, jobId);

  return useMemo(() => {
    if (activeNodeTable.type !== NodeTableType.NONE && nodeTypesToDisable.includes(node.type)) {
      return {
        disabled: true,
      };
    }

    switch (activeNodeTable.type) {
      case NodeTableType.FRAME_VOLUMES: {
        // If we are hovering over a volume node during volumes selection for frames
        if (node.type === NodeType.VOLUME) {
          const attachVolumeError = attachVolumeToFrameError(
            node.id,
            selectedNode?.id,
            simParam,
            entityGroupMap,
            staticVolumes,
            autoSelectSurfaces,
          );
          // If some volume cannot be selected for a frame, we'll return an error that will be shown
          // as a tooltip and we'll disable the row.
          if (attachVolumeError) {
            return {
              disabled: true,
              disabledReason: attachVolumeError,
            };
          }
        }
        break;
      }
      case NodeTableType.FRAME_SURFACES: {
        if (node.type === NodeType.SURFACE || node.type === NodeType.SURFACE_GROUP) {
          const attachSurfaceErorr = attachSurfaceToFrameError(
            node.id,
            selectedNode?.id,
            simParam,
            entityGroupMap,
          );
          // If some surface or surface group cannot be selected for a frame, we'll return an error
          // that will be shown as a tooltip and we'll disable the row
          if (attachSurfaceErorr) {
            return {
              disabled: true,
              disabledReason: attachSurfaceErorr,
            };
          }
        }
        break;
      }
      case NodeTableType.IN_SURFACES: {
        if (!selectedNode) {
          break;
        }
        const outputNode = findOutputNodeById(outputNodes, selectedNode.id);
        const expand = expandGroups(leafMap);
        const outputSurfaces = outputNode?.inSurfaces && expand(outputNode?.inSurfaces);
        if (outputNode?.nodeProps.case === 'force') {
          const porousSurfaces = new Set(getPorousSurfaces(simParam, staticVolumes));
          const wallSurfaces = new Set(getWallSurfaces(simParam));
          // LC-17106, LC-18838
          // Porous force calculations have a special case where if a porous interface is
          // selected, then only other porous surfaces can be selected for its output
          const porousInterfaces = new Set(getPorousInterfaces(simParam, staticVolumes));
          // once a porous interface is selected, only porous surfaces can be selected
          const selectedPorousInterface = !!outputSurfaces?.length && outputSurfaces.some(
            (surface) => porousInterfaces.has(surface),
          );
          // if a non-porous surface is selected, only other non-porous surfaces can be selected
          const selectedNonPorous = !!outputSurfaces?.length && outputSurfaces.some(
            (surface) => !porousSurfaces.has(surface),
          );
          if (node.type === NodeType.SURFACE) {
            // non-wall surfaces cannot be selected for force outputs
            if (!wallSurfaces.has(node.id) && !porousSurfaces.has(node.id)) {
              return {
                disabled: true,
                disabledReason: 'You can only select wall surfaces or surfaces bounding ' +
                  'Porous Volumes.',
              };
            }
            if (selectedPorousInterface && !porousSurfaces.has(node.id)) {
              return {
                disabled: true,
                disabledReason: 'You can only select bounding surfaces of Porous Volumes.',
              };
            }
            if (selectedNonPorous && porousInterfaces.has(node.id)) {
              return {
                disabled: true,
                disabledReason: 'You can only select surfaces that do not bound Porous Volumes.',
              };
            }
          } else if (node.type === NodeType.SURFACE_GROUP) {
            const surfaces = new Set(expand([node.id]));
            if (subtractSet(subtractSet(surfaces, wallSurfaces), porousInterfaces).size) {
              return {
                disabled: true,
                disabledReason: 'You can only select groups containing only wall surfaces or ' +
                  'only surfaces bounding Porous Volumes.',
              };
            }
            if (selectedPorousInterface && subtractSet(surfaces, porousSurfaces).size) {
              return {
                disabled: true,
                disabledReason: 'You can only select groups containing bounding surfaces ' +
                  'of Porous Volumes.',
              };
            }
            if (selectedNonPorous && !subtractSet(surfaces, porousInterfaces).size) {
              return {
                disabled: true,
                disabledReason: 'You can only select groups containing only wall surfaces.',
              };
            }
          }
        }
        break;
      }
      default:
        break;
    }
    return {
      disabled: false,
    };
  }, [
    activeNodeTable.type,
    autoSelectSurfaces,
    entityGroupMap,
    leafMap,
    node,
    outputNodes,
    selectedNode,
    simParam,
    staticVolumes,
  ]);
};
