// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo } from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { colors } from '../../../lib/designSystem';
import { isGroupVisible } from '../../../lib/entityGroupUtils';
import { getAllAttachedDomains } from '../../../lib/motionDataUtils';
import { useEntityGroupMap } from '../../../recoil/entityGroupState';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useLcVisReadyValue } from '../../../recoil/lcvis/lcvisReadyState';
import { useLcvisVisibilityMapValue } from '../../../recoil/lcvis/lcvisVisibilityMap';
import { useToggleVisibility } from '../../../recoil/vis/useToggleVisibility';
import { useStaticVolume, useStaticVolumes } from '../../../recoil/volumes';
import { useSimulationParam } from '../../../state/external/project/simulation/param';
import { useIsGeometryView } from '../../../state/internal/global/currentView';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import CreateTagDialog from '../../dialog/CreateTag';
import { useTagsInteractiveGeometry } from '../../hooks/useInteractiveGeometry';
import { useNodeRenaming } from '../../hooks/useNodeRenaming';
import { useVolumeNodeRowMenu } from '../../hooks/useVolumeNodeRowMenu';
import { IconSpec, TreeRow, VisibilityControl } from '../TreeRow';

const PRIMARY_ICON: IconSpec = { name: 'cubeSolid' };

export const VolumeTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;

  // == Contexts
  const { visibilityMap, viewState } = useParaviewContext();
  const { projectId, workflowId, jobId } = useProjectContext();
  const { selectedNodeIds } = useSelectionContext();
  const isGeometryView = useIsGeometryView();

  // == Recoil
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const staticVolumes = useStaticVolumes(projectId);
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisReady = useLcVisReadyValue();
  const visibilityV2 = useLcvisVisibilityMapValue({ projectId, workflowId, jobId });
  const simParam = useSimulationParam(projectId, workflowId, jobId);

  // == Hooks
  const renaming = useNodeRenaming(node);
  const {
    getExtraContextMenuItems,
    isCreateTagDialogOpen,
    isCreateTagFacesDialogOpen,
    setIsCreateTagDialogOpen,
    setIsCreateTagFacesDialogOpen,
  } = useVolumeNodeRowMenu(node.id);
  const staticVolume = useStaticVolume({ projectId, volumeId: node.id });
  const { createTag } = useTagsInteractiveGeometry();

  // Check if any of the volume's surfaces are visible.
  const isVisible = useMemo(
    () => [...(staticVolume?.bounds ?? [])].some((bound) => isGroupVisible(
      lcvisEnabled ? visibilityV2 : visibilityMap,
      entityGroupMap,
      bound,
    )),
    [entityGroupMap, lcvisEnabled, staticVolume, visibilityMap, visibilityV2],
  );

  // Get the surfaces for a given array of volume IDs.
  const getBounds = useCallback((nodeIds: string[]) => staticVolumes.reduce((result, volume) => {
    if (nodeIds.includes(volume.id)) {
      return new Set([...result, ...volume.bounds]);
    }
    return result;
  }, new Set<string>()), [staticVolumes]);

  // Toggle all the selected IDs, if this ID is selected.
  const isSelected = useMemo(() => selectedNodeIds.includes(node.id), [selectedNodeIds, node.id]);
  const bounds = useMemo(
    () => (isSelected ? getBounds(selectedNodeIds) : getBounds([node.id])),
    [isSelected, getBounds, node.id, selectedNodeIds],
  );
  const toggleVis = useToggleVisibility(bounds, isVisible);

  const visibilityControl = useMemo(
    () => {
      if (!staticVolume) {
        return undefined;
      }

      return {
        disabled: lcvisEnabled ? !lcvisReady : !viewState,
        show: isVisible,
        isHovered: (rowHovered: boolean) => rowHovered,
        toggle: toggleVis,
      } as VisibilityControl;
    },
    [isVisible, lcvisEnabled, lcvisReady, staticVolume, toggleVis, viewState],
  );

  const auxIcons: IconSpec[] = [];
  if (
    staticVolume &&
    getAllAttachedDomains(simParam, { motion: 'moving' }).has(staticVolume.domain)
  ) {
    auxIcons.push({
      name: 'rotatingDots',
      color: colors.citronGreen600,
    });
  }

  return (
    <>
      {/* Create tag from the volume */}
      <>
        {isCreateTagDialogOpen && (
          <CreateTagDialog
            isOpen={isCreateTagDialogOpen}
            onCancel={() => {
              setIsCreateTagDialogOpen(false);
            }}
            onSubmit={async (name) => {
              await createTag(name, [node.id]);
              setIsCreateTagDialogOpen(false);
            }}
          />
        )}
      </>
      {/* Create tag from the volume faces */}
      <>
        {isCreateTagFacesDialogOpen && (
          <CreateTagDialog
            isOpen={isCreateTagFacesDialogOpen}
            onCancel={() => {
              setIsCreateTagFacesDialogOpen(false);
            }}
            onSubmit={async (name) => {
              if (staticVolume) {
                await createTag(name, Array.from(staticVolume.bounds));
              }
              setIsCreateTagFacesDialogOpen(false);
            }}
          />
        )}
      </>
      <TreeRow
        {...props}
        auxIcons={auxIcons}
        canMultiSelect
        getExtraContextMenuItems={getExtraContextMenuItems}
        primaryIcon={PRIMARY_ICON}
        propertiesControl={!isGeometryView}
        renaming={!isGeometryView ? renaming : undefined}
        visibility={visibilityControl}
      />
    </>
  );
};
