// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, Suspense, forwardRef, useMemo, useState } from 'react';

import { Badge } from '@mui/material';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { colors } from '../../../lib/designSystem';
import { parseString } from '../../../lib/html';
import { isInExploration } from '../../../lib/navigation';
import { LeveledMessage } from '../../../lib/notificationUtils';
import objectId from '../../../lib/objectId';
import { isSensitivityAnalysis } from '../../../lib/workflowUtils';
import { useWorkflowState } from '../../../recoil/workflowState';
import { useProjectValidator } from '../../../state/external/project/validator';
import { useIsAnalysisView } from '../../../state/internal/global/currentView';
import { explorationTreeSelector } from '../../../state/internal/tree/exploration';
import { useGeometryTree } from '../../../state/internal/tree/section/geometry';
import { setupTreeSelector } from '../../../state/internal/tree/setup';
import OutputChartPanel from '../../OutputChart/OutputChartPanel';
import PaneSwitcher from '../../Pane/PaneSwitcher';
import suspenseWidget from '../../SuspenseWidget';
import { createStyles, makeStyles } from '../../Theme';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { ChartLineIcon } from '../../svg/ChartLineIcon';
import { Resizable } from '../Resizable';

const defaultBackground = colors.surfaceDark1;
const splitterOnHover = colors.purple600;

const useBadgeStyles = makeStyles(
  () => createStyles({
    badge: {
      background: colors.red500,
      fontSize: '9px',
      height: '15px',
      minWidth: '15px',
      width: '15px',
    },
  }),
  { name: 'FooterBadge' },
);

const useStyles = makeStyles(
  () => createStyles({
    alertsBar: {
      width: '100%',
      height: 'fit-content',
      display: 'flex',
      flexDirection: 'row',
      gap: '16px',
      padding: '2px 12px 4px',
      fontSize: '12px',
      justifyContent: 'flex-start',
      backgroundColor: defaultBackground,
      // must be higher than the zIndex of the Resizable component
      zIndex: 200,
    },
    alert: {
      padding: '2px 8px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: colors.surfaceMedium1,
      },
    },
    splitter: {
      borderTop: `1px solid ${colors.neutral50}`,
      height: '4px',
      width: '100%',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: splitterOnHover,
      },
    },
    resizableContent: {
      width: '100%',
      height: '100%',
      flex: '0 0 auto',
      backgroundColor: defaultBackground,
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    header: {
      padding: '8px 12px 0px',
    },
    tabContent: {
      padding: '12px 20px',
      backgroundColor: defaultBackground,
      overflow: 'auto',
      flex: '1 1 auto',
      '&.noPadding': {
        padding: '0px',
      },
    },

    // TODO: Move the below classes to a separate component
    message: {
      cursor: 'pointer',
      padding: '0px 8px',
      '&:hover': {
        textDecoration: 'underline',
      },
      '&.active': {
        backgroundColor: colors.purple500,
        color: 'white',
        textDecoration: 'none',
        '&:hover': {
          backgroundColor: colors.purple600,
        },
      },
    },
  }),
  { name: 'FooterBase' },
);

type InfoFooterItem = {
  title: ReactNode;
  icon: ReactNode;
  content: ReactNode;
  // if true, the content will have its own padding, otherwise,
  // the info footer will provide padding
  customPadding?: boolean;
};

type InfoFooterProps = {
  items: InfoFooterItem[];
};

/**
 * Things we need
 * - Alerts bar at the very bottom. This has a fixed height and displays small icons or text
 * - When an alert is clicked, a new panel opens up in the footer with more information
 *
 * The footer is resizable
 * - The splitter is at the top of the footer. Double-clicking the splitter will open or close it
 * - The footer has 'tabs' that can be clicked to open different panels
 * - Each tab has a title, and a content area which can be structured however we want
 * - Each tab also has a related icon which is displayed in the alerts bar.
 *    - Clicking the icon will open the tab related to it.
 */

export const FooterBase = forwardRef((props: InfoFooterProps, ref) => {
  const { items } = props;
  const boundingRef = ref as React.MutableRefObject<HTMLDivElement | null>;
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(true);
  const [windowHeight, setWindowHeight] = useState('0px');
  // TODO: rework this so that multiple tabs can be open at once
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  const handleAlertClick = (index: number) => {
    if (collapsed) {
      // hack to expand the footer if it's collapsed
      if (
        windowHeight === '30%' ||
        windowHeight === '0%'
      ) {
        setWindowHeight('31%');
      } else {
        setWindowHeight('30%');
      }
      setCollapsed(false);
    } else if (activeTab === index) {
      setWindowHeight('0%');
      setCollapsed(true);
    }
    setActiveTab(index);
  };

  const paneSwitcherButtons = items.map((item, index) => ({
    text: item.title,
    selected: index === activeTab,
    onClick: () => handleTabClick(index),
  }));

  const content = (
    <div className={cx(classes.tabContent, { noPadding: items[activeTab].customPadding })}>
      {items[activeTab].content}
    </div>
  );

  return (
    <>
      <Resizable
        clickSettings={{
          popOpenSize: '100px',
          openOnClick: true,
          closeOnClick: true,
        }}
        getDragInputs={() => ({ boundingNode: boundingRef.current ?? undefined })}
        initialSize={windowHeight}
        maxSize={700}
        minSize={0}
        onCollapseOrExpand={(col) => setCollapsed(col)}
        splitterContent={<div className={classes.splitter} />}
        splitterPlacement="top"
        // higher zIndex needed for tooltip to be visible above main page
        zIndex={100}
        zIndexSplitter={100}>
        <div className={classes.resizableContent}>
          <div className={classes.header}>
            <PaneSwitcher buttons={paneSwitcherButtons} compact />
          </div>
          {content}
        </div>
      </Resizable>
      <div className={classes.alertsBar}>
        {items.map((item, index) => (
          <div
            className={classes.alert}
            key={objectId(item)}
            onClick={(ev) => handleAlertClick(index)}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}>
            {item.icon}
          </div>
        ))}
      </div>
    </>
  );
});

export const InfoFooter = forwardRef((props, ref) => {
  const classes = useStyles();
  const badgeClasses = useBadgeStyles();
  const { projectId, workflowId, jobId } = useProjectContext();
  const { setSelection, setScrollTo, selectedNodeIds } = useSelectionContext();
  const selectedSet = useMemo(() => new Set(selectedNodeIds), [selectedNodeIds]);
  const validator = useProjectValidator(projectId, workflowId, jobId);
  const location = useLocation();

  const explorationTree = useRecoilValue(explorationTreeSelector({ projectId, workflowId, jobId }));
  const setupTree = useRecoilValue(setupTreeSelector({ projectId, workflowId, jobId }));
  const geometryTree = useGeometryTree(projectId, workflowId, jobId);

  const isAnalysisView = useIsAnalysisView();
  const workflow = useWorkflowState(projectId, workflowId);
  const isExploration = isInExploration(location.pathname);
  const isSensitivity = isExploration && !!workflow && isSensitivityAnalysis(workflow);

  const showOutputPanel = isAnalysisView && !isSensitivity;

  const nodeIds = useMemo(() => {
    const ids = new Set<string>();
    if (isExploration) {
      explorationTree.traverse((node) => {
        ids.add(node.id);
      });
    } else {
      setupTree.traverse((node) => {
        ids.add(node.id);
      });
    }
    geometryTree.traverse((node) => {
      ids.add(node.id);
    });
    return ids;
  }, [explorationTree, setupTree, geometryTree, isExploration]);

  const simWarnings = useMemo(() => {
    const messages = validator.messageMap;
    const warnings: { id: string, message: LeveledMessage }[] = [];
    messages.forEach((collector, id) => {
      if (!nodeIds.has(id)) {
        return;
      }
      collector.messages.forEach((message) => {
        if (message.level === 'warning' || message.level === 'error') {
          warnings.push({ id, message });
        }
      });
    });
    return warnings;
  }, [validator.messageMap, nodeIds]);

  const simWarningsContent = (
    simWarnings.map(({ id, message }) => (
      <div
        className={cx(classes.message, { active: selectedSet.has(id) })}
        key={message.message}
        onClick={() => {
          setSelection([id]);
          setScrollTo({
            node: id,
            fast: true,
          });
        }}
        onKeyDown={(ev) => {
          if (ev.key === 'Enter' || ev.key === ' ') {
            setSelection([id]);
            setScrollTo({
              node: id,
              fast: true,
            });
          }
        }}
        role="button"
        tabIndex={0}>
        {parseString(message.message)}
      </div>
    ))
  );

  const baseProps: InfoFooterItem[] = [
    {
      title: <>Simulation Issues ({simWarnings.length})</>,
      icon: (
        <Badge badgeContent={simWarnings.length} classes={badgeClasses}>
          Simulation Issues
        </Badge>
      ),
      content: (
        <>
          {simWarningsContent}
        </>
      ),
    },
    // {
    //   title: 'AI Assistant',
    //   icon: (
    //     <div>
    //       <SparkleDoubleIcon color={colors.purple500} maxHeight={12} maxWidth={12} />
    //       AI Assistant
    //     </div>
    //   ),
    //   // To work on the assistant, replace the DummyChat with the AssistantChat component.
    //   content: (
    //     <div style={{ height: '100%', width: '100%' }}>
    //       <DummyChat resizable={false} />
    //     </div>
    //   ),
    // },
  ];

  if (showOutputPanel) {
    baseProps.push({
      title: 'Output Chart',
      icon: (
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <ChartLineIcon color={colors.purple800} maxHeight={12} maxWidth={12} />
          Output Chart
        </div>
      ),
      content: (
        <Suspense fallback={suspenseWidget}>
          <OutputChartPanel />
        </Suspense>
      ),
      customPadding: true,
    });
  }

  return <FooterBase items={baseProps} ref={ref} />;
});
