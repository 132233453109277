// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import { NodeType } from '../../../lib/simulationTree/node';
import { defaultNodeFilter } from '../../../lib/subselectUtils';
import { mapIdsToDomains } from '../../../lib/volumeUtils';
import { useHeatSource } from '../../../model/hooks/useHeatSource';
import { NodeFilter } from '../../../recoil/simulationTreeSubselect';
import { useStaticVolumes } from '../../../recoil/volumes';
import { useProjectContext } from '../../context/ProjectContext';

export const useHeatSourceVolumes = (heatSourceId: string) => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == Recoil
  const staticVolumes = useStaticVolumes(projectId);

  // == Model hooks
  const {
    disabledDomainReason,
    setHeatSourceDomains,
  } = useHeatSource(projectId, workflowId, jobId, readOnly, heatSourceId);

  const domainAssignmentDisabledReasons = useMemo(() => {
    const reasons: Record<string, string> = {};
    staticVolumes.forEach(({ domain, id }) => {
      reasons[id] = disabledDomainReason(domain);
    });
    return reasons;
  }, [disabledDomainReason, staticVolumes]);

  // Memoize the nodeFilter object passed to NodeSubselect to avoid infinite looping
  // TODO(LC-21829): Adaot this to using tags.
  const nodeFilter = useCallback<NodeFilter>((nodeType, nodeId) => {
    if (nodeType === NodeType.VOLUME) {
      const disabledReason = domainAssignmentDisabledReasons[nodeId] ?? '';
      return {
        related: true,
        tooltip: disabledReason,
        disabled: !!disabledReason,
      };
    }
    return defaultNodeFilter(nodeType);
  }, [domainAssignmentDisabledReasons]);

  // Update the list of volumes assigned to the heat source
  const setAssignedVolumeNodeIds = useCallback(async (volumeIds: string[]) => {
    const domains = mapIdsToDomains(staticVolumes, volumeIds);
    await setHeatSourceDomains(domains);
  }, [setHeatSourceDomains, staticVolumes]);

  return {
    nodeFilter,
    setAssignedVolumeNodeIds,
  };
};
