import React, { forwardRef, useCallback, useLayoutEffect, useState } from 'react';

import { colors } from '../../../lib/designSystem';
import { ActionButton } from '../../Button/ActionButton';
import { TextInput } from '../../Form/TextInput';
import { createStyles, makeStyles } from '../../Theme';
import { PaperAirplaneIcon } from '../../svg/PaperAirplaneIcon';
import { SparkleDoubleIcon } from '../../svg/SparkleDoubleIcon';

// TODO:
// - Handle markdown in messages
// - Add some loading indicator when the assistant has not responded yet
// - Fake-stream the response from the assistant so it looks like it's typing the response

export type ChatMessage = {
  id: string;
  text: string;
  timestamp: Date;
  isUser: boolean;
}

type ChatProps = {
  messages: ChatMessage[];
  onSendMessage: (message: string) => void;
  // If true, the user cannot send messages.
  disabled?: boolean;
}

const MAX_CHAT_WIDTH = 500;
const MIN_CHAT_WIDTH = 200;

const useStyles = makeStyles(
  () => createStyles({
    root: {
      width: '100%',
      maxWidth: `${MAX_CHAT_WIDTH}px`,
      minWidth: `${MIN_CHAT_WIDTH}px`,
      height: '100%',
      backgroundColor: colors.neutral150,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      overflow: 'hidden',
    },
    chatHistory: {
      overflowY: 'auto',
      padding: '8px',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      flex: '1',
      fontSize: '13px',
    },
    inputBox: {
      display: 'flex',
      gap: '8px',
      padding: '8px',
      position: 'relative',
      alignSelf: 'center',
    },
    spacer: {
      // Pushes the messages to the bottom
      flex: '1',
    },
  }),
  { name: 'Chat' },
);

const useAssistantChatStyles = makeStyles(
  () => createStyles({
    assistantMessage: {
      backgroundColor: 'inherit',
      color: colors.lowEmphasisText,
      display: 'flex',
      flexDirection: 'row',
      gap: '6px',
      alignSelf: 'flex-start',
      alignItems: 'baseline',
      maxWidth: '80%',
    },
    assistantIcon: {
      height: '12px',
      width: '12px',
      flex: '0 0 auto',
    },
  }),
  { name: 'AssistantChat' },
);

const useUserChatStyles = makeStyles(
  () => createStyles({
    userMessage: {
      backgroundColor: colors.neutral300,
      color: colors.highEmphasisText,
      borderRadius: '8px',
      padding: '4px 8px',
      maxWidth: '80%',
      alignSelf: 'flex-end',
      textAlign: 'right',
      width: 'max-content',
    },
  }),
  { name: 'UserChat' },
);

const AssistantMessage = forwardRef<HTMLDivElement, { text: string }>(({ text }, ref) => {
  const classes = useAssistantChatStyles();
  return (
    <div className={classes.assistantMessage} ref={ref}>
      <div className={classes.assistantIcon}>
        <SparkleDoubleIcon color={colors.purple800} maxHeight={12} maxWidth={12} />
      </div>
      {text}
    </div>
  );
});

const UserMessage = forwardRef<HTMLDivElement, { text: string }>(({ text }, ref) => {
  const classes = useUserChatStyles();
  return (
    <div className={classes.userMessage} ref={ref}>
      {text}
    </div>
  );
});

/**
 * A chat component that displays messages and allows the user to send messages.
 * It's a controlled component that requires a list of messages and a callback to send messages.
 */
export const Chat = (props: ChatProps) => {
  const { messages, onSendMessage, disabled } = props;
  const classes = useStyles();
  const mostRecentMessageRef = React.useRef<HTMLDivElement | null>(null);
  const [currentMessage, setCurrentMessage] = useState('');

  const disableInput = disabled || messages.length === 0;
  // Disable the send button if the last message was from the user
  const disableSend = (
    currentMessage.length === 0 ||
    disableInput ||
    messages[messages.length - 1].isUser
  );

  const scrollToBottom = useCallback(() => {
    if (mostRecentMessageRef.current) {
      mostRecentMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  useLayoutEffect(() => {
    // Whenever the messages change, scroll to the bottom
    scrollToBottom();
  }, [messages, scrollToBottom]);

  const handleSubmit = () => {
    if (currentMessage.length > 0 && !disableSend) {
      onSendMessage(currentMessage);
      setCurrentMessage('');
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.chatHistory}>
        <div className={classes.spacer} /> {/* Spacer to push messages to the bottom */}
        {messages.map((message, index) => {
          if (message.isUser) {
            return (
              <UserMessage
                key={message.id}
                ref={index === messages.length - 1 ?
                  mostRecentMessageRef : null}
                text={message.text}
              />
            );
          }
          return (
            <AssistantMessage
              key={message.id}
              ref={index === messages.length - 1 ?
                mostRecentMessageRef : null}
              text={message.text}
            />
          );
        })}
      </div>
      <div className={classes.inputBox}>
        <TextInput
          cols={50}
          disabled={disableInput}
          endAdornment={(
            <div style={{ padding: '8px 8px 0px 0px' }}>
              <ActionButton
                disabled={disableSend}
                onClick={handleSubmit}
                size="small">
                <PaperAirplaneIcon maxHeight={12} maxWidth={12} /> Send
              </ActionButton>
            </div>
          )}
          multiline
          onChange={(newValue: string) => {
            setCurrentMessage(newValue);
          }}
          onEnter={handleSubmit}
          placeholder="Enter a message"
          // TODO: Make the TextInput resize automatically when the content overflows,
          // up to a max number of lines
          resize="none"
          rows={3}
          submitOnEnter
          value={currentMessage}
        />
      </div>
    </div>
  );
};
