// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { addRpcError } from '../../lib/transientNotification';
import * as ParaviewRpc from '../../pvproto/ParaviewRpc';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useLcVisReadyValue } from '../../recoil/lcvis/lcvisReadyState';
import { useLcvisSettingsState } from '../../recoil/lcvis/lcvisSettings';
import { ToolbarButton } from '../Toolbar/ToolbarButton';
import { useProjectContext } from '../context/ProjectContext';

import { useParaviewContext } from './ParaviewManager';

// Button that toggles the Axes Grid On or Off.
const AxesGridButton = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const { paraviewRenderer, onRpcSuccess, viewState } = useParaviewContext();
  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisReady = useLcVisReadyValue();

  const [lcvisSettings, setLcvisSettings] = useLcvisSettingsState({ projectId, workflowId, jobId });
  const setLcvAxesGrid = (axesGrid: boolean) => {
    setLcvisSettings(
      (prev) => (axesGrid !== prev.showAxes ? { ...prev, showAxes: axesGrid } : prev),
    );
  };
  const pvAxesGrid = !!viewState?.axesGridVisibility;
  const axesGrid = lcvisEnabled ? lcvisSettings.showAxes : pvAxesGrid;

  return (
    <ToolbarButton
      disabled={lcvisEnabled ? !lcvisReady : !viewState}
      icon={{ name: axesGrid ? 'gridOn' : 'gridOff', maxHeight: 15 }}
      key="axesGrid"
      locator="axes-grid-button"
      onClick={() => {
        if (lcvisEnabled) {
          setLcvAxesGrid(!axesGrid);
        } else {
          paraviewRenderer.showAxesGrid(!axesGrid).then((res: ParaviewRpc.RpcResult) => {
            setLcvAxesGrid(!axesGrid);
            onRpcSuccess('showAxesGrid', res);
          }).catch((err: Error) => {
            addRpcError('Could not set grid axes', err);
          });
        }
      }}
      title={axesGrid ? 'Hide Axes Grid' : 'Show Axes Grid'}
    />
  );
};

export default AxesGridButton;
