// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

// ZoomControl provides a <div> element that provides a zoom & drag control.

import React from 'react';

import { ParentSize } from '@visx/responsive';
import { scaleLinear } from '@visx/scale';
import { Zoom, applyMatrixToPoint } from '@visx/zoom';
import { ProvidedZoom, TransformMatrix } from '@visx/zoom/lib/types';
import { ScaleLinear } from 'd3-scale';

import { clamp } from '../../lib/number';

const INITIAL_TRANSFORM: TransformMatrix = {
  scaleX: 1.0,
  scaleY: 1.0,
  translateX: 0,
  translateY: 0,
  skewX: 0,
  skewY: 0,
};

// Maximum allowed padding in the horizontal direction when dragging.
const MAX_X_PADDING = 50;

// The amount that the scale changes with each mouse wheel event.
const ZOOM_FACTOR = 1.1;

// State of the zoom control passed to the child.
// This is a copy of an unexported type in @vizx/zoom/Zoom.
//
// TODO(saito) export it upstream.
export type ZoomState = ProvidedZoom<HTMLDivElement> & {
  initialTransformMatrix: TransformMatrix; // always INITIAL_TRANSFORM
  transformMatrix: TransformMatrix; // the latest transformation
  isDragging: boolean;
};

// The current component size.
export type ViewSize = {
  height: number,
  width: number,
}

export interface ZoomControlProps {
  // The component height. Must be set.
  height: number;
  // The component width. If unset, it fills the parent component.
  width?: number;
  // A function that draws the child components. Invoked on mount, and on every
  // redraw or zoom change.
  //
  // Arg size is the current component size. Arg zoom is the current zoom
  // setting.
  children: (size: ViewSize, zoom: ZoomState) => React.ReactNode;
  // Optional callback to be invoked when the mouse moves inside the window.
  // Arg size is the current component size. Arg zoom is the current zoom
  // setting.
  onMouseMove?: (
    ev: React.MouseEvent<HTMLDivElement>,
    size: ViewSize,
    zoom: ZoomState) => void;
  // Optional callback to be invoked when the mouse moves into the window.
  // Arg size is the current component size. Arg zoom is the current zoom
  // setting.
  onMouseEnter?: (
    ev: React.MouseEvent<HTMLDivElement>,
    size: ViewSize,
    zoom: ZoomState) => void;
  // Optional callback to be invoked when the mouse leaves the window.
  // Arg size is the current component size. Arg zoom is the current zoom
  // setting.
  onMouseLeave?: () => void;
  // Optional callback to be invoked on mouse click.
  // Arg size is the current component size. Arg zoom is the current zoom
  // setting.
  onClick?: (
    ev: React.MouseEvent<HTMLDivElement>,
    size: ViewSize,
    zoom: ZoomState) => void;
  // If the zooming is disabled.
  disabled?: boolean;
  // If the zoom drag is disabled
  disabledDrag?: boolean;
}

export const ZoomControl = (props: ZoomControlProps) => (
  <ParentSize>
    {(size) => {
      const scaleMin = 1;
      const scaleMax = props.disabled ? 1 : 8;
      const width = props.width || size.width;
      const height = props.height || size.height;
      return (
        <Zoom
          constrain={(transformMatrix, prevTransformMatrix) => {
            // Apply a constrain to the transform to make sure that the users cannot move away from
            // the horizontal graph area of interest when dragging. We allow for certain amount of
            // padding defined by MAX_X_PADDING) along the x/horizontal direction.
            // Taken from: https://github.com/airbnb/visx/issues/1276#issuecomment-965310768.

            const { scaleX, scaleY, translateX, translateY } = transformMatrix;

            // Fix constrain scale.
            if (scaleX < scaleMin) {
              transformMatrix.scaleX = scaleMin;
            }
            if (scaleY < scaleMin) {
              transformMatrix.scaleY = scaleMin;
            }

            // Fix constrain translate [left, top] position. Note that we allow for some padding
            // along the x-direction.
            if (translateX > MAX_X_PADDING) {
              transformMatrix.translateX = MAX_X_PADDING;
            }
            if (translateY > 0) {
              transformMatrix.translateY = 0;
            }

            // Fix constrain translate [right, bottom] position. Note that we allow for some padding
            // along the x-direction.
            const max = applyMatrixToPoint(transformMatrix, {
              x: width,
              y: height,
            });
            if (max.x + MAX_X_PADDING < width) {
              transformMatrix.translateX = translateX + Math.abs(max.x + MAX_X_PADDING - width);
            }
            if (max.y < height) {
              transformMatrix.translateY = translateY + Math.abs(max.y - height);
            }

            // Return the matrix
            return transformMatrix;
          }}
          height={height}
          initialTransformMatrix={INITIAL_TRANSFORM}
          scaleXMax={scaleMax}
          scaleXMin={scaleMin}
          scaleYMax={scaleMax}
          scaleYMin={scaleMin}
          width={width}>
          {(zoom: ZoomState) => (
            <div
              onClick={(event) => {
                props.onClick?.(event, size, zoom);
              }}
              onMouseDown={props.disabled || props.disabledDrag ? undefined : zoom.dragStart}
              onMouseEnter={(event) => {
                props.onMouseEnter?.(event, size, zoom);
              }}
              onMouseLeave={() => {
                if (zoom.isDragging) {
                  zoom.dragEnd();
                }
                props.onMouseLeave?.();
              }}
              onMouseMove={(event) => {
                zoom.dragMove(event);
                props.onMouseMove?.(event, size, zoom);
              }}
              onMouseUp={zoom.dragEnd}
              onWheel={props.disabled ? undefined : (event) => {
                const { scaleX, translateX } = zoom.transformMatrix;
                const multiplier = (event.deltaY > 0) ? 1 / ZOOM_FACTOR : ZOOM_FACTOR;
                const sNew = clamp(scaleX * multiplier, [scaleMin, scaleMax]);
                zoom.setTransformMatrix({
                  ...zoom.transformMatrix,
                  scaleX: sNew,
                  translateX: event.clientX - (sNew / scaleX) * (event.clientX - translateX),
                });
              }}
              role="presentation"
              style={{
                width: size.width,
                height: props.height,
                position: 'relative',
                overflow: 'hidden',
              }}>
              {props.children(size, zoom)}
            </div>
          )}
        </Zoom>
      );
    }}
  </ParentSize>
);

function transformPx(
  value: number,
  scale: ScaleLinear<number, number>,
  transformMatrix: TransformMatrix,
): number {
  return scale.invert((value - transformMatrix.translateX) / transformMatrix.scaleX);
}

// newZoomedXLinearScale creates a new D3 ScaleLinear object that
// maps the domain [minDomain, maxDomain] to range [minPx, maxPx], but scaled to
// the current zoom setting. nice means it will round the start and end values.
export function newZoomedXLinearScale(
  zoom: ZoomState,
  minDomain: number,
  maxDomain: number,
  range: number[],
  nice: boolean,
): ScaleLinear<number, number> {
  const scale = scaleLinear<number>({
    domain: [minDomain, maxDomain],
    range,
    nice,
  });
  scale.domain([
    transformPx(range[0], scale, zoom.transformMatrix),
    transformPx(range[1], scale, zoom.transformMatrix),
  ]);
  return scale;
}
