// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import { selectorFamily, waitForAll } from 'recoil';

import { CurrentView } from '../../../lib/componentTypes/context';
import {
  GENERAL_SETTINGS_NODE_ID,
  NodeType,
  ROOT_SIMULATION_CONTAINER_ID,
  STOPPING_CONDITIONS_NODE_ID,
  SimulationTreeNode,
} from '../../../lib/simulationTree/node';
import SectionRecoilKey from '../../../lib/simulationTree/sectionRecoilKey';
import { paraviewToSimulationNode } from '../../../lib/simulationTree/utils';
import { lcVisEnabledSelector } from '../../../recoil/lcvis/lcvisEnabledState';
import { TreeViewType, treeViewTypeState } from '../../../recoil/useTreeViewState';
import { viewStateAtomFamily_DEPRECATED } from '../../../recoil/useViewState';
import { filterStateSelector } from '../../../recoil/vis/filterState';
import { currentViewAtom_DEPRECATED } from '../global/currentView';

import { cameraSectionSelector } from './section/camera';
import { materialsSectionSelector } from './section/material';
import { meshSectionSelector } from './section/mesh';
import { motionSectionSelector } from './section/motion';
import { outputsSectionSelector } from './section/output';
import { physicsSectionSelector } from './section/physics';
import { plotSectionSelector } from './section/plot';

export const setupTreeSelector = selectorFamily<SimulationTreeNode, SectionRecoilKey>({
  key: 'setupTreeSelector',
  get: (key: SectionRecoilKey) => async ({ get }) => {
    const [
      mesh,
      materials,
      physics,
      outputs,
      cameras,
      plot,
      motion,
      viewState,
      currentView,
      treeViewType,
      filterState,
      lcvisEnabled,
    ] = get(waitForAll([
      meshSectionSelector(key),
      materialsSectionSelector(key),
      physicsSectionSelector(key),
      outputsSectionSelector(key.projectId),
      cameraSectionSelector(key.projectId),
      plotSectionSelector(key.projectId),
      motionSectionSelector(key),
      viewStateAtomFamily_DEPRECATED(key.projectId),
      currentViewAtom_DEPRECATED,
      treeViewTypeState,
      filterStateSelector(key),
      lcVisEnabledSelector(key.projectId),
    ]));

    const getVisChildren = () => {
      if (lcvisEnabled) {
        return [paraviewToSimulationNode(filterState)];
      }
      if (viewState) {
        return [paraviewToSimulationNode(viewState.root)];
      }
      return [];
    };

    const general = new SimulationTreeNode(
      NodeType.GENERAL_SETTINGS,
      GENERAL_SETTINGS_NODE_ID,
      'General',
    );

    const stoppingConditions = new SimulationTreeNode(
      NodeType.STOPPING_CONDITIONS,
      STOPPING_CONDITIONS_NODE_ID,
      'Stopping Conditions',
    );

    const childNodes: SimulationTreeNode[] = [];

    // The Analysis tree has 2 views that the user can pick: "Setup Details" and "Post-processing".
    if (currentView === CurrentView.ANALYSIS) {
      switch (treeViewType) {
        case TreeViewType.POST_PROCESSING: {
          childNodes.push(
            outputs,
            cameras,
            plot,
            ...getVisChildren(),
          );
          break;
        }
        case TreeViewType.SETUP: {
          childNodes.push(
            general,
            mesh,
            materials,
            motion,
            physics,
            outputs,
            stoppingConditions,
          );
          break;
        }
        default: {
          // node default, enum exhausted
        }
      }
    } else {
      childNodes.push(
        general,
        mesh,
        materials,
        motion,
        physics,
        outputs,
        stoppingConditions,
        cameras,
        plot,
        ...getVisChildren(),
      );
    }

    return new SimulationTreeNode(
      NodeType.ROOT_SIMULATION,
      ROOT_SIMULATION_CONTAINER_ID,
      'Simulation',
      childNodes,
    );
  },
  dangerouslyAllowMutability: true,
});
