// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { colors } from '../../lib/designSystem';
import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useLcVisReadyValue } from '../../recoil/lcvis/lcvisReadyState';
import { useResetFilterState } from '../../recoil/vis/filterState';
import { pushConfirmation, useSetConfirmations } from '../../state/internal/dialog/confirmations';
import { IconButton } from '../Button/IconButton';
import { useParaviewContext } from '../Paraview/ParaviewManager';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';
import { ResetIcon } from '../svg/ResetIcon';

const useStyles = makeStyles(
  () => createStyles({
    iconButton: {
      color: 'currentColor',
      padding: 0,
      border: '1px solid transparent',
      '&:focus': {
        borderColor: colors.primaryCta,
      },
    },
  }),
  { name: 'ResetVisualizationsButton' },
);

// Reset button to wipe out the visualization filters. This is useful
// for cases in which there exist errors when applying the filters, for example,
// if we are running out of memory. The button is active even if the connection
// with the renderer client is lost.
export const ResetVisualizationsButton = () => {
  // Hooks
  const classes = useStyles();
  const setConfirmStack = useSetConfirmations();
  const { resetVisState } = useParaviewContext();
  const { projectId, workflowId, jobId } = useProjectContext();
  const resetFilterState = useResetFilterState({ projectId, workflowId, jobId });
  const lcvisReady = useLcVisReadyValue();
  const lcvisEnabled = useLcVisEnabledValue(projectId);

  const resetVisualizations = () => {
    if (lcvisEnabled) {
      if (lcvisReady) {
        lcvHandler.display!.filterHandler!.deleteAllNodes();
      }
      resetFilterState();
    } else {
      resetVisState();
    }
  };

  // Handlers
  const queueReset = () => {
    pushConfirmation(setConfirmStack, {
      destructive: true,
      onContinue: () => resetVisualizations(),
      title: 'Reset Visualization State',
      children: (
        <div>This will reset the visualization state. Are you sure you want to proceed?</div>
      ),
    });
  };

  return (
    <IconButton
      className={classes.iconButton}
      onClick={() => queueReset()}
      title="Reset Visualization State">
      <ResetIcon maxHeight={12} maxWidth={12} />
    </IconButton>
  );
};
