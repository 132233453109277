import mixpanel from 'mixpanel-browser';

import { isHostedEnvironment, isProd } from '../lib/RuntimeParams';
import { MIXPANEL_TOKEN_DEV, MIXPANEL_TOKEN_PROD } from '../lib/constants';

export interface IAnalytics {
  init(): void;
  identify(userId: string, traits?: Record<string, any>): void;
  track(eventName: string, properties?: Record<string, any>): void;
  page(pageName: string, properties?: Record<string, any>): void;
}

class MixpanelAnalytics implements IAnalytics {
  init(): void {
    if (!isHostedEnvironment) {
      return console.warn('Not Hosted Environment, Mixpanel is not initialized');
    }

    const token = isProd ? MIXPANEL_TOKEN_PROD : MIXPANEL_TOKEN_DEV;
    mixpanel.init(token, { debug: !isHostedEnvironment });
  }

  identify(userId: string, traits?: Record<string, any>): void {
    if (!isHostedEnvironment) {
      return console.warn(`Mixpanel identify: ${userId}`, traits);
    }

    mixpanel.identify(userId);
    if (traits) {
      mixpanel.people.set(traits);
    }
  }

  track(eventName: string, properties?: Record<string, any>): void {
    if (!isHostedEnvironment) {
      return console.warn(`Mixpanel event: ${eventName}`, properties);
    }
    mixpanel.track(eventName, properties);
  }

  page(pageName: string, properties?: Record<string, any>): void {
    if (!isHostedEnvironment) {
      return console.warn(`Mixpanel page view: ${pageName}`, properties);
    }
    mixpanel.track('Page View', { page: pageName, ...properties });
  }
}

export const analytics: IAnalytics = new MixpanelAnalytics();
