// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { newOriginProto } from '../../../../lib/Vector';
import assert from '../../../../lib/assert';
import { subtractSet } from '../../../../lib/lang';
import {
  getAllAttachedDomains,
  getAllAttachedSurfaceIds,
  summarizeFrameCoordinateSystem,
} from '../../../../lib/motionDataUtils';
import { NodeTableType } from '../../../../lib/nodeTableUtil';
import { useNodePanel } from '../../../../lib/useNodePanel';
import { mapDomainsToIds } from '../../../../lib/volumeUtils';
import { useStaticVolumes } from '../../../../recoil/volumes';
import { CollapsiblePanel } from '../../../Panel/CollapsiblePanel';
import Divider from '../../../Theme/Divider';
import { useCommonMotionFramePropStyles, useCommonTreePropsStyles } from '../../../Theme/commonStyles';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { useSimulationConfig } from '../../../hooks/useSimulationConfig';
import Collapsible from '../../../transition/Collapsible';
import NodeTable from '../../NodeTable';
import PropertiesSection from '../../PropertiesSection';
import { FrameCoordinates } from '../shared/FrameCoordinates';

export const MotionGlobalFramePropPanel = () => {
  const { projectId } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected global frame row');
  const staticVolumes = useStaticVolumes(projectId);
  const frameClasses = useCommonMotionFramePropStyles();
  const propClasses = useCommonTreePropsStyles();

  const { id: nodeId } = node;

  const { simParam } = useSimulationConfig();

  const origin = newOriginProto();
  const orientation = newOriginProto();

  const coordsPanel = useNodePanel(nodeId, 'coordinates');
  const geometryPanel = useNodePanel(nodeId, 'geometry', { defaultExpanded: true });

  const coordinatesSummary = summarizeFrameCoordinateSystem(undefined);
  const attachedSurfaceIds = useMemo(() => getAllAttachedSurfaceIds(simParam), [simParam]);
  const attachedVolumes = useMemo(
    () => mapDomainsToIds(staticVolumes, getAllAttachedDomains(simParam)),
    [simParam, staticVolumes],
  );

  // Start with all geometry elements (surfaces and volumes) and then subtract those geometry
  // elements that have been assigned to non-global motion frames.  The result is a set of explicit
  // volumes and surfaces (the unassigned geometry) to represent in the tree.
  const allGeometry = useMemo(
    () => staticVolumes.reduce((result, staticVolume) => {
      result.volumes.push(staticVolume.id);
      result.surfaces.push(...staticVolume.bounds);
      return result;
    }, { volumes: [] as string[], surfaces: [] as string[] }),
    [staticVolumes],
  );

  const explicitVolumes = useMemo(
    () => [...subtractSet(allGeometry.volumes, attachedVolumes)],
    [allGeometry.volumes, attachedVolumes],
  );
  const explicitSurfaces = useMemo(
    () => [...subtractSet(allGeometry.surfaces, attachedSurfaceIds)],
    [allGeometry.surfaces, attachedSurfaceIds],
  );

  return (
    <div className={propClasses.properties}>
      <PropertiesSection>
        <CollapsiblePanel
          collapsed={coordsPanel.collapsed}
          heading="Coordinates"
          onToggle={coordsPanel.toggle}>
          <FrameCoordinates
            global
            orientation={orientation}
            origin={origin}
            readOnly
            setOrientation={() => { }}
            setOrigin={() => { }}
          />
        </CollapsiblePanel>
        <Collapsible collapsed={coordsPanel.expanded} transitionPeriod={250}>
          <div className={frameClasses.framePanelSummary}>
            <div className={frameClasses.frameConfigSummary}>{coordinatesSummary}</div>
          </div>
        </Collapsible>
      </PropertiesSection>
      <Divider />
      <PropertiesSection>
        <CollapsiblePanel
          collapsed={geometryPanel.collapsed}
          heading="Geometry"
          onToggle={geometryPanel.toggle}>
          <div className={propClasses.sectionDescription}>
            All geometry is by default in the global frame and not moving unless attached to a
            child frame.
          </div>
          <NodeTable
            editable={false}
            nodeIds={explicitVolumes}
            tableId="global-frame-volumes"
            tableType={NodeTableType.FRAME_VOLUMES}
            title="Volumes"
          />
          <NodeTable
            editable={false}
            nodeIds={explicitSurfaces}
            tableId="global-frame-surfaces"
            tableType={NodeTableType.FRAME_SURFACES}
            title="Surfaces"
          />
        </CollapsiblePanel>
      </PropertiesSection>
    </div>
  );
};
