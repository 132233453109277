// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { SimulationRowProps } from '../../../../lib/componentTypes/simulationTree';
import AddExplorationVarButton from '../../AddExplorationVarButton';
import { IconSpec, TreeRow } from '../../TreeRow';

const PRIMARY_ICON: IconSpec = { name: 'wavesQuadruple' };

export const ExplorationVariableContainerTreeRow = (props: SimulationRowProps) => (
  <TreeRow
    {...props}
    addControl={<AddExplorationVarButton />}
    primaryIcon={PRIMARY_ICON}
  />
);
