// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement, Suspense } from 'react';

import { ErrorBoundary } from 'react-error-boundary';

import { NodeType } from '../../lib/simulationTree/node';
import suspenseWidget from '../SuspenseWidget';
import { createStyles, makeStyles } from '../Theme';
import { useSelectionContext } from '../context/SelectionManager';

import { ContactPropPanel } from './propPanel/Contact';
import { EmptyPropPanel } from './propPanel/Empty';
import { FarFieldPropPanel } from './propPanel/FarField';
import { FilterPropPanel } from './propPanel/Filter';
import { GeneralSettingsPropPanel } from './propPanel/GeneralSettings';
import { GeometryPropPanel } from './propPanel/Geometry';
import { MonitorPlanePropPanel } from './propPanel/MonitorPlane';
import { MultiMeshParentPanel } from './propPanel/MultiMeshParentPanel';
import { OutputPropPanel } from './propPanel/Output';
import { OutputsPropPanel } from './propPanel/Outputs';
import { ParticleGroupPropPanel } from './propPanel/ParticleGroup';
import { PhysicalBehaviorPropPanel } from './propPanel/PhysicalBehavior';
import { PlotPropPanel } from './propPanel/Plot';
import { PorousModelPropPanel } from './propPanel/PorousModel';
import { ProbePointPropPanel } from './propPanel/ProbePoint';
import { ReferenceValuePropPanel } from './propPanel/ReferenceValue';
import { StoppingConditionsPropPanel } from './propPanel/StoppingConditions';
import { SurfacePropPanel } from './propPanel/Surface';
import { SurfaceGroupPropPanel } from './propPanel/SurfaceGroup';
import { VolumePropPanel } from './propPanel/Volume';
import { ExplorationPolicyPanel } from './propPanel/exploration/Policy';
import { ExplorationVariablePanel } from './propPanel/exploration/Variable';
import { GeometryModificationPropPanel } from './propPanel/geometry/GeometryModification';
import { MaterialFluidPropPanel } from './propPanel/material/Fluid';
import { MaterialSolidPropPanel } from './propPanel/material/Solid';
import { AdaptationBoundaryPropPanel } from './propPanel/mesh/AdaptationBoundary';
import { MeshBoundaryPropPanel } from './propPanel/mesh/Boundary';
import { MeshModelPropPanel } from './propPanel/mesh/Model';
import { RefinementRegionPropPanel } from './propPanel/mesh/RefinementRegion';
import { MeshSizePropPanel } from './propPanel/mesh/Size';
import { MotionFramePropPanel } from './propPanel/motion/Frame';
import { MotionGlobalFramePropPanel } from './propPanel/motion/GlobalFrame';
import { PhysicsFluidPropPanel } from './propPanel/physics/Fluid';
import { PhysicsHeatPropPanel } from './propPanel/physics/Heat';
import { PhysicsMultiPropPanel } from './propPanel/physics/Multi';
import { PhysicsPeriodicPropPanel } from './propPanel/physics/PeriodicPair';
import { PhysicsSlidingInterfacePropPanel } from './propPanel/physics/SlidingInterface';
import { PhysicsVolumeSelectionPropPanel } from './propPanel/physics/VolumeSelection';
import { PhysicsFluidBoundaryConditionPropPanel } from './propPanel/physics/fluid/BoundaryCondition';
import { PhysicsFluidBoundaryConditionContainerPropPanel } from './propPanel/physics/fluid/BoundaryConditionContainer';
import { PhysicsFluidInitializationPropPanel } from './propPanel/physics/fluid/Initialization';
import { PhysicsHeatBoundaryConditionPropPanel } from './propPanel/physics/heat/BoundaryCondition';
import { PhysicsHeatBoundaryConditionContainerPropPanel } from './propPanel/physics/heat/BoundaryConditionContainer';
import { PhysicsHeatHeatSourcePropPanel } from './propPanel/physics/heat/HeatSource';
import { PhysicsHeatInitializationPropPanel } from './propPanel/physics/heat/Initialization';
import { PhysicsMultiInterfacePropPanel } from './propPanel/physics/multi/Interface';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      fontSize: '13px',
      overflow: 'auto',
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'space-between',
    },
  }),
  { name: 'PropertiesPanel' },
);

/**
 * A panel for displaying detailed properties of whichever node is currently
 * selected.
 */
export const PropertiesPanel = () => {
  const { selectedNode } = useSelectionContext();
  const { id } = selectedNode ?? {};

  const classes = useStyles();

  let nodeProperties: ReactElement | null = null;

  switch (selectedNode?.type) {
    case NodeType.GEOMETRY_CONTACT:
      nodeProperties = <ContactPropPanel />;
      break;
    case NodeType.GEOMETRY_MODIFICATION:
      nodeProperties = <GeometryModificationPropPanel />;
      break;
    case NodeType.EXPLORATION_VARIABLE:
      nodeProperties = <ExplorationVariablePanel />;
      break;
    case NodeType.EXPLORATION_POLICY:
      nodeProperties = <ExplorationPolicyPanel />;
      break;
    case NodeType.FAR_FIELD:
      nodeProperties = <FarFieldPropPanel />;
      break;
    case NodeType.FILTER:
      nodeProperties = <FilterPropPanel />;
      break;
    case NodeType.GENERAL_SETTINGS:
      nodeProperties = <GeneralSettingsPropPanel />;
      break;
    case NodeType.GEOMETRY:
      nodeProperties = <GeometryPropPanel />;
      break;
    case NodeType.SURFACE:
      nodeProperties = <SurfacePropPanel />;
      break;
    case NodeType.SURFACE_GROUP:
      nodeProperties = <SurfaceGroupPropPanel />;
      break;
    case NodeType.MATERIAL_FLUID:
      nodeProperties = <MaterialFluidPropPanel />;
      break;
    case NodeType.MATERIAL_SOLID:
      nodeProperties = <MaterialSolidPropPanel />;
      break;
    case NodeType.MESH:
      nodeProperties = <MultiMeshParentPanel />;
      break;
    case NodeType.MESH_ADAPTATION_BOUNDARY:
      nodeProperties = <AdaptationBoundaryPropPanel />;
      break;
    case NodeType.MESH_BOUNDARY:
      nodeProperties = <MeshBoundaryPropPanel />;
      break;
    case NodeType.MESH_MODEL:
      nodeProperties = <MeshModelPropPanel />;
      break;
    case NodeType.MESH_SIZE:
      nodeProperties = <MeshSizePropPanel />;
      break;
    case NodeType.MOTION_GLOBAL_FRAME:
      nodeProperties = <MotionGlobalFramePropPanel />;
      break;
    case NodeType.MOTION_FRAME:
      nodeProperties = <MotionFramePropPanel />;
      break;
    case NodeType.OUTPUT:
      nodeProperties = <OutputPropPanel />;
      break;
    case NodeType.OUTPUT_CONTAINER:
      nodeProperties = <OutputsPropPanel />;
      break;
    case NodeType.REFERENCE_VALUE:
      nodeProperties = <ReferenceValuePropPanel />;
      break;
    case NodeType.MONITOR_PLANE:
      nodeProperties = <MonitorPlanePropPanel />;
      break;
    case NodeType.PARTICLE_GROUP:
      nodeProperties = <ParticleGroupPropPanel />;
      break;
    case NodeType.PHYSICAL_BEHAVIOR:
      nodeProperties = <PhysicalBehaviorPropPanel />;
      break;
    case NodeType.PHYSICS_FLUID:
      nodeProperties = <PhysicsFluidPropPanel />;
      break;
    case NodeType.PHYSICS_FLUID_BOUNDARY_CONDITION:
      nodeProperties = <PhysicsFluidBoundaryConditionPropPanel />;
      break;
    case NodeType.PHYSICS_FLUID_BOUNDARY_CONDITION_CONTAINER:
      nodeProperties = <PhysicsFluidBoundaryConditionContainerPropPanel />;
      break;
    case NodeType.PHYSICS_FLUID_INITIALIZATION:
      nodeProperties = <PhysicsFluidInitializationPropPanel />;
      break;
    case NodeType.PHYSICS_PERIODIC_PAIR:
      nodeProperties = <PhysicsPeriodicPropPanel />;
      break;
    case NodeType.PHYSICS_HEAT:
      nodeProperties = <PhysicsHeatPropPanel />;
      break;
    case NodeType.PHYSICS_HEAT_BOUNDARY_CONDITION:
      nodeProperties = <PhysicsHeatBoundaryConditionPropPanel />;
      break;
    case NodeType.PHYSICS_HEAT_BOUNDARY_CONDITION_CONTAINER:
      nodeProperties = <PhysicsHeatBoundaryConditionContainerPropPanel />;
      break;
    case NodeType.PHYSICS_HEAT_HEAT_SOURCE:
      nodeProperties = <PhysicsHeatHeatSourcePropPanel />;
      break;
    case NodeType.PHYSICS_HEAT_INITIALIZATION:
      nodeProperties = <PhysicsHeatInitializationPropPanel />;
      break;
    case NodeType.PHYSICS_MULTI:
      nodeProperties = <PhysicsMultiPropPanel />;
      break;
    case NodeType.PHYSICS_MULTI_INTERFACE:
      nodeProperties = <PhysicsMultiInterfacePropPanel />;
      break;
    case NodeType.PHYSICS_SLIDING_INTERFACE:
      nodeProperties = <PhysicsSlidingInterfacePropPanel />;
      break;
    case NodeType.PHYSICS_VOLUME_SELECTION:
      nodeProperties = <PhysicsVolumeSelectionPropPanel />;
      break;
    case NodeType.PLOT:
      nodeProperties = <PlotPropPanel />;
      break;
    case NodeType.POROUS_MODEL:
      nodeProperties = <PorousModelPropPanel />;
      break;
    case NodeType.PROBE_POINT:
      nodeProperties = <ProbePointPropPanel />;
      break;
    case NodeType.REFINEMENT_REGION:
      nodeProperties = <RefinementRegionPropPanel />;
      break;
    case NodeType.STOPPING_CONDITIONS:
      nodeProperties = <StoppingConditionsPropPanel />;
      break;
    case NodeType.VOLUME:
      nodeProperties = <VolumePropPanel />;
      break;
    default:
      nodeProperties = <EmptyPropPanel />;
      break;
  }

  return (
    <Suspense fallback={suspenseWidget}>
      <ErrorBoundary
        fallbackRender={({ error }) => (
          <div>
            <p>The following error occurred: {error.message}</p>
            <p>Try reloading the page. If the error still exists, contact support.</p>
          </div>
        )}
        resetKeys={[selectedNode]}>
        <div className={classes.root} key={id}>
          {nodeProperties}
        </div>
      </ErrorBoundary>
    </Suspense>
  );
};
