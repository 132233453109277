// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { LCVKeyModifier, LCVObject, LCVSelectionType, LCVType, kLCVInvalidObjectId } from '@luminarycloudinternal/lcvis';

import { LcvModule } from '../../types';
import { LcvData1D } from '../LcvData1D';
import { LcvFrame } from '../LcvFrame';

import { LcvWidget } from './LcvWidget';

/**
 * A widget which receives mouse events and returns an array
 * of the indices in the importDatasetFilter dataset corresponding to the selected surfaces.
 */
export class LcvSelectionWidget extends LcvWidget {
  /** Whether we've already registered a callback to this widget */
  hasCallback = false;

  hasMouseoverCallback = false;

  constructor(
    lcv: LcvModule,
    sessionHandle: number,
    frame: LcvFrame,
  ) {
    super(lcv, sessionHandle, 'selection');
    this.attachFrame(frame);
  }

  getSelection(): number[] {
    const selectionHandle = this.getProperty('selection', LCVType.kLCVDataTypeData1D);
    const selectionData1D = new LcvData1D(this.lcv, selectionHandle, this.sessionHandle);
    const mappedData = selectionData1D.mapData();
    const ptr = mappedData.mapping;
    const nSelection = mappedData.size;
    // Selection contains nSelection (object ID, primitive ID) tuples
    const selection = new Uint32Array(this.lcv.memory(), ptr, nSelection * 2).slice();
    selectionData1D.unmapData();
    selectionData1D.release();

    const arr = (selection[0] === kLCVInvalidObjectId) ? [] : Array.from(selection);
    return arr;
  }

  /** Add a callback which fires whenever the current selection changes. */
  setSelectionCallback(callback: (selection: number[],
                                  controlKey: boolean,
                                  selectionType: LCVSelectionType) => void) {
    const internalCb = (
      _lcv: LcvModule,
      _session: LCVObject,
      obj: LCVObject,
      message: string,
    ) => {
      const selection = this.getSelection();
      const modifiers = this.getProperty('modifiers', LCVType.kLCVDataTypeUint);
      // eslint-disable-next-line no-bitwise
      const control : boolean = (modifiers & LCVKeyModifier.kLCVKeyModifierCtrl) > 0;
      const selectionType = this.getProperty(
        'selection_type',
        LCVType.kLCVDataTypeUint,
      ) as LCVSelectionType;
      callback(selection, control, selectionType);
    };
    this.setParam('selection_updated_callback', LCVType.kLCVDataTypeFunction, internalCb);
  }

  setMouseOverCallback(callback: ((selection: number[], message?: string) => void) | null) {
    if (!callback) {
      // if this function is called with callback === null, unset the callback on the widget.
      this.setParam('mouse_over_updated_callback', LCVType.kLCVDataTypeFunction, null);
      return;
    }
    const internalCb = (
      _lcv: LcvModule,
      _session: LCVObject,
      obj: LCVObject,
      message: string,
    ) => {
      const objectId = this.getProperty('mouse_over_object_id', LCVType.kLCVDataTypeUint2);
      callback(objectId, message);
    };

    this.setParam('mouse_over_updated_callback', LCVType.kLCVDataTypeFunction, internalCb);
    this.hasMouseoverCallback = true;
  }
}
