// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, useContext, useRef, useState } from 'react';

import cx from 'classnames';

import { CommonMenuItem } from '../../../lib/componentTypes/menu';
import { ColumnLabel, ColumnState, MultiRowHeaderConfig } from '../../../lib/componentTypes/table';
import { useClickBasedOpenState } from '../../../lib/useClickBasedOpenState';
import { useDataTableSelectedRows } from '../../../state/internal/component/dataTable';
import Form from '../../Form';
import { CommonMenu } from '../../Menu/CommonMenu';
import Tooltip from '../../Tooltip';
import { ArrowDownIcon } from '../../svg/ArrowDownIcon';
import { GearIcon } from '../../svg/GearIcon';
import Collapsible from '../../transition/Collapsible';

import { ColumnSettingsPanel } from './ColumnSettingsPanel';
import { TableContext } from './context';
import {
  getCellContentClasses,
  getCellSizingClasses,
  getCellSortClasses,
  getColumnLabelLines,
  getMultiRowHeaderConfig,
  labelToLines,
} from './util';

interface HeaderCellProps {
  column?: ColumnState;
  label?: ColumnLabel;
  colspan?: number;
  rowspan?: number;
}

const HeaderCell = (props: HeaderCellProps) => {
  const { colspan, rowspan, label, column } = props;

  const labelLines = column ?
    getColumnLabelLines(column.config.id, label ?? column?.config.label ?? undefined) :
    labelToLines(label ?? '');

  const [showMenu, setShowMenu] = useState(false);
  const anchorRef = useRef(null);

  const highlight = column?.config.displayOptions?.highlight;

  return (
    <TableContext.Consumer>
      {(context) => {
        const {
          disableSortHighlighting, changeColumnVisibility, currentSort, sortOnColumn,
          density, variant,
        } = context;

        const contentClasses = column ? getCellContentClasses(column, false) : [];
        const sizingClasses = column ? getCellSizingClasses(column) : [];
        const sortClasses = (column && currentSort && !disableSortHighlighting) ?
          getCellSortClasses(currentSort, column) : [];

        const lines = labelLines.map((line, i) => (
          <div className="labelLine" key={line}>{line}</div>
        ));

        const disableSorting = !column || column.config.disableSorting || context.disableSorting;

        const menuItems: CommonMenuItem[] = [];
        if (column) {
          if (!disableSorting) {
            menuItems.push(
              {
                label: 'Sort Ascending',
                onClick: () => sortOnColumn?.(column, false),
                startIcon: { name: 'sortAsc' },
                selected: currentSort?.columnId === column.config.id && !currentSort.descending,
              },
              {
                label: 'Sort Descending',
                onClick: () => sortOnColumn?.(column, true),
                startIcon: { name: 'sortDesc' },
                selected: currentSort?.columnId === column.config.id && currentSort.descending,
              },
            );
          }

          if (!column.config.disableVisibility) {
            menuItems.push({
              label: 'Hide',
              onClick: () => changeColumnVisibility?.(column.config.id, false),
              startIcon: { name: 'eyeOff' },
            });
          }
        }

        return (
          <th
            className={cx(contentClasses, sizingClasses, sortClasses, `${variant}`, { highlight })}
            colSpan={colspan}
            onContextMenu={(event) => {
              if (menuItems.length && !showMenu) {
                event.preventDefault();
                setShowMenu(true);
              }
            }}
            ref={anchorRef}
            rowSpan={rowspan}>
            <Tooltip title={column?.config.description || ''}>
              <button
                className={cx('cellContent control', `${density}`)}
                disabled={disableSorting}
                onClick={() => {
                  if (column) {
                    context.clickColumnHeaderCell?.(column);
                  }
                }}
                type="button">
                <div className="headerCellLines">{lines}</div>
                {!disableSorting && (
                  <div className="sortIndicator">
                    <ArrowDownIcon maxHeight={10} maxWidth={10} />
                  </div>
                )}
              </button>
            </Tooltip>
            <CommonMenu
              anchorEl={anchorRef.current}
              closeOnSelect
              menuItems={menuItems}
              onClose={() => setShowMenu(false)}
              open={showMenu}
              position="below-right"
            />
          </th>
        );
      }}
    </TableContext.Consumer>
  );
};

interface HeaderSystemColumnProps {
  rowspan?: number;
}

interface HeaderColumnSelectCellProps extends HeaderSystemColumnProps {
  tableName: string;
}

const HeaderColumnSelectCell = (props: HeaderColumnSelectCellProps) => {
  const { rowspan = 1, tableName } = props;

  const [selectedRows, setSelectedRows] = useDataTableSelectedRows(tableName);

  return (
    <TableContext.Consumer>
      {(context) => {
        const { selectableRowIds, showSelectionColumn, density } = context;

        if (showSelectionColumn && selectableRowIds && selectedRows && setSelectedRows) {
          const someSelected = selectableRowIds.some((rowId) => selectedRows?.has(rowId));
          const someUnselected = selectableRowIds.some((rowId) => !selectedRows?.has(rowId));

          const checked = selectableRowIds.every((rowId) => selectedRows.has(rowId));
          const indeterminate = !checked && someSelected && someUnselected;

          return (
            <th
              className="control"
              rowSpan={rowspan}>
              <div className={cx('cellContent control', `${density}`)}>
                <Form.CheckBox
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={() => {
                    setSelectedRows(() => {
                      // If the table has mixed row selections (indeterminate) or it's unchecked,
                      // then interpret this click as a positive selection for all rows.
                      if (indeterminate || !checked) {
                        return new Set(selectableRowIds);
                      }

                      return new Set();
                    });
                  }}
                />
              </div>
            </th>
          );
        }
        return null;
      }}
    </TableContext.Consumer>
  );
};

const HeaderColumnControlCell = (props: HeaderSystemColumnProps) => {
  const { rowspan = 1 } = props;

  const { density } = useContext(TableContext);

  const controlRef = useRef<HTMLButtonElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const { open, setOpen } = useClickBasedOpenState(controlRef, panelRef);

  return (
    <TableContext.Consumer>
      {(context) => {
        const { disableColumnSettings, showControlColumn } = context;

        if (showControlColumn) {
          return (
            <th
              className="control"
              rowSpan={rowspan}>
              {!disableColumnSettings && (
                <>
                  <Tooltip title={open ? '' : 'Table settings'}>
                    <button
                      className={cx('cellContent control', { engaged: open }, `${density}`)}
                      onClick={(event: React.MouseEvent) => {
                        setOpen((oldValue) => !oldValue);
                      }}
                      ref={controlRef}
                      type="button">
                      <GearIcon maxHeight={16} maxWidth={16} />
                    </button>
                  </Tooltip>
                  <div className="controlCellMenu">
                    <Collapsible collapsed={!open} transitionPeriod={250}>
                      <div ref={panelRef}>
                        <ColumnSettingsPanel />
                      </div>
                    </Collapsible>
                  </div>
                </>
              )}
            </th>
          );
        }
        return null;
      }}
    </TableContext.Consumer>
  );
};

/**
 * Used when one or more column configs have a 'superLabel' value, this component uses two header
 * rows, along with appropriate rowSpan and colSpan values to generate the right header structure.
 */
interface MultiRowsProps {
  tableName: string;
  headerRowConfig: MultiRowHeaderConfig[];
}

function MultiRows(props: MultiRowsProps) {
  const { headerRowConfig, tableName } = props;

  // When one or more columns have a "superLabel" value, the table header is rendered with two
  // rows, which are initialized here.
  const firstCells: ReactNode[] = [];
  const secondCells: ReactNode[] = [];

  firstCells.push(
    <HeaderColumnSelectCell
      key="__systemColumnSelect"
      rowspan={2}
      tableName={tableName}
    />,
  );

  headerRowConfig.forEach((rowconfig, i) => {
    const { superLabel, columns, key } = rowconfig;

    if (superLabel) {
      firstCells.push(
        <HeaderCell
          colspan={columns.length}
          key={key}
          label={superLabel}
        />,
      );
      secondCells.push(
        ...columns.map((column) => {
          const { config } = column;
          return (
            <HeaderCell
              column={column}
              key={`header-${config.id}`}
            />
          );
        }),
      );
    } else {
      firstCells.push(
        ...columns.map((column) => {
          const { config } = column;
          return (
            <HeaderCell
              column={column}
              key={`header-${config.id}`}
              rowspan={2}
            />
          );
        }),
      );
    }
  });

  firstCells.push(
    <HeaderColumnControlCell
      key="__systemColumnControl"
      rowspan={2}
    />,
  );

  return (
    <>
      <tr>{firstCells}</tr>
      <tr>{secondCells}</tr>
    </>
  );
}

export interface TableHeaderProps {
  tableName: string;
  columns: ColumnState[];
}

export function TableHeader(props: TableHeaderProps) {
  const { columns, tableName } = props;

  return (
    <thead>
      <MultiRows
        headerRowConfig={getMultiRowHeaderConfig(columns)}
        tableName={tableName}
      />
    </thead>
  );
}
