// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect, useMemo } from 'react';

import { Empty } from '@bufbuild/protobuf';
import { ConnectError } from '@connectrpc/connect';
import { atom, useRecoilState } from 'recoil';

import { getLcUserId } from '../lib/jwt';
import * as rpc from '../lib/rpc';
import { addRpcError } from '../lib/transientNotification';
import * as frontendpb from '../proto/frontend/frontend_pb';

const rpcPool = new rpc.StreamingRpcPool<Empty, frontendpb.AccountInfoReply>(
  'AccountInfo',
  rpc.client.accountInfo,
);

export const accountInfoState = atom<frontendpb.AccountInfoReply | null>({
  key: 'accountInfo',
  default: null,
});

export default function useAccountInfo(): frontendpb.AccountInfoReply | null {
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoState);
  useEffect(
    () => rpcPool.start(
      'AccountInfo',
      () => new Empty(),
      (reply: frontendpb.AccountInfoReply) => setAccountInfo(reply),
      (err: ConnectError) => addRpcError('Failed to get account info', err),
    ),
    [setAccountInfo],
  );

  return accountInfo;
}

export const useCurrentUser = () => {
  const accountInfo = useAccountInfo();

  return useMemo(() => (accountInfo?.user || []).find(
    (user) => user.lcUserId === getLcUserId(),
  ), [accountInfo]);
};

export const useIsAdmin = () => {
  const currentUser = useCurrentUser();
  return currentUser?.accountRole === 'admin';
};
