// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

// Тhe LeftNav is the main navigation shown as a sidebar for all pages outside of a project.

import React, { ReactNode } from 'react';

import cx from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import * as AuthV2 from '../lib/AuthV2';
import { IconName } from '../lib/componentTypes/svgIcon';
import { colors } from '../lib/designSystem';
import { isUnmodifiedEnterKey, isUnmodifiedSpaceKey } from '../lib/event';
import { projectsLink, routes } from '../lib/navigation';
import { PlatformAdminIcon, PlatformAdminRoleType } from '../lib/platformAdminUtils';
import { validRoles } from '../lib/systemRoles';
import useAccountInfo, { useIsAdmin } from '../recoil/useAccountInfo';
import { useEnabledPlatformAdminRoles } from '../recoil/usePersonalPlatformAdmin';
import { useIsStaff } from '../state/external/user/frontendRole';
import { LeftNavSectionsState, useLeftNavExpandedSectionsState, useLeftNavExpandedValue } from '../state/internal/component/leftNav';

import { SvgIcon } from './Icon/SvgIcon';
import { TRANSITION_DELAY, TRANSITION_TIME } from './LeftNavToggle';
import { createStyles, makeStyles } from './Theme';
import Tooltip from './Tooltip';
import { ChevronDownIcon } from './svg/ChevronDownIcon';
import { ChevronUpIcon } from './svg/ChevronUpIcon';
import Collapsible from './transition/Collapsible';

const ICON_SIZE = 16;
const LEFT_PADDING = 10;

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingTop: '22px',
      paddingBottom: '40px',
      overflow: 'hidden',
    },
    item: {
      '--backgroundColor': colors.neutral50,

      backgroundColor: 'var(--backgroundColor)',
      display: 'flex',
      alignItems: 'center',
      padding: `8px 16px 8px ${LEFT_PADDING}px`,
      borderLeft: '6px solid transparent',
      cursor: 'pointer',
      fontSize: '14px',
      lineHeight: '20px',
      height: '40px',
      userSelect: 'none',
      overflow: 'hidden',
      position: 'relative',

      '&::after': {
        content: '""',
        position: 'absolute',
        right: 0,
        width: '24px',
        top: 0,
        height: '100%',
        backgroundColor: 'var(--backgroundColor)',
      },

      '&:hover': {
        '--backgroundColor': colors.surfaceMedium1,
        borderLeftColor: colors.neutral300,
      },

      '&.nested': {
        paddingLeft: 'var(--nested-item-padding)',
        transition: `padding-left ${TRANSITION_TIME}ms`,
        transitionDelay: `${TRANSITION_DELAY}ms`,
      },

      '&.selected': {
        '--backgroundColor': colors.purple50,
        borderLeftColor: colors.primaryCta,
      },

      '&.group': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: colors.mediumEmphasisText,
      },

      '&.link': {
        textDecoration: 'none',
        color: colors.mediumEmphasisText,
      },

      '&:hover .LeftNav-itemIconAndText': {
        color: 'white',
      },
    },

    itemIconAndText: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      color: colors.neutral850,
    },

    itemIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '32px',
      height: ICON_SIZE,
    },

    arrowIcon: {
      position: 'absolute',
      top: 0,
      height: '100%',
      width: '24px',
      backgroundColor: 'var(--backgroundColor)',
      right: 'var(--group-arrow-padding)',
      zIndex: '1',
      transition: `right ${TRANSITION_TIME}ms`,
      transitionDelay: `${TRANSITION_DELAY}ms`,

      '& > svg': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
    },
  }),
  { name: 'LeftNav' },
);

interface ModifiedTooltipProps {
  title: string;
  children: ReactNode;
}

export const ModifiedTooltip = (props: ModifiedTooltipProps) => {
  const { title, children } = props;

  return (
    <Tooltip
      arrow={false}
      placement="right"
      PopperProps={{ modifiers: [{ name: 'offset', options: { offset: [30, -45] } }] }}
      title={title}>
      <span>
        {children}
      </span>
    </Tooltip>
  );
};

interface NavItem {
  text: string;
  iconName: IconName;
  link?: string;
  onClick?: () => void;
  group?: NavItem[];
  locator: string;
}

export const LeftNav = () => {
  const classes = useStyles();
  const accountInfo = useAccountInfo();
  const location = useLocation();
  const [leftNavExpandedSections, setLeftNavExpandedSections] = useLeftNavExpandedSectionsState();
  const leftNavExpanded = useLeftNavExpandedValue();

  const roles = validRoles();

  const isAdmin = useIsAdmin();
  const isStaff = useIsStaff();
  const platformAdminsRoles = useEnabledPlatformAdminRoles();

  const usageNavItems: NavItem[] = [
    {
      link: routes.account,
      text: 'Profile',
      iconName: 'person',
      locator: 'left-nav-link-profile',
    },
    {
      link: routes.accountUsage,
      text: 'My Usage',
      iconName: 'barChart',
      locator: 'left-nav-link-account-usage',
    },
  ];

  const navItems: NavItem[] = [
    {
      link: routes.getStarted,
      text: 'Get Started',
      iconName: 'diskLightning',
      locator: 'left-nav-link-get-started',
    },
    {
      link: projectsLink(),
      text: 'Projects',
      iconName: 'documentClipped',
      locator: 'left-nav-link-project-list',
    },
    {
      link: routes.library,
      text: 'Library',
      iconName: 'fileDrawer',
      locator: 'left-nav-link-library',
    },
    {
      text: 'My Account',
      iconName: 'person',
      locator: 'left-nav-link-my-account',
      group: usageNavItems,
    },
  ];

  const adminNavItems: NavItem[] = [];

  if (isAdmin) {
    adminNavItems.push(
      {
        link: routes.adminUsers,
        text: 'Users',
        iconName: 'people',
        locator: 'left-nav-link-users',
      },
      {
        link: routes.adminUsage,
        text: 'Usage',
        iconName: 'barChart',
        locator: 'left-nav-link-usage',
      },
    );
  }

  if (accountInfo?.hasSecurityInfo) {
    adminNavItems.push({
      link: routes.adminSecurity,
      text: 'Security',
      iconName: 'shield',
      locator: 'left-nav-link-security',
    });
  }

  if (accountInfo?.billing) {
    adminNavItems.push({
      link: routes.adminBilling,
      text: 'Billing',
      iconName: 'bill',
      locator: 'left-nav-link-billing',
    });
  }

  if (roles.length > 1) {
    adminNavItems.push({
      link: routes.adminSettings,
      text: 'Settings',
      iconName: 'gear',
      locator: 'left-nav-link-settings',
    });
  }

  // Add the admin routes into a new collapsible section
  if (adminNavItems.length) {
    navItems.push({
      group: adminNavItems,
      text: 'Administration',
      iconName: 'clipboardCheck',
      locator: 'left-nav-link-administration',
    });
  }

  const platformNavItems: NavItem[] = [];

  if (isStaff) {
    // let all staff see the platform admin admin page.
    platformNavItems.push(
      {
        link: routes.platformAdminTeam,
        text: 'Platform Admins',
        iconName: PlatformAdminIcon.ADMIN,
        locator: 'left-nav-link-platform-admins',
      },
    );
    if (platformAdminsRoles.includes(PlatformAdminRoleType.SUPPORT)) {
      platformNavItems.push(
        {
          link: routes.platformAdminSupport,
          text: 'Support',
          iconName: PlatformAdminIcon.SUPPORT,
          locator: 'left-nav-link-platform-support',
        },
      );
    }
    if (platformAdminsRoles.includes(PlatformAdminRoleType.SAMPLE)) {
      platformNavItems.push(
        {
          link: routes.platformAdminSample,
          text: 'Sample Projects',
          iconName: PlatformAdminIcon.SAMPLE,
          locator: 'left-nav-link-platform-support',
        },
      );
    }
    if (platformAdminsRoles.includes(PlatformAdminRoleType.BIZOPS)) {
      platformNavItems.push(

        {
          link: routes.platformAdminBizops,
          text: 'Bizops',
          iconName: PlatformAdminIcon.BIZOPS,
          locator: 'left-nav-link-platform-support',
        },
      );
    }
  }

  // Add the platform routes into a new collapsible section
  if (platformNavItems.length) {
    navItems.push({
      group: platformNavItems,
      text: 'Platform',
      iconName: 'lcLogoGraphOnly',
      locator: 'left-nav-link-platform',
    });
  }

  // The items on the bottom of the nav sidebar
  const bottomNavItems: NavItem[] = [{
    onClick: () => {
      AuthV2.logout();
    },
    text: 'Logout',
    iconName: 'logout',
    locator: 'left-nav-logout',
  }];

  const renderIcon = (iconName: IconName) => (
    <span className={cx(classes.itemIcon)}>
      <SvgIcon maxHeight={ICON_SIZE} maxWidth={ICON_SIZE} name={iconName} />
    </span>
  );

  const renderIconWithText = (link: NavItem) => (
    <div className={classes.itemIconAndText}>
      {renderIcon(link.iconName)}
      {link.text}
    </div>
  );

  const renderLink = (link: NavItem, nested: boolean) => {
    const selected = link.link === location.pathname;
    const className = cx(classes.item, 'link', { selected, nested });

    // if the item has a link, render as a Link, otherwise render as a clickable div
    if (link.link) {
      return (
        <Link
          aria-label={link.text}
          className={className}
          data-locator={link.locator}
          to={link.link!}>
          {renderIconWithText(link)}
        </Link>
      );
    }

    return (
      <div
        aria-label={link.text}
        className={className}
        data-locator={link.locator}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
            link.onClick!();
        }}
        onKeyUp={(event) => {
          if (isUnmodifiedEnterKey(event)) {
              link.onClick!();
          }
        }}
        role="button"
        tabIndex={0}>
        {renderIconWithText(link)}
      </div>
    );
  };

  const getSubMenuKey = (group: NavItem[]): keyof LeftNavSectionsState => {
    if (group === adminNavItems) {
      return 'administration';
    }
    if (group === platformNavItems) {
      return 'platform';
    }
    if (group === usageNavItems) {
      return 'myaccount';
    }
    throw Error('Unknown submenu');
  };

  const toggleSubmenu = (group: NavItem[]) => {
    const submenuKey = getSubMenuKey(group);

    setLeftNavExpandedSections((oldValue) => ({
      ...oldValue,
      [submenuKey]: !oldValue[submenuKey],
    }));
  };

  const renderNavItem = (item: NavItem) => {
    if (item.link || item.onClick) {
      return renderLink(item, false);
    }
    if (item.group) {
      const subNav = leftNavExpandedSections[getSubMenuKey(item.group)];
      return (
        <React.Fragment>
          <div
            aria-label={item.text}
            className={cx(classes.item, 'group')}
            onClick={() => toggleSubmenu(item.group!)}
            onKeyUp={(event) => {
              if (isUnmodifiedSpaceKey(event)) {
                toggleSubmenu(item.group!);
              }
            }}
            role="button"
            tabIndex={0}>
            {renderIconWithText(item)}
            <div className={cx(classes.arrowIcon, leftNavExpanded && 'expanded')}>
              {subNav ? (
                <ChevronUpIcon maxWidth={8} />
              ) : (
                <ChevronDownIcon maxWidth={8} />
              )}
            </div>
          </div>
          <Collapsible collapsed={!subNav} overflowHidden>
            {item.group.map(
              (groupItem) => (
                <React.Fragment key={groupItem.text}>
                  {renderLink(groupItem, true)}
                </React.Fragment>
              ),
            )}
          </Collapsible>
        </React.Fragment>
      );
    }
    return null;
  };

  return (
    <div className={classes.root} data-locator="left-nav">
      <div>
        {navItems.map(
          (item) => <React.Fragment key={item.text}>{renderNavItem(item)}</React.Fragment>,
        )}
      </div>
      <div>
        {bottomNavItems.map(
          (item) => <React.Fragment key={item.text}>{renderNavItem(item)}</React.Fragment>,
        )}
      </div>
    </div>
  );
};
