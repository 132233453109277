// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { Vector3 } from '../../../proto/base/base_pb';
import { CenterInput } from '../PrimitiveInputs/CenterInput';
import { LengthVectorInput } from '../PrimitiveInputs/LengthVectorInput';

export type Box = {
  center: { x: number, y: number, z: number },
  size: { x: number, y: number, z: number },
}

export interface BoxInputProps {
  value: Box,
  onCommit: (value: Box) => void,
  disabled?: boolean,
}

export const BoxInput = (props: BoxInputProps) => {
  const { value, onCommit, disabled = false } = props;
  const { center, size } = value;

  const handleCenterChange = (newCenter: Vector3) => {
    onCommit({ ...value, center: { x: newCenter.x, y: newCenter.y, z: newCenter.z } });
  };

  const handleSizeChange = (newSize: Vector3) => {
    onCommit({ ...value, size: { x: newSize.x, y: newSize.y, z: newSize.z } });
  };

  return (
    <>
      <CenterInput
        disabled={disabled}
        label="Center"
        onCommit={handleCenterChange}
        value={new Vector3({ ...center })}
      />
      <LengthVectorInput
        disabled={disabled}
        label="Lengths"
        onCommit={handleSizeChange}
        value={new Vector3({ ...size })}
      />
    </>
  );
};
