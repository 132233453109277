// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import * as basepb from '../proto/base/base_pb';
import * as frontendpb from '../proto/frontend/frontend_pb';
import { Code } from '../proto/lcstatus/codes_pb';
import * as lcstatuspb from '../proto/lcstatus/lcstatus_pb';
import { Level } from '../proto/lcstatus/levels_pb';

import { IconName } from './componentTypes/svgIcon';
import { EMPTY_VALUE } from './constants';
import { colors } from './designSystem';
import { formatNumber, fromBigInt } from './number';
import { gpuTypeName } from './projectDataUtils';
import { Parser } from './status';
import { detailedStatus } from './stoppingCondsUtils';

export type JobStatusIconType = 'success' | 'warning' | 'error' | 'info' | undefined;

export const JOB_STATUS_TYPE_NAMES = [
  'Invalid', 'Active', 'Completed', 'Failed', 'Canceled', 'Pending Retry', 'Suspending',
];

export function hasBatchMessage(message: string) {
  return message.includes('Queued for Batch');
}

export function isJobInActiveState(job: frontendpb.GetWorkflowReply_Job | null) {
  return job?.status?.typ === basepb.JobStatusType.Active;
}

export function isJobInStoppedState(job: frontendpb.GetWorkflowReply_Job | null) {
  const statusType = job?.status?.typ;
  if (statusType) {
    return [
      basepb.JobStatusType.Completed,
      basepb.JobStatusType.Failed,
      basepb.JobStatusType.Invalid,
      basepb.JobStatusType.Suspended,
    ].includes(statusType);
  }
  return null;
}

function getLcStatusUserMessage(status: lcstatuspb.LCStatus) {
  switch (status.code) {
    case Code.LC_INTERNAL:
      return 'An internal error occured. Please contact support.';
    default:
      return 'An unknown error has occured. Please contact support.';
  }
}

type JobState =
  'failed' | // Job has failed
  'suspended' | // Job is suspended
  'active' | // Job is active and no schedule event has been received
  'completed' | // Job successfully completed
  'running'; // Job is active and running (scheduler event message contains 'Running')

type JobStatus = {
  state?: JobState,
  message: string,
}

// Returns the state and a message for a job. State can only have values 'failed', 'suspended'
// or 'running'. If it is undefined the job is currently being scheduled.
export function getJobStatus(job: frontendpb.GetWorkflowReply_Job | null): JobStatus {
  if (!job) {
    return { message: '' };
  }
  const jobStatus = job.status!;
  const typ = jobStatus.typ;
  switch (typ) {
    case basepb.JobStatusType.Failed:
      if (jobStatus.failedReason.case === 'status') {
        const parser = new Parser(jobStatus.failedReason.value);
        return { state: 'failed', message: parser.headlineMessage() };
      }
      if (
        jobStatus.failedReason.case === 'lcstatus' &&
        jobStatus.failedReason.value.level === Level.ERROR
      ) {
        return {
          state: 'failed',
          message: getLcStatusUserMessage(jobStatus.failedReason.value),
        };
      }
      break;
    case basepb.JobStatusType.Active: {
      const event = job.lastSchedulerEvent;
      // If we have a scheduler event we can determine a state, otherwise
      // the state will be 'undefined' and we return the "default" status at
      // the end of this function.
      if (event) {
        if (event.typ.case === 'error') {
          return {
            state: 'failed',
            message: event.typ.value.message,
          };
        }
        // We only set the running state if the scheduler event is 'Running'
        const message = event.typ.case === 'message' ? event.typ.value : '';
        return {
          state: (message.includes('Running') ||
          message.includes('Initializing Solver')) ? 'running' : 'active',
          message,
        };
      }
    }
      break;
    case basepb.JobStatusType.Suspended:
    case basepb.JobStatusType.Suspending:
      return { state: 'suspended', message: JOB_STATUS_TYPE_NAMES[jobStatus.typ] };
    case basepb.JobStatusType.Completed:
      return { state: 'completed', message: JOB_STATUS_TYPE_NAMES[jobStatus.typ] };
    case basepb.JobStatusType.PendingRetry:
    case basepb.JobStatusType.Invalid:
      break;
    // no default
  }
  return { message: JOB_STATUS_TYPE_NAMES[jobStatus.typ] };
}

// Returns an icon type for the job depending on the status of the job. This is used in the control
// panel where we show a clickable status icon that opens the Run Status pane.
// The logic that we use for determining which type to return should match the logic that is
// used in the <JobStatus> component to determine which <StatusIconMessage> to return.
export function getJobStatusIconType(
  job: frontendpb.GetWorkflowReply_Job | null,
  stopStatus: frontendpb.StoppingConditionReply | null,
): JobStatusIconType {
  const { state } = getJobStatus(job);

  if (state === 'failed') {
    return 'error';
  }
  if (state === 'suspended') {
    return 'info';
  }
  if (stopStatus && isJobInStoppedState(job)) {
    if (detailedStatus(stopStatus)) {
      return 'warning';
    }
    return 'success';
  }
  return undefined;
}

type IconProps = { color: string, name: IconName; maxWidth?: number; maxHeight?: number };

// Given a particular type, the function returns props for the SvgIcon to display a correct icon.
// This is used both in the Run Status panel AND in the control panel where we have a clickable
// status icon that opens the said Run Status.
export function getJobStatusIconProps(type: JobStatusIconType): IconProps {
  switch (type) {
    case 'success':
      return {
        color: colors.green600,
        name: 'diskCheck',
      };
    case 'warning':
      return {
        color: colors.green600,
        name: 'successWithWarning',
        maxWidth: 15,
        maxHeight: 15,
      };
    case 'error':
      return {
        color: colors.red500,
        name: 'diskExclamation',
      };
    case 'info':
      return {
        color: colors.neutral750,
        name: 'diskInfo',
      };
    default:
      throw Error('Unexpected icon type');
  }
}

// In the results table, only display job run time if the job is completed. Otherwise, show
// EMPTY_VALUE.
export function getJobRuntime(job: frontendpb.GetWorkflowReply_Job | null) {
  if (!job) {
    return '';
  }
  // display the run time only for jobs that are not running.
  if (isJobInStoppedState(job)) {
    return fromBigInt(job.runTime);
  }
  return EMPTY_VALUE;
}

export function getJobCredits(job: frontendpb.GetWorkflowReply_Job | null) {
  // mCredits * (1 Credit / 1000 mCredits)
  if (job?.credits) {
    return formatNumber(fromBigInt(job.credits) / 1000, { numDecimals: 2 });
  }
  if (job) {
    return EMPTY_VALUE;
  }
  return '';
}

export function getJobGpu(job: frontendpb.GetWorkflowReply_Job | null) {
  const stat = job?.lastIncarnationStat;
  if (!stat) {
    return EMPTY_VALUE;
  }
  const gpu = stat.gpuPref;
  if (!gpu) {
    return EMPTY_VALUE;
  }
  const gpuType = gpu.gpuType;
  const nPu = gpu.nPu;
  return `${gpuTypeName(gpuType)}⨉${nPu}`;
}
