// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useLocation } from 'react-router-dom';

import { isInExploration } from '../../../lib/navigation';
import { WORK_ORDER_TYPES } from '../../../lib/workOrderTypes';
import { isSensitivityAnalysis } from '../../../lib/workflowUtils';
import { usePendingWorkOrders } from '../../../recoil/pendingWorkOrders';
import { GeometryTreePositionType, useGeometryTreePosition } from '../../../recoil/useGeometryTreePosition';
import { useWorkflowState } from '../../../recoil/workflowState';
import { useIsRunStatusVisibleValue } from '../../../state/internal/component/isRunStatusVisible';
import { useLeftOverlayCardsWidthValue } from '../../../state/internal/component/leftOverlayCards';
import { useStatusCardPropsValue } from '../../../state/internal/component/statusCard';
import { useIsAnalysisView, useIsGeometryView, useIsSetupView } from '../../../state/internal/global/currentView';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import { useProjectContext } from '../../context/ProjectContext';

const EDGE_PADDING = 20;
const COLOR_SETTINGS_WIDTH = 150;
const RIGHT_PANEL_TOGGLE_WIDTH = 20;

/**
 * Returns the left and right attributes to use for a floating element to be centered in the
 * viewport, depending on the visible cards.
 */
export const useToolbarMargin = (): { left: number, right: number } => {
  const location = useLocation();
  const { projectId, workflowId, jobId } = useProjectContext();
  const { viewState } = useParaviewContext();
  const isAnalysisView = useIsAnalysisView();
  const isGeometryView = useIsGeometryView();
  const isSetupView = useIsSetupView();
  const workflow = useWorkflowState(projectId, workflowId);
  const isRunStatusVisible = useIsRunStatusVisibleValue({ projectId, workflowId, jobId });
  const [pendingWorkOrders] = usePendingWorkOrders(projectId);
  const initialStatusCardProps = useStatusCardPropsValue(projectId);
  const geometryTreePosition = useGeometryTreePosition({ projectId, workflowId, jobId });
  const leftOverlayCardsWidth = useLeftOverlayCardsWidthValue();

  const isExploration = isInExploration(location.pathname);
  const isSensitivity = isExploration && !!workflow && isSensitivityAnalysis(workflow);

  const hasPendingWorkOrder = WORK_ORDER_TYPES.some((type) => pendingWorkOrders.workOrders[type]);

  // Leave some space from the left edge or for the leftPane/summary windows.
  let marginLeft = EDGE_PADDING;
  if (
    (!isSensitivity && !!workflow && isRunStatusVisible) || // Run Status panel
    (!isSensitivity && isExploration) || // Exploration panel in DoE simulation
    initialStatusCardProps.isVisible || // Meshing panel
    geometryTreePosition === GeometryTreePositionType.FLOATING || // Geometry tree in 3D viewer
    (
      hasPendingWorkOrder &&
      (isSetupView || isGeometryView)
    ) // Geometry processing in progress
  ) {
    marginLeft = EDGE_PADDING + leftOverlayCardsWidth;
  }

  function getMarginRight() {
    if (
      viewState?.data.length &&
      viewState.attrs &&
      isAnalysisView
    ) {
      return COLOR_SETTINGS_WIDTH;
    }
    if (isGeometryView) {
      return EDGE_PADDING + leftOverlayCardsWidth;
    }
    return EDGE_PADDING + RIGHT_PANEL_TOGGLE_WIDTH;
  }

  // Leave some space from the right edge or for the color settings.
  const marginRight = getMarginRight();

  return { left: marginLeft, right: marginRight };
};
