import React, { useEffect, useRef, useState } from 'react';

import cx from 'classnames';

import { colors } from '../../../lib/designSystem';
import { isUnmodifiedSpaceKey } from '../../../lib/event';
import { pushConfirmation, useSetConfirmations } from '../../../state/internal/dialog/confirmations';
import { ActionButton } from '../../Button/ActionButton';
import { createStyles, makeStyles } from '../../Theme';
import { FolderTriangle } from '../../controls/FolderTriangle';
import { FixedSizeWrapper } from '../../layout/FixedSizeWrapper';
import { ResetIcon } from '../../svg/ResetIcon';
import { SparkleDoubleIcon } from '../../svg/SparkleDoubleIcon';

import { DummyChat } from './Chat.stories';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      height: '100%',
      borderLeft: `1px solid ${colors.neutral50}`,
      width: '300px',
      transition: 'width 0.3s',
      flex: '0 0 auto',
      display: 'flex',
      flexDirection: 'column',

      '&.collapsed': {
        width: '36px',
        '&:hover': {
          cursor: 'w-resize',
        },
      },
    },

    header: {
      height: '36px',
      display: 'flex',
      justifyContent: 'space-between',
      // 4 top and bottom, 18 left, 10 right to account for reset button padding
      padding: '4px 10px 4px 18px',
      alignItems: 'center',
      flex: '0 0 auto',
      // box shadown on bottom
      boxShadow: `0px 1px 9px 0px rgba(0, 0, 0, 0.5)`,
      zIndex: 1,

      '&.collapsed': {
        justifyContent: 'center',
        padding: '4px 18px',
      },
    },
    headerGroup: {
      display: 'flex',
      gap: '6px',
      alignItems: 'center',
      fontWeight: 600,
      fontSize: '13px',
      color: colors.highEmphasisText,
    },
    toggleButton: {
      width: '6px',
      height: '16px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    body: {
      overflow: 'auto',
      flex: '1 1 auto',
      // This is a hack to make the chat scrollable while also preventing the entire chat
      // from expanding beyond the viewport height. The 94px is a magic number to match the
      // PageHeader and chat header.
      maxHeight: 'calc(100vh - 94px)',

      '&.collapsed': {
        display: 'none',
      },
    },
  }),
  { name: 'SideRail' },
);

/**
 * A rail component on the right of the screen which holds the assistant chat. It's collapsible.
 */
export const SideRail = () => {
  const classes = useStyles();
  const rootRef = useRef<HTMLDivElement>(null);
  const [collapsed, setCollapsed] = useState(false);
  const setConfirmStack = useSetConfirmations();

  useEffect(() => {
    if (!rootRef.current) {
      return;
    }
    const handleClick = (event: MouseEvent) => {
      setCollapsed((prev) => {
        if (prev) {
          return false;
        }
        return prev;
      });
    };

    const savedRootRef = rootRef.current;
    savedRootRef.addEventListener('click', handleClick);
    return () => savedRootRef.removeEventListener('click', handleClick);
  }, []);

  const onClickReset = () => {
    pushConfirmation(setConfirmStack, {
      title: 'Are you sure you want to reset your chat?',
      subtitle: `Resetting your chat will remove your chat history and 
            Lumi AI will no longer have context to your existing conversation. 
            Are you sure you want to proceed?`,
      onContinue: () => {
        // TODO: Make this do something to assistantMessageState or send some
        // rpc to reset the chat
      },
      destructive: true,
    });
  };

  const icon = (
    <FixedSizeWrapper height={12} width={12}>
      <SparkleDoubleIcon color={colors.purple800} />
    </FixedSizeWrapper>
  );

  const headerContent = (
    collapsed ? (
      icon
    ) : (
      <>
        <div className={classes.headerGroup}>
          <div
            className={classes.toggleButton}
            onClick={() => setCollapsed(true)}
            onKeyUp={(event) => {
              if (isUnmodifiedSpaceKey(event)) {
                setCollapsed(true);
              }
            }}
            role="button"
            tabIndex={0}>
            <FolderTriangle open={!collapsed} />
          </div>
          {icon}
          <div>Assistant</div>
        </div>
        <div className={classes.headerGroup}>
          <ActionButton
            kind="minimal"
            onClick={onClickReset}>
            <FixedSizeWrapper height={12} width={12}>
              <ResetIcon />
            </FixedSizeWrapper>
            <div style={{ fontSize: '13px' }}>Reset</div>
          </ActionButton>
        </div>
      </>
    )
  );

  return (
    <div className={cx(classes.root, { collapsed })} ref={rootRef}>
      <div className={cx(classes.header, { collapsed })}>
        {headerContent}
      </div>
      <div className={cx(classes.body, { collapsed })}>
        <DummyChat resizable={false} />
      </div>
    </div>
  );
};
