import React, { useState } from 'react';

import * as random from '../../../lib/random';
import { createStyles, makeStyles } from '../../Theme';

import { Chat, ChatMessage } from './Chat';

export default {
  title: 'Components/Chat',
  component: Chat,
};

const useStyles = makeStyles(() => createStyles({
  root: {
    overflow: 'auto',
    maxHeight: '100%',
    width: '600px',
    height: '400px',
    backgroundColor: 'inherit',
    border: '1px solid white',
    resize: 'both',
  },
}), { name: 'ChatStory' });

/**
 * A dummy chat component.
 */
export const DummyChat = (props: { resizable?: boolean }) => {
  const { resizable = true } = props;
  const classes = useStyles();

  const [messages, setMessages] = useState<ChatMessage[]>([{
    id: random.string(8),
    text: 'What can I help you with today?',
    timestamp: new Date(),
    isUser: false,
  }]);
  const timeoutRef = React.useRef<number | null>(null);

  const handleSendMessage = (message: string) => {
    console.log(`Sending message: ${message}`);
    setMessages((prev) => [
      ...prev,
      {
        id: random.string(8),
        text: message,
        timestamp: new Date(),
        isUser: true,
      },
    ]);

    // Simulate the assistant's response
    timeoutRef.current = window.setTimeout(() => {
      setMessages((prev) => [
        ...prev,
        {
          id: random.string(8),
          text: random.choice([
            'Oops, I don\'t know how to respond to that.',
            'This is a multi-line response. It can be quite long and contain multiple lines.' +
              ' This is a multi-line response. It can be quite long and contain multiple lines.' +
              ' This is a multi-line response. It can be quite long and contain multiple lines.',
            'That sounds good to me, but you should ask someone who knows better.',
            'I recommend reading the docs',
            'I don\'t really know lol.',
          ]),
          timestamp: new Date(),
          isUser: false,
        },
      ]);
    }, 1000);
  };

  if (resizable) {
    return (
      <div className={classes.root}>
        <Chat
          messages={messages}
          onSendMessage={handleSendMessage}
        />
      </div>
    );
  }

  return (
    <Chat
      messages={messages}
      onSendMessage={handleSendMessage}
    />
  );
};
