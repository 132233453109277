// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useAtom, useAtomValue } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

const STORAGE_KEY = 'overlay-cards-width';
const DEFAULT_WIDTH = 250;

const leftOverlayCardsWidthState = atomWithStorage(STORAGE_KEY, DEFAULT_WIDTH);

export const useLeftOverlayCardsWidth = () => useAtom(leftOverlayCardsWidthState);
export const useLeftOverlayCardsWidthValue = () => useAtomValue(leftOverlayCardsWidthState);
