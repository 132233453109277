// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback } from 'react';

import { useRecoilCallback } from 'recoil';

import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { getPhysicsId, getTypeLabel } from '../../lib/physicsUtils';
import { typesToAdd, usePhysicsSet } from '../../model/hooks/usePhysicsSet';
import * as simulationpb from '../../proto/client/simulation_pb';
import { addPhysicsResidualsCallback } from '../../recoil/addPhysicsResidualNode';
import { initializeNewNode, useSetNewNodes } from '../../recoil/nodeSession';
import { useControlPanelMode } from '../../recoil/useProjectPage';
import { useSetRowsOpened } from '../../recoil/useSimulationTreeState';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { useGeometryStatus } from '../hooks/useGeometryStatus';

import { AddNodeMenuButton } from './AddNodeButton';

export const AddPhysicsButton = () => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { setSelection } = useSelectionContext();

  // == Recoil
  const setNewNodes = useSetNewNodes();
  const [controlPanelMode] = useControlPanelMode();
  const setNodesOpened = useSetRowsOpened(projectId, controlPanelMode);
  const addResidualNodes = useRecoilCallback(addPhysicsResidualsCallback);

  // == Hooks
  const {
    addPhysics,
    disabledAddReason,
  } = usePhysicsSet(projectId, workflowId, jobId, readOnly);
  const { working } = useGeometryStatus();

  const addPhysicsNode = useCallback(async (type: simulationpb.Physics['params']['case']) => {
    const newPhysics = await addPhysics(type);
    const newId = getPhysicsId(newPhysics);
    if (newId) {
      setNewNodes((nodes) => [...nodes, initializeNewNode(newId)]);
      setSelection([newId]);
      setNodesOpened((oldValue) => ({ ...oldValue, [newId]: true }));
      await addResidualNodes([newPhysics], projectId, workflowId, jobId);
    }
  }, [
    addPhysics,
    setNewNodes,
    setNodesOpened,
    setSelection,
    addResidualNodes,
    projectId,
    workflowId,
    jobId,
  ]);

  if (readOnly) {
    return null;
  }

  const menuItems: CommonMenuItem[] = typesToAdd.map((type) => {
    const disabledReason = disabledAddReason(type);
    return {
      label: getTypeLabel(type),
      onClick: () => addPhysicsNode(type),
      disabledReason,
      disabled: !!disabledReason,
    };
  });

  return (
    <AddNodeMenuButton disabled={working} menuItems={menuItems} />
  );
};
