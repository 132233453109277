// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { ReactNode, useState } from 'react';

import * as flags from '../../flags';
import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { BATCH_PROCESSING_URL } from '../../lib/constants';
import { colors } from '../../lib/designSystem';
import { LeveledMessage } from '../../lib/notificationUtils';
import { copyJobConfig } from '../../lib/proto';
import { getSimCount } from '../../lib/simulationTree/utils';
import * as workflowpb from '../../proto/workflow/workflow_pb';
import { useEnabledExperiments } from '../../recoil/useExperimentConfig';
import { useSkipSetupSummaryValue } from '../../recoil/user';
import { useCurrentConfig } from '../../recoil/workflowConfig';
import { analytics } from '../../services/analytics';
import { pushConfirmation, useSetConfirmations } from '../../state/internal/dialog/confirmations';
import { useIsGeometryView, useIsSetupView } from '../../state/internal/global/currentView';
import { SplitButton } from '../Button/SplitButton';
import { RadioButton } from '../Form/RadioButton';
import { createStyles, makeStyles } from '../Theme';
import Tooltip from '../Tooltip';
import { useProjectContext } from '../context/ProjectContext';
import { SetupSummaryDialog } from '../dialog/SetupSummary';
import { useRuntimeEstimate } from '../hooks/useRuntimeEstimate';
import { LeveledMessageList } from '../notification/LeveledMessageList';
import { ClockResetIcon } from '../svg/ClockResetIcon';
import { DiskInfoIcon } from '../svg/DiskInfoIcon';

const useStyles = makeStyles(
  () => createStyles({
    runControl: {
      flex: '0 0 auto',
      display: 'flex',
      padding: '8px',
    },
    runButton: {
      flex: '1 1 auto',
    },
    batchDelayIcon: {
      display: 'flex',
    },
    optionsMenuItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    radioOptionsIcon: {
      flexGrow: 1,
    },
    batchProcessingLink: {
      display: 'flex',
      alignItems: 'center',
    },
    setupSummaryDropdownLabel: {
      // this matches the radio button we show for the other dropdown items
      paddingLeft: '24px',
    },
  }),
  { name: 'RunSimulationButton' },
);

interface RunSimulationButtonProps {
  messages: LeveledMessage[];
  disabled: boolean;
  isExploration: boolean;
  // Called when the user runs a simulation or an exploration.
  onRunSimulation: (isExploration: boolean) => Promise<void>;
  children: ReactNode;
}

const explorationWarningThreshold: number = 10;

function updateBatchPriority(oldConfig: workflowpb.Config, batch: boolean): workflowpb.Config {
  const newConfig = copyJobConfig(oldConfig);
  const priority = new workflowpb.Priority({ batch });
  newConfig.jobConfigTemplate!.priority = priority;
  return newConfig;
}

const RunSimulationButton = (props: RunSimulationButtonProps) => {
  const {
    projectId,
    workflowId,
    jobId,
    onNewWorkflowConfig,
  } = useProjectContext();
  const [runSimulationPending, setRunSimulationPending] = useState<boolean>(false);
  const [summaryOpened, setSummaryOpened] = useState(false);
  const currentConfig = useCurrentConfig(projectId, workflowId, jobId);
  const setConfirmStack = useSetConfirmations();
  const classes = useStyles();
  const experimentConfig = useEnabledExperiments();
  const runtimeEstimate = useRuntimeEstimate();
  const isGeometryView = useIsGeometryView();
  const isSetupView = useIsSetupView();
  const skipSetupSummary = useSkipSetupSummaryValue();
  const showBatchMode = !isGeometryView;
  const batchModeChecked = currentConfig.jobConfigTemplate?.priority?.batch ?? false;
  // The setup summary should be present only when creating an individual simulation
  const hasSetupSummary = isSetupView && !props.isExploration;

  let runtimeText = '';
  if (experimentConfig.includes(flags.runtimeEstimate) && getSimCount(currentConfig) === 1) {
    switch (runtimeEstimate.state) {
      case 'hasValue': {
        const duration = runtimeEstimate.contents.duration;
        if (duration > 30) {
          runtimeText = 'Runtime: >30 min';
        } else if (duration > 0) {
          runtimeText = `Runtime: <${Math.ceil(duration)} min`;
        } else {
          runtimeText = 'Runtime: Not available';
        }
      }
        break;
      case 'loading':
        runtimeText = 'Estimating Runtime ...';
        break;
      case 'hasError':
      default:
        throw Error('Error estimating runtime');
    }
  }

  const queueSimulation = (simCount: number) => {
    pushConfirmation(setConfirmStack, {
      onContinue: async () => {
        setRunSimulationPending(true);
        try {
          await props.onRunSimulation(props.isExploration);
        } catch (error: any) {
          setRunSimulationPending(false);
          throw error;
        }
        setRunSimulationPending(false);
      },
      title: 'Confirm Exploration',
      children: (
        <div>
          The requested exploration will create {simCount.toLocaleString()} simulations. Are you
          sure you want to proceed?
        </div>
      ),
    });
  };

  const handleRunSimulation = async () => {
    if (runSimulationPending) {
      return;
    }
    setRunSimulationPending(true);
    try {
      if (props.isExploration) {
        const simCount = getSimCount(currentConfig);
        if (simCount > explorationWarningThreshold) {
          queueSimulation(simCount);
        } else {
          await props.onRunSimulation(props.isExploration);
          analytics.track('Run Simulation Button Clicked', {
            type: 'exploration',
            simCount,
            batchMode: batchModeChecked,
          });
        }
      } else {
        await props.onRunSimulation(props.isExploration);
        analytics.track('Run Simulation Button Clicked', {
          type: 'single',
          batchMode: batchModeChecked,
        });
      }
    } catch (error: any) {
      setRunSimulationPending(false);
      throw error;
    }
    setRunSimulationPending(false);
  };

  const onClick = async () => {
    if (hasSetupSummary && !skipSetupSummary) {
      setSummaryOpened(true);
    } else {
      await handleRunSimulation();
    }
  };

  const tooltip = props.messages.length ?
    <LeveledMessageList maxToShow={10} messages={props.messages} /> :
    runtimeText;

  const menuItems: CommonMenuItem[] = [];
  if (hasSetupSummary) {
    menuItems.push({
      label: <div className={classes.setupSummaryDropdownLabel}>Setup Summary</div>,
      onClick: () => {
        setSummaryOpened(true);
        analytics.track('Setup Summary Opened');
      },
    });
  }
  if (showBatchMode) {
    if (menuItems.length) {
      menuItems.push({ separator: true });
    }
    menuItems.push(
      {
        label: (
          <div className={classes.optionsMenuItem}>
            <RadioButton
              checked={!batchModeChecked}
              name="runMode"
              onClick={() => { }}
              value="normal"
            />
            Run Immediately
          </div>
        ),
        nameAttribute: 'run-option-normal',
        onClick: () => {
          onNewWorkflowConfig(updateBatchPriority(currentConfig, false));
          analytics.track('Batch Mode Toggled', { enabled: false });
        },
      },
      {
        label: (
          <div className={classes.optionsMenuItem}>
            <RadioButton
              checked={batchModeChecked}
              name="runMode"
              onClick={() => { }}
              value="batch"
            />
            <div className={classes.radioOptionsIcon}>
              Delayed Start and Save
            </div>
            <Tooltip title={`Delay the start of your simulation using Batch Processing and save
            credits. Click to learn more`}>
              <a
                className={classes.batchProcessingLink}
                href={BATCH_PROCESSING_URL}
                onClick={(event) => event.stopPropagation()}
                rel="noopener noreferrer"
                target="_blank">
                <DiskInfoIcon color={colors.lowEmphasisText} maxHeight={12} maxWidth={12} />
              </a>
            </Tooltip>
          </div>
        ),
        nameAttribute: 'run-option-batch',
        onClick: () => {
          onNewWorkflowConfig(updateBatchPriority(currentConfig, true));
          analytics.track('Batch Mode Toggled', { enabled: true });
        },
      },
    );
  }

  return (
    <div className={classes.runControl}>
      <div className={classes.runButton}>
        <SplitButton
          asBlock
          dataLocator="runSimulationButton"
          disabled={runSimulationPending || props.disabled}
          kind="primary"
          menuItems={menuItems}
          onClick={onClick}
          showSpinner={runSimulationPending}
          size="small"
          splitIcon={{ name: 'verticalControls', maxHeight: 12 }}>
          {batchModeChecked && (
            <Tooltip title="Delayed Start and Save Active">
              <span className={classes.batchDelayIcon} data-locator="runSimulationBatchDelayIcon">
                <ClockResetIcon maxHeight={12} />
              </span>
            </Tooltip>
          )}
          <Tooltip title={tooltip}>
            <span>{props.children}</span>
          </Tooltip>
        </SplitButton>
      </div>

      <SetupSummaryDialog
        batchModeChecked={batchModeChecked}
        onClose={() => setSummaryOpened(false)}
        onContinue={handleRunSimulation}
        open={summaryOpened}
      />
    </div>
  );
};

export default RunSimulationButton;
