// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useAtom, useSetAtom } from 'jotai';

/* Read/set clicked node links without paraview dependency */

const nodeLinkState = atom<string[]>([]);

export function useNodeLinkState() {
  return useAtom(nodeLinkState);
}

export function useSetNodeLinkState() {
  return useSetAtom(nodeLinkState);
}
