// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import {
  SetterOrUpdater,
  atom,
  selectorFamily,
  useRecoilValue,
  useSetRecoilState,
  waitForAll,
} from 'recoil';

import * as flags from '../../flags';
import { getSessionStorageData } from '../../lib/browserStorage';
import { CurrentView } from '../../lib/componentTypes/context';
import { sessionStorageEffect } from '../../lib/persist';
import { isGeometryFile } from '../../lib/upload/uploadUtils';
import * as projectstatepb from '../../proto/projectstate/projectstate_pb';
import { currentViewAtom_DEPRECATED } from '../../state/internal/global/currentView';
import { meshUrlState } from '../meshState';
import { enabledExperimentsSelector_DEPRECATED } from '../useExperimentConfig';

const LCVIS_ENABLED_KEY = 'lcvisEnabled';

/** Logic for whether to allow the user to switch to LCVis. */
export function lcvisAllowedLogic(
  tags: string[],
  currentView: CurrentView,
  meshState: projectstatepb.MeshUrl,
): boolean {
  const remeshingEnabled = tags.includes(flags.remeshing);
  const geoMode = meshState.activeType === projectstatepb.UrlType.GEOMETRY;
  // while geometry clean up is in progress, the Url is empty but a geometry preview is available
  const noExternalMesh = isGeometryFile(meshState.url) || meshState.preview || remeshingEnabled;

  const isGeometry = currentView === CurrentView.GEOMETRY;
  const isSetup = currentView === CurrentView.SETUP;

  return (
    isGeometry ||
    (tags.includes(flags.lcvis) && (geoMode && noExternalMesh) && isSetup) ||
    (tags.includes(flags.lcvisMesh) && isSetup)
  );
}

/** A selector which gates switching between Paraview and LCVis. */
const lcVisAllowedSelector = selectorFamily<boolean, string>({
  key: 'lcvisAllowedSelector',
  get: (projectId: string) => ({ get }) => {
    const [tags, currentView, meshState] = get(waitForAll([
      enabledExperimentsSelector_DEPRECATED,
      currentViewAtom_DEPRECATED,
      meshUrlState(projectId),
    ]));
    return lcvisAllowedLogic(tags, currentView, meshState);
  },
});

/**
 * Changing the lcVisEnabledAtom will only affect the lcVisEnabledSelector if the user is already
 * on the setup tab and has the lcvis feature flag enabled.
*/
const lcVisEnabledAtom = atom({
  key: 'lcVisEnabledAtom',
  default: getSessionStorageData(LCVIS_ENABLED_KEY, true),
  effects: [sessionStorageEffect(LCVIS_ENABLED_KEY)],
});

/** A selector which gates lcvis and switching between lcvis and Paraview rendering */
export const lcVisEnabledSelector = selectorFamily<boolean, string>({
  key: 'lcVisEnabledSelector',
  get: (projectId: string) => ({ get }) => {
    const [
      allowed,
      enabled,
      currentView,
    ] = get(waitForAll([
      lcVisAllowedSelector(projectId),
      lcVisEnabledAtom,
      currentViewAtom_DEPRECATED,
    ]));
    if (currentView === CurrentView.GEOMETRY) {
      // in the geometry view, we never use Paraview.
      return true;
    }
    return allowed && enabled;
  },
});

export const useLcVisAllowedValue = (
  projectId: string,
) => useRecoilValue(lcVisAllowedSelector(projectId));

export const useLcVisEnabledValue = (
  projectId: string,
) => useRecoilValue(lcVisEnabledSelector(projectId));

/**
 * If the feature flag is on and the user is on the mesh setup tab
 * this allows the user to toggle between lcvis and Paraview enabled states.
 */
export const useLcVisEnabledState = (projectId: string): [boolean, SetterOrUpdater<boolean>] => {
  const value = useLcVisEnabledValue(projectId);
  const setter = useSetRecoilState(lcVisEnabledAtom);

  return [value, setter];
};

export const useSetLcVisEnabledState = (): SetterOrUpdater<boolean> => {
  const setter = useSetRecoilState(lcVisEnabledAtom);
  return setter;
};
