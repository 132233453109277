// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback } from 'react';

import { SimulationRowProps } from '../../../../../lib/componentTypes/simulationTree';
import { deleteTreeNodeMenuItem, duplicateTreeNodeMenuItem } from '../../../../../lib/treeUtils';
import { useFluidBoundaryCondition } from '../../../../../model/hooks/useFluidBoundaryCondition';
import { useProjectContext } from '../../../../context/ProjectContext';
import { useCopyFluidBC } from '../../../../hooks/nodeDuplication/useCopyBoundaryCondition';
import { useGeometryStatus } from '../../../../hooks/useGeometryStatus';
import { useNodeDeletion } from '../../../../hooks/useNodeDeletion';
import { useNodeRenaming } from '../../../../hooks/useNodeRenaming';
import { ContextMenuSection, IconSpec, TreeRow } from '../../../TreeRow';

const PRIMARY_ICON: IconSpec = { name: 'hash' };

export const PhysicsFluidBoundaryConditionTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;

  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // == Hooks
  const renaming = useNodeRenaming(node);
  const { canDelete, deleteBoundaryNode, postDeleteNodeIds } = useNodeDeletion();
  const {
    isDependent,
  } = useFluidBoundaryCondition(projectId, workflowId, jobId, readOnly, node.id);
  const copyFluidBC = useCopyFluidBC();
  const { working } = useGeometryStatus();

  const deleteRow = useCallback(async () => {
    if (await deleteBoundaryNode(node.id)) {
      postDeleteNodeIds([node.id]);
    }
  }, [deleteBoundaryNode, node.id, postDeleteNodeIds]);

  const getExtraContextMenuItems = useCallback(() => {
    const sections: ContextMenuSection[] = [];

    if (!isDependent) {
      const disabled = !canDelete(node.type, node.id);
      const deleteItem = deleteTreeNodeMenuItem(deleteRow, disabled);
      const duplicateItem = duplicateTreeNodeMenuItem(
        () => copyFluidBC(node.name, node.id),
        working || readOnly,
      );
      sections.push({ section: 'crud', menuItems: [duplicateItem, deleteItem] });
    }

    return sections;
  }, [
    canDelete,
    deleteRow,
    isDependent,
    node.id,
    node.type,
    node.name,
    copyFluidBC,
    working,
    readOnly,
  ]);

  return (
    <TreeRow
      {...props}
      getExtraContextMenuItems={getExtraContextMenuItems}
      primaryIcon={PRIMARY_ICON}
      renaming={renaming}
    />
  );
};
