// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

/**
 * Displays all the simulation and exploration results in a table.
 */
import React, { Suspense, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import JobTable from '../components/JobPanel/JobTable';
import TabPanel from '../components/Pane/TabPanel';
import ProjectStateSync from '../components/RecoilSync/ProjectState';
import suspenseWidget from '../components/SuspenseWidget';
import { createStyles, makeStyles } from '../components/Theme';
import Project from '../components/context/ProjectContext';
import { MainPageLayout } from '../components/layout/page/Main';
import { CurrentView } from '../lib/componentTypes/context';
import useProjectMetadata from '../recoil/useProjectMetadata';
import { useSetCurrentView } from '../state/internal/global/currentView';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: '0',
      overflow: 'hidden',
      height: '100%',
    },
    table: {
      flex: '1 1 auto',
      overflow: 'auto',
    },
  }),
  { name: 'ResultsPage' },
);
const ResultsPage = () => {
  const params = useParams();
  const projectId = params.projectId || '';
  const projectMetadata = useProjectMetadata(projectId);

  const projectName = projectMetadata ?
    projectMetadata.summary!.name :
    projectId;

  const classes = useStyles();
  const setCurrentView = useSetCurrentView();

  useEffect(() => {
    setCurrentView(CurrentView.RESULTS);
  }, [setCurrentView]);

  return (
    <MainPageLayout projectId={projectId} title={projectName}>
      <Suspense fallback={suspenseWidget}>
        <ProjectStateSync projectId={projectId}>
          <Project
            projectId={projectId}
            selectedJobId=""
            workflowId="">
            <div className={classes.root}>
              <TabPanel />
              <div className={classes.table}>
                <JobTable />
              </div>
            </div>
          </Project>
        </ProjectStateSync>
      </Suspense>
    </MainPageLayout>
  );
};
export default ResultsPage;
