// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { VIEWER_PADDING } from '../../lib/visUtils';
import { useRightOverlayCardsWidth } from '../../state/internal/component/rightOverlayCards';
import { useIsGeometryView } from '../../state/internal/global/currentView';
import { createStyles, makeStyles } from '../Theme';

import { ModificationTreePanel } from './ModificationTreePanel';

const useStyles = makeStyles(
  () => createStyles({
    cards: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: `${VIEWER_PADDING}px`,
      right: `${VIEWER_PADDING}px`,
      zIndex: 3,
      overflow: 'auto',
      maxHeight: `calc(100% - 2 * ${VIEWER_PADDING}px)`,
      justifyCOntent: 'space-between',
    },
  }),
  { name: 'RightOverlayCards' },
);

/**
 * This is a floating panel that is shown on the right side of the 3D viewer.
 * It is used for the Modification Tree Panel on the geometry page.
 */
export const RightOverlayCards = ({ overlayRef }: any) => {
  // == Hooks
  const classes = useStyles();
  const isGeometryView = useIsGeometryView();

  // == Recoil
  const [width] = useRightOverlayCardsWidth();

  if (!isGeometryView) {
    return null;
  }

  // == Render
  return (
    <div className={classes.cards} style={{ width }}>
      {isGeometryView && (
        <ModificationTreePanel />
      )}
    </div>
  );
};
