// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, {
  CSSProperties,
  KeyboardEvent,
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import cx from 'classnames';

import * as flags from '../../flags';
import { CurrentView } from '../../lib/componentTypes/context';
import { CommonMenuItem, CommonMenuPositionTransform } from '../../lib/componentTypes/menu';
import { SvgIconSpec } from '../../lib/componentTypes/svgIcon';
import { getRelativeEventCoordinates, isUnmodifiedSpaceKey } from '../../lib/event';
import { parseString } from '../../lib/html';
import { isSuperset } from '../../lib/lang';
import { NodeTableType } from '../../lib/nodeTableUtil';
import { levelToRank, mostSevereMessageLevel, sortLeveledMessages } from '../../lib/notificationUtils';
import { clamp } from '../../lib/number';
import { useUserCanEdit } from '../../lib/projectRoles';
import { SelectionAction, UNGROUP_TABLES, allowableMultiSelect } from '../../lib/selectionUtils';
import { GEOMETRY_TREE_NODE_TYPES, NodeType, SIMULATION_TREE_DATA_LOCATOR, SimulationTreeNode } from '../../lib/simulationTree/node';
import { getNodeMessages } from '../../lib/simulationValidation';
import { canSubselectNode, isNodeDisabled } from '../../lib/subselectUtils';
import { getIconSpecDims } from '../../lib/svgIcon/utils';
import useResizeObserver from '../../lib/useResizeObserver';
import * as entitygrouppb from '../../proto/entitygroup/entitygroup_pb';
import { CameraGroupMapAccessType, useCameraGroupMap } from '../../recoil/cameraState';
import { useEntityGroupData } from '../../recoil/entityGroupState';
import { featuresErrors, useGeometryState } from '../../recoil/geometry/geometryState';
import { useLcVisEnabledValue } from '../../recoil/lcvis/lcvisEnabledState';
import { useLcvisHoveredIdValue } from '../../recoil/lcvis/lcvisHoveredId';
import { useSetPropertiesPanelVisible, useShowGeometryPropertiesPanelOnSelectValue } from '../../recoil/propertiesPanel';
import { useSimulationTreeSubselect } from '../../recoil/simulationTreeSubselect';
import { useIsEnabled } from '../../recoil/useExperimentConfig';
import { useMeshReadyState } from '../../recoil/useMeshReadyState';
import useProjectMetadata from '../../recoil/useProjectMetadata';
import { useControlPanelMode } from '../../recoil/useProjectPage';
import {
  useLastClickedCamera,
  useLastClickedEntity,
  useLastClickedNode,
  useRowsOpened,
} from '../../recoil/useSimulationTreeState';
import { useProjectValidator } from '../../state/external/project/validator';
import { useEditableTextState } from '../../state/internal/component/editableText';
import { useCurrentView, useIsGeometryView } from '../../state/internal/global/currentView';
import { useNodeSelectHoveredIdValue } from '../../state/internal/selection/highlighting';
import { useSetClickedTreeRowVisOffset } from '../../state/internal/tree/clickedTreeRowVisOffset';
import { useSimulationTree } from '../../state/internal/tree/simulation';
import { SvgIcon } from '../Icon/SvgIcon';
import { CommonMenu } from '../Menu/CommonMenu';
import VisibilityButton from '../Paraview/VisibilityButton';
import { ROW_INNER_HEIGHT, useCommonTreeRowStyles } from '../Theme/commonStyles';
import Tooltip from '../Tooltip';
import { EarlyAccessLink } from '../common/EarlyAccessLink';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { CONTENT_EDITABLE_DATA_LOCATOR, EDITABLE_TEXT_BORDER, EditableText } from '../controls/EditableText';
import { FolderTriangle } from '../controls/FolderTriangle';
import { useCancelEdit } from '../hooks/useCancelEdit';
import { useIsAttachedToSelectedNode } from '../hooks/useIsAttachedToSelectedNode';
import { useNodeTableTreeRowError } from '../hooks/useNodeTableTreeRowError';
import { useToggleHighlightForNode } from '../hooks/useToggleHighlightForNode';
import { LeveledMessageList } from '../notification/LeveledMessageList';

import { useMouseDown } from './NodeSubselect/utils';
import { ShowPropPanelButton } from './ShowPropPanelButton';
import { updateLastHighlightedValue } from './useArrowKeyNav';

export interface VisibilityControl {
  show?: boolean;
  disabled?: boolean;
  toggle: () => void;
}

export interface RenamingControl {
  onCommit: (newLabel: string) => void;
  disabled?: boolean;
}

export interface IconSpec extends SvgIconSpec {
  tooltip?: string;
  opacity?: number;
}

// Context menus are split (with separators) into different functional sections
// - CRUD stands for create/read/update/delete functionality
// - GROUPING is for grouping-related menu items
// - AUX is for other operations like hide/show
// - RELATIONS is for managing relationships with other entities (e.g. volume <--> porous models)
export type ContextMenuSectionType = 'relations' | 'crud' | 'aux' | 'grouping';

export interface ContextMenuSection {
  // Section in which the menuItems should appear
  section: ContextMenuSectionType;
  // Menu items to add to the internally generated items
  menuItems: CommonMenuItem[];
  // Whether to insert these menu items before existing items instead of appending them
  prepend?: boolean;
}

const contextMenuYOffset = ROW_INNER_HEIGHT * 0.75;

// coverableNodeTypes contains node types for which we partially highlight children
// of selected nodes (right now only true for surfaces & surface groups).
const coverableNodeTypes: Set<NodeType> = new Set([
  NodeType.SURFACE_GROUP,
  NodeType.MOTION_FRAME,
  NodeType.MOTION_GLOBAL_FRAME,
]);

// Concatenate and flatten lists of menu items, adding separators between lists if appropriate
function assembleMenuSections(...menuItemsList: CommonMenuItem[][]) {
  const finalItems: CommonMenuItem[] = [];

  menuItemsList.forEach((menuItems, i) => {
    if (finalItems.length && menuItems.length) {
      finalItems.push({ separator: true });
    }
    finalItems.push(...menuItems);
  });

  return finalItems;
}

export interface TreeRowProps {
  node: SimulationTreeNode;
  label?: string;
  sublabel?: string;
  primaryIcon?: IconSpec;
  auxIcons?: IconSpec[];
  renaming?: RenamingControl;
  visibility?: VisibilityControl;
  addControl?: ReactElement;
  auxControl?: ReactElement;
  propertiesControl?: boolean; // if true, the row will have a button for showing the prop panel
  earlyAccess?: boolean;
  depth?: number;
  canMultiSelect?: boolean;
  hideWarnings?: boolean;
  dimmed?: boolean;
  disableToggle?: boolean;
  getExtraContextMenuItems?: () => ContextMenuSection[];
}

type ClickLikeEvent<T extends HTMLElement> = MouseEvent<T> | KeyboardEvent<T>;

// Create the callback to stopPropagation outside the TreeRow so it doesn't get redeclared
// every render.
function stopPropagationFunc(
  event: React.MouseEvent<HTMLDivElement | HTMLButtonElement, globalThis.MouseEvent>,
) {
  event.stopPropagation();
}

interface ContextMenuState {
  // Items to show in the context menu; also a surrogate for 'open' (when menuItems.length > 0)
  menuItems: CommonMenuItem[];
  transform?: CommonMenuPositionTransform;
}

// A container around an individual row displaying one tree node.
export const TreeRow = (props: TreeRowProps) => {
  // == Props
  const {
    node,
    label = node.name,
    sublabel,
    primaryIcon,
    auxIcons = [],
    renaming,
    visibility,
    addControl,
    auxControl,
    propertiesControl,
    earlyAccess,
    depth = 0,
    canMultiSelect,
    hideWarnings,
    dimmed,
    disableToggle,
    getExtraContextMenuItems,
  } = props;

  // == Contexts
  const { projectId, workflowId, jobId, geometryId } = useProjectContext();
  const {
    isTreeModal,
    selectedNode,
    selectedNodeIds,
    activeNodeTable,
    modifySelection,
    highlightedInSimTree,
  } = useSelectionContext();

  // == Recoil
  const meshReadyState = useMeshReadyState(projectId, workflowId, jobId);
  const [controlPanelMode] = useControlPanelMode();
  const currentView = useCurrentView();
  const [nodesOpened, setNodesOpened] = useRowsOpened(projectId, controlPanelMode);
  const simulationTree = useSimulationTree(projectId, workflowId, jobId);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const cameraGroupMap = useCameraGroupMap(projectId, CameraGroupMapAccessType.ALL);
  const projectMetadata = useProjectMetadata(projectId);
  const [lastClickedNode, setLastClickedNode] = useLastClickedNode(projectId, controlPanelMode);
  const lastClickedEntity = useLastClickedEntity(projectId, workflowId, jobId, controlPanelMode);
  const lastClickedCamera = useLastClickedCamera(
    projectId,
    controlPanelMode,
    CameraGroupMapAccessType.ALL,
  );
  const lcvisHoveredId = useLcvisHoveredIdValue();
  const undockPropPanelsEnabled = useIsEnabled(flags.undockPropPanels);
  const geoState = useGeometryState(projectId, geometryId);
  const showGeometryPropertiesPanelOnSelect = useShowGeometryPropertiesPanelOnSelectValue();
  const setPropertiesPanelVisible = useSetPropertiesPanelVisible();
  const lcVisEnabled = useLcVisEnabledValue(projectId);
  const isGeometryView = useIsGeometryView();
  const [tempText, setTempText] = useEditableTextState();

  // == Hooks
  const classes = useCommonTreeRowStyles();
  const validator = useProjectValidator(projectId, workflowId, jobId);
  const treeSubselect = useSimulationTreeSubselect();
  const nodeTableTreeError = useNodeTableTreeRowError(node);
  const isAttachedToSelectedNode = useIsAttachedToSelectedNode();
  const toggleHighlightForNode = useToggleHighlightForNode();
  const nodeSelectHoveredId = useNodeSelectHoveredIdValue();
  const cancelEdit = useCancelEdit();
  const setClickedTreeRowVisOffset = useSetClickedTreeRowVisOffset();
  const handleSubselectMouseDown = useMouseDown();

  // == State
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const nameRef = useRef<HTMLDivElement>(null);
  const nameSize = useResizeObserver(nameRef);
  const [isRenaming, setIsRenaming] = useState(false);
  const [contextMenuState, setContextMenuState] = useState<ContextMenuState>({ menuItems: [] });
  const [showNameTooltip, setShowNameTooltip] = useState(false);

  // == Data
  const showPropertiesButton = propertiesControl && undockPropPanelsEnabled;
  const messages = useMemo(() => {
    if (!meshReadyState) {
      return [];
    }
    if (geoState) {
      return featuresErrors(geoState).get(node.id) ?? [];
    }
    if (currentView === CurrentView.SETUP) {
      const nodeMessages = getNodeMessages(validator, node, levelToRank('warning'));
      sortLeveledMessages(nodeMessages);
      return nodeMessages;
    }
    return [];
  }, [currentView, geoState, meshReadyState, node, validator]);
  const hasChildren = (node.children.length > 0);
  const userCanEdit = useUserCanEdit(projectMetadata?.summary);
  const nodeTableType = activeNodeTable.type;
  const nodeTableActive = nodeTableType !== NodeTableType.NONE;
  const editable = (!!renaming && !renaming.disabled);
  const isOutlined = nodeTableActive && (node === selectedNode);
  const hoveredInVis = (node.id === lcvisHoveredId);
  const provisionIcon = !hideWarnings && !!(primaryIcon || messages.length);
  const boldLabel = node.parent?.type === NodeType.ROOT_FLOATING_GEOMETRY && nodesOpened[node.id];

  const getRowTooltip = useMemo(() => {
    if (treeSubselect.active) {
      return parseString(treeSubselect.nodeFilter(node.type, node.id).tooltip);
    }
    if (nodeTableActive) {
      return nodeTableTreeError.disabledReason;
    }
    if (node.type === NodeType.MONITOR_PLANE && lcVisEnabled) {
      return 'Monitor Planes are not yet supported with client-side visualization.';
    }
    if (showNameTooltip && !isRenaming) {
      return label;
    }
    return '';
  }, [
    node,
    treeSubselect,
    nodeTableActive,
    nodeTableTreeError.disabledReason,
    showNameTooltip,
    label,
    isRenaming,
    lcVisEnabled,
  ]);

  const isDisabled = useMemo(() => (
    treeSubselect.active && isNodeDisabled(treeSubselect.nodeFilter, node)
  ), [treeSubselect, node]);

  const canSelect = useMemo(() => (
    !nodeTableTreeError.disabled && !isDisabled
  ), [nodeTableTreeError.disabled, isDisabled]);

  const isRowDimmed = useMemo(() => {
    if (isDisabled) {
      return true;
    }
    // In the geometry view, we also dim the row if there are any messages.
    if (isGeometryView) {
      return !!messages.length || dimmed;
    }
    return dimmed;
  }, [dimmed, isGeometryView, messages, isDisabled]);

  const isSubselectReference = useMemo(() => (
    treeSubselect.referenceNodeIds.includes(node.id)
  ), [node.id, treeSubselect]);

  const badgeLevel = useMemo(
    () => (messages.length ? mostSevereMessageLevel(messages) : null),
    [messages],
  );

  const handleOpenPropPanel = () => {
    // Calculate the distance between the top edge of the vis and the clicked tree row, so that
    // we can position the geometry prop panel relative to the clicked row.
    if (propertiesControl && nodeRef.current) {
      // When we retire paraview, we can change this so that the we use the lcVis's ref directly
      const selector = lcVisEnabled ? '#lcVisManager' : '[data-locator=paraviewCanvasPlaceholder]';
      const ancestor = document.querySelector(selector) as HTMLElement | null;
      if (ancestor) {
        const targetRect = nodeRef.current.getBoundingClientRect();
        const ancestorRect = ancestor.getBoundingClientRect();
        const distanceFromAncestorTop = targetRect.top - ancestorRect.top;
        setClickedTreeRowVisOffset(distanceFromAncestorTop);
      }
    }
  };

  const onHover = (hovered: boolean) => {
    toggleHighlightForNode(node.id, node.type === NodeType.VOLUME, hovered);
  };

  // When a surface table/subselect is active, clicking on a surface toggles it in or out
  // of the table. In normal mode, clicking a row selects the row. Ctrl-click
  // toggles it. Shift-click adds all rows between it and the previous click.
  const handleClickRow = (event: ClickLikeEvent<HTMLDivElement>) => {
    if (!canSelect || ![undefined, 0].includes((event as MouseEvent).button)) {
      return;
    }

    const { cancelSubselect } = handleSubselectMouseDown(event.target as Node);

    // If the row selection is changing and we're not in a node table/subselect, cancel any filter
    // node edits
    if (selectedNode?.id !== node.id && !isTreeModal) {
      cancelEdit();

      // If there is rename in progress in a floating prop panel with the EditableText component,
      // when we click on another tree row (without saving the edit), the EditableText doesn't
      // unmount and the recoil temp state doesn't get cleared. This can result in stale edit data
      // if we start another edit after that. Resetting the editableText when we click on another
      // tree row fixes that.
      if (tempText) {
        setTempText(null);
      }
    }

    // Multi select is only allowed for nodes representing geometric entities, certain combinations
    // of geometric entities and for cameras.
    // Check if it's a geometric entity
    const entityNode = (
      entityGroupData.groupMap.has(node.id) && entityGroupData.groupMap.get(node.id)
    );
    const hasGeometricEntityMultiSelect = (
      entityNode &&
      lastClickedEntity &&
      allowableMultiSelect.get(lastClickedEntity.entityType)?.includes(entityNode!.entityType)
    );

    // Check if it's a camera
    const cameraNode = cameraGroupMap.has(node.id) && cameraGroupMap.get(node.id);
    const hasCameraMultiSelect = (
      cameraNode &&
      lastClickedCamera && (
        cameraGroupMap.get(cameraNode.id).item?.cameraAccess ===
        cameraGroupMap.get(lastClickedCamera.id).item?.cameraAccess
      )
    );

    const tagGroup =
      entityGroupData.groupMap.has(node.id) &&
      entityGroupData.groupMap.get(node.id).entityType === entitygrouppb.EntityType.TAG_CONTAINER;
    const hasTagGroupMultiSelect =
      tagGroup && lastClickedEntity?.entityType === entitygrouppb.EntityType.TAG_CONTAINER;

    const multiSelect =
      hasGeometricEntityMultiSelect || hasCameraMultiSelect || hasTagGroupMultiSelect;

    // Multiple IDs can be added when using the SHIFT key.
    let multipleNewIds: string[] = [];
    let shiftAdd = false;
    if (event.shiftKey && selectedNodeIds.length > 0 && simulationTree && canMultiSelect) {
      shiftAdd = true;
      const { parent } = node;
      if (parent && lastClickedNode?.parentId === parent.id) {
        // Add all rows between the new index and the last index.
        const newIndex = parent.children.indexOf(node);
        let newNodes: SimulationTreeNode[] = [];
        // Only add nodes if they are of the same type
        if (multiSelect) {
          if (newIndex > lastClickedNode.index) {
            newNodes = parent.children.slice(lastClickedNode.index + 1, newIndex + 1);
          } else {
            newNodes = parent.children.slice(newIndex, lastClickedNode.index).reverse();
          }

          // Filter out nodes that cannot be selected as per the subselect filter.
          if (treeSubselect.active) {
            newNodes = newNodes.filter((nodeF) => (
              canSubselectNode(treeSubselect.nodeFilter, nodeF)
            ));
          }
          const extraIds = newNodes.map((newNode) => newNode.id);
          multipleNewIds = extraIds.filter((newId) => !selectedNodeIds.includes(newId));
        }
      }
    }

    const modificationIds = multipleNewIds.length > 0 ? multipleNewIds : [node.id];
    // For active node tables, clicking toggles the surfaces. With no table, it
    // overwrites the selection unless CTRL is pressed.
    let action = SelectionAction.OVERWRITE;
    let nodeTableOverride = activeNodeTable;
    // For adding nodes using shift the types must be the same
    if (shiftAdd && multiSelect) {
      action = SelectionAction.ADD;
      // For ctrl we allow some combinations of nodes of different types
    } else if (
      (event.ctrlKey || event.metaKey) && lastClickedNode && multiSelect && canMultiSelect
    ) {
      action = SelectionAction.TOGGLE;
    } else if (isTreeModal) {
      // If we have an active NodeTable and we click on a row from the sim tree (not geometry tree)
      // we don't want to toggle the selection, but instead we want to end the selection mode and
      // to select/open/highlight the clicked item.
      const simTreeContainerEl = document.querySelector(
        `[data-locator=${SIMULATION_TREE_DATA_LOCATOR}]`,
      );
      if (event.target && simTreeContainerEl?.contains(event.target as Node)) {
        action = SelectionAction.OVERWRITE;
        nodeTableOverride = { type: NodeTableType.NONE };
      } else {
        // In every other case, we toggle the item in the active selection
        action = SelectionAction.TOGGLE;
      }
    } else if (event.altKey) {
      action = SelectionAction.SUBTRACT;
    }
    if (!contextMenuState.menuItems.length) {
      modifySelection({ action, modificationIds, nodeTableOverride, cancelSubselect });
    }

    setLastClickedNode(() => {
      const siblingIds = node.parent?.children.map((child) => child.id) ?? [];
      return {
        id: node.id,
        parentId: node.parent?.id,
        index: siblingIds.indexOf(node.id),
      };
    });

    if (!isTreeModal) {
      const geometryNodeClicked = GEOMETRY_TREE_NODE_TYPES.has(node.type);
      setPropertiesPanelVisible(geometryNodeClicked ? showGeometryPropertiesPanelOnSelect : true);

      handleOpenPropPanel();
    }
  };

  const toggleExpanded = () => {
    if (hasChildren) {
      // Toggle a node open or closed using its ID
      setNodesOpened((prevValue) => {
        const newValue = { ...prevValue };
        if (newValue[node.id]) {
          delete newValue[node.id];
        } else {
          newValue[node.id] = true;
        }
        return newValue;
      });
    }
  };

  const handleClickToggle = (event: MouseEvent<HTMLButtonElement>) => {
    toggleExpanded();
    event.stopPropagation();
  };

  const handleDoubleClickRow = (event: MouseEvent<HTMLDivElement>) => {
    toggleExpanded();
    event.stopPropagation();
  };

  // Returns true if the list of rows to highlight contains every item in the group.
  const isEntityHighlightedInTree = useCallback((groupId: string) => {
    // Return early if the node is not a groupable node
    if (!entityGroupData.groupMap.has(groupId)) {
      return false;
    }
    const leafs = entityGroupData.leafMap.get(groupId);
    return leafs?.size ? isSuperset(highlightedInSimTree, leafs) : false;
  }, [entityGroupData, highlightedInSimTree]);

  // Returns true if all the children leafs of a node are attached to the currently selected node.
  const isNodeOrItsLeafsAttachedToSelectedNode = useCallback((
    ancestor: SimulationTreeNode,
  ): boolean => {
    // Always check if the individual node id is attached
    if (isAttachedToSelectedNode(ancestor.id)) {
      return true;
    }
    // For surface group nodes, check all leafs of the node
    if (ancestor.type === NodeType.SURFACE_GROUP) {
      return ancestor.children.every((child) => isNodeOrItsLeafsAttachedToSelectedNode(child));
    }
    return false;
  }, [isAttachedToSelectedNode]);

  // If the row is explicitly selected, it's considered 'highlighted'.
  // If it's a descendant of an explicitly selected row, it's considered 'subHighlighted' and is
  // partially highlighted in the tree.
  // If some prop panel is opened that contains a selection table with the current node it it, the
  // row should also become 'subHighlighted'.
  const highlightState = useMemo(() => {
    let highlighted = false;
    let subHighlighted = false;

    if (UNGROUP_TABLES.includes(nodeTableType)) {
      // Highlight or partially highlight this node based on the list of surfaces in the node table.
      // This function applies to only "ungrouped" tables, so the input list should consist only of
      // individual surface IDs. If all of a group's surfaces are in surfaces, highlight the group
      // node and partially highlight the child surfaces.  Otherwise, fully highlight the individual
      // surfaces.
      if (highlightedInSimTree.has(node.id) || isEntityHighlightedInTree(node.id)) {
        // If a node or all its children are selected, it should be highlighted.
        if (node.parent && isEntityHighlightedInTree(node.parent.id)) {
          // If the parent is highlighted, partially highlight.
          subHighlighted = true;
        } else {
          // If the parent is not highlighted, fully highlight.
          highlighted = true;
        }
      }
    } else {
      // Highlight or partially highlight this node based on the list of selected node IDs.  This
      // function is for "grouped" node tables (which includes the case of no node table, where a
      // mixture of groups and surfaces may be selected). In this case, a parent being highlighted
      // will partially highlight all child nodes, except that if an individual node is also
      // explicitly selected, it will be fully highlighted.
      // eslint-disable-next-line no-lonely-if
      if (highlightedInSimTree.has(node.id)) {
        highlighted = true;
      } else if (node.isDescendant(highlightedInSimTree, coverableNodeTypes)) {
        subHighlighted = true;
      }
    }

    // Even when we are NOT in selection mode but we have opened a node that has a selection table
    // in its prop panel, we should subhighlight the current node if is in that table or if all of
    // its children leafs are in the table.
    if (!isTreeModal && isNodeOrItsLeafsAttachedToSelectedNode(node)) {
      subHighlighted = true;
    }

    return { highlighted, subHighlighted };
  }, [
    isTreeModal,
    nodeTableType,
    highlightedInSimTree,
    isEntityHighlightedInTree,
    isNodeOrItsLeafsAttachedToSelectedNode,
    node,
  ]);

  useEffect(() => {
    let frame = 0;
    // The problem with calling setHighlightTime directly in the useEffect is that it will run
    // after React has sent the data to the browser DOM, but before the repaint has actually
    // completed. So we have to wrap it in requestAnimationFrame to ensure that the TreeRow's
    // highlight was visibly applied in the UI before updating the last highlight time.
    if (highlightState.highlighted) {
      frame = requestAnimationFrame(() => {
        // Update the last updated highlight time to tell the Simtree that it can accept new arrow
        // key inputs to change the selection, once this TreeRow has rerendered.
        updateLastHighlightedValue();
      });
    }
    return () => {
      frame && cancelAnimationFrame(frame);
    };
  }, [highlightState.highlighted, classes]);

  // We check if the name fits in the boundaries of the element or if it gets truncated w/ ellipsis.
  // If it gets truncated, we'll trigger a Tooltip with the name.
  useEffect(() => {
    if (!nameSize.width) {
      return;
    }
    const el = editable ? nameRef.current?.querySelector(
      `[data-locator="${CONTENT_EDITABLE_DATA_LOCATOR}"]`,
    ) as HTMLElement : nameRef.current;
    if (el && el.offsetWidth > 0) {
      // This checks if the text fits into the element boundaries. The EditableText has some border
      // so we need to add it here for the check to work properly.
      if (el.offsetWidth < (el.scrollWidth + (editable ? 2 * EDITABLE_TEXT_BORDER : 0))) {
        if (!showNameTooltip) {
          setShowNameTooltip(true);
        }
      } else if (showNameTooltip) {
        setShowNameTooltip(false);
      }
    }
  }, [editable, showNameTooltip, setShowNameTooltip, nameSize.width]);

  const getContextMenuItems = useCallback(() => {
    const menuSections: Record<ContextMenuSectionType, CommonMenuItem[]> = {
      relations: [],
      crud: [],
      grouping: [],
      aux: [],
    };

    const extraItems = getExtraContextMenuItems?.();

    // Place generated menu items marked 'prepend' first
    extraItems?.forEach((item) => {
      if (item.prepend) {
        menuSections[item.section].push(...item.menuItems);
      }
    });

    if (visibility) {
      menuSections.aux.push({
        label: visibility.show ? 'Hide' : 'Show',
        disabled: visibility.disabled,
        onClick: visibility.toggle,
      });
    }

    if (renaming) {
      menuSections.crud.push({
        label: 'Rename',
        disabled: renaming.disabled,
        onClick: () => setIsRenaming(true),
      });
    }

    // Place generated menu items not marked 'prepend' last
    extraItems?.forEach((item) => {
      if (!item.prepend) {
        menuSections[item.section].push(...item.menuItems);
      }
    });

    const finalItems = assembleMenuSections(
      menuSections.relations,
      menuSections.aux,
      menuSections.grouping,
      menuSections.crud,
    );

    if (selectedNode?.id === node.id || selectedNodeIds.includes(node.id)) {
      // If there's a selectedNode and it's this node, we don't need a title
      return finalItems;
    }

    // If we're here, then there are multiple selected nodes or there's one selected node and the
    // user right-clicked a different one.  In either case, we add a title to the menu to make it
    // absolutely clear which row will be operated on by the context menu.
    if (finalItems.length) {
      return [
        { title: label },
        ...finalItems,
      ];
    }

    return finalItems;
  }, [
    selectedNodeIds,
    getExtraContextMenuItems,
    label,
    node.id,
    renaming,
    selectedNode?.id,
    visibility,
  ]);

  const getMenuTransform = useCallback((event: MouseEvent) => {
    if (nodeRef.current) {
      const coords = getRelativeEventCoordinates(
        event.nativeEvent,
        nodeRef.current,
        { fromRight: true },
      );
      return { left: coords.x, top: contextMenuYOffset };
    }
    return undefined;
  }, []);

  const handleContextMenu = (event: MouseEvent) => {
    // For now, skip opening the context menu if a node table is active or if it's already open
    // (i.e. menuItems has length)
    if (!isTreeModal && !contextMenuState.menuItems.length) {
      const menuItems = getContextMenuItems();
      if (menuItems.length) {
        event.preventDefault();
        setContextMenuState({
          menuItems,
          transform: getMenuTransform(event),
        });
      }
    }
  };

  return (
    <div
      className={cx(classes.rowRoot, {
        [classes.highlighted]: highlightState.highlighted,
        [classes.subHighlighted]: highlightState.subHighlighted,
        [classes.outlined]: isOutlined || contextMenuState.menuItems.length || isSubselectReference,
        [classes.hovered]: (
          nodeSelectHoveredId === node.id ||
          (hoveredInVis && !highlightState.highlighted && !highlightState.subHighlighted)
        ),
        [classes.dimmed]: isRowDimmed,
      })}
      data-locator="simulationTreeRow"
      data-node-type={node.type}
      data-row-id={node.id}
      data-row-name={node.name}
      data-terminal={hasChildren ? 'false' : 'true'}
      key={node.name}
      onContextMenu={handleContextMenu}
      onDoubleClick={handleDoubleClickRow}
      onKeyUp={(event) => {
        if (isUnmodifiedSpaceKey(event)) {
          handleClickRow(event);
        }
      }}
      onMouseDown={handleClickRow}
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
      ref={nodeRef}
      role="button"
      style={{ '--depth': depth } as CSSProperties}
      tabIndex={0}>
      <button
        className={classes.collapseToggle}
        disabled={!hasChildren || disableToggle}
        name="rowToggle"
        onClick={handleClickToggle}
        onMouseDown={stopPropagationFunc}
        type="button">
        <FolderTriangle open={nodesOpened[node.id]} />
      </button>
      {provisionIcon && (
        <Tooltip
          title={messages.length ?
            <LeveledMessageList maxToShow={10} messages={messages} /> :
            ''}>
          <div className={classes.iconContainer}>
            {badgeLevel && (
              <span className={cx(classes.badge, badgeLevel)} />)}
            {primaryIcon && (
              <SvgIcon
                {...primaryIcon}
                {...getIconSpecDims(primaryIcon, 12, 12)}
              />
            )}
          </div>
        </Tooltip>
      )}
      <Tooltip title={getRowTooltip}>
        <div className={classes.innerRow}>
          <div className={classes.rowText}>
            {editable ? (
              <div className={classes.rowName} ref={nameRef}>
                <EditableText
                  active={isRenaming && !treeSubselect.active && !nodeTableActive}
                  onChange={(value) => {
                    renaming.onCommit(value);
                    setIsRenaming(false);
                  }}
                  onDoubleClick={(event) => {
                    setIsRenaming(true);
                    event.stopPropagation();
                  }}
                  truncate
                  value={label}
                />
              </div>
            ) : (
              <div
                className={cx(classes.rowLabel, { bold: boldLabel })}
                data-locator="simulationTreeNodeRowLabel"
                ref={nameRef}>
                {label}
              </div>
            )}
            {sublabel && (
              <div className={classes.rowSublabel}>
                {sublabel}
              </div>
            )}
            {earlyAccess && (
              <div className={classes.earlyAccess}>
                <EarlyAccessLink inheritColor />
              </div>
            )}
            {(!!auxIcons.length) && (
              <div className={classes.auxIcons}>
                {auxIcons.map((auxIcon) => (
                  <Tooltip key={`tooltip-${auxIcon.name}`} title={auxIcon.tooltip ?? ''}>
                    <div
                      className={classes.auxIcon}
                      key={auxIcon.name}
                      style={{ opacity: clamp(auxIcon.opacity ?? 1, [0, 1]) }}>
                      <SvgIcon
                        {...auxIcon}
                        {...getIconSpecDims(auxIcon, 12, 12)}
                      />
                    </div>
                  </Tooltip>
                ))}
              </div>
            )}
          </div>
          {(visibility || propertiesControl || (userCanEdit && (addControl || auxControl))) && (
            <div
              className={classes.controls}
              onDoubleClick={stopPropagationFunc}
              onMouseDown={stopPropagationFunc}
              role="button"
              tabIndex={-1}>
              {visibility && (
                <div className={cx(classes.control, 'opaque', 'colorable', {
                  frozen: !visibility.disabled && visibility.show === false,
                })}>
                  <VisibilityButton
                    disabled={!!visibility.disabled}
                    isVisible={visibility.show !== false}
                    onClick={visibility.toggle}
                  />
                </div>
              )}
              {showPropertiesButton && (
                <div className={cx(classes.control, 'opaque', 'colorable')}>
                  <ShowPropPanelButton node={node} onClick={handleOpenPropPanel} />
                </div>
              )}
              {userCanEdit && addControl && (
                <div className={cx(classes.control, 'colorable', {})}>
                  {addControl}
                </div>
              )}
              {userCanEdit && auxControl && (
                <div className={cx(classes.control, 'colorable', {})}>
                  {auxControl}
                </div>
              )}
            </div>
          )}
        </div>
      </Tooltip>
      {!!contextMenuState.menuItems.length && (
        <CommonMenu
          anchorEl={nodeRef.current}
          closeOnSelect
          menuItems={contextMenuState.menuItems}
          onClose={() => setContextMenuState({ menuItems: [] })}
          open
          position="right-down"
          positionTransform={contextMenuState.transform}
        />
      )}
    </div>
  );
};
