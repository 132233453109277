// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { useEffect, useRef } from 'react';

import { shallowEqual } from 'fast-equals';

import * as flags from '../../flags';
import { GEOMETRY_TREE_NODE_TYPES, NodeType } from '../../lib/simulationTree/node';
import { NewNode, useNewNodes } from '../../recoil/nodeSession';
import { useSetPropertiesPanelVisible, useShowGeometryPropertiesPanelOnSelectValue, useSimulationPropertiesPanelDockedValue } from '../../recoil/propertiesPanel';
import { useIsEnabled } from '../../recoil/useExperimentConfig';
import { useSelectionContext } from '../context/SelectionManager';

export enum PropertiesPanelPosition {
  POPPED_IN,
  POPPED_OUT_LEFT,
  POPPED_OUT_RIGHT,
}

/**
 * Return true if properties panel is popped out on either side
 */
export function isPoppedOut(position: PropertiesPanelPosition) {
  return (
    position === PropertiesPanelPosition.POPPED_OUT_LEFT ||
    position === PropertiesPanelPosition.POPPED_OUT_RIGHT
  );
}

/**
 * Determine where to show the properties panel, depending on the docked option for the simulation
 * tree and the type of node that is selected.
 */
export function usePropertiesPanelPosition() {
  // == Contexts
  const { selectedNode } = useSelectionContext();

  // == Recoil
  const undockPropPanelsEnabled = useIsEnabled(flags.undockPropPanels);
  const simulationPropertiesPanelDocked = useSimulationPropertiesPanelDockedValue();

  if (!undockPropPanelsEnabled) {
    return PropertiesPanelPosition.POPPED_IN;
  }

  if (selectedNode && GEOMETRY_TREE_NODE_TYPES.has(selectedNode?.type)) {
    return PropertiesPanelPosition.POPPED_OUT_LEFT;
  }

  if (simulationPropertiesPanelDocked) {
    return PropertiesPanelPosition.POPPED_IN;
  }

  return PropertiesPanelPosition.POPPED_OUT_RIGHT;
}

/**
 * If the properties panel is shown as a floating panel and the panel is empty, we won't show them
 * if they belong to the geometry tree. We'll continue to show these empty prop panels for the
 * simulation tree, because their prop panels can be popped-in, where they are shown anyway.
 */
export function useIgnoreFloatingPropertiesPanel() {
  // == Contexts
  const { selectedNode } = useSelectionContext();

  switch (selectedNode?.type) {
    case NodeType.GEOMETRY_CONTACT_CONTAINER:
    case NodeType.SURFACE_CONTAINER:
    case NodeType.POINT_CONTAINER:
    case NodeType.VOLUME_CONTAINER: {
      return true;
    }
    default:
      return false;
  }
}

/**
 * When a new node is added into the geometry tree, we should open the property panel if the
 * "Show prop panel on select" is enabled.
 * When a new node is added into the simulation tree, we should open the prop panel without
 * any other conditions.
 */
export function useShowPropertyPanelOnNewNode() {
  // == Context
  const { selectedNode } = useSelectionContext();

  // == Recoil
  const [newNodes] = useNewNodes();
  const showGeometryPropertiesPanelOnSelect = useShowGeometryPropertiesPanelOnSelectValue();
  const setPropertiesPanelVisible = useSetPropertiesPanelVisible();

  // == Data
  const newNodesRef = useRef<NewNode[]>([]);

  useEffect(() => {
    if (
      selectedNode &&
      newNodes.at(-1)?.nodeId === selectedNode.id &&
      !shallowEqual(newNodesRef.current, newNodes)
    ) {
      newNodesRef.current = newNodes;

      const geometryNodeAdded = GEOMETRY_TREE_NODE_TYPES.has(selectedNode.type);
      if (geometryNodeAdded) {
        setPropertiesPanelVisible(showGeometryPropertiesPanelOnSelect);
      } else {
        setPropertiesPanelVisible(true);
      }
    }
  }, [showGeometryPropertiesPanelOnSelect, newNodes, setPropertiesPanelVisible, selectedNode]);
}
