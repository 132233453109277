// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import { ParamScope, createParamScope } from '../../lib/ParamScope';
import { appendHeatBoundaryCondition, getUnassignedSurfacesByPhysics } from '../../lib/boundaryConditionUtils';
import { ConfigurableHeatSourceType, appendHeatSource } from '../../lib/heatSourceUtils';
import { applyHeatDiscretizationPreset } from '../../lib/physicsHeatDiscretizationPresets';
import {
  findPhysicsById,
  getHeat,
  getHeatPhysicsMaterials,
  getOrCreateHeatSolutionControls,
  getOrCreateHeatSpatialDiscretization,
} from '../../lib/physicsUtils';
import { assignSurfacesToBoundaryCondition, updateMeshingFromBc } from '../../lib/simulationUtils';
import * as simulationpb from '../../proto/client/simulation_pb';
import { useEntityGroupMap } from '../../recoil/entityGroupState';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useEnabledExperiments } from '../../recoil/useExperimentConfig';
import { useSetMeshMultiPart } from '../../recoil/useMeshingMultiPart';
import { useStaticVolumes } from '../../recoil/volumes';

import { usePhysics } from './usePhysics';
import { useWorkflowConfig } from './useWorkflowConfig';

export const useHeatPhysics = (
  projectId: string,
  workflowId: string,
  jobId: string,
  readOnly: boolean,
  id: string, // Physics ID
) => {
  // == Recoil
  const experimentConfig = useEnabledExperiments();
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const setMeshMultiPart = useSetMeshMultiPart(projectId);
  const staticVolumes = useStaticVolumes(projectId);
  const geometryTags = useGeometryTags(projectId);

  const { saveParamAsync, simParam } = useWorkflowConfig(projectId, workflowId, jobId, readOnly);
  const {
    physics,
    hasVolumes,
    volumes,
    surfacesWithoutBoundaryConditions,
  } = usePhysics(projectId, workflowId, jobId, readOnly, id);

  const heatPhysics = useMemo(() => (physics ? getHeat(physics) : undefined), [physics]);

  const materials = useMemo(
    () => (physics ? getHeatPhysicsMaterials(simParam, physics, geometryTags, staticVolumes) : []),
    [physics, simParam, geometryTags, staticVolumes],
  );

  const disablePresetsReason = useMemo(() => {
    if (!physics) {
      return 'Unknown error with this physics';
    }
    return '';
  }, [physics]);

  const spatialDiscretization = useMemo(
    () => heatPhysics?.spatialDiscretizationHeat,
    [heatPhysics],
  );

  const setSpatialDiscretization = useCallback(
    async (value: simulationpb.SpatialDiscretizationHeat) => saveParamAsync(
      (newParam) => {
        const newPhysics = findPhysicsById(newParam, id);
        const newHeat = newPhysics ? getHeat(newPhysics) : null;
        if (newHeat) {
          newHeat.spatialDiscretizationHeat = value;
        }
      },
    ),
    [id, saveParamAsync],
  );

  const spatialDiscretizationPreset = useMemo(
    () => spatialDiscretization?.spatialDiscretizationHeatPreset,
    [spatialDiscretization],
  );

  const setSpatialDiscretizationPreset = useCallback(
    async (value: simulationpb.SpatialDiscretizationHeatPreset) => saveParamAsync(
      (newParam) => {
        const newPhysics = findPhysicsById(newParam, id);
        const newHeat = newPhysics ? getHeat(newPhysics) : null;
        if (newHeat) {
          const spatialDisc = getOrCreateHeatSpatialDiscretization(newHeat);
          spatialDisc.spatialDiscretizationHeatPreset = value;
          applyHeatDiscretizationPreset(newPhysics!, newParam, experimentConfig);
        }
      },
    ),
    [experimentConfig, id, saveParamAsync],
  );

  const solutionControls = useMemo(
    () => heatPhysics?.solutionControlsHeat,
    [heatPhysics],
  );

  const setSolutionControls = useCallback(
    async (value: simulationpb.SolutionControlsHeat) => saveParamAsync(
      (newParam) => {
        const newPhysics = findPhysicsById(newParam, id);
        const newHeat = newPhysics ? getHeat(newPhysics) : null;
        if (newHeat) {
          newHeat.solutionControlsHeat = value;
        }
      },
    ),
    [id, saveParamAsync],
  );

  const floatType = simParam?.general?.floatType;

  const adjointSolutionControls = useMemo(() => heatPhysics?.adjointControlsHeat, [heatPhysics]);

  const setAdjointSolutionControls = useCallback(
    async (value: simulationpb.AdjointControlsHeat) => saveParamAsync(
      (newParam) => {
        const newPhysics = findPhysicsById(newParam, id);
        const newHeat = newPhysics ? getHeat(newPhysics) : null;
        if (newHeat) {
          newHeat.adjointControlsHeat = value;
        }
      },
    ),
    [id, saveParamAsync],
  );

  const solutionControlsPreset = useMemo(
    () => solutionControls?.solutionControlsHeatPreset,
    [solutionControls],
  );

  const setSolutionControlsPreset = useCallback(
    async (value: simulationpb.SolutionControlsHeatPreset) => saveParamAsync(
      (newParam) => {
        const newPhysics = findPhysicsById(newParam, id);
        const newHeat = newPhysics ? getHeat(newPhysics) : null;
        if (newHeat) {
          const spatialDisc = getOrCreateHeatSolutionControls(newHeat);
          spatialDisc.solutionControlsHeatPreset = value;
        }
      },
    ),
    [id, saveParamAsync],
  );

  const initialization = useMemo(
    () => heatPhysics?.initializationHeat,
    [heatPhysics],
  );

  const setInitialization = useCallback(
    async (value: simulationpb.InitializationHeat) => saveParamAsync(
      (newParam) => {
        const newPhysics = findPhysicsById(newParam, id);
        const newHeat = newPhysics ? getHeat(newPhysics) : null;
        if (newHeat) {
          newHeat.initializationHeat = value;
        }
      },
    ),
    [id, saveParamAsync],
  );

  const createHeatBoundaryConditionWithUnassignedSurfaces = useCallback(
    async (boundaryType: simulationpb.HeatPhysicalBoundary) => saveParamAsync((newParam) => {
      const newBoundCond = appendHeatBoundaryCondition(newParam, id, boundaryType);
      assignSurfacesToBoundaryCondition(
        getUnassignedSurfacesByPhysics(newParam, id, geometryTags, staticVolumes),
        newBoundCond,
        newParam,
        geometryTags,
        staticVolumes,
        entityGroupMap,
      );
      updateMeshingFromBc(newParam, simParam, setMeshMultiPart);
      return newBoundCond.boundaryConditionName;
    }),
    [geometryTags, entityGroupMap, id, saveParamAsync, setMeshMultiPart, simParam, staticVolumes],
  );

  const heatSourceTypesToAdd: ConfigurableHeatSourceType[] = [
    simulationpb.HeatSourceType.HEAT_SOURCE_TYPE_POWER,
    simulationpb.HeatSourceType.HEAT_SOURCE_TYPE_POWER_PER_UNIT_OF_VOLUME,
  ];

  const unassignedHeatSourceDomains = useCallback((excludingSourceId?: string) => {
    if (physics?.params.case === 'heat') {
      const domainPool = new Set(volumes.map((volume) => volume.domain));
      const heat = physics.params.value;
      heat.heatSource.forEach((source, i) => {
        if (excludingSourceId && excludingSourceId === source.heatSourceId) {
          return;
        }
        source.heatSourceZoneIds.forEach((zoneId) => domainPool.delete(zoneId));
      });
      return domainPool;
    }
    // For safety, retun an empty set if physics happens to be undefined
    return new Set<string>();
  }, [physics, volumes]);

  const addHeatSource = useCallback((type: simulationpb.HeatSourceType) => saveParamAsync(
    (newParam) => appendHeatSource(newParam, id)?.heatSourceId,
  ), [id, saveParamAsync]);

  // A map of domains (from volumes assigned to the parent physics) to heat source IDs for lookup
  // purposes
  const heatSourceDomainAssignmentMap = useMemo(() => {
    const record: Record<string, string> = {};

    heatPhysics?.heatSource.forEach((source) => {
      source.heatSourceZoneIds.forEach((domain) => {
        record[domain] = source.heatSourceId;
      });
    });

    return record;
  }, [heatPhysics]);

  // Return text describing the reason that a domain (volume) may not be assigned to a heat source.
  // If the reason is an empty string, then the assignment is allowed.
  const disabledHeatSourceDomainReason = useCallback((sourceId: string, domain: string) => {
    const assignedSourceId = heatSourceDomainAssignmentMap[domain];
    if (assignedSourceId && assignedSourceId !== sourceId) {
      return 'Volume already has another heat source applied';
    }
    return '';
  }, [heatSourceDomainAssignmentMap]);

  const getParamScope = useCallback((paramScope: ParamScope) => (
    physics ? createParamScope(physics, experimentConfig, paramScope) : paramScope
  ), [experimentConfig, physics]);

  return {
    physics,
    heatPhysics,
    hasVolumes,
    volumes,
    materials,
    getParamScope,

    spatialDiscretization,
    setSpatialDiscretization,
    spatialDiscretizationPreset,
    setSpatialDiscretizationPreset,

    solutionControls,
    setSolutionControls,
    solutionControlsPreset,
    setSolutionControlsPreset,

    floatType,
    adjointSolutionControls,
    setAdjointSolutionControls,

    disablePresetsReason,

    initialization,
    setInitialization,

    heatSourceTypesToAdd,
    addHeatSource,
    disabledHeatSourceDomainReason,
    unassignedHeatSourceDomains,

    surfacesWithoutBoundaryConditions,
    createHeatBoundaryConditionWithUnassignedSurfaces,
  };
};
