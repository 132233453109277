// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import cx from 'classnames';

import { SvgIconSpec } from '../../../lib/componentTypes/svgIcon';
import { getIconForEntityGroup } from '../../../lib/entityGroupUtils';
import { AnyKeyboardEvent, isUnmodifiedEnterKey, isUnmodifiedEscapeKey, isUnmodifiedTabKey } from '../../../lib/event';
import { toggleSetItem } from '../../../lib/lang';
import { LeveledMessage } from '../../../lib/notificationUtils';
import { NodeType, SimulationTreeNode } from '../../../lib/simulationTree/node';
import { formatDescendantCount } from '../../../lib/simulationTree/utils';
import { canSubselectNode, isSubselectActive } from '../../../lib/subselectUtils';
import { wordsToList } from '../../../lib/text';
import { ListenerEvent, useEventListener } from '../../../lib/useEventListener';
import { useEntityGroupMap, useNumDescendantsMap } from '../../../recoil/entityGroupState';
import { useSetEntitySelection } from '../../../recoil/selectionOptions';
import {
  NodeFilter,
  useResetSimulationTreeSubselect,
  useSelectedVisualizerEntities,
  useSetSimulationTreeSubselect,
  useSimulationTreeSubselect,
} from '../../../recoil/simulationTreeSubselect';
import { useSetNodeSelectHoveredId } from '../../../state/internal/selection/highlighting';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { useToggleHighlightForNode } from '../../hooks/useToggleHighlightForNode';
import { useGetNodeFromAnyTree } from '../../hooks/useTree';
import { SectionMessage } from '../../notification/SectionMessage';
import { NodeSelection } from '../NodeSelection';

import { HelperButton } from './HelperButton';
import { useSubselectControl } from './control';
import { getSelectorListLocator, useMouseDown } from './utils';

import './NodeSubselect.scss';

/** Label to be shown when a node is not found */
const NOT_FOUND_NODE_LABEL = 'Not found';

/**
 * Define an interface for two-way mapping between the assigned node IDs and the node IDs used for
 * tagging.  For example, `nodesToTags` may be used to roll up a set of surfaces to surface groups,
 * while `tagsToNodes` may be used to ungroup a set of surface groups into low-level surfaces.
 */
export interface NodeIdTranslation {
  nodesToTags: (nodeIds: string[]) => string[];
  tagsToNodes: (nodeIds: string[]) => string[];
}

export interface SelectAllOperation {
  label: string;
  onClick: () => void;
  title?: string; // optional tooltip
  disabled?: boolean;
}

export interface NodeSubselectProps {
  /** Unique table identifier, used to identify which instance is active */
  id: string;
  /** Optional table title */
  title?: string;
  /** Currently selected nodes (by ID) */
  nodeIds: string[];
  /** Filter tree nodes for selection eligibility */
  nodeFilter: NodeFilter;
  /** A list of node IDs from which subselect originated */
  referenceNodeIds: string[];
  /** Respond to changes in the sub-selected node IDs */
  onChange: (nodeIds: string[]) => void;
  /** Human-readable list of node types being managed (for help text) */
  labels: string[];
  /** Optionally disable all controls */
  readOnly?: boolean;
  /** Optionally convert a list of entities clicked in Paraview to node ID(s) to toggle */
  mapVisualizerEntities?: (ids: string[]) => string[];
  /** Optional message to show */
  message?: LeveledMessage;
  /** Optionally start editing as soon as rendering is complete */
  autoStart?: boolean;
  /**
   * By default, when subselect is active, parent node selection is proxied by selection of its
   * children.  In other words, if a user selects a surface group, then its child surfaces are
   * selected; and if all child surfaces are selected, then the parent is inferred to be selected.
   * (This behavior is set in the SimulationTree component by setting `selectByChildren` to true on
   * the DataTree component.)  In some cases, such as Output surfaces, parents and children need to
   * be treated independently.  That is, surfaces and surface groups should be independently
   * selectable.  For those cases, set independentSelection to true.
   */
  independentSelection?: boolean;
  /**
   * If we pass some NodeTypes, only these types will be visible in the Geometry tree when
   * the NodeSubselect is activated.
   */
  visibleTreeNodeTypes?: NodeType[];
  /**
   * Whether to show nodes that are not found in the tree.
   * NOTE: no icon is added to these nodes, but they can still be deleted.
   */
  showNotFoundNodes?: boolean;
  /** Icon to be used to represent nodes that are not found in the tree. */
  iconNotFoundNodes?: SvgIconSpec;
  /** Disable the button for clearing all selections */
  disableClearAll?: boolean;
  /** Optional callback which is called anytime the NodeSubselect is first activated */
  onStart?: () => void;
  /** Optionally configure a button for selecting nodes in bulk */
  selectAll?: SelectAllOperation;
}

interface NodeTagConfig {
  nodeId: string;
  label: string;
  disabledReason: string;
  subLabel?: string;
}

function labelsList(labels: string[]) {
  return wordsToList(labels, { conjunction: 'or' });
}

function getDefaultMessage(labels: string[]) {
  return `Select ${labelsList(labels)}`;
}

/**
 * NodeSubselect is used to place the simulation tree in subselect mode, which allows the user
 *   to associate a set of nodes with another set of nodes.  For example, the user may wish to add a
 *   set of surfaces to a boundary condition.  In this case, the selected boundary condition would
 *   be the "reference" node and a pool of eligible surface nodes can be configured.  When subselect
 *   is active, all but the eligible nodes will be dimmed and all row controls will be removed.
 *
 * Minimal example props for a NodeSubselect used to manage a set of surfaces for a boundary
 * condition:
 *   labels: ['surfaces']
 *   nodeIds: SURFACE_IDS // where SURFACE_IDS is a list of surfaces currently assigned to the bc
 *   nodeFilter: { types?: [NodeType.SURFACE] }
 *   referenceNodeIds: [ID] // where ID is the bc ID
 *   onChange: (surfaceIds: string[]) => saveSurfaces(surfaceIds)
*/
export const NodeSubselect = (props: NodeSubselectProps) => {
  // == Props
  const {
    autoStart,
    disableClearAll,
    iconNotFoundNodes,
    id,
    independentSelection,
    labels,
    mapVisualizerEntities,
    message,
    nodeIds,
    nodeFilter,
    onChange,
    readOnly,
    referenceNodeIds,
    selectAll,
    showNotFoundNodes,
    title,
    visibleTreeNodeTypes = [],
    onStart,
  } = props;

  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();
  const { setSelection } = useSelectionContext();

  // == Recoil
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const countMap = useNumDescendantsMap(projectId, workflowId, jobId);
  const treeSubselect = useSimulationTreeSubselect();
  const setTreeSubselect = useSetSimulationTreeSubselect();
  const resetTreeSubselect = useResetSimulationTreeSubselect();
  const [
    selectedVisualizerEntities,
    setSelectedVisualizerEntities,
  ] = useSelectedVisualizerEntities();
  const setEntitySelectionStateBase = useSetEntitySelection(projectId);

  // == Hooks
  const getNodeFromAnyTree = useGetNodeFromAnyTree();
  const { endSubselect, startSubselect } = useSubselectControl();
  const toggleHighlightForNode = useToggleHighlightForNode();
  const setNodeSelectHoveredId = useSetNodeSelectHoveredId();
  const handleSubselectMouseDown = useMouseDown();

  // == Data
  const isActive = useMemo(() => isSubselectActive(treeSubselect, id), [id, treeSubselect]);
  const isDisabled = useMemo(
    () => readOnly || (treeSubselect.active && treeSubselect.id !== id),
    [id, readOnly, treeSubselect],
  );

  // Nodes that should be represented with a Tag
  const nodesToShow = useMemo(() => nodeIds.reduce((result, nodeId) => {
    const node = getNodeFromAnyTree(nodeId);
    if (node) {
      const disabledReason = nodeFilter(node.type, node.id).tooltip ?? '';
      const descendantCount = countMap.get(node.id);
      const subLabel = independentSelection ? undefined : formatDescendantCount(descendantCount);
      result.push({
        nodeId: node.id,
        label: node.name,
        subLabel,
        disabledReason,
      });
    } else if (showNotFoundNodes) {
      result.push({
        nodeId,
        label: NOT_FOUND_NODE_LABEL,
        disabledReason: '',
      });
    }
    return result;
  }, [] as NodeTagConfig[]), [
    countMap,
    getNodeFromAnyTree,
    independentSelection,
    nodeFilter,
    nodeIds,
    showNotFoundNodes,
  ]);

  // Memoize lists of nodes that can and can't be cleared.  If the canClear list has length, the
  // Clear button is enabled, and clicking the button updates the selection to the cannotClear list.
  const clearAllNodesState = useMemo(() => {
    const result = {
      canClear: [] as string[],
      cannotClear: [] as string[],
    };
    nodesToShow.forEach(({ nodeId, disabledReason }) => {
      if (disabledReason) {
        result.cannotClear.push(nodeId);
      } else {
        result.canClear.push(nodeId);
      }
    });
    return result;
  }, [nodesToShow]);

  // == Handlers
  const handleStart = useCallback(() => {
    if (isDisabled || isActive) {
      return;
    }

    startSubselect(
      id,
      nodeFilter,
      referenceNodeIds,
      nodeIds,
      independentSelection,
      visibleTreeNodeTypes,
      onChange,
      onStart,
    ).catch(
      (err) => console.error('Error starting subselect'),
    );

    const noVolumesAllowed = visibleTreeNodeTypes.every((item) => item !== NodeType.VOLUME);
    const noSurfacesAllowed = visibleTreeNodeTypes.every((item) => item !== NodeType.SURFACE);

    // don't change current selection if node types don't match
    if (noVolumesAllowed && noSurfacesAllowed) {
      return;
    }

    setEntitySelectionStateBase(noVolumesAllowed ? 'surface' : 'volume');
  }, [
    id,
    isActive,
    independentSelection,
    nodeIds,
    nodeFilter,
    onChange,
    isDisabled,
    referenceNodeIds,
    startSubselect,
    visibleTreeNodeTypes,
    onStart,
    setEntitySelectionStateBase,
  ]);

  const handleChange = useCallback((newNodeIds: string[]) => {
    onChange(newNodeIds);
  }, [onChange]);

  const handleEnd = useCallback((idsToSelect?: string[]) => {
    // If `idsToSelect` is not provided, reselect the reference node IDs by default
    endSubselect(idsToSelect ?? referenceNodeIds);
  }, [endSubselect, referenceNodeIds]);

  const handleClear = useCallback(() => {
    if (isActive) {
      setSelection(clearAllNodesState.cannotClear);
    } else {
      handleChange(clearAllNodesState.cannotClear);
    }
  }, [clearAllNodesState, setSelection, handleChange, isActive]);

  const handleRemoveNode = useCallback((taggedNodeId: string) => {
    const newNodeIds = nodeIds.filter((nodeId) => taggedNodeId !== nodeId);
    if (isActive) {
      setSelection(newNodeIds);
    } else {
      handleChange(newNodeIds);
    }
  }, [isActive, nodeIds, setSelection, handleChange]);

  const onHover = (nodeId: string, hovered: boolean) => {
    toggleHighlightForNode(nodeId, visibleTreeNodeTypes.includes(NodeType.VOLUME), hovered);
    setNodeSelectHoveredId(hovered ? nodeId : null);
  };

  // == Effects
  const started = useRef(false);
  // Automatically start editing if `autoStart` is true
  useEffect(() => {
    if (autoStart && !started.current) {
      handleStart();
      started.current = true;
    }
  }, [autoStart, handleStart]);

  // Respond to clicks in the paraview window
  useEffect(() => {
    if (isActive && selectedVisualizerEntities) {
      const { ids } = selectedVisualizerEntities;
      const inferredNodeIds = mapVisualizerEntities?.(ids) ?? ids;

      const inferredNodes = inferredNodeIds.reduce((result, nodeId) => {
        const node = getNodeFromAnyTree(nodeId);
        if (node && canSubselectNode(treeSubselect.nodeFilter, node)) {
          result.push(node);
        }
        return result;
      }, [] as SimulationTreeNode[]);

      const nodeIdSet = new Set(nodeIds);
      inferredNodes.forEach((node) => toggleSetItem(nodeIdSet, node.id));

      setSelection([...nodeIdSet]);
      setSelectedVisualizerEntities(null);
    }
  }, [
    selectedVisualizerEntities,
    isActive,
    mapVisualizerEntities,
    nodeIds,
    setSelectedVisualizerEntities,
    setSelection,
    getNodeFromAnyTree,
    treeSubselect.nodeFilter,
  ]);

  // Dynamically update the nodeFilter value in the treeSubselect recoil state so that row dimming
  // and tooltips can change as selections change.
  // WARNING: this useEffect can cause infinite re-rendering if the `nodeFilter` prop reference
  // keeps changing.  To prevent this, use useMemo or useRef to pin down in the value passed as
  // `nodeFilter` from the parent component to this component.
  useEffect(() => {
    if (isActive) {
      // Active filtering may change as selections change
      setTreeSubselect((oldValue) => ({ ...oldValue, nodeFilter }));
    }
  }, [isActive, nodeFilter, setTreeSubselect]);

  const handleKeyDown = useCallback((event: ListenerEvent) => {
    if (isActive && (
      isUnmodifiedEscapeKey(event as AnyKeyboardEvent) ||
      isUnmodifiedTabKey(event as AnyKeyboardEvent)
    )) {
      handleEnd();
    }
  }, [handleEnd, isActive]);

  // End the selection mode if we press ESC or Tab
  useEventListener('keydown', handleKeyDown);

  const handleMouseDown = useCallback((event: ListenerEvent) => {
    const { cancelSubselect, clickedRowId } = handleSubselectMouseDown(event.target as Node);

    if (cancelSubselect) {
      handleEnd(clickedRowId ? [clickedRowId] : undefined);
    }
  }, [handleEnd, handleSubselectMouseDown]);

  // End the selection if we click outside the 3d viewer, the geometry tree panel and the
  // camera toolbar. Use mousedown or this will the end the onClick handleStart prematurely.
  useEventListener('mousedown', handleMouseDown);

  // Reset subselect state when unmounting
  useEffect(() => resetTreeSubselect, [resetTreeSubselect]);

  const hasControls = selectAll || !disableClearAll;

  return (
    <div className="nodeSubselect">
      {(title || hasControls) && (
        <div className="header">
          <div className="title">
            {title && (
              <>
                {title} ({nodesToShow.length})
              </>
            )}
          </div>
          {hasControls && (
            <div className="controls">
              {selectAll && (
                <HelperButton {...selectAll} disabled={isDisabled || selectAll.disabled} />
              )}
              {!disableClearAll && (
                <HelperButton
                  disabled={isDisabled || !clearAllNodesState.canClear.length}
                  label="Clear All"
                  onClick={handleClear}
                  title={clearAllNodesState.canClear.length ? 'Click to clear selections' : ''}
                />
              )}
            </div>
          )}
        </div>
      )}
      <div
        className={cx('box', { active: isActive, disabled: isDisabled })}
        data-locator={getSelectorListLocator(id)}
        onClick={handleStart}
        onKeyDown={(event) => {
          if (isUnmodifiedEnterKey(event)) {
            event.stopPropagation();
            handleStart();
          }
        }}
        role="button"
        tabIndex={0}>
        <div className="nodeTags">
          {!isDisabled && !nodesToShow.length && (
            <div className="message">{getDefaultMessage(labels)}</div>
          )}
          {nodesToShow.map(({ nodeId, label, subLabel, disabledReason }) => (
            <NodeSelection
              auxText={subLabel}
              closing={{
                disabled: !!disabledReason,
                onClick: (event) => {
                  // We don't want the event for clicking the (x) to bubble up because it would
                  // start the selection mode (if we are not in it).
                  event.stopPropagation();
                  handleRemoveNode(nodeId);
                },
                tooltip: disabledReason,
              }}
              icon={
                entityGroupMap.has(nodeId) ?
                  getIconForEntityGroup(entityGroupMap.get(nodeId)) :
                  iconNotFoundNodes
              }
              key={nodeId}
              onClick={handleStart}
              onMouseEnter={() => onHover(nodeId, true)}
              onMouseLeave={() => onHover(nodeId, false)}
              text={label}
            />
          ))}
        </div>
      </div>
      {isActive && message && (
        <div className="message">
          <SectionMessage {...message} />
        </div>
      )}
    </div>
  );
};
