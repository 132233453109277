// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useEffect, useMemo, useRef, useState } from 'react';

import * as flags from '../../flags';
import { colors } from '../../lib/designSystem';
import { isUnmodifiedSpaceKey } from '../../lib/event';
import { NodeTableType } from '../../lib/nodeTableUtil';
import { LeveledMessage, levelToRank, sortLeveledMessages } from '../../lib/notificationUtils';
import { useUserCanEdit } from '../../lib/projectRoles';
import { NodeType } from '../../lib/simulationTree/node';
import { getRunButtonText } from '../../lib/simulationTree/utils';
import { getNodeMessages } from '../../lib/simulationValidation';
import useResizeObserver from '../../lib/useResizeObserver';
import * as projectstatepb from '../../proto/projectstate/projectstate_pb';
import { useGeometryHealth } from '../../recoil/geometryHealth';
import { useIsGeometryPending } from '../../recoil/pendingWorkOrders';
import { useProjectWithResetGeometry } from '../../recoil/project';
import { useSetSimulationPropertiesPanelDocked } from '../../recoil/propertiesPanel';
import { useIsEnabled } from '../../recoil/useExperimentConfig';
import useExplorationSet from '../../recoil/useExplorationSet';
import { GeometryTreePositionType, useGeometryTreePosition } from '../../recoil/useGeometryTreePosition';
import { useMeshReadyState } from '../../recoil/useMeshReadyState';
import useProjectMetadata from '../../recoil/useProjectMetadata';
import { useControlPanelMode, useIsBaselineMode } from '../../recoil/useProjectPage';
import { useCurrentConfig } from '../../recoil/workflowConfig';
import { useProjectValidator } from '../../state/external/project/validator';
import { useIsAnalysisView, useIsGeometryView, useIsSetupView } from '../../state/internal/global/currentView';
import { useGeometryTree } from '../../state/internal/tree/section/geometry';
import { useSimulationTree } from '../../state/internal/tree/simulation';
import { IconButton } from '../Button/IconButton';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { useIsLMAActive } from '../hooks/useMesh';
import { PropertiesPanelPosition, usePropertiesPanelPosition, useShowPropertyPanelOnNewNode } from '../hooks/usePropertiesPanel';
import { Resizable } from '../layout/Resizable';
import { SectionMessage } from '../notification/SectionMessage';
import { ChevronLeftIcon } from '../svg/ChevronLeftIcon';
import { RectanglePoppedOutIcon } from '../svg/RectanglePoppedOut';
import { PropertiesPanel } from '../treePanel/PropertiesPanel';
import SimulationTreePanel from '../treePanel/SimulationTreePanel';

import RunSimulationButton from './RunSimulationButton';

const errorLevel = levelToRank('error');

const useStyles = makeStyles(
  () => createStyles({
    topInfoMessage: {
      padding: '8px',
    },
    expControl: {
      color: colors.highEmphasisText,
      fontSize: '14px',
      padding: '13px',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '8px',
      overflow: 'hidden',
    },
    expIcon: {
      flex: '0 0 auto',
    },
    expName: {
      flex: '1 1 auto',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    runControl: {
      flex: '0 0 auto',
      display: 'flex',
      padding: '8px',
    },
    runButton: {
      flex: '1 1 auto',
    },
    propertiesHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: '13px',
      lineHeight: '16px',
      fontWeight: 600,
      padding: '10px 12px',
      width: '100%',
      zIndex: 1,
    },
    splitter: {
      borderTop: `4px solid ${colors.neutral50}`,
      borderBottom: `1px solid ${colors.neutral200}`,
    },
  }),
  { name: 'SimulationSettings' },
);

/**
  * This returns a possible info SectionMessage at the top of the SimulationSettings.
  * If there is such a message, the run simulation button will be hidden.
  */
function useGetTopInfoMessage() {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const meshReadyState = useMeshReadyState(projectId, workflowId, jobId);
  const geometryPending = useIsGeometryPending(projectId);
  const [projectWithResetGeometry] = useProjectWithResetGeometry(projectId);

  // If we don't have a mesh/geometry and we are in new project
  if (!meshReadyState && !projectWithResetGeometry) {
    return 'Please upload geometry. You can modify simulation settings once geometry ' +
      'preparation is complete.';
  }
  // If we don't have a mesh/geometry, but we are opening an old project (with removed geometry)
  if (!meshReadyState && projectWithResetGeometry) {
    return 'Existing properties settings will apply to the model when it has been uploaded.';
  }
  // If we have a geometry but it is being prepared atm
  if (geometryPending) {
    return 'You can modify simulation settings once geometry preparation is complete.';
  }

  return '';
}

interface SimulationSettingsProps {
  // Called when the user runs a simulation or an exploration.
  onRunSimulation: (isExploration: boolean) => Promise<void>;
}

/**
 * Shows a simulation tree panel which organizes the simulation settings.
 * Selecting a node in that panel will bring up more details in the properties panel.
 */
const SimulationSettings = (props: SimulationSettingsProps) => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();
  const { activeNodeTable } = useSelectionContext();

  // == Recoil
  const [controlPanelMode] = useControlPanelMode();
  const config = useCurrentConfig(projectId, workflowId, jobId);
  const [explorationSet, setExplorationSet] = useExplorationSet(projectId);
  const [geometryHealth] = useGeometryHealth(projectId);
  const geometryTreePosition = useGeometryTreePosition({ projectId, workflowId, jobId });
  const isBaselineMode = useIsBaselineMode();
  const isAnalysisView = useIsAnalysisView();
  const isSetupView = useIsSetupView();
  const isGeometryView = useIsGeometryView();
  const projectMetadata = useProjectMetadata(projectId);
  const simulationTree = useSimulationTree(projectId, workflowId, jobId);
  const sensitivityEnabled = useIsEnabled(flags.adjointSA);
  const undockPropPanelsEnabled = useIsEnabled(flags.undockPropPanels);

  // == Models
  const validator = useProjectValidator(projectId, workflowId, jobId);
  const setSimulationPropertiesPanelDocked = useSetSimulationPropertiesPanelDocked();

  // == Hooks
  const classes = useStyles();
  const geometryTree = useGeometryTree(projectId, workflowId, jobId);
  const topInfoMessage = useGetTopInfoMessage();
  const isLMA = useIsLMAActive();
  const propertiesPanelPosition = usePropertiesPanelPosition();
  const userCanEdit = useUserCanEdit(projectMetadata?.summary);

  // == Derived data
  const exploration = config.exploration;
  const isSensitivityAnalysis = exploration?.policy.case === 'sensitivityAnalysis';
  const experimentName = isBaselineMode ? '' : exploration?.name || '';
  const separatedGeometryTree = geometryTreePosition === GeometryTreePositionType.FLOATING;

  // == State
  const rootNode = useRef<HTMLDivElement>(null);
  const containerNode = useRef<HTMLDivElement>(null);
  const [propsInitSize, setPropsInitSize] = useState('');

  const rootSize = useResizeObserver(rootNode, { name: 'simSettingsPropsPanel' });

  // == Effects
  useShowPropertyPanelOnNewNode();

  useEffect(() => {
    if (rootSize.height && !propsInitSize) {
      // Set initial size on Props Panel Resizable, based on the height of this component's root
      // (minus approximately half the splitter height), but only set it once
      const size = 0.45 * rootSize.height - 50;
      setPropsInitSize(`${Math.max(0, Math.round(size))}px`);
    }
  }, [rootSize, propsInitSize]);

  // Find the root node for the current panel.
  const panelRoot = useMemo(() => {
    let nodeType = NodeType.ROOT_SIMULATION;
    switch (controlPanelMode) {
      case 'exploration':
        nodeType = NodeType.ROOT_EXPLORATION;
        break;
      case 'simulation':
        nodeType = NodeType.ROOT_SIMULATION;
        break;
      case 'geometry':
        nodeType = NodeType.ROOT_GEOMETRY;
        break;
      default:
        throw Error('Invalid panel mode');
    }
    // Due to the way in which controlPanelMode is set (through useEffect), there is a race
    // between the controlPanelMode and the simulationTree (the latter is handled via recoil).
    // For now, we'll just set the nodeType by hand if we are in the geometry view.
    if (isGeometryView) {
      nodeType = NodeType.ROOT_GEOMETRY;
    }
    return simulationTree.children.find((node) => node.type === nodeType);
  }, [controlPanelMode, isGeometryView, simulationTree]);

  const messages = useMemo(() => {
    const leveledMessages: LeveledMessage[] = [];
    // Check for any errors in the main simulation tree
    if (panelRoot) {
      leveledMessages.push(...getNodeMessages(validator, panelRoot, errorLevel));
    }
    // Check for errors in the floating geometry tree
    if (separatedGeometryTree) {
      leveledMessages.push(...getNodeMessages(validator, geometryTree, errorLevel));
    }
    // Avoid appending messages here that don't come from the getNodeMessages() function
    sortLeveledMessages(leveledMessages);
    return leveledMessages;
  }, [panelRoot, separatedGeometryTree, geometryTree, validator]);

  const hasErrors = !!messages.length;

  if (!panelRoot) {
    return <></>;
  }

  // Button is disabled with an active node table or warnings or a geometry health problem. In
  // analysis view, the button is "Copy to Setup" and the warnings don't apply.
  const hasGeometryIssues = !!geometryHealth;
  const disabled = (
    (activeNodeTable.type !== NodeTableType.NONE) ||
    ((hasErrors || hasGeometryIssues) && !isAnalysisView)
  );

  // Show the button only for users that are owners of the projects and only in the setup/geometry
  const showButton = (
    userCanEdit &&
    !topInfoMessage &&
    (isGeometryView || isSetupView)
  );

  const runButtonText = getRunButtonText(
    isGeometryView,
    isBaselineMode,
    isSensitivityAnalysis,
    config,
    isLMA,
  );

  const unselectExploration = () => {
    const oldList = explorationSet.exploration;
    const i = explorationSet.index;
    setExplorationSet(
      new projectstatepb.ExplorationSet({
        exploration: oldList.map((old, j) => ((i === j) ? exploration! : old)),
        index: -1,
      }),
    );
  };

  return (
    <div className="flexColumnLayout fullHeight" ref={rootNode}>
      {sensitivityEnabled && experimentName && (
        <div className="flexItem">
          <div
            className={classes.expControl}
            onClick={unselectExploration}
            onKeyUp={(event) => {
              if (isUnmodifiedSpaceKey(event)) {
                unselectExploration();
              }
            }}
            role="button"
            tabIndex={0}>
            <div className={classes.expIcon}>
              <ChevronLeftIcon color={colors.neutral650} maxHeight={10} maxWidth={10} />
            </div>
            <div className={classes.expName}>{experimentName}</div>
          </div>
        </div>
      )}
      {showButton && (
        <RunSimulationButton
          disabled={disabled}
          isExploration={!isBaselineMode}
          messages={messages}
          onRunSimulation={props.onRunSimulation}>
          {runButtonText.label}
        </RunSimulationButton>
      )}
      {topInfoMessage && (
        <div className={classes.topInfoMessage}>
          <SectionMessage
            level="info"
            message={topInfoMessage}
          />
        </div>
      )}
      <div className="flexItem elastic">
        <div className="flexColumnLayout" ref={containerNode} style={{ height: '100%' }}>
          <div
            className="flexItem elastic"
            data-locator="simulationTreePanelContainer"
            style={{ flexBasis: '1px', overflow: 'hidden' }}>
            <SimulationTreePanel panelRoot={panelRoot} />
          </div>
          {propertiesPanelPosition === PropertiesPanelPosition.POPPED_IN && (
            <Resizable
              getDragInputs={() => ({ boundingNode: containerNode.current ?? undefined })}
              initialSize={propsInitSize}
              minSize={0}
              splitterContent={(
                <div style={{ width: '100%' }}>
                  <div className={classes.splitter} />
                  <div className={classes.propertiesHeader}>
                    Properties
                    {undockPropPanelsEnabled && (
                      <IconButton onClick={() => setSimulationPropertiesPanelDocked(false)}>
                        <RectanglePoppedOutIcon maxHeight={13} />
                      </IconButton>
                    )}
                  </div>
                </div>
              )}
              splitterPlacement="top">
              <PropertiesPanel />
            </Resizable>
          )}
        </div>
      </div>
    </div>
  );
};

export default SimulationSettings;
