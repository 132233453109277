// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect } from 'react';

import WorkflowConfigValidator, { formatValidatorMessage } from '../../lib/WorkflowConfigValidator';
import { Logger } from '../../lib/observability/logs';
import { PARAM_VALIDATION_ERROR_NOTIFICATION_ID, removeNotification, setNotification } from '../../lib/transientNotification';
import * as basepb from '../../proto/base/base_pb';

let configValidator: WorkflowConfigValidator | undefined;
let observerCount = 0;

const logger = new Logger('ConfigValidator');

/** Some errors are expected and can be safely ignored by not displaying an error toast */
const IGNORED_ERRORS = [
  // When the user toggles "existing solution" and no solution has been selected yet
  'Existing solution was not provided\n',
];

function getConfigValidator(): WorkflowConfigValidator {
  if (!configValidator) {
    configValidator = new WorkflowConfigValidator(async (err: basepb.Status) => {
      if (err.code === 0) {
        removeNotification(PARAM_VALIDATION_ERROR_NOTIFICATION_ID);
      } else if (
        !IGNORED_ERRORS.some((expectedError) => err.message.endsWith(expectedError))
      ) {
        setNotification(
          PARAM_VALIDATION_ERROR_NOTIFICATION_ID,
          'error',
          formatValidatorMessage(err),
          err,
        );
        logger.warn(`Got validation result: ${err.toJsonString()}`);
      }
    });
  }
  return configValidator!;
}

function stopConfigValidator() {
  configValidator?.stop();
  configValidator = undefined;
}

/**
 * Expose a singleton WorkflowConfigValidator object
 */
export const useConfigValidator = (): WorkflowConfigValidator => {
  useEffect(() => {
    observerCount += 1;

    return () => {
      observerCount -= 1;
      if (!observerCount) {
        stopConfigValidator();
      }
    };
  }, []);

  // return actual singleton validator
  return getConfigValidator();
};
