// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback } from 'react';

import { SimulationRowProps } from '../../../../lib/componentTypes/simulationTree';
import { deleteTreeNodeMenuItem } from '../../../../lib/treeUtils';
import { useNodeDeletion } from '../../../hooks/useNodeDeletion';
import { useNodeRenaming } from '../../../hooks/useNodeRenaming';
import { ContextMenuSection, IconSpec, TreeRow } from '../../TreeRow';

const PRIMARY_ICON: IconSpec = { name: 'hash' };

export const PhysicsSlidingInterfaceTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;

  // == Contexts

  // == Hooks
  const renaming = useNodeRenaming(node);
  const { canDelete, deleteSlidingInterfaceNode, postDeleteNodeIds } = useNodeDeletion();

  const deleteRow = useCallback(async () => {
    if (await deleteSlidingInterfaceNode(node.id)) {
      postDeleteNodeIds([node.id]);
    }
  }, [deleteSlidingInterfaceNode, node.id, postDeleteNodeIds]);

  const getExtraContextMenuItems = useCallback(() => {
    const sections: ContextMenuSection[] = [];

    const disabled = !canDelete(node.type, node.id);
    const deleteItem = deleteTreeNodeMenuItem(deleteRow, disabled);
    sections.push({ section: 'crud', menuItems: [deleteItem] });

    return sections;
  }, [canDelete, deleteRow, node.id, node.type]);

  return (
    <TreeRow
      {...props}
      getExtraContextMenuItems={getExtraContextMenuItems}
      primaryIcon={PRIMARY_ICON}
      renaming={renaming}
    />
  );
};
