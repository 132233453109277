// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement } from 'react';

import { getNonSlipWallSurfaces, getWallSurfaces } from '../../../../lib/boundaryConditionUtils';
import { validateGtZeroInt, validateGteOne, validateGteZero } from '../../../../lib/inputValidationUtils';
import {
  BOUNDARY_ID,
  COMMON_START_ICON,
  boundaryHeading,
  conflictingMeshBoundaryLayerSurfaces,
  nullableMeshing,
} from '../../../../lib/mesh';
import { NodeTableType } from '../../../../lib/nodeTableUtil';
import { DEFAULT_BL } from '../../../../lib/paramDefaults/meshingMultiPartState';
import { SelectionAction } from '../../../../lib/selectionUtils';
import { defaultBoundaryLayerParams } from '../../../../lib/simulationUtils';
import { wordsToList } from '../../../../lib/text';
import * as meshgenerationpb from '../../../../proto/meshgeneration/meshgeneration_pb';
import { QuantityType } from '../../../../proto/quantity/quantity_pb';
import { useMeshReadOnly } from '../../../../recoil/useMeshReadOnly';
import { useMeshSurfaces } from '../../../../recoil/useMeshSurfaces';
import { WarningLocation, useMeshValidator } from '../../../../recoil/useMeshValidator';
import useMeshMultiPart, { useSetMeshMultiPart } from '../../../../recoil/useMeshingMultiPart';
import { pushConfirmation, useSetConfirmations } from '../../../../state/internal/dialog/confirmations';
import { ActionButton } from '../../../Button/ActionButton';
import { IconButton } from '../../../Button/IconButton';
import Form from '../../../Form';
import { DataField } from '../../../Form/DataSelect/DataField';
import { NumberField } from '../../../Form/NumberField';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import Divider from '../../../Theme/Divider';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { useSimulationConfig } from '../../../hooks/useSimulationConfig';
import { SectionMessage } from '../../../notification/SectionMessage';
import { ArrowUpRightIcon } from '../../../svg/ArrowUpRightIcon';
import { ResetIcon } from '../../../svg/ResetIcon';
import { TrashIcon } from '../../../svg/TrashIcon';
import NodeTable from '../../NodeTable';
import PropertiesSection from '../../PropertiesSection';
import { CustomCount } from '../shared/CustomCount';

type BoundaryParams = meshgenerationpb.MeshingMultiPart_BoundaryLayerParams;
const SelectionType = meshgenerationpb.MeshingMultiPart_BoundaryLayerParams_SelectionType;

interface SelectionChange {
  selection: string[],
  type: meshgenerationpb.MeshingMultiPart_BoundaryLayerParams_SelectionType,
  overlapIndices: number[],
}

interface MeshBoundaryParamsProps {
  // The index of the boundary layer params this is displaying.
  boundaryIndex: number;
  // If the panel contains inputs rather than constant values.
  isInput: boolean;
  // Set a pending selection that must be confirmed before it is applied.
  setPendingChange?: (selectionChange: SelectionChange) => void;
}

// A panel displaying a single BoundaryParams.
export const MeshBoundaryParams = (props: MeshBoundaryParamsProps) => {
  const { boundaryIndex, isInput } = props;
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { setSelection, setScrollTo, modifySelection, activeNodeTable } = useSelectionContext();
  const setMeshMultiPart = useSetMeshMultiPart(projectId);
  const { simParam } = useSimulationConfig();
  const meshMultiPart = useMeshMultiPart(projectId, workflowId, jobId);
  const boundary = meshMultiPart?.blParams[boundaryIndex];
  const {
    disabledLevel,
    disabledReason,
    warningLocations,
  } = useMeshValidator(projectId, workflowId, jobId, readOnly);
  const meshSurfaces = useMeshSurfaces(projectId);
  const meshReadOnly = useMeshReadOnly(projectId);

  const disabled = readOnly || meshReadOnly;
  const showWarning = isInput && warningLocations.includes(WarningLocation.BL_SIZE);

  if (!boundary) {
    return null;
  }
  if (isInput && !props.setPendingChange) {
    throw new Error('setPendingChange expected for an input.');
  }
  const updateBoundary = (diff: Partial<BoundaryParams>) => {
    const newMeshMultiPart = meshMultiPart.clone();
    const newBoundary = new meshgenerationpb.MeshingMultiPart_BoundaryLayerParams({
      ...boundary,
      ...diff,
    });
    newMeshMultiPart.blParams[boundaryIndex] = newBoundary;
    setMeshMultiPart(newMeshMultiPart);
  };
  const selectionOptions = [
    {
      name: 'All Surfaces',
      value: SelectionType.ALL,
      selected: boundary.selection === SelectionType.ALL,
    },
    {
      name: 'All Non-Slip Wall Surfaces',
      value: SelectionType.WALL_NO_SLIP,
      selected: boundary.selection === SelectionType.WALL_NO_SLIP,
    },
    {
      name: 'Selected Surfaces',
      value: SelectionType.SELECTED,
      selected: boundary.selection === SelectionType.SELECTED,
    },
    {
      name: 'No Surfaces',
      value: SelectionType.NONE,
      selected: boundary.selection === SelectionType.NONE,
    },
  ];

  const isDefault = (boundaryIndex === 0);
  const headerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMeshMultiPart((oldMeshMultiPart: nullableMeshing) => {
      if (!oldMeshMultiPart) {
        return null;
      }
      const newMeshMultiPart = meshMultiPart.clone();
      if (isDefault) {
        // The default has a reset button that resets everything.
        newMeshMultiPart.blParams = [defaultBoundaryLayerParams(simParam)];
      } else {
        // The other sections have a trash button that delete this particular boundary layer.
        newMeshMultiPart.blParams.splice(boundaryIndex, 1);
      }
      return newMeshMultiPart;
    });
  };
  const editClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setSelection([BOUNDARY_ID]);
    setScrollTo({ node: BOUNDARY_ID });
  };
  const headerButton = isInput ? (
    <IconButton
      disabled={disabled}
      onClick={headerClick}>
      {isDefault ? (
        <ResetIcon maxHeight={13} />
      ) : (
        <TrashIcon maxHeight={13} />
      )}
    </IconButton>
  ) : (
    <ActionButton kind="minimal" onClick={editClick} size="small">
      {disabled ? 'View' : 'Edit'}
      <ArrowUpRightIcon maxHeight={9} />
    </ActionButton>
  );

  const changeAppliesTo = (newValue: number) => {
    let newSelection: string[] = [];
    switch (newValue) {
      case SelectionType.ALL:
        newSelection = meshSurfaces;
        break;
      case SelectionType.WALL_NO_SLIP:
        newSelection = getNonSlipWallSurfaces(simParam);
        break;
      case SelectionType.SELECTED:
        newSelection = meshMultiPart!.blParams[0].surfaces;
        break;
      case SelectionType.NONE:
      default:
        break;
    }

    // Find all other meshing boundary layers (by index) whose surfaces include one or more member
    // of `newSelection`
    const overlapIndices = conflictingMeshBoundaryLayerSurfaces(
      boundaryIndex,
      newSelection,
      meshMultiPart.blParams,
    );

    // Require the change to be confirmed if there is some overlap with other volumes.
    if (overlapIndices.length) {
      props.setPendingChange!({ selection: newSelection, type: newValue, overlapIndices });
    } else {
      updateBoundary({ selection: newValue });
      modifySelection({
        action: SelectionAction.OVERWRITE_EXCLUDE,
        modificationIds: newSelection,
        nodeTableOverride: { type: NodeTableType.MESHING_BL, index: 0 },
        updateHighlighting: false,
      });
    }
  };
  const showNodeTable = (
    isInput && (!isDefault || boundary.selection === SelectionType.SELECTED)
  );
  const hasWalls = !!getWallSurfaces(simParam).length;
  const appliesToHelp = hasWalls ?
    '' : 'Setting up Wall Boundary Conditions before Mesh Generation is recommended.';

  return (
    <PropertiesSection>
      <CollapsibleNodePanel
        disabled={activeNodeTable.type === NodeTableType.MESHING_BL}
        headerRight={headerButton}
        heading={boundaryHeading(boundaryIndex)}
        nodeId={`${BOUNDARY_ID}-${boundaryIndex}`}
        panelName="main">
        <Form.LabeledInput label="Number of Layers">
          <NumberField
            asBlock
            disabled={disabled}
            isInput={isInput}
            onCommit={(newValue: number) => {
              updateBoundary({ nLayers: newValue });
            }}
            readOnly={disabled}
            validate={validateGtZeroInt}
            value={boundary.nLayers}
          />
        </Form.LabeledInput>
        <Form.LabeledInput label="Initial Size">
          <NumberField
            asBlock
            disabled={disabled}
            faultType={warningLocations.includes(WarningLocation.BL_SIZE) ?
              'warning' : undefined}
            isInput={isInput}
            onCommit={(newValue: number) => {
              updateBoundary({ initialSize: newValue });
            }}
            quantity={QuantityType.LENGTH}
            readOnly={disabled}
            validate={validateGteZero}
            value={boundary.initialSize}
          />
        </Form.LabeledInput>
        {showWarning && (
          <div style={{ marginTop: '8px' }}>
            <SectionMessage level={disabledLevel}>
              {disabledReason}
            </SectionMessage>
          </div>
        )}
        <Form.LabeledInput label="Growth Rate">
          <NumberField
            asBlock
            disabled={disabled}
            isInput={isInput}
            onCommit={(newValue: number) => {
              updateBoundary({ growthRate: newValue });
            }}
            readOnly={disabled}
            validate={validateGteOne}
            value={boundary.growthRate}
          />
        </Form.LabeledInput>
        {isDefault && (
          <Form.LabeledInput
            faultType={hasWalls ? undefined : 'warning'}
            help={appliesToHelp}
            label="Applies to">
            <DataField
              asBlock
              disabled={disabled}
              isInput={isInput}
              onChange={changeAppliesTo}
              options={selectionOptions}
              size="small"
            />
          </Form.LabeledInput>
        )}
        {showNodeTable && (
          <div style={{ paddingTop: '8px' }}>
            <NodeTable
              editable={!disabled}
              formGroups
              nodeIds={boundary.surfaces}
              tableId={`meshing-bl-${boundaryIndex}`}
              tableIndex={boundaryIndex}
              tableType={NodeTableType.MESHING_BL}
              title="Surfaces"
            />
          </div>
        )}
        {!isInput && <CustomCount count={meshMultiPart.blParams.length - 1} />}
      </CollapsibleNodePanel>
    </PropertiesSection>
  );
};

// A panel for displaying the mesh boundary layer parameters.
export const MeshBoundaryPropPanel = () => {
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const meshMultiPart = useMeshMultiPart(projectId, workflowId, jobId);
  const setMeshMultiPart = useSetMeshMultiPart(projectId);
  const { modifySelection } = useSelectionContext();
  const setConfirmStack = useSetConfirmations();
  const meshReadOnly = useMeshReadOnly(projectId);

  const disabled = readOnly || meshReadOnly;

  if (!meshMultiPart) {
    return null;
  }

  const confirmChange = (pendingChange: SelectionChange) => {
    const headings = pendingChange.overlapIndices.map((idx) => boundaryHeading(idx));
    pushConfirmation(setConfirmStack, {
      onContinue: () => {
        setMeshMultiPart((oldMeshMultiPart) => {
          const newMeshMultiPart = oldMeshMultiPart!.clone();
          newMeshMultiPart.blParams[0].selection = pendingChange.type;
          return newMeshMultiPart;
        });
        modifySelection({
          action: SelectionAction.OVERWRITE_EXCLUDE,
          modificationIds: pendingChange.selection,
          nodeTableOverride: { type: NodeTableType.MESHING_BL, index: 0 },
          updateHighlighting: false,
        });
      },
      subtitle: `This will remove some surfaces from ${wordsToList(headings)}.
        Do you wish to continue?`,
      title: 'Confirm',
    });
  };

  const addCustomMeshSize = () => {
    setMeshMultiPart((oldMeshMultiPart: nullableMeshing) => {
      if (!oldMeshMultiPart) {
        return null;
      }
      const newMeshMultiPart = oldMeshMultiPart.clone();
      newMeshMultiPart.blParams.push(new meshgenerationpb.MeshingMultiPart_BoundaryLayerParams({
        ...DEFAULT_BL,
        selection: SelectionType.SELECTED,
      }));
      return newMeshMultiPart;
    });
  };
  const boundaryList: ReactElement[] = [];
  for (let i = 0; i < meshMultiPart.blParams.length; i += 1) {
    boundaryList.push(
      <MeshBoundaryParams
        boundaryIndex={i}
        isInput
        key={`vol-${i}`}
        setPendingChange={confirmChange}
      />,
    );
    boundaryList.push(<Divider key={`div-${i}`} />);
  }
  return (
    <div>
      {boundaryList}
      <PropertiesSection>
        <ActionButton
          disabled={disabled}
          kind="secondary"
          onClick={addCustomMeshSize}
          size="small"
          startIcon={COMMON_START_ICON}>
          Custom Boundary Layer
        </ActionButton>
      </PropertiesSection>
    </div>
  );
};
