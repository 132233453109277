// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import * as flags from '../../../flags';
import assert from '../../../lib/assert';
import { isFarfield } from '../../../lib/farfieldUtils';
import { NodeType } from '../../../lib/simulationTree/node';
import { useEntityGroupData } from '../../../recoil/entityGroupState';
import { useIsEnabled } from '../../../recoil/useExperimentConfig';
import { useIsGeometryView } from '../../../state/internal/global/currentView';
import Divider from '../../Theme/Divider';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { ModelSelector } from '../../controls/ModelSelector';
import { useSurfaceListBoundaries } from '../../hooks/useSurfaceListBoundaries';
import { LabeledSection } from '../LabeledSection';
import PropertiesSection from '../PropertiesSection';

import { FarFieldPropPanel } from './FarField';
import { GeometryMotion } from './shared/GeometryMotion';
import { ParentSelector } from './shared/ParentSelector';

// A panel displaying information about a group of surfaces.
export const SurfaceGroupPropPanel = () => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected surface group row');

  // == Recoil
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const isGeometryView = useIsGeometryView();
  const geoModEnabled = useIsEnabled(flags.geoModifications);

  const showFarField = (
    isFarfield(node.id) && (!geoModEnabled || isGeometryView)
  );
  const surfaceIds = useMemo(
    (): Set<string> => entityGroupData.leafMap.get(node.id) ?? new Set(),
    [entityGroupData, node.id],
  );

  const bcModels = useSurfaceListBoundaries(surfaceIds);

  return (
    <div>
      <LabeledSection label="Boundary Conditions">
        <ModelSelector
          creators={bcModels.creators}
          disabled={readOnly || !!bcModels.disabledReason}
          models={bcModels.modelData}
          onSelect={(model) => bcModels.attachBoundaryCondition(model.id)}
          onUnselect={() => bcModels.attachBoundaryCondition()}
          selected={bcModels.selected}
          tooltip={bcModels.disabledReason}
        />
      </LabeledSection>
      <Divider />
      <GeometryMotion nodeId={node.id} nodeType={NodeType.SURFACE_GROUP} />
      <Divider />
      <PropertiesSection>
        <ParentSelector node={node} />
      </PropertiesSection>
      {showFarField && <FarFieldPropPanel />}
    </div>
  );
};
