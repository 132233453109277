// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useLayoutEffect, useRef } from 'react';

import useResizeObserver from '../../../lib/useResizeObserver';
import { useSetToolbarGroupsWidths } from '../../../state/internal/component/toolbar';
import { useProjectContext } from '../../context/ProjectContext';

import BoxOrSingleSelectSwitcher from './BoxOrSingleSelectSwitcher';
import SurfaceOrVolumeSelectDropdown from './SurfaceOrVolumeSelectDropdown';

/** A group of DataSelects to show in the Toolbar for setting selection options.  */
const SelectionControlBar = () => {
  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();

  // == Recoil
  const setToolbarGroupsWidths = useSetToolbarGroupsWidths({ projectId, workflowId, jobId });

  // == Data
  const rootRef = useRef<HTMLDivElement>(null);
  const rootWidth = useResizeObserver(rootRef).width;

  useLayoutEffect(() => {
    setToolbarGroupsWidths((old) => ({ ...old, selectionBar: rootWidth }));
  }, [rootWidth, setToolbarGroupsWidths]);

  return (
    <div
      data-locator="selection-control-bar"
      ref={rootRef}
      style={{
        display: 'flex',
        alignItems: 'center',
        flex: '1 1 auto',
      }}>
      <SurfaceOrVolumeSelectDropdown />
      <BoxOrSingleSelectSwitcher />
    </div>
  );
};

export default SelectionControlBar;
