// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

/*
 Displays status of one project.
*/
import React, { Suspense, useEffect } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import ProjectStateSync from '../components/RecoilSync/ProjectState';
import suspenseWidget from '../components/SuspenseWidget';
import Project from '../components/context/ProjectContext';
import { MainPageLayout } from '../components/layout/page/Main';
import { CurrentView } from '../lib/componentTypes/context';
import { isInExploration } from '../lib/navigation';
import useProjectMetadata from '../recoil/useProjectMetadata';
import { useSetCurrentView } from '../state/internal/global/currentView';

import PageBody from './PageBody';

async function invalidOnRunSimulation() {
  throw Error('onRunSimulation: shall not be called');
}

const AnalysisPage = () => {
  const params = useParams();
  const projectId = params.projectId || '';
  const workflowId = params.workflowId || '';
  const jobId = params.jobId || '';
  const location = useLocation();
  const projectMetadata = useProjectMetadata(projectId);
  const setCurrentView = useSetCurrentView();

  const projectName = projectMetadata ?
    projectMetadata.summary!.name :
    projectId;

  useEffect(() => {
    setCurrentView(CurrentView.ANALYSIS);
  }, [setCurrentView]);

  return (
    <MainPageLayout projectId={projectId} title={projectName}>
      <Suspense fallback={suspenseWidget}>
        <ProjectStateSync projectId={projectId}>
          <Project
            projectId={projectId}
            readOnly
            selectedJobId={jobId}
            workflowId={workflowId}>
            <PageBody
              isExploration={isInExploration(location.pathname)}
              onRunSimulation={invalidOnRunSimulation}
            />
          </Project>
        </ProjectStateSync>
      </Suspense>
    </MainPageLayout>
  );
};
export default AnalysisPage;
