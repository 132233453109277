import * as uploadpb from '../../proto/upload/upload_pb';
import { SelectOption } from '../componentTypes/form';

// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
export interface FileType {
  /** The official name of the file format */
  name: string;
  /** The possible file extensions for the file format */
  ext: string[];
}

/** Parasolid file type definition. */
export const PK_FILE_TYPE: FileType = {
  name: 'Parasolid', ext: ['.x_t', '.x_b', '.xmt_txt', '.xmt_bin'],
};

/**
 * All supported CAD files for upload.
 *
 * This list should be kept in sync with the 'CAD Models' on the Learning Luminary docs page
 * and the function FilenameToFileType in  go/core/jobmaster/db/dbtype.go for FileTypeUserGeo and
 * FileTypeUserSurf
 *
 * https://docs.luminarycloud.com/en/articles/9274255-upload-cad
 *
 * All extensions must be lowercase.
 */
export const CAD_FILE_TYPES: FileType[] = [
  { name: 'Rhino 3D', ext: ['.3dm'] },
  { name: 'CATIA V5', ext: ['.catpart'] },
  { name: 'AutoCAD', ext: ['.dwg', '.dxf'] },
  { name: 'IGES/ICAM', ext: ['.iges', '.igs'] },
  { name: 'Inventor', ext: ['.ipt'] },
  { name: 'Light', ext: ['.light'] },
  { name: 'CATIA 4', ext: ['.model'] },
  { name: 'SolidEdge', ext: ['.par', '.psm'] },
  { name: 'Creo Elements/Pro', ext: ['.prt'] },
  { name: 'NX', ext: ['.prt'] },
  { name: 'ACIS / ACIS Assembly', ext: ['.sat', '.sab', '.asat', '.asab'] },
  { name: 'SolidWorks', ext: ['.sldprt'] },
  { name: 'STEP', ext: ['.step', '.stp'] },
  { name: 'Dassault CGM', ext: ['.xcgm'] },
  PK_FILE_TYPE,
];

/** File extensions of supported CAD file types */
export const CAD_FILE_EXTENSIONS = CAD_FILE_TYPES.flatMap((type) => type.ext);

/**
 * All supported mesh files for upload.
 *
 * This list should be kept in sync with the 'Meshes' on the Learning Luminary docs page
 * and the function FilenameToFileType in  go/core/jobmaster/db/dbtype.go for FileTypeUserMesh
 *
 * https://docs.luminarycloud.com/en/articles/9275233-upload-a-mesh
 */
export const MESH_FILE_TYPES: FileType[] = [
  { name: 'ANSYS', ext: ['.msh', '.zip', '.tar', '.tgz', '.gz'] },
  { name: 'OpenFOAM', ext: ['.zip', '.tar', '.tgz', '.gz'] },
  { name: 'CGNS', ext: ['.cgns', '.zip', '.tar', '.tgz', '.gz'] },
];

/** File extensions of supported mesh file types */
export const MESH_FILE_EXTENSIONS = [...MESH_FILE_TYPES.flatMap((type) => type.ext), '.lcmesh'];

/** File types for discrete geometry */
export const DISCRETE_GEOMETRY_FILE_TYPES: FileType[] = [
  { name: 'STL', ext: ['.stl'] },
];

/** File extensions of supported discrete geometry file types */
export const DISCRETE_GEOMETRY_FILE_EXTENSIONS = [
  ...DISCRETE_GEOMETRY_FILE_TYPES.flatMap(
    (type) => type.ext,
  ),
  '.mesh', '.meshb', '.ugrid',
];

/**
 * A list of file extensions with an optional numeric suffix e.g. spaceModulator.prt.4 or
 * deathstar.asm.3.
 */
export const NUMERIC_CAD_FILE_EXTENSIONS = ['.prt', '.asm'];

/** Numeric file extensions e.g. spaceModulator.prt.4 or deathstar.asm.3 */
export const NUMERIC_EXTENSIONS = ['.1', '.2', '.3', '.4', '.5', '.6', '.7', '.8', '.9'];

/**
 * List of file types that directory upload supports
 */
export const DIRECTORY_FILE_TYPES = ['OpenFOAM'];

/** File types for internal staff use only */
export const STAFF_ONLY_FILE_TYPES: FileType[] = [
  { name: 'lcmesh', ext: ['.lcmesh', '.lcmeshbundle'] },
  { name: 'lcsoln', ext: ['.lcsoln', '.lcsolnbundle'] },
  { name: 'MESH', ext: ['.mesh', '.meshb'] },
  { name: 'UGRID', ext: ['.ugrid'] },
];

/**
 * @returns SelectOptions for the given file types where the value is the file extension.
 */
export function fileTypesToSelectOption(
  fileTypes: FileType[],
): SelectOption<FileType>[] {
  return fileTypes.map((fileType) => ({
    value: fileType,
    name: fileType.name,
    description: fileType.ext.join(', '),
  }));
}

export function fileTypeToMeshTypeProto(fileType: FileType) {
  switch (fileType.name) {
    case 'ANSYS':
      return uploadpb.MeshType.ANSYS;
    case 'OpenFOAM':
      return uploadpb.MeshType.OPENFOAM;
    case 'CGNS':
      return uploadpb.MeshType.CGNS;
    default:
      return uploadpb.MeshType.UNSPECIFIED;
  }
}
