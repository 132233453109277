// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode } from 'react';

import cx from 'classnames';

import { colors } from '../../lib/designSystem';
import objectId from '../../lib/objectId';
import { createStyles, makeStyles } from '../Theme';
import Tooltip from '../Tooltip';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      display: 'flex',
      gap: '16px',

      '--active-border-size': '4px',
      '--font-size': '14px',
      '--font-weight': 400,
      '--line-height': '20px',
      '--padding': '14px 8px 10px',

      '&.compact': {
        '--active-border-size': '2px',
        '--font-size': '13px',
        '--font-weight': 600,
        '--line-height': '16px',
        '--padding': '7px 0',
      },
    },
    button: {
      borderBottom: 'var(--active-border-size) solid transparent',
      color: colors.lowEmphasisText,
      fontSize: 'var(--font-size)',
      fontWeight: 'var(--font-weight)',
      lineHeight: 'var(--line-height)',
      padding: 'var(--padding)',
      margin: 0,
      outline: 0,
      border: 0,
      backgroundColor: 'transparent',
      cursor: 'pointer',

      '&.disabled': {
        color: colors.disabledType,
      },

      '&.selected': {
        borderBottomColor: colors.primaryCta,
        color: 'white',
      },
    },
  }),
  { name: 'PaneSwitcher' },
);

interface PaneButtonProps {
  // Whether the button reflects the current mode
  selected: boolean;
  // Button click handler
  onClick: () => void;
  // The text of the button.
  text: ReactNode;
  // The title that appears in the tooltip.
  title?: string;
  // Disables the button.
  disabled?: boolean;
  // Uses a smaller font size and padding
  compact?: boolean;
}

// Draw a single button for switching to a particular mode.
const PaneButton = (props: PaneButtonProps) => {
  const { disabled, selected, compact, onClick, text, title = '' } = props;
  const classes = useStyles();

  return (
    <div>
      <Tooltip title={disabled ? '' : title}>
        <span>
          <button
            className={cx(classes.button, { disabled, selected, compact })}
            data-pane-button={text}
            disabled={disabled}
            onClick={onClick}
            title={title}
            type="button">
            {text}
          </button>
        </span>
      </Tooltip>
    </div>
  );
};

interface PaneSwitcherProps {
  buttons: PaneButtonProps[];
  compact?: boolean;
}

// Draw several buttons for switching between modes in the right pane.
const PaneSwitcher = (props: PaneSwitcherProps) => {
  const { buttons, compact } = props;

  const classes = useStyles();

  return (
    <div className={cx(classes.root, { compact })}>
      {buttons.map((buttonProps) => (
        <PaneButton {...buttonProps} key={objectId(buttonProps)} />
      ))}
    </div>
  );
};

export default PaneSwitcher;
