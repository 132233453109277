// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import assert from '../../../lib/assert';
import Divider from '../../Theme/Divider';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { ModelSelector } from '../../controls/ModelSelector';
import { useSurfaceListBoundaries } from '../../hooks/useSurfaceListBoundaries';
import { LabeledSection } from '../LabeledSection';
import PropertiesSection from '../PropertiesSection';

import { GeometryMotion, GeometryNodeType } from './shared/GeometryMotion';
import { ParentSelector } from './shared/ParentSelector';

// A panel displaying information about a group of surfaces.
export const SurfacePropPanel = () => {
  // == Contexts
  const { readOnly } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected surface row');

  const bcModels = useSurfaceListBoundaries(new Set([node.id]));

  return (
    <div>
      <LabeledSection label="Boundary Condition">
        <ModelSelector
          creators={bcModels.creators}
          disabled={readOnly || !!bcModels.disabledReason}
          models={bcModels.modelData}
          onSelect={(model) => bcModels.attachBoundaryCondition(model.id)}
          onUnselect={() => bcModels.attachBoundaryCondition()}
          selected={bcModels.selected}
          tooltip={bcModels.disabledReason}
        />
      </LabeledSection>
      <Divider />
      <GeometryMotion
        nodeId={node.id}
        nodeType={node.type as GeometryNodeType}
      />
      <Divider />
      <PropertiesSection>
        <ParentSelector node={node} />
      </PropertiesSection>
    </div>
  );
};
