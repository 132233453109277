// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import cx from 'classnames';
import { Virtuoso as List } from 'react-virtuoso';

import { colors } from '../../lib/designSystem';
import { isUnmodifiedEscapeKey } from '../../lib/event';
import { clamp } from '../../lib/number';
import { MODIFICATIONS_TREE_DATA_LOCATOR, NodeType, SimulationTreeNode } from '../../lib/simulationTree/node';
import { VIEWER_PADDING } from '../../lib/visUtils';
import { usePanel } from '../../recoil/expandedPanels';
import { useGeometryBusyState, useGeometryServerStatus, useIsGeoServerCreatingFeature } from '../../recoil/geometry/geometryServerStatus';
import { useGeometryState } from '../../recoil/geometry/geometryState';
import { useLoadToSetup } from '../../recoil/geometry/useLoadToSetup';
import { useSelectedGeometry } from '../../recoil/selectedGeometry';
import { pushConfirmation, useSetConfirmations } from '../../state/internal/dialog/confirmations';
import { useSimulationTree } from '../../state/internal/tree/simulation';
import { useVisHeightValue } from '../../state/internal/vis/visHeight';
import { ActionButton } from '../Button/ActionButton';
import { SvgIcon } from '../Icon/SvgIcon';
import { CollapsiblePanel } from '../Panel/CollapsiblePanel';
import { ROW_OUTER_HEIGHT } from '../Theme/commonStyles';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { useIsSetupGeometryUpToDate } from '../hooks/useInteractiveGeometry';
import { useTree } from '../hooks/useTree';
import { DiskInfoIcon } from '../svg/DiskInfoIcon';
import { SearchIcon } from '../svg/SearchIcon';
import { SimulationRowContainer } from '../treePanel/SimulationRowContainer';
import { useArrowKeyNav } from '../treePanel/useArrowKeyNav';
import { LoadingEllipsis } from '../visual/LoadingEllipsis';

import { MIN_HEIGHT, TREE_VERTICAL_PADDING, useTreePanelStyles } from './treePanelShared';

const warningText = (
  <div>
    If you continue with this action, your currently loaded geometry and its meshes, settings,
    and results will be overwritten.
    <p />
    If you’d like to use your current simulation settings with a new geometry, create a new project
    and use the Library to share settings.
    <p />
    Do you still want to proceed?
  </div>
);
/**
 * The ModificationTreePanel component is a card window that appears in the 3D viewer and displays
 * the Modification tree when on the Geometry page.
 * @returns CollapsiblePanel with the Geometry tree
 */
export const ModificationTreePanel = () => {
  // == Context
  const { projectId, workflowId, jobId, geometryId, readOnly } = useProjectContext();
  const { selectedNodeIds, isTreeModal } = useSelectionContext();

  // == Hooks
  const classes = useTreePanelStyles();
  const loadToSetup = useLoadToSetup();

  // == Recoil
  const visHeight = useVisHeightValue();
  const simTree = useSimulationTree(projectId, workflowId, jobId);
  const [expanded, setExpanded] = usePanel({
    nodeId: `modification-tree-${projectId}`,
    panelName: 'modification-tree',
    defaultExpanded: true,
  });
  const [geoServerStatus] = useGeometryServerStatus(geometryId);
  const [geoServerBusyState] = useGeometryBusyState(geometryId);
  const isGeoServerCreatingFeature = useIsGeoServerCreatingFeature(geometryId);
  const geoState = useGeometryState(projectId, geometryId);
  const setConfirmStack = useSetConfirmations();
  const [selectedGeometry] = useSelectedGeometry(projectId);

  // == Data
  const [listContainerHeight, setListContainerHeight] = useState(MIN_HEIGHT);
  const [filter, setFilter] = useState<null | string>(null);
  const filterActive = filter !== null;
  const filterFilled = filterActive && filter !== '';
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  // This may break eventually (simTree.children[0]), but for now there is an extra parent node that
  // wraps modifications and history.
  const {
    listRef,
    rowProps,
    maybeUpdateRowsOpened,
  } = useTree(simTree.children[0], filterFilled);
  const isInitialLoadingState = geoServerStatus === 'busy' && !geoState;

  const isUpToDate = useIsSetupGeometryUpToDate();

  const { disabled, disabledReason } = useMemo(() => {
    if (geoServerStatus === 'disconnected') {
      return { disabled: true, disabledReason: 'Geometry server is not connected.' };
    }
    if (geoServerStatus === 'busy') {
      return { disabled: true, disabledReason: 'Geometry server is busy.' };
    }
    if (isUpToDate) {
      return { disabled: true, disabledReason: 'Current geometry is already loaded.' };
    }
    if (geoState?.metadata.zone.length === 0) {
      return { disabled: true, disabledReason: 'No geometry detected.' };
    }
    if (geoState?.featureIssuesServer?.length) {
      return {
        disabled: true,
        disabledReason: 'Modifications have errors. Fix or remove failing modifications.',
      };
    }
    return { disabled: false, disabledReason: undefined };
  }, [
    isUpToDate,
    geoServerStatus,
    geoState?.metadata.zone.length,
    geoState?.featureIssuesServer?.length,
  ]);

  const pendingRowProps = useMemo(() => {
    // If the geo server is creating a feature, get its ID and append 'Pending' to the name.
    if (geoServerStatus === 'busy' && isGeoServerCreatingFeature) {
      const featureId = geoServerBusyState?.BusyStateType.value?.featureId;
      if (!featureId) {
        return rowProps;
      }

      // Geometry uploads have multiple steps (messages), and pending will be appended multiple
      // times if we don't check for it.
      const pendingRegex = /^Pending .*\.\.\.$/;
      return rowProps.map((val, id) => {
        if (val.node.id === featureId && !pendingRegex.test(val.node.name)) {
          val.node.name = `Pending ${val.node.name} ...`;
        }
        return val;
      });
    }
    return rowProps;
  }, [rowProps, geoServerStatus, geoServerBusyState, isGeoServerCreatingFeature]);

  // If the filter is non-empty, we'll keep only the nodes which name matches the filter and the
  // nodes that contains a children with a name that matches it (even if the parent is collapsed).
  const filteredRowProps = useMemo(() => {
    if (filter === null || filter === '') {
      return pendingRowProps;
    }
    const filterString = filter.toLowerCase();

    const filterItem = (node: SimulationTreeNode): boolean => {
      // We don't need the sub containers
      if (node.type === NodeType.ROOT_GEOMETRY) {
        return false;
      }
      if (node.name.toLowerCase().includes(filterString)) {
        return true;
      }
      return false;
    };

    return pendingRowProps
      // Do the actual filter per name
      .filter((row) => filterItem(row.node));
  }, [pendingRowProps, filter]);

  const handleLoadToSetup = async () => {
    if (selectedGeometry?.geometryId) {
      // If there is selectedGeometry, user has already loaded to setup and this will overwrite the
      // setup geometry and parameters.
      pushConfirmation(setConfirmStack, {
        onContinue: loadToSetup,
        symbol: (
          <div style={{ padding: '2px' }}>
            <DiskInfoIcon color={colors.purple800} maxHeight={16} maxWidth={16} />
          </div>
        ),
        title: 'Loading to Setup',
        children: warningText,
      });
    } else {
      // If there is no selected geometry, we have not attempted load to setup yet.
      await loadToSetup();
    }
  };

  const handleKeyPress = useCallback((event) => {
    if (filterActive && isUnmodifiedEscapeKey(event)) {
      setFilter(null);
    }
  }, [filterActive]);

  // We are using the regular addEventListener because useEventListener doesn't work properly here
  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  // Listen to arrow keys for navigating in the geometry tree with keyboard shortcuts
  useArrowKeyNav(simTree, filteredRowProps, listRef);

  const renderRow = useCallback((index, row) => (
    <SimulationRowContainer {...row} disableToggle={filterFilled} />
  ), [filterFilled]);

  useEffect(() => {
    maybeUpdateRowsOpened(simTree, selectedNodeIds);
  }, [selectedNodeIds, maybeUpdateRowsOpened, simTree]);

  // Make sure the List's parent container has some reasonable height depending on the content
  useLayoutEffect(() => {
    if (!visHeight) {
      return;
    }

    // Calculate the available height
    let maxHeight = (
      visHeight - (
        // Remove the paddings around the edges of the 3D viewer
        2 * VIEWER_PADDING
      ) - (
        // Remove the collapsible header for the Geometry panel + internal padding around the list
        36 + 2 * TREE_VERTICAL_PADDING
      ) - (
        // Account some space for the 3D axis in the bottom left
        150
      )
    );

    // We should put a hardcap of 70% from the 3D viewer's height
    maxHeight = Math.min(visHeight * 0.7, maxHeight);

    // Set the height depending on the amount of rows, but no more than the calculated limit
    setListContainerHeight(
      clamp(filteredRowProps.length * ROW_OUTER_HEIGHT, [MIN_HEIGHT, maxHeight]),
    );
  }, [filteredRowProps, visHeight, listContainerHeight]);

  if (!simTree) {
    return (
      <></>
    );
  }

  // Render
  return (
    <div
      className={cx(classes.root, { inSelectionMode: isTreeModal })}
      data-locator="modificationPanel">
      <CollapsiblePanel
        collapsed={!expanded}
        disabled={filterActive}
        expandWhenDisabled
        heading={(
          <div className={classes.heading}>
            <button
              className={classes.searchButton}
              onClick={(event) => {
                if (filterActive) {
                  setFilter(null);
                } else {
                  setFilter('');
                  requestAnimationFrame(() => {
                    searchInputRef.current?.focus();
                  });
                }
                // Clicking the icon should not trigger the parent CollapsiblePanel
                event.stopPropagation();
              }}
              type="button">
              <SearchIcon maxWidth={12} />
            </button>
            {filterActive ? (
              <input
                className={classes.searchInput}
                onChange={(event) => setFilter(event.target.value)}
                // Clicking over the input should not trigger the parent CollapsiblePanel
                onClick={(event) => event.stopPropagation()}
                placeholder="Find..."
                ref={searchInputRef}
                type="text"
                value={filter}
              />
            ) : 'Modification List'}
          </div>
        )}
        onToggle={() => setExpanded(!expanded)}
        primaryHeading>
        <div className={classes.content}>
          <div className={classes.loadButtonContainer}>
            {!readOnly && (
              <ActionButton
                asBlock
                disabled={disabled}
                onClick={handleLoadToSetup}
                size="small"
                title={disabledReason}>
                Load to Setup
              </ActionButton>
            )}
          </div>
          {!!rowProps.length && !filteredRowProps.length && (
            <div className={classes.noResults}>No Modifications</div>
          )}
          {!!filteredRowProps.length && (
            <div
              className={classes.list}
              data-locator={MODIFICATIONS_TREE_DATA_LOCATOR}
              style={{ height: listContainerHeight }}>
              <List
                data={filteredRowProps}
                defaultItemHeight={ROW_OUTER_HEIGHT} // not necessary, but helps performance
                itemContent={renderRow}
                ref={listRef}
              />
            </div>
          )}
          {isInitialLoadingState && (
            <div className={classes.pendingRow}>
              <SvgIcon maxHeight={12} maxWidth={12} name="diskArrowUp" />
              <div>Connecting to the server<LoadingEllipsis /></div>
            </div>
          )}
        </div>
      </CollapsiblePanel>
    </div>
  );
};
