// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { matchPath } from 'react-router-dom';

import { WorkflowMetadata } from '../proto/frontend/frontend_pb';
import { JobType } from '../proto/notification/notification_pb';
import * as projectstatepb from '../proto/projectstate/projectstate_pb';

import assert from './assert';
import { JobNameMap } from './jobNameMap';
import { geometryLink, jobLink, resultsLink, routes, workflowLink } from './navigation';

// This file is for maintaining the list of tabs. These include temporary tabs
// that can be opened and closed and permanent tabs that are derived from the
// list of workflows.

// The state of the tabs in the tab panel.
export interface TabsState {
  projectId: string;
  closableTabs: projectstatepb.TabInfo[];
  tabs: projectstatepb.TabInfo[];
}

export function newTabsState(
  projectId: string,
  workflows: WorkflowMetadata[],
  geoModEnabled: boolean,
  meshUrl: string,
  closableTabs: projectstatepb.TabInfo[],
  selectedGeometry: projectstatepb.SelectedGeometry,
): TabsState {
  const permanentTabs = [];
  if (geoModEnabled) {
    permanentTabs.push(new projectstatepb.TabInfo({
      text: 'Geometry',
      link: geometryLink(projectId),
      closable: false,
    }));
  }

  if (meshUrl || workflows.length > 0 || selectedGeometry.geometryId) {
    permanentTabs.push(
      new projectstatepb.TabInfo({
        text: 'Setup',
        link: `/project/${projectId}`,
        closable: false,
      }),
    );
  }
  if (workflows.length > 0) {
    permanentTabs.push(new projectstatepb.TabInfo({
      text: 'Results',
      link: resultsLink(projectId),
      closable: false,
    }));
  }
  return {
    projectId,
    closableTabs,
    tabs: permanentTabs.concat(closableTabs),
  };
}

export function addClosableTab(
  text: string,
  link: string,
  tabsState: TabsState,
): TabsState {
  // Do not add the tab if it already exists.
  if (tabsState.closableTabs.some((tab) => link === tab.link)) {
    return tabsState;
  }
  const tab = new projectstatepb.TabInfo({
    text,
    link,
    closable: true,
  });
  const { projectId } = tabsState;
  const closableTabs = tabsState.closableTabs.slice();
  const tabs = tabsState.tabs.slice();
  closableTabs.push(tab);
  tabs.push(tab);
  return { projectId, closableTabs, tabs };
}

// Remove a closable tab.
export function removeTab(
  subtraction: projectstatepb.TabInfo,
  tabsState: TabsState,
): TabsState {
  const { projectId } = tabsState;
  const closableTabs = tabsState.closableTabs.filter((tab) => tab !== subtraction);
  const tabs = tabsState.tabs.filter((tab) => tab !== subtraction);
  return {
    projectId,
    closableTabs,
    tabs,
  };
}

// Returns the tab link path associated with a given JobType, projectId, workflowId and, if the job
// type is JobType.EXPLORATION_JOB, the jobId. In the latter case, jobId cannot be undefined.
export function tabLink(
  type: JobType,
  projectId: string,
  workflowId: string,
  jobId: string | undefined,
): string {
  switch (type) {
    case JobType.SIMULATION_JOB:
      return workflowLink(projectId, workflowId, false);
    case JobType.SENSITIVITY_ANALYSIS:
    case JobType.SENSITIVITY_JOB:
      return workflowLink(projectId, workflowId, true);
    case JobType.EXPLORATION_JOB:
      assert(jobId !== undefined, 'Cannot construct tab link without a job ID');
      return jobLink(projectId, workflowId, jobId);
    default:
      throw Error('Open should be disabled for explorations.');
  }
}

// Replace the text of tab. The tab is identified via its link path. Returns a new TabsState.
export function replaceTabText(
  link: string,
  newText: string,
  tabsState: TabsState,
): TabsState {
  const { projectId, closableTabs, tabs } = tabsState;
  const rename = (tab: projectstatepb.TabInfo) => {
    const newTab = tab.clone();
    if (newTab.link === link) {
      newTab.text = newText;
    }
    return newTab;
  };

  return {
    projectId,
    closableTabs: closableTabs.map(rename),
    tabs: tabs.map(rename),
  };
}

// Update the given tab to use the names in the given JobNameMap.
// If the tab is not for a simulation/workflow or the JobNameMap doesn't contain a name for the
// simulation/workflow, an identical TabInfo is returned.
export function renameTabWithJobName(
  tab: projectstatepb.TabInfo,
  jobNameMap: JobNameMap,
): projectstatepb.TabInfo {
  const newTab = tab.clone();
  const tabPath = newTab.link;
  let newName: string | undefined;

  const jobPathMatch = matchPath(routes.explorationJob, tabPath);
  const explorationPathMatch = matchPath(routes.exploration, tabPath);
  const simulationPathMatch = matchPath(routes.simulation, tabPath);
  if (jobPathMatch) {
    const { params } = jobPathMatch;
    newName = jobNameMap.get({
      workflowId: params.workflowId!,
      jobId: params.jobId,
    });
  } else if (explorationPathMatch) {
    const { params } = explorationPathMatch;
    newName = jobNameMap.get({
      workflowId: params.workflowId!,
    });
  } else if (simulationPathMatch) {
    const { params } = simulationPathMatch;
    newName = jobNameMap.get({
      workflowId: params.workflowId!,
    });
  } else {
    // If not a simulation or exploration tab, do nothing and return.
    return newTab;
  }

  newTab.text = newName || ''; // If there is no name, display nothing for now.
  return newTab;
}

// Find the text of a tab with the given link.
export function getTabText(
  link: string,
  tabState: TabsState,
): string | null {
  const foundTab = tabState.tabs.find((tab) => (tab.link === link));
  return foundTab?.text ?? null;
}
