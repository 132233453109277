// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useEffect, useState } from 'react';

import AuthForm from '../../components/Form/AuthForm';
import { AuthPageLayout, AuthPageState } from '../../components/layout/page/AuthPageLayout';
import { TEMP_STORAGE_ACC_INVITER, handleAuthenticatedV2, webAuth } from '../../lib/AuthV2';
import { isItarEnv } from '../../lib/RuntimeParams';
import { useHandleMfaRequired } from '../../lib/auth/useHandleMfaRequired';
import { locationOriginRoute, routes } from '../../lib/navigation';
import { Logger } from '../../lib/observability/logs';
import * as rpc from '../../lib/rpc';
import { isStorybookEnv } from '../../lib/testing/utils';
import { isUnixTimestampOlderThan48Hours } from '../../lib/usermanagementUtils';
import * as frontendpb from '../../proto/frontend/frontend_pb';

import { NewUserTos } from './AcceptTermsGoogleActivation';
import InvalidActivateLinkPageBody from './InvalidActivateLink';

const logger = new Logger('activateaccount');

interface ActivateAccountPageProps {
  // Optional initial page state. This should be used ONLY for the storybook
  initialPageState?: AuthPageState;
}

const ActivateAccountPage = (props: ActivateAccountPageProps) => {
  // Props
  const { initialPageState = AuthPageState.INITIALIZING } = props;

  // Hooks
  const handleMfaRequired = useHandleMfaRequired();

  // State
  const [pageState, setPageState] = useState(initialPageState);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  // Other state
  const ticketId = new URLSearchParams(window.location.search).get('ticketid') || '';
  const accName = decodeURIComponent(new URLSearchParams(window.location.search).get('acc') || '');
  const email = decodeURIComponent(new URLSearchParams(window.location.search).get('email') || '');

  // Effects
  useEffect(() => {
    if (isStorybookEnv()) {
      return;
    } if (props.initialPageState !== undefined) {
      throw Error('Do not use the initialPageState outside of storybook env.');
    }

    const timestamp = new URLSearchParams(window.location.search).get('t') || '';
    if (!accName || !email || isUnixTimestampOlderThan48Hours(Number(timestamp))) {
      logger.error(`Invalid link for activate account: ${window.location.search}`);
      setPageState(AuthPageState.INVALID_LINK);
      return;
    }

    // Save the account name that sent the invite so we can later show it in the AcceptTerms page
    // if the user is authenticating with Google.
    localStorage.setItem(TEMP_STORAGE_ACC_INVITER, accName);

    const req = new frontendpb.UseTicketRequest({ ticketId });
    logger.info('sending UseTicket');
    rpc.callRetry('UseTicket', rpc.client.useTicket, req).then(() => {
      logger.info('UseTicket success');
      setPageState(AuthPageState.FORM);
    }).catch((err) => {
      logger.error(`UseTicket: ${JSON.stringify(err)}`);
      setPageState(AuthPageState.INVALID_LINK);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handlers
  const handleSubmit = async (data: Record<string, string>) => {
    if (data.password.trim() === '') {
      setError('Password cannot be empty');
      return;
    }
    setError('');
    setSubmitting(true);
    try {
      logger.info('sending SetPassword');
      const passReq = new frontendpb.SetPasswordRequest({ ticketId, password: data.password });
      await rpc.callRetry('SetPassword', rpc.client.setPassword, passReq);

      logger.info('sending AcceptTermsV2');
      const termsReq = new frontendpb.AcceptTermsV2Request({ email, ticketId });
      await rpc.callRetry('AcceptTermsV2', rpc.client.acceptTermsV2, termsReq);

      logger.info('logging in via auth0');
      webAuth.client.login({
        username: data.email,
        password: data.password,
        realm: 'Username-Password-Authentication',
        scope: 'openid profile email address phone offline_access',
      }, async (err, jwt) => {
        if (err) {
          if (err?.code === 'mfa_required') {
            logger.info('mfa required');
            await handleMfaRequired(err);
          } else {
            logger.error(`Activate/login for ${email} error: `, err);
            setError(err?.description || err?.code || err?.name || 'An error occurred');
            setSubmitting(false);
          }
        } else {
          logger.info('running handleAuthenticatedV2');
          handleAuthenticatedV2(jwt.idToken).then(() => {
            logger.info('handleAuthenticatedV2 success');
            // Do nothing. The auth subscribers will update the auth state and the hooks listening
            // to the state will do the navigation. Do not set submitting to true because there's a
            // delay before the redirect kicks in and it's best to show the loading in the meantime
          }).catch((authError) => {
            logger.error('handle handleAuthenticatedV2 error: ', err);
            setError(authError.message || 'Authentication failed');
          }).finally(() => {
            setSubmitting(false);
          });
        }
      });
    } catch (err) {
      logger.error(`Activating account failed: ${JSON.stringify(err)}`);
      setSubmitting(false);
      setError(err.message || 'Authentication failed');
    }
  };

  if (pageState === AuthPageState.INITIALIZING) {
    return <AuthPageLayout loading rightSection={false} />;
  }

  if (pageState === AuthPageState.INVALID_LINK) {
    return <InvalidActivateLinkPageBody />;
  }

  if (pageState === AuthPageState.DONE) {
    return (
      <AuthPageLayout
        backToLogin
        subtitle="Account activated sucessfully.
          Please go to the login page if you are not logged in automatically"
        title="Account activated"
      />
    );
  }

  return (
    <AuthPageLayout
      alternativeOption={{
        label: 'Continue with Google',
        isGoogle: true,
        onClick: () => {
          webAuth.authorize({
            connection: 'google-oauth2',
            responseType: 'code',
            state: '',
            connection_scope: 'email',
            scope: 'openid profile email enroll read:authenticators offline_access',
            approvalPrompt: 'force',
            redirectUri: locationOriginRoute(routes.googleAuthCallback),
          });
        },
      }}
      subtitle={
        <><b>{accName}</b> has invited you to join Luminary Cloud.</>
      }
      title={`Activate your ${isItarEnv ? 'ITAR' : ''} account`}>
      <AuthForm
        fields={[
          {
            asBlock: true,
            autofocus: true,
            label: 'Email',
            disabled: true,
            disabledReason: 'You can\'t change email',
            name: 'email',
            required: true,
            value: email,
          },
          {
            asBlock: true,
            label: 'Password',
            disabled: submitting,
            name: 'password',
            placeholder: 'Enter your password',
            required: true,
            strength: true,
            type: 'password',
          },
          {
            label: <NewUserTos />,
            disabled: submitting,
            name: 'consent',
            required: true,
            type: 'checkbox',
          },
        ]}
        globalError={error}
        onSubmit={handleSubmit}
        submit={{
          disabled: submitting,
          showSpinner: submitting,
          label: 'Activate Now',
        }}
      />
    </AuthPageLayout>
  );
};

export default ActivateAccountPage;
