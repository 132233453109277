// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { Empty } from '@bufbuild/protobuf';

import * as frontendpb from '../proto/frontend/frontend_pb';
import useProjectMetadata from '../recoil/useProjectMetadata';
import { useSetTabState } from '../recoil/useTabsState';
import { useWorkflowMap } from '../recoil/workflowState';

import { TabsState, removeTab, tabLink } from './TabManager';
import { getJobType } from './jobNameMap';
import * as rpc from './rpc';

/**
 * Return a function that deletes a workflow with ID `workflowId` from the project with
 * ID `projectId` using the "DeleteWorkFlow" RPC.
 *
 * As a side effect, the returned function will also remove the workflow tab
 * if the workflow is currently open.
 *
 * @param projectId the ID of the project to delete from
 * @returns a function which deletes the workflow with `workflowId` from the project
 */
export function useDeleteWorkflow(projectId: string): (workflowId: string) => Promise<Empty> {
  const setTabsState = useSetTabState(projectId);
  const projectMetadata = useProjectMetadata(projectId);
  const workflowList = projectMetadata?.workflow;
  const allWorkflowIds = workflowList?.map((workflow) => workflow.workflowId) ?? [];
  const workflowMap = useWorkflowMap(projectId, allWorkflowIds);

  // array of workflows we want to delete
  return (workflowId: string) => new Promise((resolve, reject) => {
    const req = new frontendpb.DeleteWorkflowRequest({ projectId, workflowId });
    rpc.callRetry('DeleteWorkflow', rpc.client.deleteWorkflow, req).then(
      (value) => {
        // when a workflow is deleted, we must remove its tab if it's open
        setTabsState((oldTabsState) => {
          let newTabsState: TabsState = { ...oldTabsState };
          const workflowReply = workflowMap[workflowId];
          const jobMap = workflowReply?.job;
          if (!workflowReply || !jobMap) {
            return oldTabsState;
          }
          const tabInfo = oldTabsState.closableTabs;
          const jobLinks = Object.entries(jobMap).map(([jobId, _]) => (
            tabLink(getJobType(workflowReply), projectId, workflowId, jobId)));
          // Find all open tabs we have to close because of the deleted workflow
          tabInfo.forEach((tab) => {
            if (jobLinks.includes(tab.link)) {
              newTabsState = removeTab(tab, newTabsState);
            }
          });
          return newTabsState;
        });
        resolve(value);
      },
    ).catch((error) => {
      reject(error);
    });
  });
}
