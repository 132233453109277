// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import * as basepb from '../proto/base/base_pb';
import * as quantitypb from '../proto/quantity/quantity_pb';
import * as ParaviewRpc from '../pvproto/ParaviewRpc';

// Get scalar value from a basepb.AdFloatType
export function getAdValue(adFloat: basepb.AdFloatType | undefined): number {
  switch (adFloat?.adTypes.case) {
    case 'firstOrder':
      return adFloat.adTypes.value.value;
    case 'secondOrder':
      return adFloat.adTypes.value.value!.value;
    case 'value':
      return adFloat.adTypes.value;
    default: // cases exhausted, no default
  }
  return 0;
}

// Return a new basepb.AdFloatType object initialized with a value
export function newAdFloat(value: number): basepb.AdFloatType {
  return new basepb.AdFloatType({ adTypes: { case: 'value', value } });
}

/**
 * Create a new AdVector3 message with scalar (not first or second order) values for x, y, and z
 * @param x
 * @param y
 * @param z
 * @returns
 */
export function newScalarAdVector(x = 0, y = 0, z = 0): basepb.AdVector3 {
  return new basepb.AdVector3({ x: newAdFloat(x), y: newAdFloat(y), z: newAdFloat(z) });
}

// Convert a basepb.AdVector3 type to basepb.Vector3
export function adVec3ToVec3(adVec3: basepb.AdVector3): basepb.Vector3 {
  return new basepb.Vector3({
    x: getAdValue(adVec3.x),
    y: getAdValue(adVec3.y),
    z: getAdValue(adVec3.z),
  });
}

// Convert a basepb.Vector3 type to basepb.AdVector3
export function vec3ToAdVec3(vec3: basepb.Vector3): basepb.AdVector3 {
  const { x, y, z } = vec3;
  return newScalarAdVector(x, y, z);
}

// Set a quantity on a basepb.AdVector3
export function setAdVec3Quantity(
  adVec3: basepb.AdVector3,
  quantity: quantitypb.QuantityType,
): void {
  if (adVec3.x) {
    adVec3.x.quantity = { case: 'quantityType', value: quantity };
  }
  if (adVec3.y) {
    adVec3.y.quantity = { case: 'quantityType', value: quantity };
  }
  if (adVec3.z) {
    adVec3.z.quantity = { case: 'quantityType', value: quantity };
  }
}

// Check if the vector has zero magnitude
export function equalsZero(vec: basepb.AdVector3): boolean {
  return !vec || (
    getAdValue(vec.x) === 0 &&
    getAdValue(vec.y) === 0 &&
    getAdValue(vec.z) === 0);
}

/**
 * @param a paraview Vector3
 * @returns an AdVector3 with the same values.
 */
export function pvToAdVec3(vec: ParaviewRpc.Vector3): basepb.AdVector3 {
  const { x, y, z } = vec;
  return newScalarAdVector(x, y, z);
}

/**
 * @param vec AdVector3
 * @returns a paraview Vector3 with the same values.
 */
export function adVec3ToPv(vec: basepb.AdVector3): ParaviewRpc.Vector3 {
  return {
    x: getAdValue(vec.x),
    y: getAdValue(vec.y),
    z: getAdValue(vec.z),
  };
}
