// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import React from 'react';

import { lcvHandler } from '../../lib/lcvis/handler/LcvHandler';
import { LcVisButtonType } from '../../lib/lcvis/types';
import { EditSource } from '../../lib/visUtils';
import * as ParaviewRpc from '../../pvproto/ParaviewRpc';
import { useLcvisCancelEdit } from '../../recoil/lcvis/hooks/useLcvisCancelEdit';
import { useSetLcvisMeasureState } from '../../recoil/lcvis/lcvisMeasureState';
import { useSetLcvisProbeState } from '../../recoil/lcvis/lcvisProbeState';
import { useRemoveOverlays } from '../../recoil/lcvis/useRemoveOverlays';
import { useEditState } from '../../recoil/paraviewState';
import { useSetPropertiesPanelVisible } from '../../recoil/propertiesPanel';
import useProjectMetadata from '../../recoil/useProjectMetadata';
import { useCurrentTab } from '../../recoil/useTabsState';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { newClipSliceParam } from '../treePanel/propPanel/filter/ClipSlice';

import { useVisFilterParentNode } from './useVisFilterParentNode';

type FilterType = ParaviewRpc.TreeNodeType.CLIP | ParaviewRpc.TreeNodeType.SLICE;

export const useHandleLcVisFilterClick = () => {
  // Context
  const { projectId, workflowId, jobId } = useProjectContext();
  const { setSelection } = useSelectionContext();

  // Hooks
  const currentTab = useCurrentTab(projectId);
  const projectMetadata = useProjectMetadata(projectId);
  const removeLcvisOverlays = useRemoveOverlays({ projectId, workflowId, jobId });
  const setLcvisProbe = useSetLcvisProbeState();
  const setLcvisMeasure = useSetLcvisMeasureState();
  const [editState, setEditState] = useEditState();
  const setPropertiesPanelVisible = useSetPropertiesPanelVisible();

  // Other props
  const projectName = projectMetadata?.summary?.name ?? '';
  const defaultDisplayProps: ParaviewRpc.DisplayProps = {
    reprType: 'Surface',
    displayVariable: null,
  };
  const parentNode = useVisFilterParentNode();
  const lcvisCancelEdit = useLcvisCancelEdit();

  // Called when add clip or slice is selected.
  const addClipSlice = (type: FilterType) => {
    if (editState?.param.typ === type) {
      lcvisCancelEdit();
      return;
    }
    let visibleBounds: ParaviewRpc.Bounds = [-1, 1, -1, 1, -1, 1];
    const bounds = lcvHandler.display?.getCurrentDatasetBounds();
    if (bounds) {
      visibleBounds = bounds as ParaviewRpc.Bounds;
    }
    // Create a new clip or slice node.
    // Select the new node and enable editing on it.
    setSelection([parentNode!.id]);
    setEditState({
      newNode: true,
      nodeId: parentNode!.id,
      param: newClipSliceParam(visibleBounds, type),
      displayProps: defaultDisplayProps,
      editSource: EditSource.FORM,
    });
    setPropertiesPanelVisible(true);
  };

  return async (event: React.MouseEvent | React.KeyboardEvent, buttonType: LcVisButtonType) => {
    switch (buttonType) {
      case 'clip':
        await removeLcvisOverlays();
        addClipSlice(ParaviewRpc.TreeNodeType.CLIP);
        break;
      case 'slice':
        await removeLcvisOverlays();
        addClipSlice(ParaviewRpc.TreeNodeType.SLICE);
        break;
      case 'probe':
        await removeLcvisOverlays({ excludeType: 'probe' });
        setLcvisProbe((old) => ({ ...old, active: !old.active, probedId: '' }));
        break;
      case 'measure':
        await removeLcvisOverlays({ excludeType: 'measure' });
        setLcvisMeasure((old) => ({ ...old, active: !old.active, pointToUdpdate: 0 }));
        break;
      case 'screenshot': {
        lcvHandler.queueDisplayFunction(
          'screenshot',
          (display) => display.screenshot(
            `Project ${projectName} ${currentTab!.text}`,
            // take a screenshot with a transparent background if the button is ctrl clicked
            event.metaKey || event.ctrlKey,
          ),
        );
        break;
      }
      default:
    }
  };
};
