// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement } from 'react';

import { getWallSurfaces } from '../../../../lib/boundaryConditionUtils';
import { validateGtZero } from '../../../../lib/inputValidationUtils';
import { COMMON_START_ICON, MODEL_ID, conflictingMeshModelSurfaces, modelHeading, nullableMeshing } from '../../../../lib/mesh';
import { NodeTableType } from '../../../../lib/nodeTableUtil';
import { DEFAULT_MODEL } from '../../../../lib/paramDefaults/meshingMultiPartState';
import { SelectionAction } from '../../../../lib/selectionUtils';
import { defaultModelParams } from '../../../../lib/simulationUtils';
import { wordsToList } from '../../../../lib/text';
import * as cadmetadatapb from '../../../../proto/cadmetadata/cadmetadata_pb';
import * as meshgenerationpb from '../../../../proto/meshgeneration/meshgeneration_pb';
import { QuantityType } from '../../../../proto/quantity/quantity_pb';
import { initializeNewNode, useSetNewNodes } from '../../../../recoil/nodeSession';
import { useCadMetadata } from '../../../../recoil/useCadMetadata';
import { useMeshReadOnly } from '../../../../recoil/useMeshReadOnly';
import { useMeshSurfaces } from '../../../../recoil/useMeshSurfaces';
import { WarningLocation, useMeshValidator } from '../../../../recoil/useMeshValidator';
import useMeshMultiPart, { useSetMeshMultiPart } from '../../../../recoil/useMeshingMultiPart';
import { pushConfirmation, useSetConfirmations } from '../../../../state/internal/dialog/confirmations';
import { ActionButton } from '../../../Button/ActionButton';
import { IconButton } from '../../../Button/IconButton';
import Form from '../../../Form';
import { DataField } from '../../../Form/DataSelect/DataField';
import { NumberField } from '../../../Form/NumberField';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import Divider from '../../../Theme/Divider';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { useSimulationConfig } from '../../../hooks/useSimulationConfig';
import { SectionMessage } from '../../../notification/SectionMessage';
import { ArrowUpRightIcon } from '../../../svg/ArrowUpRightIcon';
import { ResetIcon } from '../../../svg/ResetIcon';
import { TrashIcon } from '../../../svg/TrashIcon';
import NodeTable from '../../NodeTable';
import PropertiesSection from '../../PropertiesSection';
import { CustomCount } from '../shared/CustomCount';

type ModelParams = meshgenerationpb.MeshingMultiPart_ModelParams;
const SelectionType = meshgenerationpb.MeshingMultiPart_ModelParams_SelectionType;

const getDefaultModel = (
  cadMetadata: cadmetadatapb.CadMetadata,
) => new meshgenerationpb.MeshingMultiPart_ModelParams({
  ...DEFAULT_MODEL,
  maxSize: cadMetadata.globalMaxSizeM,
});

interface SelectionChange {
  selection: string[],
  type: meshgenerationpb.MeshingMultiPart_ModelParams_SelectionType,
  overlapIndices: number[],
}

interface MeshModelParamsProps {
  // The index of the model params this is displaying.
  modelIndex: number;
  // If the panel contains inputs rather than constant values.
  isInput: boolean;
  // Set a pending selection that must be confirmed before it is applied.
  setPendingChange?: (selectionChange: SelectionChange) => void;
}

// A panel displaying a single ModelParams.
export const MeshModelParams = (props: MeshModelParamsProps) => {
  const { modelIndex, isInput } = props;
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { setSelection, setScrollTo, modifySelection, activeNodeTable } = useSelectionContext();
  const { simParam } = useSimulationConfig();
  const meshMultiPart = useMeshMultiPart(projectId, workflowId, jobId);
  const setMeshMultiPart = useSetMeshMultiPart(projectId);
  const [cadMetadata] = useCadMetadata(projectId);
  const modelParams = meshMultiPart?.modelParams[modelIndex];
  const setNewNodes = useSetNewNodes();
  const {
    disabledLevel,
    disabledReason,
    warningLocations,
  } = useMeshValidator(projectId, workflowId, jobId, readOnly);
  const meshSurfaces = useMeshSurfaces(projectId);
  const meshReadOnly = useMeshReadOnly(projectId);

  const disabled = readOnly || meshReadOnly;
  const showWarning = isInput && warningLocations.includes(WarningLocation.MODEL_SIZE);

  if (!modelParams) {
    return null;
  }
  if (isInput && !props.setPendingChange) {
    throw new Error('setPendingChange expected for an input.');
  }
  const updateModel = (diff: Partial<ModelParams>) => {
    const newMeshMultiPart = meshMultiPart.clone();
    const newModel = new meshgenerationpb.MeshingMultiPart_ModelParams({
      ...modelParams,
      ...diff,
    });
    newMeshMultiPart.modelParams[modelIndex] = newModel;
    setMeshMultiPart(newMeshMultiPart);
  };
  const selectionOptions = [
    {
      name: 'All Surfaces',
      value: SelectionType.ALL,
      selected: modelParams.selection === SelectionType.ALL,
    },
    {
      name: 'All Wall Surfaces',
      value: SelectionType.WALL,
      selected: modelParams.selection === SelectionType.WALL,
    },
    {
      name: 'Selected Surfaces',
      value: SelectionType.SELECTED,
      selected: modelParams.selection === SelectionType.SELECTED,
    },
    {
      name: 'No Surfaces',
      value: SelectionType.NONE,
      selected: modelParams.selection === SelectionType.NONE,
    },
  ];

  const isDefault = (modelIndex === 0);
  const headerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMeshMultiPart((oldMeshMultiPart: nullableMeshing) => {
      if (!oldMeshMultiPart) {
        return null;
      }
      const newMeshMultiPart = meshMultiPart.clone();
      if (isDefault) {
        // The default has a reset button that resets everything.
        newMeshMultiPart.modelParams = [defaultModelParams(cadMetadata, simParam)];
      } else {
        // The other sections have a trash button that delete this particular model params.
        newMeshMultiPart.modelParams.splice(modelIndex, 1);
      }
      return newMeshMultiPart;
    });
  };
  const editClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setSelection([MODEL_ID]);
    setScrollTo({ node: MODEL_ID });
    setNewNodes((nodes) => [...nodes, initializeNewNode(MODEL_ID)]);
  };
  const headerButton = isInput ? (
    <IconButton
      disabled={disabled}
      onClick={headerClick}>
      {isDefault ? (
        <ResetIcon maxHeight={13} />
      ) : (
        <TrashIcon maxHeight={13} />
      )}
    </IconButton>
  ) : (
    <ActionButton kind="minimal" onClick={editClick} size="small">
      {disabled ? 'View' : 'Edit'}
      <ArrowUpRightIcon maxHeight={9} />
    </ActionButton>
  );

  const changeAppliesTo = (newValue: number) => {
    let newSelection: string[] = [];
    switch (newValue) {
      case SelectionType.ALL:
        newSelection = meshSurfaces;
        break;
      case SelectionType.WALL:
        newSelection = getWallSurfaces(simParam);
        break;
      case SelectionType.SELECTED:
        newSelection = meshMultiPart!.modelParams[0].surfaces;
        break;
      case SelectionType.NONE:
      default:
        break;
    }

    // Find all other meshing models (by index) whose surfaces include one or more member of
    // `newSelection`
    const overlapIndices = conflictingMeshModelSurfaces(
      modelIndex,
      new Set(newSelection),
      meshMultiPart,
    );

    // Require the change to be confirmed if there is some overlap with other surfaces.
    if (overlapIndices.length) {
      props.setPendingChange!({ selection: newSelection, type: newValue, overlapIndices });
    } else {
      updateModel({ selection: newValue });
      modifySelection({
        action: SelectionAction.OVERWRITE_EXCLUDE,
        modificationIds: newSelection,
        nodeTableOverride: { type: NodeTableType.MESHING_MODEL, index: 0 },
        updateHighlighting: false,
      });
    }
  };
  const showNodeTable = (
    isInput && (!isDefault || modelParams.selection === SelectionType.SELECTED)
  );
  const hasWalls = !!getWallSurfaces(simParam).length;
  const appliesToHelp = hasWalls ?
    '' : 'Setting up Wall Boundary Conditions before Mesh Generation is recommended.';

  return (
    <PropertiesSection>
      <CollapsibleNodePanel
        disabled={activeNodeTable.type === NodeTableType.MESHING_MODEL}
        headerRight={headerButton}
        heading={modelHeading(modelIndex)}
        nodeId={`${MODEL_ID}-${modelIndex}`}
        panelName="main">
        <Form.LabeledInput label="Curvature">
          <NumberField
            asBlock
            disabled={disabled}
            isInput={isInput}
            onCommit={(newValue: number) => {
              updateModel({ curvature: newValue });
            }}
            quantity={QuantityType.DEGREE}
            readOnly={disabled}
            value={modelParams.curvature}
          />
        </Form.LabeledInput>
        <Form.LabeledInput label="Max Size">
          <NumberField
            asBlock
            disabled={disabled}
            faultType={warningLocations.includes(WarningLocation.MODEL_SIZE) ?
              'warning' : undefined}
            isInput={isInput}
            onCommit={(newValue: number) => {
              updateModel({ maxSize: newValue });
            }}
            quantity={QuantityType.LENGTH}
            readOnly={disabled}
            validate={validateGtZero}
            value={modelParams.maxSize}
          />
        </Form.LabeledInput>
        {showWarning && (
          <div style={{ marginTop: '8px' }}>
            <SectionMessage level={disabledLevel}>
              {disabledReason}
            </SectionMessage>
          </div>
        )}
        {isDefault && (
          <Form.LabeledInput
            faultType={hasWalls ? undefined : 'warning'}
            help={appliesToHelp}
            label="Applies to">
            <DataField
              asBlock
              disabled={disabled}
              isInput={isInput}
              onChange={changeAppliesTo}
              options={selectionOptions}
              size="small"
            />
          </Form.LabeledInput>
        )}
        {showNodeTable && (
          <div style={{ paddingTop: '8px' }}>
            <NodeTable
              editable={!disabled}
              formGroups
              nodeIds={modelParams.surfaces}
              tableId={`meshing-model-${modelIndex}`}
              tableIndex={modelIndex}
              tableType={NodeTableType.MESHING_MODEL}
              title="Surfaces"
            />
          </div>
        )}
        {!isInput && <CustomCount count={meshMultiPart.modelParams.length - 1} />}
      </CollapsibleNodePanel>
    </PropertiesSection>
  );
};

// A panel for displaying the mesh model parameters.
export const MeshModelPropPanel = () => {
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const meshMultiPart = useMeshMultiPart(projectId, workflowId, jobId);
  const setMeshMultiPart = useSetMeshMultiPart(projectId);
  const [cadMetadata] = useCadMetadata(projectId);
  const { modifySelection } = useSelectionContext();
  const setConfirmStack = useSetConfirmations();
  const meshReadOnly = useMeshReadOnly(projectId);

  const disabled = readOnly || meshReadOnly;

  if (!meshMultiPart) {
    return null;
  }

  const confirmChange = (pendingChange: SelectionChange) => {
    const headings = pendingChange.overlapIndices.map((idx) => modelHeading(idx));
    pushConfirmation(setConfirmStack, {
      onContinue: () => {
        setMeshMultiPart((oldMeshMultiPart) => {
          const newMeshMultiPart = oldMeshMultiPart!.clone();
          newMeshMultiPart.modelParams[0].selection = pendingChange.type;
          return newMeshMultiPart;
        });
        modifySelection({
          action: SelectionAction.OVERWRITE_EXCLUDE,
          modificationIds: pendingChange.selection,
          nodeTableOverride: { type: NodeTableType.MESHING_MODEL, index: 0 },
          updateHighlighting: false,
        });
      },
      subtitle: `This will remove some surfaces from ${wordsToList(headings)}.
        Do you wish to continue?`,
      title: 'Confirm',
    });
  };

  const addCustomMeshSize = () => {
    setMeshMultiPart((oldMeshMultiPart: nullableMeshing) => {
      if (!oldMeshMultiPart) {
        return null;
      }
      const newMeshMultiPart = oldMeshMultiPart.clone();
      newMeshMultiPart.modelParams.push(new meshgenerationpb.MeshingMultiPart_ModelParams({
        ...getDefaultModel(cadMetadata),
        selection: SelectionType.SELECTED,
      }));
      return newMeshMultiPart;
    });
  };
  const modelList: ReactElement[] = [];
  for (let i = 0; i < meshMultiPart.modelParams.length; i += 1) {
    modelList.push(
      <MeshModelParams
        isInput
        key={`vol-${i}`}
        modelIndex={i}
        setPendingChange={confirmChange}
      />,
    );
    modelList.push(<Divider key={`div-${i}`} />);
  }
  return (
    <div>
      {modelList}
      <PropertiesSection>
        <ActionButton
          disabled={disabled}
          kind="secondary"
          onClick={addCustomMeshSize}
          size="small"
          startIcon={COMMON_START_ICON}>
          Custom Model
        </ActionButton>
      </PropertiesSection>
    </div>
  );
};
