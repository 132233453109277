// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { ActionLink } from '../../components/Button/ActionLink';
import AuthForm, { DataValues } from '../../components/Form/AuthForm';
import { AuthPageLayout } from '../../components/layout/page/AuthPageLayout';
import { LoginWithMfaLocationState, MFA_TOKEN_EXPIRED_ERROR, getLoginTokenWithOobMfa, isMfaTokenExpired, startOobLogin } from '../../lib/AuthMFA';
import { OobChallengeResponse, OobMfaInStorage, getLastOobForUser, handleAuthenticatedV2, setLastOobForUser } from '../../lib/AuthV2';
import { getCleanErrorMessage } from '../../lib/errors';
import { milliseconds } from '../../lib/formatDate';
import { routes } from '../../lib/navigation';
import { isStorybookEnv } from '../../lib/testing/utils';

const LoginSms2FA = () => {
  // This can be undefined only if we open the page directly. Otherwise, the useHandleMfaRequired
  // would have set the state when navigating to this page.
  const locationState = useLocation().state as LoginWithMfaLocationState | undefined;
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);
  const [fieldsError, setFieldsError] = useState<{ bindingCode?: string }>({});
  const [oob, setOob] = useState<OobChallengeResponse | undefined>();

  const smsMfaOption = useMemo(
    () => locationState?.activeMfaList?.find((mfa) => (mfa.oob_channel === 'sms')),
    [locationState?.activeMfaList],
  );

  const handleSubmit = async (data: DataValues) => {
    setSubmitting(true);
    setFieldsError({});

    if (isMfaTokenExpired(locationState?.mfaTokenExpiry)) {
      navigate(routes.login, { state: { error: MFA_TOKEN_EXPIRED_ERROR } });
      return;
    }

    // Get the login token and pass it to the handleAuthenticatedV2. It should set the AuthState
    // to AuthState.AUTHENTICATED and the useEffect in the main index.tsx should redirect to the
    // projects page.
    try {
      const loginToken = await getLoginTokenWithOobMfa(
        locationState?.mfaToken || '',
        '',
        oob?.oob_code || '',
        data.bindingCode,
        true,
        // data.remember === 'true',
      );
      await handleAuthenticatedV2(loginToken);
    } catch (err) {
      // If the getLoginTokenWithOobMfa fails, we probably entered a wrong bindingCode
      setFieldsError({ bindingCode: getCleanErrorMessage(err) });
      setSubmitting(false);
    }
  };

  // Automatically submit the form when 6 symbols are entered
  const handleChange = async (data: DataValues) => {
    if (data.bindingCode.length === 6) {
      await handleSubmit(data);
    }
  };

  const sendSmsChallenge = useCallback(async () => {
    try {
      const lastOob = getLastOobForUser();
      // If we have the oob in the session storage and it's no more than 5 minutes old, we use it,
      // instead of firing a new request.
      if (lastOob && lastOob.timestamp + milliseconds({ minutes: 5 }) > new Date().getTime()) {
        setOob(lastOob?.oob);
      } else {
        // Start the otp challenge to get the oob code. This will also send an SMS to the user's
        // phone which needs to be entered into the form. Then, when the form is submitted,
        // both codes will be used for the getLoginTokenWithOobMfa.
        const oobResponse = await startOobLogin(
          locationState?.mfaToken || '',
          smsMfaOption?.id || '',
        );
        setOob(oobResponse);

        // Save the oob to browser storage so we don't send another request for this user on page
        // refresh or if we open another page (e.g. 'Try other methods') and return here afterwards.
        const oobForStorage: OobMfaInStorage = {
          oob: oobResponse,
          timestamp: new Date().getTime(),
        };
        setLastOobForUser(oobForStorage);
      }
    } catch (err) {
      // If the mfa/challenge fails, we should start the login from scratch.
      navigate(routes.login, { state: { error: err.message } });
    }
  }, [smsMfaOption, locationState?.mfaToken, navigate]);

  // We should never really come in this component if not redirected with these params from the
  // useHandleMfaRequired, but add some protection just in case.
  useEffect(() => {
    if (isStorybookEnv()) {
      return;
    }

    if (!locationState?.mfaToken || !locationState.activeMfaList) {
      navigate(routes.login, { state: { error: 'Authentication error: no MFA state for login' } });
      return;
    }

    sendSmsChallenge().catch((err) => {});
  }, [locationState?.activeMfaList, locationState?.mfaToken, sendSmsChallenge, navigate]);

  // If we don't have the oob code yet, that means the mfa/challenge hasn't completed yet and we'll
  // just show the loading while it does.
  if (!oob) {
    return <AuthPageLayout loading />;
  }

  return (
    <AuthPageLayout
      back
      footer={(
        <>
          Having problems?&nbsp;
          <ActionLink onClick={() => {
            navigate(routes.loginBackup, { state: { mfaToken: locationState?.mfaToken } });
          }}>
            Try other options
          </ActionLink>.
        </>
      )}
      subtitle={(
        <>
          Enter the 6-digit code we texted
          to {smsMfaOption?.name ? `+X-XXX-XXX-${smsMfaOption?.name.slice(-4)}` : 'you'}.
          It may take a minute for the SMS to arrive.
        </>
      )}
      title="Enter the code we sent to verify it’s you">
      <AuthForm
        fields={[
          {
            asBlock: true,
            autofocus: true,
            label: '6-digit code',
            disabled: submitting,
            name: 'bindingCode',
            placeholder: '000000',
            required: true,
          },
          // The "remember" option stopped worked after the Auth0 Actions migration.
          // Hiding the checkbox until LC-19810 is resolved.
          // {
          //   label: 'Remember this device',
          //   disabled: submitting,
          //   name: 'remember',
          //   type: 'checkbox',
          // },
        ]}
        fieldsError={fieldsError}
        onChange={handleChange}
        onSubmit={handleSubmit}
        resetFieldsOnError={['bindingCode']}
        submit={{
          disabled: submitting,
          showSpinner: submitting,
          label: 'Continue',
        }}
      />
    </AuthPageLayout>
  );
};

export default LoginSms2FA;
