// Copyright 2021-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { Table } from '../../components/data/Table';
import { MainPageLayout, useMainCommonStyles } from '../../components/layout/page/Main';
import { ColumnConfig, RowConfig } from '../../lib/componentTypes/table';
import { colors } from '../../lib/designSystem';
import { fromBigInt } from '../../lib/number';
import { Logger } from '../../lib/observability/logs';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import useAccountInfo from '../../recoil/useAccountInfo';

const logger = new Logger('AccountPage/BillingPageBody');

const formatDate = (ts: number) => (
  new Date(ts * 1000).toLocaleDateString(
    'en-US',
    { year: 'numeric', day: 'numeric', month: 'long' },
  ));

const formatCents = (value: number) => (
  (value / 100).toLocaleString('en-US', { currency: 'USD', style: 'currency' })
);

function getStatus(invoice: frontendpb.AccountInfoReply_Invoice): string {
  if (invoice.totalCharges === BigInt(0)) {
    return 'No Usage';
  }
  if (invoice.status === 'paid') {
    return 'Paid';
  }
  if (invoice.status !== 'open') {
    logger.error(`Invoice ${invoice.id}: unknown status ${invoice.status}`);
    return invoice.status;
  }
  if (new Date(fromBigInt(invoice.dueDate) * 1000) < new Date()) {
    return 'OVERDUE';
  }
  return 'DUE';
}

function getStatusColor(status: string): string {
  switch (status) {
    case 'No Usage':
      return colors.neutral500;
    case 'Paid':
      return colors.greenYellow600;
    case 'OVERDUE':
      return colors.red500;
    case 'DUE':
      return colors.yellow500;
    default:
      return colors.purple400;
  }
}

const billingTableColumnConfigs: ColumnConfig[] = [
  { id: 'invoiceDate', label: 'Invoice Date', type: 'number', format: 'date' },
  { id: 'status', label: 'Status', type: 'string' },
  { id: 'openingBalance', label: 'Opening Balance', type: 'number', format: 'currency' },
  { id: 'totalConsumed', label: 'Total Consumed', type: 'number', format: 'currency' },
  { id: 'endingBalance', label: 'Ending Balance', type: 'number', format: 'currency' },
  { id: 'amountDue', label: 'Amount Due', type: 'number', format: 'currency' },
];

const BillingPageBody = () => {
  const accountInfo = useAccountInfo();
  const commonClasses = useMainCommonStyles();

  const latestInvoice = useMemo(() => (
    accountInfo?.billing?.invoice[0]
  ), [accountInfo]);

  const balance = useMemo(() => (
    fromBigInt(accountInfo?.billing?.balance || BigInt(0))
  ), [accountInfo]);

  const billingTableRowData: RowConfig[] = accountInfo?.billing?.invoice?.map(
    (invoice) => {
      const invoiceStatusDisplay = getStatus(invoice);
      return {
        id: invoice.id,
        values: {
          invoiceDate: fromBigInt(invoice.date) * 1000,
          status: invoiceStatusDisplay,
          openingBalance: -1 * fromBigInt(invoice.startingBal) / 100 || 0,
          totalConsumed: fromBigInt(invoice.totalCharges) / 100,
          endingBalance: -1 * fromBigInt(invoice.endingBal) / 100 || 0,
          amountDue: fromBigInt(invoice.amountDue) / 100,
        },
        cellDisplay: {
          status: [
            { type: 'bullet', color: getStatusColor(invoiceStatusDisplay) },
          ],
        },
        menuItems: [
          {
            label: 'Download',
            onClick: () => window.open(invoice.pdfUrl),
            startIcon: { name: 'pdf' },
          },
        ],
      };
    },
  ) ?? [];

  return (
    <MainPageLayout permission={!!accountInfo?.billing} title="Billing">
      <div className={commonClasses.summaryContainer}>
        <div className={commonClasses.summary}>
          <span>Current Owed Balance</span>
          <div className={commonClasses.summaryMain}>
            {formatCents(Math.max(0, balance))}
          </div>
          <span>
            {latestInvoice &&
              `Current invoice: ${formatCents(fromBigInt(latestInvoice.amountDue))}`}
          </span>
        </div>
        <div className={commonClasses.summary}>
          <span>Next Payment Due</span>
          <div className={commonClasses.summaryMain}>
            {latestInvoice?.status === 'open' ?
              formatDate(fromBigInt(latestInvoice.dueDate)) :
              'No Payment Due'}
          </div>
        </div>
        <div className={commonClasses.summary}>
          <span>Remaining Funds</span>
          <div className={commonClasses.summaryMain}>
            {formatCents(Math.max(0, -1 * balance))}
          </div>
          <span>
            {balance >= 0 && '* Call sales to add funds'}
          </span>
        </div>
      </div>
      <div className={commonClasses.title}>Setup</div>
      <div className={commonClasses.summaryContainer}>
        <div className={commonClasses.summary}>
          <span>Billing Contact</span>
          <div className={commonClasses.summaryMain}>
            {accountInfo?.billing?.email}
          </div>
          <span>{accountInfo?.billing?.address}</span>
        </div>
      </div>
      <div className={commonClasses.title} style={{ marginBottom: '16px' }}>Invoices</div>
      <Table
        asBlock
        columnConfigs={billingTableColumnConfigs}
        defaultSort={{ columnId: 'invoiceDate', descending: true }}
        name="invoices-table"
        rowConfigs={billingTableRowData}
      />
    </MainPageLayout>
  );
};

export default BillingPageBody;
