// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import { generateNKeysBetween } from 'fractional-indexing';

import assert from './assert';
import * as random from './random';
import { compareAlphanumeric } from './sorting';

export interface Groupable {
  // Id of the group
  id: string;
  // Name of the group
  name: string;
  // Children in the group
  children: Set<string>;
  // Id of the parent group
  parentId?: string;
  // Level of the group in the tree (depth from root)
  level: number;
  // Maximum level for grouping (depth from root)
  maxLevel?: number;
  // The fractional index of this group for sorting
  index: string;
}

export type GroupLeafMap = Map<string, Set<string>>;

/** A sorting function to compare fractional indices in ascending order. */
const INDEX_COMPARE_FN = (a: Groupable | undefined, b: Groupable | undefined) => {
  if (a && b) {
    // Both groups are defined
    if (a.index! < b.index!) {
      return -1;
    }
    if (a.index! > b.index!) {
      return 1;
    }
    return 0;
  }

  if (a) {
    // Only b is undefined, so move it to the end of the list
    return -1;
  }

  // Only a is undefined, so move it to the end of the list
  return 1;
};

// Class representing a structure to store hierarchical grouping information. Every group is
// stored as a single map entry to provide constant access times. Can be provided with a callback
// routine that is called whenever the structure of a group (i.e. the list of children) changes
// This can be used to update any properties of a group that depends on its children.
export class GroupMap<Group extends Groupable> {
  private groupMap: Map<string, Group>;
  public static readonly rootId = 'root';

  constructor(
    private updateCallback: (group: Group, groupMap: GroupMap<Group>) => void,
    existingGroupMap?: GroupMap<Group>,
  ) {
    if (existingGroupMap) {
      this.groupMap = new Map<string, Group>(existingGroupMap.groupMap);
    } else {
      this.groupMap = new Map<string, Group>(
        [[
          GroupMap.rootId,
          {
            id: GroupMap.rootId,
            name: GroupMap.rootId,
            children: new Set<string>(),
            level: 0,
            index: '0',
          } as Group,
        ]],
      );
    }
  }

  /**
   * Sorts the children of the given group using the given compareFn,
   * and assigns them an index corresponding to their new placement in the sorted list.
   *
   * @param groupId The id of the group from which to start assigning indices. Usually this will
   * be the root id.
   * @param compareFn The comparison function to use to sort the children.
   */
  public sortChildren(
    groupId: string,
    compareFn = (g1: Group, g2: Group) => compareAlphanumeric(g1.name, g2.name),
  ): void {
    // list the sorted child groups.
    const childGroups = [...this.get(groupId).children].map((id) => ({
      id,
      group: this.has(id) ? this.get(id) : undefined,
    }));
    childGroups.sort((a, b) => {
      if (a.group && b.group) {
        return compareFn(a.group, b.group);
      }
      if (a.group) {
        // Only b.group is undefined, so move it to the end of the list
        return -1;
      }
      // Only a.group is undefined, so move it to the end of the list
      return 1;
    });

    // generate n new indices and assign them to each child.
    const keys: string[] = generateNKeysBetween(null, null, childGroups.length);
    childGroups.forEach(({ group }, i) => {
      if (group) {
        group.index = keys[i];
      }
    });
  }

  /**
   * Recursively sorts the children of the given group using the given comparator function.
   * Assigns the index of each child based on its new position in the list.
   *
   * @param groupId The id of the group at which to start sorting children.
   * @param compareFn The comparison function to use to sort the children.
   */
  public sortChildrenRecursive(
    groupId: string,
    compareFn = (g1: Group, g2: Group) => compareAlphanumeric(g1.name, g2.name),
  ): void {
    if (this.has(groupId)) {
      this.sortChildren(groupId, compareFn);
      this.get(groupId).children.forEach((childId) => {
        this.sortChildrenRecursive(childId, compareFn);
      });
    }
  }

  /**
   * Returns the list of child ids, sorted by index. If the children do not yet
   * have indices, sorts the children and generates an index for each child using
   * compareAlphanumeric as the default sorting algorithm.
   *
   * @param groupId The id of the group whose child ids will be returned.
   * @returns a list of child ids, sorted by index.
   */
  public getChildren(groupId: string): string[] {
    // first, check that all the children have a valid index. If any are missing indices, run
    // this.sortChildren.
    const childIdList = [...this.get(groupId).children];
    const needSort = childIdList.some((id) => this.has(id) && this.get(id).index === undefined);
    if (needSort) {
      this.sortChildrenRecursive(groupId);
    }

    // Use a Schwartzian Transform to associate each child ID with a group, so that we can still
    // sort without losing information about IDs that aren't in the map (for whatever unlikely
    // reason).
    const childGroups = childIdList.map((id) => ({
      id,
      group: this.has(id) ? this.get(id) : undefined,
    }));
    childGroups.sort((a, b) => INDEX_COMPARE_FN(a.group, b.group));
    return childGroups.map(({ id }) => id);
  }

  // Get all groups for which the filter predicate evalutes to true. If no filter is provided,
  // all groups (except root) will be returned.
  public getGroups(filter?: (group: Group) => boolean, includeRoot?: boolean) {
    const groupsFilter = (([id, group]: [string, Group]) => (
      // Apply the provided filter if defined.
      (filter ? filter(group) : true) && (includeRoot || id !== GroupMap.rootId)
    ));
    return Array.from(this.groupMap).filter(groupsFilter).map(([_, group]) => group);
  }

  // Get a group with an id from the map. Throws an error if group cannot be found.
  public get(id: string) {
    const group = this.groupMap.get(id);
    if (!group) {
      throw Error(`Group with id ${id} not found in group map.`);
    }
    return group;
  }

  // Checks if a group can be found in the map
  public has(id: string) {
    return this.groupMap.has(id);
  }

  // Creates a new group with the provided children. If all children are in the same group, the
  // parent of the new group will be the parent of the children, i.e. all children are moved one
  // level down. If the children have different parents, the new group will be created at the top,
  // i.e. the parent will be root. Returns the new group.
  public group(groupName: string, childrenIds: string[], groupId?: string) {
    // Some basic checks. We only allow grouping within the same group so all
    // children must have the same parent.
    const children = childrenIds.map((childId) => this.get(childId));
    const parentIds = children.map((child) => child.parentId);
    let parentId: string;
    if (!parentIds.every((pId) => pId === children[0].parentId)) {
      parentId = GroupMap.rootId;
    } else {
      parentId = parentIds[0]!;
    }
    assert(!!parentId, 'Cannot group without a parent ID');
    // Create a new group
    const newGroupId = groupId ?? random.string(32);

    this.add({
      parentId,
      name: groupName,
      id: newGroupId,
      level: this.get(parentId).level + 1,
      maxLevel: Math.min(...children.map((child) => (child.maxLevel ?? Infinity))),
    } as Group);

    // Move items to the new group
    childrenIds.forEach((childId) => {
      this.move(childId, newGroupId);
    });

    this.updateCallback(this.get(newGroupId), this);
    return this.get(newGroupId);
  }

  // Merge groups by creating a new group that contains the children of all groups in the requested
  // ids array
  public merge(groupName: string, ids: string[]) {
    const idsToGroup: string[] = [];
    ids.forEach((childId) => {
      idsToGroup.push(...this.ungroup(childId));
    });
    // Put everything into a new group
    return this.group(groupName, idsToGroup);
  }

  // Ungroup a group and add all its children to the parent. Returns a list of all
  // children that were originally part of that group. If the group is a leaf node
  // it will simply be moved to the parent of the parent.
  public ungroup(groupId: string) {
    const group = this.get(groupId);
    assert(!!group.parentId, 'Cannot ungroup without a parent ID');
    const ungrouped: string[] = [];
    const parent = this.get(group.parentId!);
    if (group.children.size) {
      group.children.forEach((childId) => {
        ungrouped.push(childId);
        this.move(childId, group.parentId!);
      });
    } else if (!GroupMap.isRoot(group.parentId!)) {
      this.move(group.id, parent.parentId!);
      ungrouped.push(group.id);
    }
    this.updateCallback(parent, this);
    return ungrouped;
  }

  // Delete a group if it is empty. Returns true if was deleted, otherwise returns false
  private maybeDeleteGroup(groupId: string): boolean {
    const group = this.get(groupId);
    if (!group.children.size && !GroupMap.isRoot(groupId)) {
      const parent = this.get(group.parentId!);
      parent.children.delete(group.id);
      this.updateCallback(parent, this);
      return this.groupMap.delete(group.id);
    }
    return false;
  }

  // Recursively removes a group and its children
  private deleteGroupRecursive(groupId: string) {
    const group = this.get(groupId);
    group.children.forEach((childId: string) => {
      this.deleteGroupRecursive(childId);
    });
    this.groupMap.delete(groupId);
  }

  // Deletes a specific group and any of its ancestors that become empty
  public delete(groupId: string) {
    if (this.has(groupId)) {
      // Moving the group to root will remove any empty groups that are generated by removing the
      // group
      this.move(groupId, GroupMap.rootId);
      this.root().children.delete(groupId);
      this.deleteGroupRecursive(groupId);
    }
  }

  // Moves a group to a different group (i.e. changes the parent of a group)
  // Deletes groups that are empty after the move
  public move(groupId: string, newParentId: string) {
    const group = this.get(groupId);
    assert(!!group.parentId, 'Cannot move without a parent ID');
    const parent = this.get(group.parentId!);
    parent.children.delete(group.id);
    this.updateCallback(parent, this);
    // Walk through all ancestors of the group and remove them if they don't have children anymore
    // Note: its not possible to pass member functions as callback arguments, so we need a wrapper
    // https://github.com/Microsoft/TypeScript/wiki/FAQ#why-does-this-get-orphaned-in-my-instance-methods
    // We do not want to delete the new parent since we add the new group to it below.
    this.walkAncestors(
      group.id,
      (anc) => (anc.id !== newParentId && this.maybeDeleteGroup(anc.id)),
    );
    const newParent = this.get(newParentId);
    group.parentId = newParent.id;
    newParent.children.add(group.id);
    this.resetChildLevelsRecursively(newParentId);
    this.updateCallback(newParent, this);
  }

  public resetChildLevelsRecursively(groupId: string) {
    if (this.has(groupId)) {
      const group = this.get(groupId);
      this.getChildren(groupId).forEach((childId) => {
        this.get(childId).level = group.level + 1;
        this.resetChildLevelsRecursively(childId);
      });
    }
  }

  // Add a new sub-group to an existing group. If no id is provided it will be generated.
  public add(newGroup: Partial<Group>) {
    const parentId = newGroup.parentId ?? GroupMap.rootId;
    const parent = this.get(parentId);
    const groupId = newGroup.id ?? random.string(32);
    // Add the new group to the map. Defaults are used for any
    // of the necessary properties if they are not provided. Everything else
    // is just forwarded.
    this.groupMap.set(
      groupId,
      {
        ...newGroup,
        name: newGroup.name || 'New Group',
        id: groupId,
        parentId,
        level: parent.level + 1,
        children: newGroup.children ?? new Set<string>(),
      } as Group,
    );
    parent.children.add(groupId);
    this.updateCallback(parent, this);
    return this;
  }

  // Returns a map that contains for each group a list of ids of all children that are leafs
  public createLeafMap(): GroupLeafMap {
    // Initialize the map
    const leafMap = new Map<string, Set<string>>(Array.from(this.groupMap).map(
      ([id, group]) => [id, new Set(group.children.size ? [] : [id])],
    ));
    // Sort groups based on their levels (descending) so that children are processed before their
    // parents.
    Array.from(this.groupMap).sort((a, b) => b[1].level - a[1].level).forEach(([id, group]) => {
      if (group.parentId && !GroupMap.isRoot(group.parentId)) {
        const parentLeafs = leafMap.get(group.parentId);
        const currentLeafs = leafMap.get(id);
        if (parentLeafs) {
          if (!group.children.size) {
            parentLeafs.add(id);
          } else if (currentLeafs) {
            // Until ECMA adds something like Set.add(...args)
            currentLeafs.forEach((leafId) => parentLeafs.add(leafId));
          }
        }
      }
    });
    return leafMap;
  }

  public walkAncestors(
    // Start walking up ancestor tree from this ID
    itemId: string,
    // The 'callback' argument is called for each ancestor encountered.  If it returns false, the
    // function stops walking the ancestor tree.
    callback: (entity: Group) => boolean,
  ) {
    let entry = this.has(itemId) ? this.get(itemId) : null;

    while (entry) {
      const parentId = entry.parentId;
      if (parentId) {
        const parent = this.get(parentId);
        const keepWalking = callback(parent);
        if (keepWalking) {
          entry = parent;
          continue;
        }
      }
      entry = null;
    }
  }

  // Return nearest ancestor that satisfies the provided testing function
  public findAncestor(
    itemId: string,
    callback: (entity: Group) => boolean,
  ): Group | null {
    let result: Group | null = null;

    this.walkAncestors(itemId, (entry) => {
      if (callback(entry)) {
        result = entry;
        return false;
      }
      return true;
    });

    return result;
  }

  public filterAncestors(
    itemId: string,
    callback: (entity: Group) => boolean,
  ): Group[] {
    const groups: Group[] = [];

    this.walkAncestors(itemId, (entry) => {
      if (callback(entry)) {
        groups.push(entry);
      }
      return true;
    });

    return groups;
  }

  public findDescendants(itemId: string): string[] {
    const result: string[] = [];

    if (this.has(itemId)) {
      const group = this.get(itemId);
      group.children.forEach((childId) => {
        result.push(childId, ...this.findDescendants(childId));
      });
    }

    return result;
  }

  // Get the root group
  public root() {
    return this.get(GroupMap.rootId);
  }

  // Returns true if the groupId is equal to the root node id
  public static isRoot(groupId: string) {
    return GroupMap.rootId === groupId;
  }
}

export default GroupMap;
