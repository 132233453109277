// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useState } from 'react';

import { animationLoop, easeOutQuart } from '../../../lib/animationUtils';
import { colors } from '../../../lib/designSystem';
import { AnyKeyboardEvent } from '../../../lib/event';
import { isEventTrigger } from '../../../lib/keyBindings';
import { ListenerEvent, useEventListener } from '../../../lib/useEventListener';
import { VIEWER_PADDING } from '../../../lib/visUtils';
import { EXPLODE_ANIMATION_DURATION, useLcvisExplodeFactorState } from '../../../recoil/lcvis/explodeFactor';
import { ActionButton } from '../../Button/ActionButton';
import { useToolbarMargin } from '../../Pane/hooks/useFloatingToolbarMargin';
import { createStyles, makeStyles } from '../../Theme';
import Tooltip from '../../Tooltip';
import { useProjectContext } from '../../context/ProjectContext';
import { SimpleSlider } from '../../controls/slider/SimpleSlider';
import { DiskInfoIcon } from '../../svg/DiskInfoIcon';

const useStyles = makeStyles(() => createStyles({
  container: {
    position: 'absolute',
    top: `${VIEWER_PADDING}px`,
  },
  root: {
    width: '320px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    backgroundColor: colors.neutral150,
    gap: '12px',
    borderRadius: '4px 0px 0px 4px',
    padding: '8px',
  },
  slider: {
    width: '130px',
    paddingRight: '4px',
  },
  label: {
    fontSize: '12px',
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
  },
}), { name: 'ExplodeModeLabel' });

const ExplodeModeLabel = () => {
  const { projectId } = useProjectContext();
  const [explodeFactor, setExplodeFactor] = useLcvisExplodeFactorState(projectId);
  const classes = useStyles();
  const rootStyles = useToolbarMargin();
  // disable the slider on mount, since we want to explode the view when the user enters this mode.
  const [disabled, setDisabled] = useState(true);

  // When the user enters probe mode, automatically explode the view to its max. Then allow
  // the user to change it.
  useEffect(() => {
    const remove = animationLoop(0, 1, EXPLODE_ANIMATION_DURATION, (curr) => {
      setExplodeFactor(curr);
      if (curr >= 1) {
        setDisabled(false);
      }
    }, easeOutQuart);
    return () => remove();
  }, [setExplodeFactor]);

  // Pressing escape should exit the overlay.
  const handleExitOverlay = useCallback((event: AnyKeyboardEvent) => {
    if (isEventTrigger('exitOverlay', event)) {
      event.preventDefault();
      setExplodeFactor(null);
    }
  }, [setExplodeFactor]);
  useEventListener(
    'keydown',
    (event: ListenerEvent) => handleExitOverlay(event as AnyKeyboardEvent),
  );

  const tooltip = (
    <Tooltip title="Explode expands objects out from the center of the scene's visible bounds.">
      <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <DiskInfoIcon maxHeight={12} maxWidth={12} />
      </span>
    </Tooltip>
  );

  return (
    <div className={classes.container} style={rootStyles}>
      <div className={classes.root}>
        <div className={classes.label}>
          Exploded View {tooltip}
        </div>
        <div className={classes.slider}>
          <SimpleSlider
            disabled={disabled}
            gutterHeight={4}
            max={1}
            min={0}
            onChange={(newVal) => setExplodeFactor(newVal)}
            readoutConfig={{ disabled: true }}
            value={disabled ? 1 : explodeFactor ?? 0}
          />
        </div>
        <ActionButton onClick={() => setExplodeFactor(null)} size="small">
          Exit
        </ActionButton>
      </div>
    </div>
  );
};

export default ExplodeModeLabel;
