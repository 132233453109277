// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import { useState } from 'react';

import { deepEqual } from 'fast-equals';

import { ColorMap, DisplayPvVariable } from '../../pvproto/ParaviewRpc';

import { useParaviewContext } from './ParaviewManager';

// The default colormap preset.
const DEFAULT_PRESET = 'Jet';

// The default colormap type is continuous.
const DEFAULT_DISCRETIZE = false;

// The default number of discrete levels/bins.
const DEFAULT_DISCRETE_BINS = 8;

// The default colormap visibility.
const DEFAULT_VISIBLE = true;

// The maximum number of discrete levels for color maps.
export const MAX_BINS = 256;

// The maximum number of ticks/labels used for legend colorbar.
export const MAX_TICKS = 9;

export type ColorPanel = {
  name: string;
  isVertical: boolean;
}

interface ColorDialogState {
  displayVariable: DisplayPvVariable;
  name: string;
  initialCmap: ColorMap;
  editedCmap: ColorMap;
}

export const useColorPanelState = () => {
  // Tracks inputs to the singleton color bar dialog (EditColorsBox)
  const [colorDialogState, setColorDialogState] = useState<ColorDialogState | null>(null);
  const [verticalColorPanels, setVerticalColorPanel] = useState<Map<string, boolean>>();

  const { displayVariableToText, getColorMap, updateColorMap } = useParaviewContext();

  const closeEditState = () => {
    setColorDialogState(null);
  };

  const updateMap = (name: string, isNewVertical: boolean) => {
    const newMap = new Map(verticalColorPanels);
    newMap.set(name, isNewVertical);

    // Set the new Map as the state
    setVerticalColorPanel(newMap);
  };

  const changeOrientation = (isVertical: boolean) => {
    if (colorDialogState) {
      updateMap(colorDialogState.name, isVertical);
    }
  };

  const getVertical = (name: string) => verticalColorPanels?.get(name) ?? true;

  const cancelEdit = () => {
    if (colorDialogState) {
      updateColorMap(colorDialogState.displayVariable, colorDialogState.initialCmap);
      closeEditState();
    }
  };

  const updateCmap = (cmap: ColorMap) => {
    if (colorDialogState && !deepEqual(colorDialogState.editedCmap, cmap)) {
      setColorDialogState({ ...colorDialogState, editedCmap: cmap });
    }
  };

  const generateColorMap = (displayVariable: DisplayPvVariable): ColorMap => (
    getColorMap(displayVariable) || {
      presetName: DEFAULT_PRESET,
      globalRange: [0, 0],
      range: [0, 0],
      bins: DEFAULT_DISCRETE_BINS,
      visible: DEFAULT_VISIBLE,
      discretize: DEFAULT_DISCRETIZE,
    }
  );

  const initColorDialogState = (displayVariable: DisplayPvVariable) => {
    const name = displayVariableToText(displayVariable);

    if (colorDialogState) {
      // There's already a dialog up
      if (colorDialogState.name !== name) {
        // If we've clicked a different color, reset the one we're editing
        cancelEdit();
      } else {
        // If we've clicked the same color bar, do nothing
        return;
      }
    }

    const initialCmap = generateColorMap(displayVariable);

    setColorDialogState({
      name,
      displayVariable,
      initialCmap,
      editedCmap: initialCmap,
    });
  };

  const colorBarsToShow = (displayVars: DisplayPvVariable[]) => (
    displayVars.map((displayVar, index) => {
      const label = displayVariableToText(displayVar);
      return {
        displayVar,
        label,
        colorMap: generateColorMap(displayVar),
        dragId: `panel-${index}`,
      };
    })
  );

  return {
    colorDialogState,
    setColorDialogState,
    closeEditState,
    cancelEdit,
    updateCmap,
    generateColorMap,
    initColorDialogState,
    colorBarsToShow,
    MAX_BINS,
    MAX_TICKS,
    changeOrientation,
    verticalColorPanels,
    getVertical,
  };
};
