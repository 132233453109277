// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';

import { getLocalStorageData } from '../../../lib/browserStorage';
import { CurrentView } from '../../../lib/componentTypes/context';
import { localStorageEffect } from '../../../lib/persist';
import { currentViewAtom_DEPRECATED } from '../global/currentView';

const RIGHT_STORAGE_KEY = 'right-overlay-cards-width';
const DEFAULT_RIGHT_WIDTH = 272;

export const rightOverlayCardsWidth = atom<number>({
  key: 'rightOverlayCardsWidth',
  default: getLocalStorageData<number>(RIGHT_STORAGE_KEY, DEFAULT_RIGHT_WIDTH),
  effects: [
    localStorageEffect(RIGHT_STORAGE_KEY),
  ],
});

export const rightOverlayCardsWidthSelector = selector<number>({
  key: 'rightOverlayCardsWidthSelector',
  get: ({ get }) => {
    const currentView = get(currentViewAtom_DEPRECATED);
    if (currentView === CurrentView.GEOMETRY) {
      return get(rightOverlayCardsWidth);
    }
    return 0;
  },
  set: ({ set }, newValue) => {
    set(rightOverlayCardsWidth, newValue);
  },
});

export const useRightOverlayCardsWidth = () => useRecoilState(rightOverlayCardsWidthSelector);
export const useRightOverlayCardsWidthValue = () => useRecoilValue(rightOverlayCardsWidthSelector);
