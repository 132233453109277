// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

import assert from './assert';

// A node table type that affects behavior.
// Note: If new type is added, it should be included to 1 of the volumes/surfaces tables at the end
export enum NodeTableType {
  NONE,
  // Table for a fluid boundary condition.
  FLUID_BOUNDARY,
  // Table for a heat boundary condition.
  HEAT_BOUNDARY,
  // Table for an outputs IN surfaces and OUT surfaces.
  IN_SURFACES,
  OUT_SURFACES,
  // Table for surfaces that should be treated as part of the model during
  // meshing.
  MESHING_MODEL,
  // Table for surfaces where a boundary layer should exist during LMA runs.
  MESHING_ADAPTATION_BL,
  // Table for surfaces where a boundary layer should exist during meshing.
  MESHING_BL,
  // Table for volumes specifying a size for meshing.
  MESHING_SIZE,
  // Table for a physical behavior's attached particle groups.
  PHYSICAL_BEHAVIOR_ATTACH,
  // Table for point probes.
  POINTS,
  // Table for extracting subset of surfaces for vis.
  EXTRACT_BOUNDARY,
  // Tables for two sides of a sliding interface.
  SLIDING_INTERFACE_SURFACES_A,
  SLIDING_INTERFACE_SURFACES_B,
  // Table for volumes assigned to porous models.
  POROUS_VOLUME,
  // Table for volumes.
  VOLUMES,
  // Table for periodic pair surfaces.
  PERIODIC_PAIR_SIDE_A,
  PERIODIC_PAIR_SIDE_B,
  // Tables for selecting Frame geometry.
  FRAME_VOLUMES,
  FRAME_SURFACES,
  // Monitor plane volumes.
  MONITOR_PLANE_VOLUMES,
  // Table for adjoint sensitivity surfaces.
  SENSITIVITY_SURFACES,
}

export interface NodeTableIdentifier {
  type: NodeTableType;
  id?: string;
  index?: number;
}

// All node table types that are meant to select volumes
export const VOLUMES_TABLES = [
  NodeTableType.VOLUMES,
  NodeTableType.POROUS_VOLUME,
  NodeTableType.MESHING_SIZE,
  NodeTableType.FRAME_VOLUMES,
];

// All node table types that are meant to select surfaces
export const SURFACES_TABLES = [
  NodeTableType.FLUID_BOUNDARY,
  NodeTableType.HEAT_BOUNDARY,
  NodeTableType.IN_SURFACES,
  NodeTableType.OUT_SURFACES,
  NodeTableType.MESHING_MODEL,
  NodeTableType.MESHING_ADAPTATION_BL,
  NodeTableType.MESHING_BL,
  NodeTableType.EXTRACT_BOUNDARY,
  NodeTableType.SLIDING_INTERFACE_SURFACES_A,
  NodeTableType.SLIDING_INTERFACE_SURFACES_B,
  NodeTableType.PERIODIC_PAIR_SIDE_A,
  NodeTableType.PERIODIC_PAIR_SIDE_B,
  NodeTableType.FRAME_SURFACES,
  NodeTableType.SENSITIVITY_SURFACES,
];

export const DISKS_TABLES = [
  NodeTableType.PHYSICAL_BEHAVIOR_ATTACH,
];

export const POINTS_TABLES = [
  NodeTableType.POINTS,
];

export const PLANE_AND_VOLUME_TABLES = [
  NodeTableType.MONITOR_PLANE_VOLUMES,
];

// All node table types (excluding the NONE type) must be part of some of the 4 entity tables above.
// This is to guarantee that the respective tree nodes will show in the Geometry tree when some
// NodeTable is active.
assert(Object.keys(NodeTableType).length / 2 === (
  VOLUMES_TABLES.length +
  SURFACES_TABLES.length +
  DISKS_TABLES.length +
  POINTS_TABLES.length +
  PLANE_AND_VOLUME_TABLES.length +
  1
), 'Node table type check failed');
