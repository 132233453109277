// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import * as frontendpb from '../proto/frontend/frontend_pb';
import * as projectstatepb from '../proto/projectstate/projectstate_pb';

export interface WorkorderStepMetadata {
  heading?: string;
  tooltip?: string;
  summary?: string;
  stepMessages?: string[];
  // Which of the steps is active. The previous steps have finished.
  currentStepIndex?: number;
}

export function getWorkorderStepMetadata(
  pending: frontendpb.PendingWorkOrder,
  type: frontendpb.WorkOrderType,
  meshUrlState: projectstatepb.MeshUrl,
  isInteractiveGeo: boolean,
): WorkorderStepMetadata {
  switch (type) {
    case frontendpb.WorkOrderType.GET_GEOMETRY: {
      const stepMessages: string[] = isInteractiveGeo ? ['Generating Tessellation'] :
        ['Geometry Clean-Up'];
      let currentStepIndex = 0;

      // When adding a far-field or igeo there is only one step.
      const hasUserGeoMod = pending.typ.case === 'getGeometry' && pending.typ.value.userGeoMod;
      if (!hasUserGeoMod && !isInteractiveGeo) {
        stepMessages.unshift('File Translation');
        // If we have a preview, the file translation step has finished.
        if (meshUrlState.preview) {
          currentStepIndex = 1;
        }
      }

      return {
        heading: 'Geometry Preparation',
        summary: `This may take a few minutes. You can modify settings once these steps complete.
          You can keep this project open or close it for later – we will continue to process your
          file in the background.`,
        currentStepIndex,
        stepMessages,
      };
    }
    case frontendpb.WorkOrderType.CHECK_GEOMETRY: {
      return {
        heading: 'Geometry Health Check',
        summary: `We're validating that your geometry can be successfully meshed. You can modify
        geometry objects and certain setup parameters as this process runs in the background.`,
        stepMessages: ['Volume Check'],
      };
    }
    case frontendpb.WorkOrderType.GET_MESH: {
      return {
        heading: 'Mesh Generation',
        tooltip: `This may take a few minutes. You can modify other settings as this runs.  You can
        keep this project open or close it for later – we will continue to generate the mesh in the
        background.`,
        stepMessages: ['Cell Estimate and Generation'],
      };
    }
    case frontendpb.WorkOrderType.COMPUTE_GEOMETRY_CONTACTS: {
      return {
        heading: 'Automatic Contact Detection',
        summary: `This may take a few minutes. You can modify other settings as this runs.`,
        stepMessages: ['Computing'],
      };
    }
    default: {
      return {};
    }
  }
}
