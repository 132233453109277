// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { DisplayProps } from '../../../../pvproto/ParaviewRpc';
import { RgbColor, colors, hexToRgbList } from '../../../designSystem';
import { isTestingEnv } from '../../../testing/utils';
import { parseRepresentationType } from '../../../visUtils';
import { Bounds, LcvModule, ViewProps } from '../../types';
import { LcvObject } from '../base/LcvObject';

import { LcvFilterType } from './filterUtils';

const SURFACE_LINE_COLOR = hexToRgbList(colors.edgeColor);

/**
 * The base class for an LCVis filter.
 * Filters are owned by the workspace, and each filter owns some set of surfaces.
 *
 * For some filters (e.g. the import dataset or farfield filters), we want to be able to set things
 * like the visibility, transparency, and color of individual surfaces.
 * For other filters (e.g. clip/slice/contour), we want to be able to set these properties for all
 * surfaces at once.
 */
export abstract class LcvFilter extends LcvObject {
  protected workspaceHandle: number;
  id: string;
  type: LcvFilterType;
  visible = true;
  viewProps?: ViewProps;

  constructor(
    lcv: LcvModule,
    handle: number,
    sessionHandle: number,
    workspaceHandle: number,
    type: LcvFilterType,
    id: string,
  ) {
    super(lcv, handle, sessionHandle);
    this.workspaceHandle = workspaceHandle;
    this.id = id;
    this.type = type;
  }

  /**
   * Edit the view properties of the filter.
   * @param perSurface Whether the filter is to be edited per-surface or as a whole.
   * @param displayProps The display properties to set.
   * @param visible Whether the entire filter should be made visible. This will be ignored if
   * perSurface is true.
   * @param color The color to set the entire filter to.
   * @returns {void}
   */
  setViewProps(
    perSurface: boolean,
    displayProps: DisplayProps,
    visible?: boolean,
    color?: RgbColor,
  ): void {
    this.viewProps = {
      perSurface,
      displayProps,
    };
    if (perSurface) {
      const numSurfaces = this.getNumSurfaces();
      for (let i = 0; i < numSurfaces; i += 1) {
        const surfaceVisible = this.getSurfaceOrLineVisibility(i);
        this.updateSurfaceAndLineVisibility(i, surfaceVisible);
      }
    } else {
      this.updateAllSurfacesAndLinesVisibility(visible ?? this.visible);
    }
    if (displayProps.reprType === 'Wireframe') {
      // If the filter is set to show wireframe, we want to show the lines in white.
      this.setAllSurfaceLinesColor([1, 1, 1]);
    } else {
      this.setAllSurfaceLinesColor(SURFACE_LINE_COLOR);
    }
    if (color && displayProps.showColors) {
      this.setAllSurfacesColor(color);
    }
  }

  /**
   * Update the visibility of the whole filter (lines and surfaces). This will take the filter's
   * current view properties into account (e.g. if the filter is set to show surfaces only,
   * it will show all surfaces but no lines).
   * @param visible Whether the filter should be visible.
   * @returns {void}
   */
  updateAllSurfacesAndLinesVisibility(visible: boolean): void {
    if (!this.viewProps || this.viewProps.perSurface) {
      return;
    }
    const { displayProps } = this.viewProps;
    const { showLines, showSurfaces } = parseRepresentationType(displayProps.reprType);

    this.setAllSurfacesVisible(showSurfaces && visible);
    this.setAllSurfaceLinesVisible(showLines && visible);
    this.visible = visible;
  }

  /**
   * Update the visibility of a specific surface in the filter. This will take the filter's current
   * view properties into account (e.g. if the filter is set to show surfaces only, it will show
   * the surface but not the lines).
   * @param surfaceIndex The index of the surface to set the visibility of.
   * @param visible Whether the surface should be visible.
   * @returns {void}
   */
  updateSurfaceAndLineVisibility(surfaceIndex: number, visible: boolean): void {
    if (!this.viewProps?.perSurface) {
      return;
    }
    const { displayProps } = this.viewProps;
    const { showLines, showSurfaces } = parseRepresentationType(displayProps.reprType);

    this.setSurfaceVisible(surfaceIndex, showSurfaces && visible);
    this.setLineVisible(surfaceIndex, showLines && visible);
  }

  /**
   * Get the bounds of a specific surface in the filter.
   * @param surfaceIndex The index of the surface to get the bounds of.
   * @returns The bounds of the surface in the form [minX, minY, minZ, maxX, maxY, maxZ].
   */
  getSurfaceBounds(surfaceIndex: number): Bounds {
    return this.lcv.getSurfaceBoundingBox(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      surfaceIndex,
      0,
    ).bounding_box;
  }

  /**
   * Set the visibility of all surfaces in the filter.
   * @param visible Whether the filter should be visible.
   */
  private setAllSurfacesVisible(visible: boolean) {
    if (!this.hasSurfaces()) {
      return;
    }
    this.lcv.setFilterSurfacesVisibility(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      visible ? 1 : 0,
    );
  }

  /**
   * Set the visibility of all surface lines in the filter
   * @param visible whether the lines should be visible
   */
  private setAllSurfaceLinesVisible(visible: boolean) {
    if (!this.hasSurfaces()) {
      return;
    }
    this.lcv.setFilterLinesVisibility(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      visible ? 1 : 0,
    );
  }

  /**
   *  Set the transparency of all surfaces in the filter.
   * @param transparent Whether the filter should be transparent.
   */
  setAllSurfacesTransparent(transparent: boolean) {
    if (!this.hasSurfaces()) {
      return;
    }
    this.lcv.setFilterSurfacesTransparency(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      transparent ? 1 : 0,
    );
  }

  /**
   * Set the color of all surfaces in the filter.
   * @param color The color to set the surfaces to.
   */
  setAllSurfacesColor(color: RgbColor) {
    if (!this.hasSurfaces()) {
      return;
    }
    this.lcv.setFilterSurfacesColor(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      color,
    );
  }

  /**
   * Sets the visibility of a specific surface in the filter.
   * @param surfaceIndex The index of the surface to set the visibility of.
   * @param visible Whether the surface should be visible.
   */
  private setSurfaceVisible(surfaceIndex: number, visible: boolean) {
    if (!this.hasSurfaces()) {
      return;
    }
    this.lcv.setSurfaceVisibility(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      surfaceIndex,
      visible ? 1 : 0,
    );
  }

  private setLineVisible(surfaceIndex: number, visible: boolean) {
    if (!this.hasSurfaces()) {
      return;
    }
    this.lcv.setSurfaceLineVisibility(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      surfaceIndex,
      visible ? 1 : 0,
    );
  }

  /**
   * Sets the color of the line of a specific surface in the filter.
   * @param surfaceIndex The index of the surface to set the line color of.
   * @param color The color to set the line to.
   */
  setLineColor(surfaceIndex: number, color: RgbColor) {
    this.lcv.setSurfaceLineColor(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      surfaceIndex,
      color,
    );
  }

  /**
   * Sets the color of all lines in the filter.
   * @param color The color to set the lines to.
   */
  setAllSurfaceLinesColor(color: RgbColor) {
    for (let i = 0; i < this.getNumSurfaces(); i += 1) {
      this.setLineColor(i, color);
    }
  }

  /**
   * Sets the transparency of a specific surface in the filter.
   * @param surfaceIndex The index of the surface to set the transparency of.
   * @param transparent Whether the surface should be transparent.
   */
  setSurfaceTransparent(surfaceIndex: number, transparent: boolean) {
    if (!this.hasSurfaces()) {
      return;
    }
    this.lcv.setSurfaceTransparency(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      surfaceIndex,
      transparent ? 1 : 0,
    );
  }

  /**
   * Sets the color of a specific surface in the filter.
   * @param surfaceIndex The index of the surface to set the color of.
   * @param color The color to set the surface to.
   */
  setSurfaceColor(surfaceIndex: number, color: RgbColor) {
    if (!this.hasSurfaces()) {
      return;
    }
    this.lcv.setSurfaceColor(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      surfaceIndex,
      color,
    );
  }

  /**
   * Filters may be represented with surfaces, lines, or both. This function returns whether the
   * specified surface, or its lines, are visible.
   *
   * @param surfaceIndex The index of the surface to check the visibility of.
   * @returns Whether the surface or its lines are visible.
   */
  getSurfaceOrLineVisibility(surfaceIndex: number): boolean {
    return this.getSurfaceVisibility(surfaceIndex) || this.getSurfaceLineVisibility(surfaceIndex);
  }

  /**
   * Gets the visibility of a specific surface in the filter.
   * @param surfaceIndex The index of the surface to get the visibility of.
   */
  private getSurfaceVisibility(surfaceIndex: number): boolean {
    if (!this.hasSurfaces()) {
      return false;
    }
    return !!this.lcv.getSurfaceVisibility(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      surfaceIndex,
      0,
    ).visibility;
  }

  private getSurfaceLineVisibility(surfaceIndex: number): boolean {
    if (!this.hasSurfaces()) {
      return false;
    }
    return !!this.lcv.getSurfaceLineVisibility(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      surfaceIndex,
      0,
    ).visibility;
  }

  /**
   * Gets the transparency of a specific surface in the filter.
   * @param surfaceIndex The index of the surface to get the transparency of.
   */
  getSurfaceTransparency(surfaceIndex: number): boolean {
    if (!this.hasSurfaces()) {
      return false;
    }
    return !!this.lcv.getSurfaceTransparency(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      surfaceIndex,
      0,
    ).transparency;
  }

  /**
   * Gets the color of a specific surface in the filter.
   * @param surfaceIndex The index of the surface to get the color of.
   */
  getSurfaceColor(surfaceIndex: number): RgbColor {
    if (!this.hasSurfaces()) {
      return [0, 0, 0];
    }
    return this.lcv.getSurfaceColor(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      surfaceIndex,
      0,
    ).color;
  }

  /**
   * Gets the name of a specific surface in the filter.
   * @param surfaceIndex The index of the surface to get the name of.
   */
  getSurfaceName(surfaceIndex: number): string {
    if (!this.hasSurfaces()) {
      return '';
    }
    return this.lcv.getSurfaceName(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      surfaceIndex,
      0,
    ).name;
  }

  getNumSurfaces(): number {
    if (isTestingEnv()) {
      return 0;
    }
    return this.lcv.getFilterNumSurfaces(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      0,
    ).num_surfaces;
  }

  hasSurfaces() {
    return this.getNumSurfaces() > 0;
  }

  /**
   * Set the shading mode of the filter to either flat or smooth shading.
   * Flat shading is good for visualizing the tesselation, but smooth shading is good for showing
   * curves.
   * @param flat Whether the shading mode should be flat.
   */
  setFlatShading(flat: boolean) {
    if (!this.hasSurfaces()) {
      return;
    }
    this.lcv.setFilterShadingMode(
      this.sessionHandle,
      this.workspaceHandle,
      this.handle,
      flat ? 1 : 0,
    );
  }
}
