// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { ParamGroupName, paramGroupDesc } from '../../../../../SimulationParamDescriptor';
import assert from '../../../../../lib/assert';
import { NodeType } from '../../../../../lib/simulationTree/node';
import { useHeatSource } from '../../../../../model/hooks/useHeatSource';
import * as simulationpb from '../../../../../proto/client/simulation_pb';
import { useSimulationParamScope } from '../../../../../state/external/project/simulation/paramScope';
import { CollapsibleNodePanel } from '../../../../Panel/CollapsibleNodePanel';
import { ParamForm } from '../../../../ParamForm';
import Divider from '../../../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../../../Theme/commonStyles';
import { useProjectContext } from '../../../../context/ProjectContext';
import { useSelectionContext } from '../../../../context/SelectionManager';
import { useHeatSourceVolumes } from '../../../../hooks/subselect/useHeatSourceVolumes';
import { HeatRemoveParams, useHeatSourceTabularData } from '../../../../hooks/useTabularData';
import { SectionMessage } from '../../../../notification/SectionMessage';
import { NodeSubselect } from '../../../NodeSubselect';
import { HelperButton } from '../../../NodeSubselect/HelperButton';
import PropertiesSection from '../../../PropertiesSection';

export const removeParams = [
  'HeatSourceZoneIds',
  ...HeatRemoveParams,
];

const paramGroup = paramGroupDesc[ParamGroupName.HeatSource];

export const PhysicsHeatHeatSourcePropPanel = () => {
  // == Contexts
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { selectedNode: node } = useSelectionContext();
  assert(!!node, 'No selected heat source row');

  // == Recoil
  const paramScope = useSimulationParamScope(projectId, workflowId, jobId);

  // == Model hooks
  const {
    getParamScope,
    heatSource,
    replaceHeatSource,
    volumes, // volumes assigned to the heat source's parent physics
    heatSourceVolumeIds,
  } = useHeatSource(projectId, workflowId, jobId, readOnly, node.id);
  assert(!!heatSource, 'No selected heat source');

  // == Other hooks
  const commonClasses = useCommonTreePropsStyles();
  const { insertTabularElements } = useHeatSourceTabularData(node.id);
  const { nodeFilter, setAssignedVolumeNodeIds } = useHeatSourceVolumes(node.id);

  // == Data
  const scope = getParamScope(paramScope);

  return (
    <div className={commonClasses.properties} style={{ position: 'relative' }}>
      <PropertiesSection>
        <CollapsibleNodePanel
          heading="Definition"
          nodeId={node.id}
          panelName="definition">
          <ParamForm<simulationpb.HeatSource>
            group={paramGroup}
            insertElement={insertTabularElements}
            onUpdate={replaceHeatSource}
            paramScope={scope}
            proto={heatSource}
            readOnly={readOnly}
            removeParams={removeParams}
          />
        </CollapsibleNodePanel>
      </PropertiesSection>
      <Divider />
      <PropertiesSection>
        <CollapsibleNodePanel
          headerRight={
            readOnly ?
              undefined :
              (
                <div className={commonClasses.panelHeaderButtons}>
                  <HelperButton
                    disabled={!heatSourceVolumeIds.length}
                    label="Clear All"
                    onClick={() => setAssignedVolumeNodeIds([])}
                    title={heatSourceVolumeIds.length ? 'Clear volume assignments' : ''}
                  />
                </div>
              )
          }
          heading="Volumes"
          nodeId={node.id}
          panelName="volumes">
          {volumes.length ? (
            <NodeSubselect
              disableClearAll
              id="heat-sources-volumes"
              labels={['Heat Sources']}
              nodeFilter={nodeFilter}
              nodeIds={heatSourceVolumeIds}
              onChange={setAssignedVolumeNodeIds}
              readOnly={readOnly}
              referenceNodeIds={[node.id]}
              visibleTreeNodeTypes={[NodeType.VOLUME]}
            />
          ) : (
            <SectionMessage level="info">
              No volumes have been assigned to this physics
            </SectionMessage>
          )}
          <div />
        </CollapsibleNodePanel>
      </PropertiesSection>
    </div>
  );
};
