// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { amrInterval, amrRefinements, logrithmicInterpolation } from '../../lib/amrUtils';
import { MESH_MAX_CELLS } from '../../lib/constants';
import newInt from '../../lib/intUtils';
import { convertBLParamsToAdaptationBLParams, deleteMeshAPI, meshAggregateStats, renameExistingMesh } from '../../lib/mesh';
import { fromBigInt } from '../../lib/number';
import { getOrCreateAdaptiveMeshRefinement } from '../../lib/simulationParamUtils';
import {
  defaultAdaptationBoundaryLayerParams,
  defaultBoundaryLayerParams,
  defaultMeshComplexityParams,
  defaultMeshingMode,
  defaultModelParams,
} from '../../lib/simulationUtils';
import { MeshingMethod } from '../../proto/client/simulation_pb';
import * as meshgenerationpb from '../../proto/meshgeneration/meshgeneration_pb';
import * as projectstatepb from '../../proto/projectstate/projectstate_pb';
import { meshUrlState } from '../../recoil/meshState';
import { useCadMetadata } from '../../recoil/useCadMetadata';
import { DEFAULT_MESH_NAME, useSetMeshName } from '../../recoil/useMeshName';
import { MeshPanelType, meshPanelStateAtom } from '../../recoil/useMeshPanelState';
import useMeshMultiPart, { defaultVolumeParams, useSetMeshMultiPart } from '../../recoil/useMeshingMultiPart';
import { projectActiveMeshSelector, useProjectActiveMesh, useProjectActiveMeshRefresher } from '../../recoil/useProjectActiveMesh';
import { useProjectActiveMeshMetadata } from '../../recoil/useProjectActiveMeshMetadata';
import { useRefetchProjectMeshList, useSetProjectMeshList } from '../../recoil/useProjectMeshList';
import { useSetRefinementRegionVisibility } from '../../recoil/useRefinementRegions';
import { useStoppingConditions } from '../../recoil/useStoppingConditions';
import { useSimulationParam } from '../../state/external/project/simulation/param';
import { useProjectContext } from '../context/ProjectContext';

import { useHandleMeshSelect } from './useHandleMeshSelect';
import { useSimulationConfig } from './useSimulationConfig';

const MINIMAL_MODE = meshgenerationpb.MeshingMultiPart_MeshComplexityParams_ComplexityType.MIN;

// When editing meshes, remove the currently active mesh and switch panel state to EDIT
export const useSetAppToMeshEditMode = (projectId: string) => {
  const setMeshUrlState = useSetRecoilState(meshUrlState(projectId));
  const setMeshPanelState = useSetRecoilState(meshPanelStateAtom(projectId));
  const setRefinementRegionVisibility = useSetRefinementRegionVisibility(projectId);
  const multi = useMeshMultiPart(projectId, '', '');
  const refinementRegionIds = multi?.refinementParams.map((region) => region.id);

  function setAppToEditMode() {
    setMeshPanelState(MeshPanelType.EDIT);
    setMeshUrlState((oldMeshUrl) => {
      const newMeshUrl = oldMeshUrl.clone();

      newMeshUrl.activeType = projectstatepb.UrlType.GEOMETRY;
      newMeshUrl.mesh = '';
      newMeshUrl.meshId = '';

      return newMeshUrl;
    });
    // Refinement regions are hidden when a mesh is selected. If we are modifying the mesh we want
    // to show them again.
    setRefinementRegionVisibility((oldVis) => {
      const newVis = { ...oldVis };
      refinementRegionIds?.forEach((id) => {
        newVis[id] = true;
      });
      return newVis;
    });
  }
  return { setAppToEditMode };
};

export const useSetupNewMeshWorkflow = (projectId: string) => {
  const { setAppToEditMode } = useSetAppToMeshEditMode(projectId);
  const setMeshName = useSetMeshName(projectId);
  const [cadMetadata] = useCadMetadata(projectId);
  const setMeshingMultiPartState = useSetMeshMultiPart(projectId);
  const { saveParam, simParam } = useSimulationConfig();

  const setupNewMeshWorkflow = () => {
    setAppToEditMode();
    setMeshName(DEFAULT_MESH_NAME);

    // Turn off LMA
    saveParam((param) => {
      if (param.adaptiveMeshRefinement) {
        param.adaptiveMeshRefinement.meshingMethod = MeshingMethod.MESH_METHOD_MANUAL;
      }
    });

    const newMeshMultiPart = new meshgenerationpb.MeshingMultiPart({
      volumeParams: [defaultVolumeParams(cadMetadata)],
      modelParams: [defaultModelParams(cadMetadata, simParam)],
      blParams: [defaultBoundaryLayerParams(simParam)],
      complexityParams: defaultMeshComplexityParams(),
      meshingMode: defaultMeshingMode(),
    });
    setMeshingMultiPartState(newMeshMultiPart);
  };

  return { setupNewMeshWorkflow };
};

export const useSetupNewLMAWorkflow = (projectId: string) => {
  const { setAppToEditMode } = useSetAppToMeshEditMode(projectId);
  const setMeshName = useSetMeshName(projectId);
  const [cadMetadata] = useCadMetadata(projectId);
  const setMeshingMultiPartState = useSetMeshMultiPart(projectId);
  const { simParam, saveParam } = useSimulationConfig();

  const setupNewLMAWorkflow = () => {
    setMeshName('Minimal Mesh');

    // Turn on LMA
    saveParam((param) => {
      const amr = getOrCreateAdaptiveMeshRefinement(param);
      amr.meshingMethod = MeshingMethod.MESH_METHOD_AUTO;
      amr.targetCvMillions = newInt(10);
      amr.boundaryLayerProfile = [defaultAdaptationBoundaryLayerParams(param)];
    });

    // Set default meshing multi part params
    const newMeshMultiPart = new meshgenerationpb.MeshingMultiPart({
      volumeParams: [defaultVolumeParams(cadMetadata)],
      modelParams: [defaultModelParams(cadMetadata, simParam)],
      blParams: [defaultBoundaryLayerParams(simParam)],
      complexityParams: {
        ...defaultMeshComplexityParams(),
        type: MINIMAL_MODE,
        limitMaxCells: BigInt(MESH_MAX_CELLS),
        targetCells: BigInt(0),
      },
      meshingMode: {
        mode: {
          case: 'base',
          value: new meshgenerationpb.MeshingMultiPart_MeshingMode_Base(),
        },
      },
    });
    setMeshingMultiPartState(newMeshMultiPart);

    setAppToEditMode();
  };

  return { setupNewLMAWorkflow };
};

/**
   * Updates the current mesh name, then updates the meshList state in recoil
   * @param newName New mesh name
   */
export function useRenameMesh() {
  const { projectId, workflowId, jobId } = useProjectContext();
  const setList = useSetProjectMeshList(projectId);
  const mesh = useRecoilValue(projectActiveMeshSelector({ projectId, workflowId, jobId }));
  const refreshActiveMesh = useProjectActiveMeshRefresher({ projectId, workflowId, jobId });

  const renameMesh = async (meshId: string, newName: string) => {
    const newMesh = await renameExistingMesh(meshId, newName);
    if (newMesh) {
      setList((currList) => currList.map((item) => (item.id === meshId ? newMesh : item)));
    }
    // If we are editing the currently selected mesh, we need to refresh it.
    if (meshId === mesh?.id) {
      refreshActiveMesh();
    }
  };

  return { renameMesh };
}

export const useIsLMAActive = () => {
  const { projectId, workflowId, jobId } = useProjectContext();

  const simParam = useSimulationParam(projectId, workflowId, jobId);

  const meshMethod = simParam?.adaptiveMeshRefinement?.meshingMethod;
  return meshMethod === MeshingMethod.MESH_METHOD_AUTO;
};

export const useIsMinimalMeshMode = (projectId: string) => {
  const multiPart = useMeshMultiPart(projectId, '', '');

  return multiPart?.complexityParams?.type === MINIMAL_MODE;
};

// TODO: Rename this hook to be more specific
export const useSetLMA = () => {
  const { projectId } = useProjectContext();
  const { saveParam } = useSimulationConfig();
  const meshMultiPart = useMeshMultiPart(projectId, '', '');

  const setLMA = (isActive: boolean) => {
    saveParam((newParam) => {
      const meshingMethod = isActive ?
        MeshingMethod.MESH_METHOD_AUTO :
        MeshingMethod.MESH_METHOD_MANUAL;
      const amr = getOrCreateAdaptiveMeshRefinement(newParam);
      amr.meshingMethod = meshingMethod;
      if (isActive) {
        // If the current mesh has boundary layer params, convert them to adaptation boundary
        // layers and set them.
        if (meshMultiPart?.blParams.length) {
          const newABLs = convertBLParamsToAdaptationBLParams(meshMultiPart?.blParams);
          amr.boundaryLayerProfile = newABLs;
        } else {
          // This should not happen, but if the selected mesh has no BLs, assign default ABL.
          amr.boundaryLayerProfile = [defaultAdaptationBoundaryLayerParams(newParam)];
        }
      }
    });
  };

  return { setLMA };
};

export const useGetMeshRefinementCount = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const meshMetadata = useProjectActiveMeshMetadata(projectId);

  const initialCVCount = meshAggregateStats(meshMetadata).counters.controlVolume;
  const finalAmrCount = fromBigInt(simParam.adaptiveMeshRefinement!.targetCvMillions!.value) *
    1_000_000;

  const getMeshRefinementCount = () => amrRefinements(initialCVCount, finalAmrCount);

  return { getMeshRefinementCount };
};

interface AMRScheduleItem {
  iteration: number;
  name: string;
  size: number;
}

export const useGetAMRSchedule = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const simParam = useSimulationParam(projectId, workflowId, jobId);
  const meshMetadata = useProjectActiveMeshMetadata(projectId);
  const [stopConds] = useStoppingConditions(projectId, workflowId, jobId);

  const initialCount = meshAggregateStats(meshMetadata).counters.controlVolume;
  const finalCount = fromBigInt(simParam.adaptiveMeshRefinement!.targetCvMillions!.value) *
    1_000_000;

  const getAMRSchedule = () => {
    const refinements = amrRefinements(initialCount, finalCount);
    const iterations = stopConds.maxIterations;

    const interval = amrInterval(refinements, iterations);
    const schedule: AMRScheduleItem[] = [];

    for (let i = 0; i < refinements; i += 1) {
      const iteration = Math.round(i * interval);
      let name = `Mesh ${i}`;
      if (i === 0) {
        name = 'Base Mesh';
      } else if (i === refinements - 1) {
        name = 'Final Mesh';
      }
      const size = logrithmicInterpolation(i, refinements, initialCount, finalCount);
      schedule.push({ iteration, name, size });
    }
    return schedule;
  };

  return { getAMRSchedule };
};

export const useDeleteMesh = () => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const setList = useSetProjectMeshList(projectId);
  const activeMesh = useProjectActiveMesh(projectId, workflowId, jobId);
  const { handleMeshSelect } = useHandleMeshSelect();
  const { refetchMeshList } = useRefetchProjectMeshList(projectId);

  const deleteMesh = async (meshId: string) => {
    // If we are deleting the selected mesh, unselect it first
    if (activeMesh?.id === meshId) {
      await handleMeshSelect();
    }
    setList((currList) => currList.filter((mesh) => mesh.id !== meshId));

    // If deleteMesh fails, refetch the mesh list to ensure it is up to date
    await deleteMeshAPI(meshId).catch(refetchMeshList);
  };

  const deleteMeshCallback = useCallback(
    deleteMesh,
    [activeMesh, handleMeshSelect, setList, refetchMeshList],
  );

  return deleteMeshCallback;
};
