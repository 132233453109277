// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement, useEffect, useRef, useState } from 'react';

import cx from 'classnames';

import PaneCollapseToggle from '../components/Pane/PaneCollapseToggle';
import TabPanel from '../components/Pane/TabPanel';
import Paraview from '../components/Paraview';
import ParaviewManager from '../components/Paraview/ParaviewManager';
import suspenseWidget from '../components/SuspenseWidget';
import { createStyles, makeStyles } from '../components/Theme';
import { TOOLBAR_HEIGHT } from '../components/Toolbar/Toolbar';
import { useProjectContext } from '../components/context/ProjectContext';
import SelectionManager from '../components/context/SelectionManager';
import { GeoDisconnectedOverlay } from '../components/layout/PageBodyOverlay';
import { Resizable } from '../components/layout/Resizable';
import { InfoFooter } from '../components/layout/footer/InfoFooter';
import { LcVisManager } from '../components/lcvis/LcVisManager';
import WorkOrderManager from '../components/meshing/WorkOrderManager';
import DragAndDropFiles from '../components/project/DragAndDropFiles';
import ExplorationSelection from '../components/project/ExplorationSelection';
import RightPaneTopControls from '../components/project/RightPaneTopControls';
import SimulationSettings from '../components/project/SimulationSettings';
import { SideRail } from '../components/project/assistant/SideRail';
import SensitivityAnalysisResults from '../components/project/sensitivityAnalysis/Results';
import * as flags from '../flags';
import { colors } from '../lib/designSystem';
import { useSetupTabTrace } from '../lib/observability/hooks/useSetupTabTrace';
import { VIEWER_PADDING } from '../lib/visUtils';
import { isSensitivityAnalysis } from '../lib/workflowUtils';
import * as basepb from '../proto/base/base_pb';
import { useRpcGeometryState } from '../recoil/geometry/geometryState';
import { useLcVisEnabledValue } from '../recoil/lcvis/lcvisEnabledState';
import { useSelectedSolution } from '../recoil/selectedSolution';
import { useEnabledExperiments, useIsEnabled } from '../recoil/useExperimentConfig';
import useExplorationSet from '../recoil/useExplorationSet';
import { useMeshReadyState } from '../recoil/useMeshReadyState';
import { useControlPanelMode } from '../recoil/useProjectPage';
import { useWorkflowState } from '../recoil/workflowState';
import { useIsAnalysisView, useIsGeometryView, useIsSetupView } from '../state/internal/global/currentView';

const useStyles = makeStyles(
  () => createStyles({
    tabBar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      gap: '16px',
      overflow: 'hidden',
      width: '100%',
      borderBottom: `2px solid ${colors.neutral150}`,
    },
    paneSwitcher: {
      flex: '0 0 auto',
    },
    rootContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    mainWindow: {
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      overflow: 'hidden',
      position: 'relative',
    },
    mainParaview: {
      flex: '1 1 auto',
      overflow: 'hidden',
    },
    resizableContent: {
      width: '100%',
      height: '100%',
      flex: '0 0 auto',
    },
    rightPane: {
      display: 'flex',
      flexDirection: 'column',
    },
    controlPanelSplitter: {
      width: '1px',
      height: '100%',
      backgroundColor: colors.surfaceBackground,
      overflow: 'visible',
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: 'calc(100% + 8px)',
        height: '100%',
        backgroundColor: 'transparent',
      },
    },
    outputPanelContent: {
      width: '100%',
    },
    outputPanelSplitter: {
      height: '8px',
      width: '100%',
      backgroundColor: colors.surfaceMedium2,
      borderStyle: 'solid',
      borderColor: colors.surfaceDark3,
      borderWidth: '1px 0',
    },
    paraviewDisplayContainer: {
      // This puts the paraviewContainer (and any modals inside of it, like the color adjuster)
      // above the Resize's splitter and content.
      zIndex: 3,
    },
    paraviewDisplay: {
      position: 'relative',
      height: '100%',
    },
    paneCollapse: {
      position: 'absolute',
      right: 0,
      top: `${VIEWER_PADDING}px`,
      zIndex: 1,
    },
  }),
  { name: 'PageBody' },
);

export interface PageBodyProps {
  // Called when the user runs a simulation or an exploration.
  onRunSimulation: (isExploration: boolean) => Promise<void>;
  // Whether we are showing the results from an exploration.
  isExploration: boolean;
}

/**
 * The main body of a page containing several panes.
 */
const PageBody = (props: PageBodyProps) => {
  const { projectId, workflowId, jobId, geometryId } = useProjectContext();
  // Start the streaming RPC for geometry updates.
  useRpcGeometryState(projectId, geometryId);
  const workflow = useWorkflowState(projectId, workflowId);
  const solution = useSelectedSolution(projectId, workflowId, jobId);
  const [explorationSet] = useExplorationSet(projectId);
  const enabledExperiments = useEnabledExperiments();
  const lcVisEnabled = useLcVisEnabledValue(projectId);
  const isAnalysisView = useIsAnalysisView();
  const isGeometryView = useIsGeometryView();
  const isSetupView = useIsSetupView();
  const getModEnabled = useIsEnabled(flags.geoModifications);
  // TODO: Make this depend on the ai chat feature flag
  const aiChatEnabled = false;

  const readyState = useMeshReadyState(projectId, workflowId, jobId);

  // Layout
  const rootRef = useRef<HTMLDivElement>(null);
  const mainWindowRef = useRef<HTMLDivElement>(null);
  const paraviewWindowRef = useRef<HTMLDivElement>(null);
  const paraviewDisplayRef = useRef<HTMLDivElement>(null);

  const isSensitivity = props.isExploration && !!workflow && isSensitivityAnalysis(workflow);

  const classes = useStyles();

  // Set the initial mode to experiments for sensitivity analysis.
  const [controlPanelMode, setControlPanelMode] = useControlPanelMode();
  const [showRightPane, setShowRightPane] = useState(true);

  // Add traces for when the user is on the setup tab, with a Geometry vs a Mesh.
  useSetupTabTrace(projectId, workflowId, jobId);

  useEffect(() => {
    if (isGeometryView) {
      setControlPanelMode('geometry');
      return;
    }
    setControlPanelMode(isSensitivity ? 'exploration' : 'simulation');
  }, [isGeometryView, isSensitivity, setControlPanelMode]);

  if (isAnalysisView) {
    // If the workflow is completed and there is no solution yet,
    // we know that we are currently fetching data
    // TODO(bamo): we should have a better way of knowing if we're in fetching state --
    // this kind of reasoning about job status/solution value is brittle (previously caused
    // LC-6388).
    if (!workflow ||
      (workflow?.status?.typ === basepb.JobStatusType.Completed && !solution)) {
      return suspenseWidget;
    }
  }

  let centerPane: ReactElement | null = null;

  if (isGeometryView) {
    if (geometryId !== '') {
      centerPane = (
        <LcVisManager
          experimentConfig={enabledExperiments}
          key={`${projectId}${workflowId}${jobId}${geometryId}`}
        />
      );
    } else {
      centerPane = (
        <DragAndDropFiles
          experimentConfig={enabledExperiments}
          projectId={projectId}
        />
      );
    }
  } else if (readyState) {
    if (isSensitivity) {
      centerPane = (<SensitivityAnalysisResults />);
    } else {
      centerPane = lcVisEnabled ? (
        <LcVisManager
          experimentConfig={enabledExperiments}
          key={`${projectId}${workflowId}${jobId}`}
        />
      ) : (
        <Paraview />
      );
    }
    // Do not show the import dialog if the geo mod is enabled. The only way to import is from the
    // geometry tab when that flag is enabled to avoid additional uploads when a geometry already
    // exists.
  } else if (!getModEnabled) {
    centerPane = (
      <DragAndDropFiles
        experimentConfig={enabledExperiments}
        projectId={projectId}
      />
    );
  }

  return (
    <SelectionManager>
      <ParaviewManager>
        <div className="flexColumnLayout fullHeight">
          <div className="flexItem elastic">
            <div className={classes.rootContainer} ref={rootRef}>
              <div className={classes.mainWindow} ref={mainWindowRef}>
                <GeoDisconnectedOverlay />
                <div className={classes.mainParaview} ref={paraviewWindowRef}>
                  <div className="flexColumnLayout fullHeight">
                    {!isSetupView && !isGeometryView && (
                    <div className="flexItem">
                      <div className={classes.tabBar}>
                        <TabPanel />
                      </div>
                    </div>
                    )}
                    <div className={cx(classes.paraviewDisplayContainer, 'flexItem', 'elastic')}>
                      <div className={classes.paraviewDisplay} ref={paraviewDisplayRef}>
                        {centerPane}
                        {!isGeometryView && (
                        <div className={classes.paneCollapse}>
                          <PaneCollapseToggle
                            expanded={showRightPane}
                            setExpanded={setShowRightPane}
                          />
                        </div>
                        )}
                      </div>
                    </div>
                    <InfoFooter ref={paraviewWindowRef} />
                  </div>
                </div>
                {!isGeometryView && showRightPane && (
                // Hide the Simulation Tree when on the geometry page
                <Resizable
                  getDragInputs={() => ({ boundingNode: mainWindowRef.current ?? undefined })}
                  initialSize="20%"
                  maxSize={800}
                  minSize={268}
                  splitterContent={(
                    <div
                      className={classes.controlPanelSplitter}
                      data-locator="controlPanelSplitter"
                    />
                  )}
                  splitterPlacement="left">
                  <div className={cx(classes.resizableContent, classes.rightPane)}>
                    <RightPaneTopControls height={TOOLBAR_HEIGHT} />
                    {controlPanelMode === 'exploration' && explorationSet.index < 0 ? (
                      <ExplorationSelection />
                    ) : (
                      <SimulationSettings onRunSimulation={props.onRunSimulation} />
                    )}
                  </div>
                </Resizable>
                )}
                {aiChatEnabled && <SideRail />}
              </div>
            </div>
          </div>
        </div>
        {!isGeometryView && <WorkOrderManager projectId={projectId} />}
      </ParaviewManager>
    </SelectionManager>
  );
};

export default PageBody;
