// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { Suspense, useEffect, useRef } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';

import ProjectStateSync from '../components/RecoilSync/ProjectState';
import suspenseWidget from '../components/SuspenseWidget';
import Project from '../components/context/ProjectContext';
import { MainPageLayout } from '../components/layout/page/Main';
import * as flags from '../flags';
import WorkflowConfigValidator, { formatValidatorMessage } from '../lib/WorkflowConfigValidator';
import { CurrentView } from '../lib/componentTypes/context';
import { EntityGroupMap } from '../lib/entityGroupMap';
import { convertToProto } from '../lib/entityGroupUtils';
import { geometryLink, resultsLink, workflowLink } from '../lib/navigation';
import { Logger } from '../lib/observability/logs';
import * as persist from '../lib/persist';
import { updateExploration } from '../lib/proto';
import * as rpc from '../lib/rpc';
import { getOrCreateConvergenceCriteria, getSimulationParam } from '../lib/simulationParamUtils';
import { updateStoppingConds } from '../lib/stoppingCondsUtils';
import {
  PARAM_VALIDATION_ERROR_NOTIFICATION_ID,
  addError,
  addRpcError,
  setNotification,
} from '../lib/transientNotification';
import * as basepb from '../proto/base/base_pb';
import * as explorationpb from '../proto/exploration/exploration_pb';
import * as frontendpb from '../proto/frontend/frontend_pb';
import { GetProjectReply } from '../proto/frontend/frontend_pb';
import * as feoutputpb from '../proto/frontend/output/output_pb';
import { Code } from '../proto/lcstatus/codes_pb';
import * as projectstatepb from '../proto/projectstate/projectstate_pb';
import * as workflowpb from '../proto/workflow/workflow_pb';
import { useLoadCameraList } from '../recoil/cameraState';
import {
  entityGroupDataSelector,
  entityGroupPrefix,
  serialize as serializeEntityGroups,
} from '../recoil/entityGroupState';
import {
  frontendMenuState,
  frontendMenuStatePrefix,
  serialize as serializeFrontendMenu,
} from '../recoil/frontendMenuState';
import { meshMetadataSelector, meshUrlState } from '../recoil/meshState';
import { outputNodesPrefix, outputNodesState, serialize as serializeOutputs } from '../recoil/outputNodes';
import { geometryPendingState } from '../recoil/pendingWorkOrders';
import { selectedGeometryState } from '../recoil/selectedGeometry';
import { useIsEnabled } from '../recoil/useExperimentConfig';
import useProjectMetadata from '../recoil/useProjectMetadata';
import {
  serialize as serializeStopConds,
  stoppingConditionsPrefix,
  stoppingConditionsSelectorUpdate,
} from '../recoil/useStoppingConditions';
import { currentConfigSelector } from '../recoil/workflowConfig';
import { useSetCurrentView } from '../state/internal/global/currentView';

import PageBody from './PageBody';

const logger = new Logger('ProjectPage');

/**
 * Called when "Run Simulation" or "Run Exploration" button is pressed.
 */

const newWorkflow = async (
  projectId: string,
  config: workflowpb.Config,
  navigate: ReturnType<typeof useNavigate>,
  frontendState: projectstatepb.FrontendMenuState,
  stopConds: feoutputpb.StoppingConditions,
  outputs: feoutputpb.OutputNodes,
  entityGroups: EntityGroupMap,
  isExploration: boolean,
) => {
  const entityGroupMap = convertToProto(entityGroups);
  const param = getSimulationParam(config);

  const paramEntityMap = getOrCreateConvergenceCriteria(param).entityGroup;
  Object.entries(entityGroupMap.groups).forEach(([key, group]) => {
    paramEntityMap[key] = group;
  });
  const req = new frontendpb.NewWorkflowRequest({ projectId, config });
  try {
    const workflowReply = await rpc.client.newWorkflow(req);
    const workflowId = workflowReply.workflowId;
    const jobIds = workflowReply.jobId;
    logger.debug('created workflow', workflowId);

    const stateUpdates: Promise<any>[] = [];

    // Set all states in the kv store that are stored on per-job basis
    jobIds.forEach((jobId) => {
      const createState = (prefix: string, value: Uint8Array): persist.State => ({
        projectId,
        key: persist.getProjectStateKey(prefix, { projectId, workflowId, jobId }),
        value,
      });
      stateUpdates.push(
        ...persist.setStatesNow(
          [
            createState(frontendMenuStatePrefix, serializeFrontendMenu(frontendState)),
            createState(stoppingConditionsPrefix, serializeStopConds(stopConds)),
            createState(outputNodesPrefix, serializeOutputs(outputs)),
            createState(entityGroupPrefix, serializeEntityGroups(convertToProto(entityGroups))),
          ],
        ),
      );
    });

    // Wait for all promises to resolve
    await Promise.all(stateUpdates);

    if (isExploration) {
      if (config.exploration!.policy.case === 'sensitivityAnalysis') {
        navigate(workflowLink(projectId, workflowId, true));
      } else {
        navigate(resultsLink(projectId));
      }
    } else {
      navigate(workflowLink(projectId, workflowId, false));
    }
  } catch (err: any) {
    addRpcError('Error creating a new workflow', err);
  }
};

/**
 * The ValidateParam message usually contains stack trace lines like
 * frontendserver.go:3230: ValidateParam cc/fvm/validator/validator.cc:1499: <actual message>
 * formatValidateParamMessage only returns the message after all the trace lines.
 */
function formatValidateParamMessage(message: string) {
  const text = message.replace(/.*\.(?:go|cc):\d+:/g, '');
  return `One or more of your simulation parameters is invalid: ${text}`;
}

/**
 * Displays status of one project.
 */
const ProjectPage = () => {
  const params = useParams();
  const projectId = params.projectId || '';
  const projectMetadata = useProjectMetadata(projectId);
  const geoModEnabled = useIsEnabled(flags.geoModifications);
  const navigate = useNavigate();
  const loadCameraList = useLoadCameraList(projectId);
  const setCurrentView = useSetCurrentView();

  // At this point, projectMetadata may be null since its result comes from a streaming RPC. To
  // circumvent this, we will only assess the need to redirect to the different tabs once
  // projectMetadata !== null.
  const projectMetadataRef = useRef<GetProjectReply | null>(null);
  const handleNavigation = useRecoilCallback(
    ({ snapshot: { getPromise } }) => async () => {
      const mesh = await getPromise(meshUrlState(projectId));
      const selectedGeometry = await getPromise(selectedGeometryState(projectId));
      const hasImport = await getPromise(geometryPendingState(projectId));
      const hasInitiatedLoadToSetup = !!selectedGeometry.geometryId;
      const hasMeshGeo = !!mesh.mesh || !!mesh.geometry;
      const shouldRedirectToGeometry = !hasInitiatedLoadToSetup && !hasMeshGeo;
      if (geoModEnabled && shouldRedirectToGeometry && !hasImport) {
        navigate(geometryLink(projectId));
      } else {
        setCurrentView(CurrentView.SETUP);
      }
    },
  );

  // We should get a fresh state of the cameras everytime we open the project page because otherwise
  // we might get a stale data (it happens when we open a project and populate its camera recoil,
  // then update the global cameras in another project and return to the first project).
  useEffect(() => {
    if (projectId) {
      loadCameraList().catch((error) => { });
    }
  }, [projectId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // If user has not pushed geometry to the Setup tab, redirect to the Geometry tab. Note that
    // projectMetadata may be null and we don't want to rerun all of this redirects after changes
    // to the streamed projectMetadata values.
    if (projectMetadataRef.current === null && projectMetadata) {
      handleNavigation().catch((err) => console.error(err));
      projectMetadataRef.current = projectMetadata;
    }
  }, [setCurrentView, projectMetadata, handleNavigation]);

  const handleWorkflowCreate = useRecoilCallback(
    ({ snapshot: { getPromise } }) => async (isExploration: boolean) => {
      const meshUrl = await getPromise(meshUrlState(projectId));
      const metaUrl = meshUrl.mesh || meshUrl.geometry;
      const meshMetadata = await getPromise(meshMetadataSelector({ projectId, meshUrl: metaUrl }));
      const recoilKey = { projectId, workflowId: '', jobId: '' };

      const projectConfig = await getPromise(
        currentConfigSelector(recoilKey),
      );
      let runConfig = projectConfig.clone();
      // If we are not running an experiment, set experiment to baseline.
      if (!isExploration) {
        const baseline = new explorationpb.Exploration({
          policy: { case: 'baseline', value: new explorationpb.Baseline() },
        });
        runConfig = updateExploration(runConfig, baseline);
      }
      const curFrontendMenuState = await getPromise(
        frontendMenuState(recoilKey),
      );
      const outputNodes = await getPromise(outputNodesState(recoilKey));
      const stopConds = await getPromise(stoppingConditionsSelectorUpdate(recoilKey));

      // Make sure to use the computational mesh when running a simulation.
      const param = getSimulationParam(runConfig);
      param.input!.filename = metaUrl;
      if (!param.input?.url) {
        param.input!.url = metaUrl;
      }

      const entityGroupData = await getPromise(entityGroupDataSelector(recoilKey));

      // Set the stopping conditions in param
      if (runConfig.jobConfigTemplate) {
        runConfig.jobConfigTemplate.typ = {
          case: 'simulationParam',
          value: updateStoppingConds(stopConds, param, outputNodes, entityGroupData),
        };
      }

      const req = new frontendpb.ValidateParamRequest({
        projectId,
        simulationParam: getSimulationParam(runConfig),
      });
      const reply = await rpc.callRetry(
        'ValidateParam',
        rpc.client.validateParam,
        req,
      );

      // if the reply from ValidateParam contains an error message, a non-retriable error
      // occurred. Break before attempting to run the simulation.
      if (reply.errorMsg) {
        addError(formatValidateParamMessage(reply.errorMsg));
        return;
      }

      logger.info(`ValidateParamRequest answer:${JSON.stringify(reply)}`);

      // Delegate the workflow creation to the validator output. If no error is
      // reported, then the workflow can be created.
      const validator = new WorkflowConfigValidator(async (err: basepb.Status) => {
        if (err.code === Code.LC_OK) {
          await newWorkflow(
            projectId,
            runConfig,
            navigate,
            curFrontendMenuState,
            stopConds,
            outputNodes,
            entityGroupData.groupMap,
            isExploration,
          );
        } else {
          setNotification(
            PARAM_VALIDATION_ERROR_NOTIFICATION_ID,
            'error',
            formatValidatorMessage(err),
            err,
          );
          logger.warn(`Got validation result: ${err.toJsonString()}`);
        }
      });
      await validator.checkAsync(runConfig, meshMetadata!.meshMetadata);
    },
  );

  // Don't display the page until all the project data has loaded.
  const projectName = projectMetadata?.summary!.name || '';

  return (
    <MainPageLayout projectId={projectId} title={projectName}>
      <Suspense fallback={suspenseWidget}>
        {projectMetadata && (
          <ProjectStateSync projectId={projectId}>
            <Project
              projectId={projectId}
              selectedJobId=""
              workflowId="">
              <PageBody
                isExploration={false}
                onRunSimulation={handleWorkflowCreate}
              />
            </Project>
          </ProjectStateSync>
        )}
      </Suspense>
    </MainPageLayout>
  );
};

export default ProjectPage;
