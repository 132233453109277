// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import { Code } from '@connectrpc/connect';
import {
  atom,
  atomFamily,
  selectorFamily,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

import { pruneBoundaryConditions, upgradeMultiPhysicsPresets } from '../lib/configUpgradeUtils';
import { jobConfigFixture, singlePhysicsWorkflowConfigFixture } from '../lib/fixtures';
import { Logger } from '../lib/observability/logs';
import { DEFAULT_CONFIG, defaultConfig, reconcileEmptyInputUrl } from '../lib/paramDefaults/workflowConfig';
import * as persist from '../lib/persist';
import { syncProjectStateEffect } from '../lib/recoilSync';
import * as rpc from '../lib/rpc';
import * as status from '../lib/status';
import { isTestingEnv } from '../lib/testing/utils';
import { JobConfigRequest, JobSpec } from '../proto/frontend/frontend_pb';
import * as projectstatepb from '../proto/projectstate/projectstate_pb';
import * as workflowpb from '../proto/workflow/workflow_pb';

import { frontendMenuState } from './frontendMenuState';
import { onGeometryTabSelector } from './geometry/geometryState';
import { meshUrlState } from './meshState';

const logger = new Logger('workflowConfig');

export function serialize(val: workflowpb.Config): Uint8Array {
  return (val.toBinary());
}

function upgradeConfig(config: workflowpb.Config, frontendMenu: projectstatepb.FrontendMenuState) {
  pruneBoundaryConditions(config);
  upgradeMultiPhysicsPresets(config, frontendMenu);
}

/**
 * @deprecated The method should not be used. Replaced by `persist.getProjectStateKey`
 */
function legacyRecoilKey(meshUrl: string) {
  return `workflowConfig/meshUrl=${encodeURIComponent(meshUrl)}`;
}

export const projectConfigPrefix = 'workflowConfig';

type ConfigSelectorKey = {
  projectId: string;
  geometryUrl: string;
}

// Selects the current project config from the session state kv store.
export const projectConfigSelectorRpc = selectorFamily<
  workflowpb.Config | null,
  ConfigSelectorKey
>({
  key: 'projectConfigSelector',
  get: (key: ConfigSelectorKey) => () => persist.getProjectState(
    key.projectId,
    [
      persist.getProjectStateKey(
        projectConfigPrefix,
        { projectId: key.projectId, workflowId: '', jobId: '' },
      ),
      legacyRecoilKey(key.geometryUrl),
    ],
    (val: Uint8Array) => (val.length ? workflowpb.Config.fromBinary(val) : null),
  ),
  dangerouslyAllowMutability: true,
});

export const workflowConfigTestFixture = atom<workflowpb.Config>({
  key: 'workflowConfigTestFixture',
  default: singlePhysicsWorkflowConfigFixture().config,
  dangerouslyAllowMutability: true,
});

// Selector used for testing
const projectConfigSelectorTesting = selectorFamily<workflowpb.Config | null, ConfigSelectorKey>({
  key: 'workflowConfigSelector/testing',
  get: () => ({ get }) => get(workflowConfigTestFixture),
  dangerouslyAllowMutability: true,
});

const projectConfigSelector = isTestingEnv() ?
  projectConfigSelectorTesting : projectConfigSelectorRpc;

// State representing the current project config that can be modified by the user on the setup page.
// Always use 'currentConfigSelector' to get the Config!
export const projectConfigState = atomFamily<workflowpb.Config, string>({
  key: 'projectConfigState',
  default: selectorFamily<workflowpb.Config, string>({
    key: 'projectConfigState/Default',
    get: (projectId: string) => ({ get }) => {
      if (isTestingEnv()) {
        return get(workflowConfigTestFixture);
      }
      const meshUrl = get(meshUrlState(projectId));
      const sessionConfig = get(projectConfigSelector(
        { projectId, geometryUrl: meshUrl.geometry },
      ));
      const newConfig = sessionConfig?.clone();
      return reconcileEmptyInputUrl(newConfig, meshUrl) ||
        defaultConfig(meshUrl.mesh, null, meshUrl.meshId);
    },
    dangerouslyAllowMutability: true,
  }),
  effects: (projectId: string) => [
    syncProjectStateEffect(
      projectId,
      persist.getProjectStateKey(projectConfigPrefix, { projectId, workflowId: '', jobId: '' }),
      workflowpb.Config.fromBinary,
      serialize,
    ),
  ],
  // Protobuf objects mutate themselves even in get*.
  dangerouslyAllowMutability: true,
});

// Sends an rpc to get the config for a job
const jobConfigState = atomFamily<workflowpb.Config, persist.RecoilProjectKey>({
  key: 'jobConfig',
  default: isTestingEnv() ? jobConfigFixture() :
    selectorFamily<workflowpb.Config, persist.RecoilProjectKey>({
      key: 'jobConfig/Default',
      get: (key: persist.RecoilProjectKey) => async () => {
        const { projectId, workflowId, jobId } = key;
        const req = new JobConfigRequest({
          jobSpec: new JobSpec({ projectId, workflowId, jobId }),
        });
        try {
          const { config } = await rpc.callRetry('JobConfig', rpc.client.jobConfig, req);
          if (!config) {
            throw Error(`No config received for job ${jobId}`);
          }
          return config;
        } catch (err) {
          logger.error(`Failed to get job config for ${jobId}: ${err}`);
          const grpcErr = status.getGrpcError(err);
          if (!grpcErr) {
            throw Error(`could not parse grpc error ${status.stringifyError(err)}`);
          }
          if (grpcErr.code !== Code.NotFound) {
            throw Error(
              `could not obtain job ${jobId} configuration ${status.stringifyError(err)}`,
            );
          }
          return DEFAULT_CONFIG.clone();
        }
      },
      dangerouslyAllowMutability: true,
    }),
});

// Selector that returns the appropriate config depending on whether workfowId or jobId is
// empty (returns config from session state kv store) or not (returns read-only config from
// database).
export const currentConfigSelector = selectorFamily<workflowpb.Config, persist.RecoilProjectKey>({
  key: 'currentConfigSelector',
  get: (key: persist.RecoilProjectKey) => ({ get }) => {
    // No need for the config in the geometry mode.
    if (get(onGeometryTabSelector)) {
      return DEFAULT_CONFIG.clone();
    }
    const frontendMenu = get(frontendMenuState(key));
    const config = !key.workflowId || !key.jobId ?
      get(projectConfigState(key.projectId)) : get(jobConfigState(key));
    // This can happen temporarily when loading to setup and using sharing.
    if (config.jobConfigTemplate?.typ.value === undefined) {
      return DEFAULT_CONFIG.clone();
    }
    const newConfig = config.clone();
    upgradeConfig(newConfig, frontendMenu);
    return newConfig;
  },
  dangerouslyAllowMutability: true,
});

export const useCurrentConfig = (
  projectId: string,
  workflowId: string,
  jobId: string,
) => useRecoilValue(currentConfigSelector({ projectId, workflowId, jobId }));

export const useSetProjectConfig = (
  projectId: string,
) => useSetRecoilState(projectConfigState(projectId));

export const useResetProjectConfig = (
  projectId: string,
) => persist.resetSessionState(
  projectId,
  persist.getProjectStateKey(projectConfigPrefix, { projectId, workflowId: '', jobId: '' }),
  useResetRecoilState(projectConfigState(projectId)),
);
