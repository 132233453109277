// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { useLcVisEnabledValue } from '../../../recoil/lcvis/lcvisEnabledState';
import { useLcVisReadyValue } from '../../../recoil/lcvis/lcvisReadyState';
import useMeshMultiPart from '../../../recoil/useMeshingMultiPart';
import { useRefinementRegionVisibility } from '../../../recoil/useRefinementRegions';
import { useParaviewContext } from '../../Paraview/ParaviewManager';
import { useProjectContext } from '../../context/ProjectContext';
import { AddRefinementRegionButton } from '../SimulationNodeAddButtons';
import { IconSpec, TreeRow, VisibilityControl } from '../TreeRow';

const PRIMARY_ICON: IconSpec = { name: 'cubeOutline' };

export const RefinementRegionContainerTreeRow = (props: SimulationRowProps) => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const multiPartMeshing = useMeshMultiPart(projectId, workflowId, jobId);
  const [refinementRegionVis, setRefinementRegionVis] = useRefinementRegionVisibility(projectId);

  const lcvisEnabled = useLcVisEnabledValue(projectId);
  const lcvisReady = useLcVisReadyValue();
  const { viewState } = useParaviewContext();

  // these are the IDs on the current tab, since refinementRegionVis may include IDs from other mesh
  // IDs.
  const currentIds = useMemo(
    () => (multiPartMeshing?.refinementParams ?? []).map((region) => region.id),
    [multiPartMeshing],
  );

  const toggleAllVisibility = () => {
    setRefinementRegionVis((oldVis) => {
      const newVis = { ...oldVis };
      const hideAll = currentIds.some((id) => !!oldVis[id]);
      currentIds.forEach((id) => {
        newVis[id] = !hideAll;
      });
      return newVis;
    });
  };

  const allHidden = useMemo(() => currentIds.every((
    id,
  ) => refinementRegionVis[id] === false), [currentIds, refinementRegionVis]);

  const visibility: VisibilityControl = {
    show: !allHidden,
    disabled: lcvisEnabled ? !lcvisReady : !viewState,
    toggle: toggleAllVisibility,
  };

  return (
    <TreeRow
      {...props}
      addControl={<AddRefinementRegionButton />}
      primaryIcon={PRIMARY_ICON}
      visibility={currentIds.length ? visibility : undefined}
    />
  );
};
