// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useMemo } from 'react';

import { SimulationRowProps } from '../../../../lib/componentTypes/simulationTree';
import { colors } from '../../../../lib/designSystem';
import { BODY_FRAME_DESCRIPTION, findFrameById, frameInheritsMovement, isFrameMoving } from '../../../../lib/motionDataUtils';
import { deleteTreeNodeMenuItem, duplicateTreeNodeMenuItem } from '../../../../lib/treeUtils';
import { useCopyFrame } from '../../../hooks/nodeDuplication/useCopyFrame';
import { useNodeDeletion } from '../../../hooks/useNodeDeletion';
import { useNodeRenaming } from '../../../hooks/useNodeRenaming';
import { useSimulationConfig } from '../../../hooks/useSimulationConfig';
import { ContextMenuSection, IconSpec, TreeRow } from '../../TreeRow';

const PRIMARY_ICON: IconSpec = { name: 'triAxes' };

export const MotionFrameTreeRow = (props: SimulationRowProps) => {
  // == Props
  const { node } = props;

  // == Hooks
  const renaming = useNodeRenaming(node);
  const { simParam } = useSimulationConfig();
  const { canDelete, deleteMotionFrameNode, postDeleteNodeIds } = useNodeDeletion();

  // == Data
  const frame = findFrameById(simParam, node.id);

  const auxIcons = useMemo(() => {
    const result: IconSpec[] = [];

    if (
      frame &&
      (isFrameMoving(frame) || frameInheritsMovement(simParam, frame))
    ) {
      result.push({
        name: 'rotatingDots',
        color: colors.citronGreen600,
      });
    }
    if (simParam.bodyFrame?.bodyFrameId === node.id) {
      result.push({
        name: 'airplane',
        color: colors.citronGreen600,
        tooltip: `This reference frame is selected as the Body Frame. ${BODY_FRAME_DESCRIPTION}`,
      });
    }
    return result;
  }, [frame, node.id, simParam]);

  const deleteRow = useCallback(async () => {
    if (await deleteMotionFrameNode(node.id)) {
      postDeleteNodeIds([node.id]);
    }
  }, [deleteMotionFrameNode, node.id, postDeleteNodeIds]);

  const duplicateRow = useCopyFrame();

  const getExtraContextMenuItems = useCallback(() => {
    const sections: ContextMenuSection[] = [];

    const disabled = !canDelete(node.type, node.id);
    const deleteItem = deleteTreeNodeMenuItem(deleteRow, disabled);
    const duplicateItem = duplicateTreeNodeMenuItem(() => duplicateRow(node.id), disabled);
    sections.push({ section: 'crud', menuItems: [duplicateItem, deleteItem] });

    return sections;
  }, [canDelete, deleteRow, node.id, node.type, duplicateRow]);

  return (
    <TreeRow
      {...props}
      auxIcons={auxIcons}
      getExtraContextMenuItems={getExtraContextMenuItems}
      primaryIcon={PRIMARY_ICON}
      renaming={renaming}
    />
  );
};
