// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, useAtom, useSetAtom } from 'jotai';

import { ControlPanelMode } from '../lib/componentTypes/controlPanel';

const controlPanelModeState = atom<ControlPanelMode>('simulation');

export function useControlPanelMode() {
  return useAtom(controlPanelModeState);
}

export function useIsBaselineMode() {
  const [controlPanelMode] = useControlPanelMode();
  return controlPanelMode === 'simulation';
}

const motionFrameToDeleteState = atom<string>('');

export function useMotionFrameToDelete() {
  return useAtom(motionFrameToDeleteState);
}

export function useSetMotionFrameToDelete() {
  return useSetAtom(motionFrameToDeleteState);
}
