// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import { ParamScope, chainParamScopes } from '../../lib/ParamScope';
import { appendFluidBoundaryCondition, getUnassignedSurfacesByPhysics } from '../../lib/boundaryConditionUtils';
import { getPhysicsDomainsWithoutUnroll } from '../../lib/entityRelationships';
import { applyFluidDiscretizationPreset } from '../../lib/physicsFluidDiscretizationPresets';
import {
  findFluidPhysicsMaterial,
  findPhysicsById,
  getFluid,
  getOrCreateFluidBasic,
  getOrCreateFluidSolutionControls,
  getOrCreateFluidSpatialDiscretization,
  getOrCreateTurbulence,
  getPhysicsId,
} from '../../lib/physicsUtils';
import { assignSurfacesToBoundaryCondition, updateMeshingFromBc } from '../../lib/simulationUtils';
import * as simulationpb from '../../proto/client/simulation_pb';
import { useEntityGroupMap } from '../../recoil/entityGroupState';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useEnabledExperiments } from '../../recoil/useExperimentConfig';
import { useSetMeshMultiPart } from '../../recoil/useMeshingMultiPart';
import { useStaticVolumes } from '../../recoil/volumes';

import { usePhysics } from './usePhysics';
import { useWorkflowConfig } from './useWorkflowConfig';

export const useFluidPhysics = (
  projectId: string,
  workflowId: string,
  jobId: string,
  readOnly: boolean,
  id: string, // Physics ID
) => {
  // == Recoil
  const experimentConfig = useEnabledExperiments();
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const setMeshMultiPart = useSetMeshMultiPart(projectId);
  const staticVolumes = useStaticVolumes(projectId);
  const geometryTags = useGeometryTags(projectId);

  const { saveParamAsync, simParam } = useWorkflowConfig(projectId, workflowId, jobId, readOnly);

  const {
    physics,
    hasVolumes,
    volumes,
    surfacesWithoutBoundaryConditions,
  } = usePhysics(projectId, workflowId, jobId, readOnly, id);
  const fluidPhysics = useMemo(() => (physics ? getFluid(physics) : undefined), [physics]);

  const spatialDiscretization = useMemo(
    () => fluidPhysics?.spatialDiscretizationFluid,
    [fluidPhysics],
  );

  const setSpatialDiscretization = useCallback(
    async (value: simulationpb.SpatialDiscretizationFluid) => saveParamAsync(
      (newParam) => {
        const newPhysics = findPhysicsById(newParam, id);
        const newFluid = newPhysics ? getFluid(newPhysics) : null;
        if (newFluid) {
          newFluid.spatialDiscretizationFluid = value;
        }
      },
    ),
    [id, saveParamAsync],
  );

  const spatialDiscretizationPreset = useMemo(
    () => spatialDiscretization?.spatialDiscretizationFluidPreset,
    [spatialDiscretization],
  );

  const setSpatialDiscretizationPreset = useCallback(
    async (value: simulationpb.SpatialDiscretizationFluidPreset) => saveParamAsync(
      (newParam) => {
        const newPhysics = findPhysicsById(newParam, id);
        const newFluid = newPhysics ? getFluid(newPhysics) : null;
        if (newFluid) {
          const spatialDisc = getOrCreateFluidSpatialDiscretization(newFluid);
          spatialDisc.spatialDiscretizationFluidPreset = value;
          applyFluidDiscretizationPreset(
            newPhysics!,
            newParam,
            experimentConfig,
            geometryTags,
            staticVolumes,
          );
        }
      },
    ),
    [experimentConfig, geometryTags, id, saveParamAsync, staticVolumes],
  );

  const solutionControls = useMemo(() => fluidPhysics?.solutionControlsFluid, [fluidPhysics]);

  const setSolutionControls = useCallback(
    async (value: simulationpb.SolutionControlsFluid) => saveParamAsync(
      (newParam) => {
        const newPhysics = findPhysicsById(newParam, id);
        const newFluid = newPhysics ? getFluid(newPhysics) : null;
        if (newFluid) {
          newFluid.solutionControlsFluid = value;
        }
      },
    ),
    [id, saveParamAsync],
  );

  const floatType = simParam?.general?.floatType;

  const adjointSolutionControls = useMemo(() => fluidPhysics?.adjointControlsFluid, [fluidPhysics]);

  const setAdjointSolutionControls = useCallback(
    async (value: simulationpb.AdjointControlsFluid) => saveParamAsync(
      (newParam) => {
        const newPhysics = findPhysicsById(newParam, id);
        const newFluid = newPhysics ? getFluid(newPhysics) : null;
        if (newFluid) {
          newFluid.adjointControlsFluid = value;
        }
      },
    ),
    [id, saveParamAsync],
  );

  const solutionControlsPreset = useMemo(
    () => solutionControls?.solutionControlsFluidPreset,
    [solutionControls],
  );

  const setSolutionControlsPreset = useCallback(
    async (value: simulationpb.SolutionControlsFluidPreset) => saveParamAsync(
      (newParam) => {
        const newPhysics = findPhysicsById(newParam, id);
        const newFluid = newPhysics ? getFluid(newPhysics) : null;
        if (newFluid) {
          const spatialDisc = getOrCreateFluidSolutionControls(newFluid);
          spatialDisc.solutionControlsFluidPreset = value;
        }
      },
    ),
    [id, saveParamAsync],
  );

  const material = useMemo(
    () => (physics ?
      findFluidPhysicsMaterial(simParam, physics, geometryTags, staticVolumes) : undefined),
    [physics, simParam, geometryTags, staticVolumes],
  );

  const disablePresetsReason = useMemo(() => {
    // Preset values depend on the associated fluid material.  If there isn't one, disable the
    // preset inputs with the given reason.
    if (physics) {
      if (!material) {
        if (!getPhysicsDomainsWithoutUnroll(simParam, getPhysicsId(physics)).size) {
          return 'Fluid physics requires volume selections assigned to the same fluid material ' +
            'before presets can be applied.';
        }
        return 'Fluid physics requires a material before presets can be applied.';
      }
    } else {
      return 'Unknown error with this physics';
    }
    return '';
  }, [material, physics, simParam]);

  const initialization = useMemo(
    () => fluidPhysics?.initializationFluid,
    [fluidPhysics],
  );

  const setInitialization = useCallback(
    async (value: simulationpb.InitializationFluid) => saveParamAsync(
      (newParam) => {
        const newPhysics = findPhysicsById(newParam, id);
        const newFluid = newPhysics ? getFluid(newPhysics) : null;
        if (newFluid) {
          newFluid.initializationFluid = value;
        }
      },
    ),
    [id, saveParamAsync],
  );

  const viscousModel = fluidPhysics?.basicFluid?.viscousModel;

  const setViscousModel = useCallback((newValue: simulationpb.ViscousModel) => saveParamAsync(
    (newParam) => {
      const newPhysics = findPhysicsById(newParam, id);
      const newFluid = newPhysics ? getFluid(newPhysics) : null;
      if (newFluid) {
        getOrCreateFluidBasic(newFluid).viscousModel = newValue;
      }
    },
  ), [id, saveParamAsync]);

  const turbulence = fluidPhysics?.turbulence;

  const setTurbulence = useCallback((value: simulationpb.Turbulence) => saveParamAsync(
    (newParam) => {
      const newPhysics = findPhysicsById(newParam, id);
      const newFluid = newPhysics ? getFluid(newPhysics) : null;
      if (newFluid) {
        newFluid.turbulence = value;
      }
    },
  ), [id, saveParamAsync]);

  const turbulenceModel = useMemo(() => turbulence?.turbulenceModel, [turbulence]);

  const setTurbulenceModel = useCallback((value: simulationpb.TurbulenceModel) => saveParamAsync(
    (newParam) => {
      const newPhysics = findPhysicsById(newParam, id);
      const newFluid = newPhysics ? getFluid(newPhysics) : null;
      if (newFluid) {
        getOrCreateTurbulence(newFluid).turbulenceModel = value;
      }
    },
  ), [id, saveParamAsync]);

  const subGridScaleModel = useMemo(() => turbulence?.subGridScaleModel, [turbulence]);

  const setSubGridScaleModel = useCallback(
    (value: simulationpb.SubGridScaleModel) => saveParamAsync((newParam) => {
      const newPhysics = findPhysicsById(newParam, id);
      const newFluid = newPhysics ? getFluid(newPhysics) : null;
      if (newFluid) {
        getOrCreateTurbulence(newFluid).subGridScaleModel = value;
      }
    }),
    [id, saveParamAsync],
  );

  const createFluidBoundaryConditionWithUnassignedSurfaces = useCallback(
    async (boundaryType: simulationpb.PhysicalBoundary) => saveParamAsync((newParam) => {
      const newBoundCond = appendFluidBoundaryCondition(newParam, id, boundaryType);
      assignSurfacesToBoundaryCondition(
        getUnassignedSurfacesByPhysics(newParam, id, geometryTags, staticVolumes),
        newBoundCond,
        newParam,
        geometryTags,
        staticVolumes,
        entityGroupMap,
      );
      updateMeshingFromBc(newParam, simParam, setMeshMultiPart);
      return newBoundCond.boundaryConditionName;
    }),
    [entityGroupMap, id, saveParamAsync, setMeshMultiPart, simParam, staticVolumes, geometryTags],
  );

  const getParamScope = useCallback(
    (paramScope: ParamScope) => chainParamScopes([physics, material], experimentConfig, paramScope),
    [experimentConfig, material, physics],
  );

  return {
    physics,
    fluidPhysics,
    hasVolumes,
    volumes,
    material,
    getParamScope,

    spatialDiscretization,
    setSpatialDiscretization,
    spatialDiscretizationPreset,
    setSpatialDiscretizationPreset,

    solutionControls,
    setSolutionControls,
    solutionControlsPreset,
    setSolutionControlsPreset,

    floatType,
    adjointSolutionControls,
    setAdjointSolutionControls,

    disablePresetsReason,

    initialization,
    setInitialization,

    viscousModel,
    setViscousModel,

    turbulence,
    setTurbulence,
    turbulenceModel,
    setTurbulenceModel,
    subGridScaleModel,
    setSubGridScaleModel,

    surfacesWithoutBoundaryConditions,
    createFluidBoundaryConditionWithUnassignedSurfaces,
  };
};
