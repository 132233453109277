// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useEffect } from 'react';

import { fromBigInt } from '../../lib/number';
import { Logger } from '../../lib/observability/logs';
import { getReferenceValues } from '../../lib/referenceValueUtils';
import * as rpc from '../../lib/rpc';
import { getOrCreateConvergenceCriteria, getSimulationParam } from '../../lib/simulationParamUtils';
import { addRpcError } from '../../lib/transientNotification';
import * as frontendpb from '../../proto/frontend/frontend_pb';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useOutputNodes } from '../../recoil/outputNodes';
import { useEnabledExperiments } from '../../recoil/useExperimentConfig';
import useProjectMetadata from '../../recoil/useProjectMetadata';
import { useStaticVolumes } from '../../recoil/volumes';
import { useWorkflowState } from '../../recoil/workflowState';
import { useStopCondReply } from '../../state/external/project/simulation/stoppingConditionReply';

const logger = new Logger('model/hooks/useStoppingConditionStatus');

export function useStoppingConditionStatus(projectId: string, workflowId: string, jobId: string) {
  // Hooks
  const experiments = useEnabledExperiments();
  const [outputNodes] = useOutputNodes(projectId, '', '');
  const workflow = useWorkflowState(projectId, workflowId);
  const [stopCondReply, setStopCondReply] = useStopCondReply(`${projectId}/${jobId}`);
  const projectName = useProjectMetadata(projectId)?.summary?.name;
  const geometryTags = useGeometryTags(projectId);
  const staticVolumes = useStaticVolumes(projectId);

  // Other props
  const job = workflow?.job[jobId];
  const iter = job?.latestIter;

  // Effects
  useEffect(() => {
    if (!jobId || !workflow || !iter) {
      return;
    }
    const param = getSimulationParam(workflow.config!);
    const refValues =
      getReferenceValues(outputNodes, param, experiments, geometryTags, staticVolumes);

    const req = new frontendpb.StoppingConditionRequest({
      jobId,
      projectId,
      referenceValues: refValues,
    });
    const convCriteria = getOrCreateConvergenceCriteria(param);
    const entitiesInOutputs = new Set<string>();
    convCriteria.stoppingCondition.forEach((oldCond) => {
      const cond = oldCond.clone();
      const initialEnd = cond.output?.range?.end!;
      // Only send the request if it is active
      if (iter >= initialEnd) {
        if (cond.output?.range) {
          cond.output.range.end = fromBigInt(iter);
        }
        req.condition.push(cond);
        cond.output!.inSurfaces.forEach((entity) => {
          entitiesInOutputs.add(entity);
        });
      }
    });

    rpc.callRetry('stoppingConditionStatus', rpc.client.stoppingConditionStatus, req).then(
      (reply: frontendpb.StoppingConditionReply) => {
        setStopCondReply(reply);
      },
    ).catch(
      (err: Error) => {
        addRpcError('Failed to get stopping condition status', err, { projectId, projectName });
        logger.warn(`rpc stoppingConditionStatus error: ${err}`);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, jobId, workflow, iter, geometryTags, staticVolumes]);
  return stopCondReply;
}
