// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import cx from 'classnames';

import { CurrentView } from '../../lib/componentTypes/context';
import { colors } from '../../lib/designSystem';
import { isUnmodifiedEnterKey } from '../../lib/event';
import { useSetNodeLinkState } from '../../recoil/useNodeLink';
import { TreeViewType, useTreeViewType } from '../../recoil/useTreeViewState';
import { useCurrentView } from '../../state/internal/global/currentView';
import { createStyles, makeStyles } from '../Theme';
import { CONTROL_HEIGHT } from '../Theme/commonStyles';
import { useGetNodeFromAnyTree } from '../hooks/useTree';

const useStyles = makeStyles(
  () => createStyles({
    link: {
      cursor: 'pointer',
      color: 'inherit',
      fontWeight: 'inherit',
      fontSize: 'inherit',
      outline: 'none',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'inline',
      lineHeight: `${CONTROL_HEIGHT}px`,
      '&.styled': {
        color: colors.highEmphasisText,
        fontWeight: 500,
        fontSize: '13px',
        textDecoration: 'underline',
      },
      '&.asBlock': {
        display: 'block',
      },
      '&.disabled': {
        cursor: 'auto',
        textDecoration: 'none',
      },
    },
  }),
  { name: 'NodeLink' },
);

export interface NodeLinkProps {
  // The node IDs to select when the link is clicked.
  nodeIds: string[];
  // The text of the link.
  text: string;
  unstyled?: boolean;
  // True if the link should be displayed as a block rather than inline
  asBlock?: boolean;
  disabled?: boolean;
}

// Displays a link to another node or set of nodes. When the link is clicked
// the nodes are selected.
export const NodeLink = (props: NodeLinkProps) => {
  // Props
  const { nodeIds, text, unstyled, asBlock, disabled } = props;

  // Hooks
  const classes = useStyles();
  const getNodeFromAnyTree = useGetNodeFromAnyTree();
  const setNodeLinkIds = useSetNodeLinkState();
  const [treeViewType, setTreeViewType] = useTreeViewType();
  const currentView = useCurrentView();

  // Handlers
  const select = () => {
    if (disabled) {
      return;
    }

    const nodeIsInTree = !!getNodeFromAnyTree(nodeIds[0]);
    // If we are in the Post-Processing view in the Analysis page, there's a chance that the
    // NodeLink is trying to link to a node that is not present in the current view and is only
    // available in the Setup Details view. If that seems to be the case, we should first open the
    // Setup Details view.
    if (
      currentView === CurrentView.ANALYSIS &&
      treeViewType === TreeViewType.POST_PROCESSING &&
      !nodeIsInTree
    ) {
      setTreeViewType(TreeViewType.SETUP);
    }

    setNodeLinkIds(nodeIds);
  };

  return (
    <div
      className={cx(classes.link, { styled: !unstyled }, { asBlock, disabled })}
      onClick={select}
      onKeyUp={(event) => isUnmodifiedEnterKey(event) && select()}
      role="link"
      tabIndex={0}>
      {text}
    </div>
  );
};

export default NodeLink;
