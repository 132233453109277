// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import * as ParaviewRpc from '../../pvproto/ParaviewRpc';

import DataComponentSelect from './DataComponentSelect';
import DataNameSelect from './DataNameSelect';
import { useParaviewContext } from './ParaviewManager';

const ResultsVariableSelect = () => {
  const {
    setViewStateDisplayVariable,
    viewState,
  } = useParaviewContext();

  if (!viewState?.data?.length || !viewState.attrs) {
    return null;
  }

  return (
    <>
      <DataNameSelect
        kind="minimal"
        onChange={(variableName: string) => {
          const newDisplayVariable: ParaviewRpc.DisplayPvVariable = {
            ...viewState!.attrs!.displayVariable!,
            displayDataName: variableName,
          };
          // displayDataNameComponent may be null when we are in the Mesh tab.
          if (!newDisplayVariable.displayDataNameComponent) {
            newDisplayVariable.displayDataNameComponent = 0;
          }
          setViewStateDisplayVariable(newDisplayVariable, false, null);
        }}
        options={viewState.data
          .filter(({ type }) => type === ParaviewRpc.FieldAssociation.POINT)
          .map(({ name }) => name)}
        size="medium"
        value={viewState.attrs.displayVariable?.displayDataName || 'None'}
      />
      {/* This dropdown is only rendered for display variables with multiple components
      (e.g. velocity vector) */}
      <DataComponentSelect
        kind="minimal"
        onChange={(component: number) => {
          const newDisplayVariable: ParaviewRpc.DisplayPvVariable = {
            ...viewState!.attrs!.displayVariable!,
            displayDataNameComponent: component,
          };
          setViewStateDisplayVariable(newDisplayVariable, true, null);
        }}
        size="medium"
        viewState={viewState}
      />
    </>
  );
};

export default ResultsVariableSelect;
