// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import { paramGroupDesc } from '../../SimulationParamDescriptor';
import * as simulationpb from '../../proto/client/simulation_pb';
import * as explorationpb from '../../proto/exploration/exploration_pb';
import * as projectstatepb from '../../proto/projectstate/projectstate_pb';
import * as workflowpb from '../../proto/workflow/workflow_pb';
import { EMPTY_GEOMETRY_TAGS } from '../../recoil/geometry/geometryTagsObject';
import { initParamGroupProto } from '../initParam';
import { newSimulationParam } from '../paramUtils';
import { applyAllFluidControlsPresets } from '../physicsFluidControlsPresets';
import { applyAllFluidDiscretizationPresets } from '../physicsFluidDiscretizationPresets';
import { applyAllHeatControlsPresets } from '../physicsHeatControlsPresets';
import { applyAllHeatDiscretizationPresets } from '../physicsHeatDiscretizationPresets';
import { getOrCreateFluidSolutionControls, getOrCreateFluidSpatialDiscretization, getOrCreateHeatSolutionControls, getOrCreateHeatSpatialDiscretization } from '../physicsUtils';
import { getSimulationParam, setInputMeshUrl, setMeshId } from '../simulationParamUtils';

export const DEFAULT_CONFIG: workflowpb.Config = ((): workflowpb.Config => new workflowpb.Config({
  exploration: new explorationpb.Exploration({
    policy: { case: 'baseline', value: new explorationpb.Baseline() },
  }),
  jobConfigTemplate: new workflowpb.JobConfig({
    typ: { case: 'simulationParam', value: newSimulationParam() },
  }),
}))();

const { DEFAULT_SOLUTION_CONTROLS_FLUID } = simulationpb.SolutionControlsFluidPreset;
const { DEFAULT_SPATIAL_DISCRETIZATION_FLUID } = simulationpb.SpatialDiscretizationFluidPreset;
const { DEFAULT_SOLUTION_CONTROLS_HEAT } = simulationpb.SolutionControlsHeatPreset;
const { DEFAULT_SPATIAL_DISCRETIZATION_HEAT } = simulationpb.SpatialDiscretizationHeatPreset;

// Applies the default presets to the param of a workflow config.
function applyDefaultPresets(config: workflowpb.Config) {
  const param = getSimulationParam(config);

  initParamGroupProto(param, paramGroupDesc.simulation_param);

  // The default presets active when a project is loaded should not and must not depend on the
  // active feature flags. Hence, do not activate any feature flag when applying presets on project
  // loading.
  const experimentConfig: string[] = [];

  param.physics.forEach((physics) => {
    if (physics.params.case === 'fluid') {
      const fluid = physics.params.value;
      const solnCtrl = getOrCreateFluidSolutionControls(fluid);
      const spatDisc = getOrCreateFluidSpatialDiscretization(fluid);
      solnCtrl.solutionControlsFluidPreset = DEFAULT_SOLUTION_CONTROLS_FLUID;
      spatDisc.spatialDiscretizationFluidPreset = DEFAULT_SPATIAL_DISCRETIZATION_FLUID;
    }
    if (physics.params.case === 'heat') {
      const heat = physics.params.value;
      const solnCtrl = getOrCreateHeatSolutionControls(heat);
      const spatDisc = getOrCreateHeatSpatialDiscretization(heat);
      solnCtrl.solutionControlsHeatPreset = DEFAULT_SOLUTION_CONTROLS_HEAT;
      spatDisc.spatialDiscretizationHeatPreset = DEFAULT_SPATIAL_DISCRETIZATION_HEAT;
    }
    applyAllFluidDiscretizationPresets(param, experimentConfig, EMPTY_GEOMETRY_TAGS, []);
    applyAllFluidControlsPresets(param, experimentConfig, EMPTY_GEOMETRY_TAGS, []);
    applyAllHeatDiscretizationPresets(param, experimentConfig);
    applyAllHeatControlsPresets(param, experimentConfig);
  });
}

// Set the input of the simulation param in a given workflow config to be consistent with
// the provided meshUrl.  Usually needed if the meshUrl changes when there are existing
// settings.
function setParamInput(
  config: workflowpb.Config,
  meshUrl: string,
  meshId: string | null,
) {
  const param = getSimulationParam(config);

  if (param.input) {
    param.input.url = meshUrl;
  }
  setMeshId(param.input, meshId ?? '');
}

// Create the default workflow config for a given mesh URL. Arg 'metadata' is to list the boundary
// conditions in the mesh.
export function defaultConfig(
  meshUrl: string,
  exploration: explorationpb.Exploration | null,
  meshId: string | null,
): workflowpb.Config {
  const config = DEFAULT_CONFIG.clone();
  if (exploration) {
    config.exploration = exploration;
  }

  // Apply the default presets when creating a new workflow configuration.
  applyDefaultPresets(config);
  // Apply the provided meshUrl
  setParamInput(config, meshUrl, meshId);

  return config;
}

export function reconcileEmptyInputUrl(
  config: workflowpb.Config | undefined,
  meshUrl: projectstatepb.MeshUrl | null,
) {
  if (!config || !meshUrl) {
    return config;
  }

  const jobConfig = config.jobConfigTemplate;
  if (jobConfig?.typ.case === 'simulationParam') {
    const input = jobConfig.typ.value.input;
    if (input && !input.url) {
      setInputMeshUrl(input, meshUrl.mesh);
      setMeshId(input, meshUrl.meshId);
    }
  }

  return config;
}
