// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useCallback, useMemo } from 'react';

import { ParamScope, chainParamScopes } from '../../lib/ParamScope';
import {
  findHeatSourceById,
  findParentPhysicsByHeatSourceId,
} from '../../lib/heatSourceUtils';
import { getHeat, getPhysicsId } from '../../lib/physicsUtils';
import { mapDomainsToIds } from '../../lib/volumeUtils';
import * as simulationpb from '../../proto/client/simulation_pb';
import { useEnabledExperiments } from '../../recoil/useExperimentConfig';
import { useStaticVolumes } from '../../recoil/volumes';

import { useHeatPhysics } from './useHeatPhysics';
import { useWorkflowConfig } from './useWorkflowConfig';

export const useHeatSource = (
  projectId: string,
  workflowId: string,
  jobId: string,
  readOnly: boolean,
  id: string, // Heat source ID
) => {
  // == Recoil
  const experimentConfig = useEnabledExperiments();
  const staticVolumes = useStaticVolumes(projectId);

  // == Custom hooks
  const { saveParamAsync, simParam } = useWorkflowConfig(projectId, workflowId, jobId, readOnly);

  // == Data
  const heatSource = useMemo(() => findHeatSourceById(simParam, id), [id, simParam]);
  const physics = useMemo(() => findParentPhysicsByHeatSourceId(simParam, id), [id, simParam]);
  const physicsId = useMemo(() => (physics ? getPhysicsId(physics) : undefined), [physics]);

  // == Model hooks
  const {
    volumes,
    disabledHeatSourceDomainReason,
    unassignedHeatSourceDomains,
    getParamScope: getPhysicsParamScope,
  } = useHeatPhysics(projectId, workflowId, jobId, readOnly, physicsId || '');

  const replaceHeatSource = useCallback(
    async (newSource: simulationpb.HeatSource) => saveParamAsync((newParam) => {
      const newPhysics = findParentPhysicsByHeatSourceId(newParam, id);
      const heat = newPhysics ? getHeat(newPhysics) : null;
      if (heat) {
        heat.heatSource = heat.heatSource.map((source, i) => {
          if (source.heatSourceId === id) {
            return newSource;
          }
          return source;
        });
      }
    }),
    [id, saveParamAsync],
  );

  const saveHeatSource = useCallback(
    async (update: (newSource: simulationpb.HeatSource) => void) => saveParamAsync(
      (newParam) => {
        const newSource = findHeatSourceById(newParam, id);
        if (newSource) {
          update(newSource);
        }
      },
    ),
    [id, saveParamAsync],
  );

  const heatSourceDomains = useMemo(
    () => new Set(heatSource?.heatSourceZoneIds || []),
    [heatSource],
  );

  const heatSourceVolumeIds = useMemo(
    () => mapDomainsToIds(staticVolumes, heatSourceDomains),
    [heatSourceDomains, staticVolumes],
  );

  const setHeatSourceDomains = useCallback(async (domains: string[]) => {
    await saveParamAsync((newParam) => {
      const newHeatSource = findHeatSourceById(newParam, id);
      if (newHeatSource) {
        newHeatSource.heatSourceZoneIds = domains;
      }
    });
  }, [id, saveParamAsync]);

  // Given the full set of volumes assigned to the heat source's parent physics, transform that list
  // to a Set of domains
  const parentPhysicsDomains = useMemo(() => volumes.reduce((result, { domain }) => {
    result.add(domain);
    return result;
  }, new Set<string>()), [volumes]);

  // Given any domain (volume), return a possible reason why it should be disabled from assignment
  // to this heat source.
  const disabledDomainReason = useCallback((domain: string) => {
    if (parentPhysicsDomains.has(domain)) {
      return disabledHeatSourceDomainReason(id, domain);
    }
    return `Volume is not assigned to this heat source's physics`;
  }, [disabledHeatSourceDomainReason, id, parentPhysicsDomains]);

  const domainPool = useMemo(
    () => unassignedHeatSourceDomains(id),
    [id, unassignedHeatSourceDomains],
  );

  const assignAvailableDomains = useCallback(async () => {
    await setHeatSourceDomains([...domainPool]);
  }, [setHeatSourceDomains, domainPool]);

  const getParamScope = useCallback((paramScope: ParamScope) => chainParamScopes(
    [heatSource],
    experimentConfig,
    getPhysicsParamScope(paramScope),
  ), [experimentConfig, getPhysicsParamScope, heatSource]);

  return {
    physics,
    volumes,
    getParamScope,

    heatSource,
    replaceHeatSource,
    saveHeatSource,
    heatSourceDomains,
    heatSourceVolumeIds,
    setHeatSourceDomains,
    disabledDomainReason,
    domainPool,
    assignAvailableDomains,
  };
};
