// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { useGeometryContacts } from '../../../recoil/geometryContactsState';
import { useProjectContext } from '../../context/ProjectContext';
import { IconSpec, TreeRow } from '../TreeRow';

export const GeometryContactTreeRow = (props: SimulationRowProps) => {
  const { node } = props;

  const { projectId } = useProjectContext();

  const geoContacts = useGeometryContacts(projectId);

  const primaryIcon = useMemo((): IconSpec => {
    const contact = geoContacts.contacts.find(({ id }) => id === node.id);
    if (contact && (contact.sideA.length > 1 || contact.sideB.length > 1)) {
      return { name: 'verticalPlanesFilledTriple' };
    }
    return { name: 'verticalPlanesFilledDouble' };
  }, [geoContacts, node.id]);

  return (
    <TreeRow
      {...props}
      primaryIcon={primaryIcon}
      propertiesControl
    />
  );
};
