// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement } from 'react';

import cx from 'classnames';

import { EMPTY_VALUE } from '../../lib/constants';
import { formatNumber } from '../../lib/number';

// Represents one category of results that are shown. This represents one column
// or one group of columns for the table when it is displayed horizontally. For
// example, a category might be lift, drag, temperature, or another output type.
// The subcategories would be the individual surfaces.
export interface JobDataCategory {
  // The name describing the category dividing into lines. Each string in the
  // array is a line.
  nameLines: string[];
  // Subcategories for this category. This is usually the surface names.
  subcategories: JobDataCategory[];
  // The values for these category of results.
  values: ReactElement[];
  // If the name field should be shown (default is true)
  showName?: boolean;
  // Top margin added to the category name and values.
  marginTop?: number;
  // Bottom margin added to the category name and values.
  marginBottom?: number;
  // Whether to left-align the column header and content.  The default alignment is right.
  leftAlign?: boolean;
  // For rows where we don't show the name/label, we may want the value column to have a colspan=2
  // so that the value cell contains the full row.
  colspan?: number;
  // When true, disables wrapping of the content.
  noWrap?: boolean;
}

// Creates a new JobDataCategory from a name and an array of values.
export function newJobDataCategory(
  nameLines: string[],
  values: (string | number | undefined)[],
  ids: string[],
): JobDataCategory {
  return {
    nameLines,
    subcategories: [],
    values: values.map((value, index) => {
      let strValue = EMPTY_VALUE;
      let numeric = false;
      if (typeof value === 'string') {
        strValue = value;
      }
      if (typeof value === 'number' && !Number.isNaN(value)) {
        strValue = formatNumber(value as number);
        numeric = true;
      }
      // TODO(cedric): Return only plain data (string | number) instead of
      // pre-formatted or pre-rendered content.  Let the components utilizing
      // this data structure decide how to format it or lay it out.
      return (
        <div
          className={cx({ 'tabularNumber compact': numeric })}
          key={`${ids[index]}`}
          style={{
            display: 'inline-block',
            maxWidth: '100%',
          }}>
          {strValue}
        </div>
      );
    }),
  };
}
