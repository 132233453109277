// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { HTMLProps } from 'react';

import cx from 'classnames';

import { createStyles, makeStyles } from '../Theme';

import CheckBox, { MultiCheckBox } from './CheckBox';
import FormControlLabel from './FormControlLabel';
import FormGroup from './FormGroup';
import HelperText from './HelperText';
import Label from './Label';
import LabeledInput from './LabeledInput';

const useStyles = makeStyles(
  ({ spacing }) => createStyles({
    root: {
      margin: spacing(0),
    },
  }),
  { name: 'Form' },
);

export interface FormProps extends HTMLProps<HTMLFormElement> {
  Group?: typeof FormGroup;
  HelperText?: typeof HelperText;
  Label?: typeof Label;
  CheckBox?: typeof CheckBox;
  MultiCheckBox?: typeof MultiCheckBox;
  ControlLabel?: typeof FormControlLabel;
  LabeledInput?: typeof LabeledInput;
}

/**
 * Basic form element.
 */
const Form = (props: FormProps) => {
  const classes = useStyles();

  return (
    <form {...props} className={cx(classes.root, props.className)} />
  );
};

Form.Group = FormGroup;
Form.HelperText = HelperText;
Form.Label = Label;
Form.CheckBox = CheckBox;
Form.MultiCheckBox = MultiCheckBox;
Form.ControlLabel = FormControlLabel;
Form.LabeledInput = LabeledInput;

export default Form;
