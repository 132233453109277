// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.

import { SetStateAction, atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { CurrentView } from '../lib/componentTypes/context';
import { currentViewState } from '../state/internal/global/currentView';

const SHOW_PROP_PANEL_ON_SELECT_KEY = 'geometry-tree:show-prop-panel-on-select';

/**
 * The property panels for the geometry tree items won't open by default when you click an item
 * row from the tree. This atom represents the value for the "Show prop panel on select" toggle
 * below the tree. If TRUE, the prop panel will open automatically when the tree row is clicked.
 */
const showGeometryPropertiesPanelOnSelectState = atomWithStorage(
  SHOW_PROP_PANEL_ON_SELECT_KEY,
  true,
);

export function useShowGeometryPropertiesPanelOnSelect() {
  return useAtom(showGeometryPropertiesPanelOnSelectState);
}
export function useShowGeometryPropertiesPanelOnSelectValue() {
  return useAtomValue(showGeometryPropertiesPanelOnSelectState);
}

/**
 * This atom holds the truth whether we should show the property panel for the geometry tree items.
 * It can be shown by either one of:
 * - clicking a button from the TreeRow;
 * - toggling the "Show prop panel on select" from the Geometry Tree and then selecting some item.
 */
const propertiesPanelVisibleAtom = atom(false);

/**
 * This state uses the atom above but also takes into account the currentView so that the atom is
 * always false if we are in the Geometry view.
 */
const propertiesPanelVisibleState = atom(
  (get) => {
    const currentView = get(currentViewState);
    if (currentView === CurrentView.GEOMETRY) {
      return false;
    }
    return get(propertiesPanelVisibleAtom);
  },
  (get, set, setValue: SetStateAction<boolean>) => {
    const currentView = get(currentViewState);
    if (currentView !== CurrentView.GEOMETRY) {
      set(
        propertiesPanelVisibleAtom,
        typeof setValue === 'function' ? setValue(get(propertiesPanelVisibleAtom)) : setValue,
      );
    }
  },
);

export function usePropertiesPanelVisible() {
  return useAtom(propertiesPanelVisibleState);
}
export function usePropertiesPanelVisibleValue() {
  return useAtomValue(propertiesPanelVisibleState);
}
export function useSetPropertiesPanelVisible() {
  return useSetAtom(propertiesPanelVisibleState);
}

const SIMULATION_PROPERTIES_PANEL_DOCKED_KEY = 'simulation-tree:properties-panel-docked';

/**
 * This atom holds the truth whether we should show the simulation tree's property panels docked
 * in the right sidebar or not.
 */
const simulationPropertiesPanelDockedState = atomWithStorage(
  SIMULATION_PROPERTIES_PANEL_DOCKED_KEY,
  false,
);

export function useSimulationPropertiesPanelDockedValue() {
  return useAtomValue(simulationPropertiesPanelDockedState);
}
export function useSetSimulationPropertiesPanelDocked() {
  return useSetAtom(simulationPropertiesPanelDockedState);
}
