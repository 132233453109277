// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { selectorFamily, useRecoilValue, waitForAll } from 'recoil';

import SectionRecoilKey from '../../../lib/simulationTree/sectionRecoilKey';
import { SimulationValidator, validateSimulation } from '../../../lib/simulationValidation';
import { onGeometryTabSelector } from '../../../recoil/geometry/geometryState';
import { geometryPendingState } from '../../../recoil/pendingWorkOrders';
import { initDataSelector } from '../../../recoil/useInitData';
import { meshReadyStateAtom } from '../../../recoil/useMeshReadyState';

import { simulationBoundaryNamesState } from './simulation/param/boundaryNames';
import { projectValidationDataState } from './validationData';

export const projectValidatorState = selectorFamily<
  SimulationValidator,
  SectionRecoilKey
>({
  key: 'projectValidatorState',
  get: (key: SectionRecoilKey) => async ({ get }) => {
    if (get(onGeometryTabSelector)) {
      return new SimulationValidator();
    }
    const [
      boundaryConditionNames,
      geometryPending,
      initData,
      meshReady,
      simData,
    ] = get(waitForAll([
      simulationBoundaryNamesState(key),
      geometryPendingState(key.projectId),
      initDataSelector(key),
      meshReadyStateAtom(key),
      projectValidationDataState(key),
    ]));
    return validateSimulation(
      { ...simData, boundaryConditionNames, geometryPending, initData, meshReady },
    );
  },
  dangerouslyAllowMutability: true,
});

export function useProjectValidator(projectId: string, workflowId: string, jobId: string) {
  return useRecoilValue(projectValidatorState({ projectId, workflowId, jobId }));
}
