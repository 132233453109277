// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import assert from '../../../../lib/assert';
import { RadioButtonOption } from '../../../../lib/componentTypes/form';
import {
  DEFAULT_FARFIELD_SCALE,
  boxCenter,
  diagonalLength,
  scaleModifier,
  validateFarfield,
} from '../../../../lib/farfieldUtils';
import { generateDefaultShape } from '../../../../lib/geometryUtils';
import { lcvHandler } from '../../../../lib/lcvis/handler/LcvHandler';
import * as random from '../../../../lib/random';
import * as rpc from '../../../../lib/rpc';
import { addRpcError } from '../../../../lib/transientNotification';
import * as geometryservicepb from '../../../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import * as shapepb from '../../../../proto/cad/shape_pb';
import * as geometrypb from '../../../../proto/geometry/geometry_pb';
import * as meshgenerationpb from '../../../../proto/meshgeneration/meshgeneration_pb';
import {
  DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE,
  GeometryState,
  createOrUpdateFeature,
  useGeometryState,
  useSetGeometrySelectedFeature,
  useSetGeometryState,
} from '../../../../recoil/geometry/geometryState';
import { useLcVisReadyValue } from '../../../../recoil/lcvis/lcvisReadyState';
import { useCadMetadata } from '../../../../recoil/useCadMetadata';
import { CadModifier } from '../../../../recoil/useCadModifier';
import Form from '../../../Form';
import { RadioButtonGroup } from '../../../Form/RadioButtonGroup';
import { useCommonTreePropsStyles } from '../../../Theme/commonStyles';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { ScaleButtons } from '../../../controls/ScaleButtons';
import { SectionMessage } from '../../../notification/SectionMessage';
import { CubePanel, CylinderPanel, HalfSpherePanel, SpherePanel, getFarFieldOptions } from '../FarField';

import GeometryModificationPanelFooter from './GeometryModificationPanelFooter';
import { EditModificationMessage } from './GeometryModificationShared';

function featureFromModifier(
  modifier: CadModifier,
  featureId: string,
  featureName: string,
  isFarfield: boolean,
) {
  assert(!!modifier, 'Missing modifier');
  const create = new geometrypb.Create();
  switch (modifier.farField.case) {
    case 'sphere':
      create.shape = { case: 'sphere', value: modifier.farField.value };
      break;
    case 'halfSphere':
      create.shape = { case: 'halfSphere', value: modifier.farField.value };
      break;
    case 'cube':
      create.shape = { case: 'box', value: modifier.farField.value };
      break;
    case 'cylinder':
      create.shape = { case: 'cylinder', value: modifier.farField.value };
      break;
    default:
      throw Error('Not supported');
  }
  if (!isFarfield) {
    return new geometrypb.Feature({
      id: featureId,
      featureName,
      operation: { case: 'create', value: create },
    });
  }
  return new geometrypb.Feature({
    id: featureId,
    featureName,
    operation: {
      case: 'farfield',
      value: new geometrypb.Farfield({
        create,
      }),
    },
  });
}

function createToUserGeometryMod(
  create: geometrypb.Create | undefined,
): meshgenerationpb.UserGeometryMod | undefined {
  switch (create?.shape.case) {
    case 'box': {
      return new meshgenerationpb.UserGeometryMod({
        farField: {
          case: 'cube',
          value: create.shape.value,
        },
      });
    }
    case 'cylinder':
    case 'sphere':
    case 'halfSphere': {
      return new meshgenerationpb.UserGeometryMod({
        farField: {
          case: create.shape.case,
          value: create.shape.value,
        },
      });
    }
    case undefined:
      return undefined;
    default:
      throw Error('Not supported');
  }
}

// A panel displaying all the settings for the generation of shapes and farfield operations in a
// geometry.
export const GeometryModificationShapePropPanel = () => {
  const { projectId, geometryId, readOnly } = useProjectContext();
  const { selectedNode: node, setSelection } = useSelectionContext();
  const setSelectedFeature = useSetGeometrySelectedFeature(geometryId);
  const geometryState = useGeometryState(projectId, geometryId);
  const setGeometryState = useSetGeometryState(projectId, geometryId);
  const [cadMetadata] = useCadMetadata(projectId);
  assert(!!node, 'No selected geometry modification shape row');
  const featureMod = useMemo(() => geometryState?.geometryFeatures.find(
    (feat) => feat.id === node.id,
  ), [geometryState, node.id]);
  const isFarfield = featureMod?.operation.case === 'farfield';
  const shapeValue = (featureMod?.operation.value as geometrypb.Farfield)?.create?.shape?.case;

  const lcvisReady = useLcVisReadyValue();
  assert(lcvisReady, 'The visualizer is not ready');

  // Define the radio buttons for the farfield.
  const shapeOptions: RadioButtonOption<string>[] = [
    { label: 'Sphere', value: 'sphere' },
    { label: 'Half Sphere', value: 'halfSphere' },
    { label: 'Box', value: 'box' },
    { label: 'Cylinder', value: 'cylinder' },
  ];

  const getCurrentModifier = (geoState: GeometryState | undefined, id: string) => {
    if (geoState === undefined) {
      return undefined;
    }
    const mod = geoState.geometryFeatures.find((feature) => feature.id === id);
    if (mod === undefined) {
      return undefined;
    }
    const create = mod.operation.case === 'farfield' ?
      mod.operation.value.create : mod.operation.value as geometrypb.Create;
    return createToUserGeometryMod(create);
  };

  // Current modifier is the CAD modifier that is current in the settings. Edit
  // modifier is the one we are editing. It will go into the settings if save
  // is clicked. It is discarded if cancel is clicked.
  const currModifier = getCurrentModifier(geometryState, node.id);
  const initialized = currModifier !== undefined;
  const bBox = cadMetadata.boundingBox;
  const center = boxCenter(bBox);
  const radius = DEFAULT_FARFIELD_SCALE * diagonalLength(bBox);
  const newModifier = new meshgenerationpb.UserGeometryMod({
    farField: { case: 'sphere', value: new shapepb.Sphere({ center, radius }) },
  });

  const [editModifier, setEditModifier] = useState<CadModifier>(
    initialized ? currModifier : newModifier,
  );
  const modifier = editModifier || currModifier;
  const inEditMode = !!editModifier;
  const commonClasses = useCommonTreePropsStyles();

  // Apply a changeMod function to the edit modifier. This sets the shape.
  const setShape = (changeMod: (oldMod: meshgenerationpb.UserGeometryMod) => void) => {
    const newMod = editModifier!.clone();
    changeMod(newMod);
    setEditModifier(newMod);
    const feature = featureFromModifier(newMod, node.id, node.name, isFarfield);
    setGeometryState((oldGeometryState) => {
      if (oldGeometryState === undefined) {
        return oldGeometryState;
      }
      const newGeometryState = { ...oldGeometryState };
      newGeometryState.geometryFeatures = newGeometryState.geometryFeatures.map((featureNew) => {
        if (featureNew.id === node.id) {
          return feature;
        }
        return featureNew;
      });
      return newGeometryState;
    });
    const farFieldOptions = getFarFieldOptions(newMod, true);
    lcvHandler.queueDisplayFunction('add far field', (display) => {
      display.workspace?.addFarField(farFieldOptions).catch((error) => console.warn(error));
    });
  };

  // Respond to changes in the radio buttons for the Far-Field.
  const onShapeChange = (newShapeValueIn: string) => {
    const newShapeValue = newShapeValueIn as Partial<geometrypb.Create['shape']['case']>;
    setShape((oldMod: meshgenerationpb.UserGeometryMod) => {
      const create = new geometrypb.Create();
      create.shape = { case: newShapeValue } as any;
      generateDefaultShape(cadMetadata, create);
      const newMod = createToUserGeometryMod(create) || oldMod;
      oldMod.farField = newMod.farField;
    });
  };

  // Determine which parameter panel to display.
  let parameterPanel: ReactNode;
  let shapeType: 'sphere' | 'halfSphere' | 'cube' | 'cylinder' | undefined;
  if (modifier?.farField.case === 'sphere') {
    shapeType = 'sphere';
    parameterPanel = (
      <SpherePanel
        readOnly={!inEditMode || readOnly}
        setSphere={(sphere) => setShape((newOp) => {
          newOp.farField = { case: 'sphere', value: sphere };
        })}
        sphere={modifier.farField.value}
      />
    );
  } else if (modifier?.farField.case === 'halfSphere') {
    shapeType = 'halfSphere';
    parameterPanel = (
      <HalfSpherePanel
        halfSphere={modifier.farField.value}
        readOnly={!inEditMode || readOnly}
        setHalfSphere={(halfSphere) => setShape((newOp) => {
          newOp.farField = { case: 'halfSphere', value: halfSphere };
        })}
      />
    );
  } else if (modifier?.farField.case === 'cube') {
    shapeType = 'cube';
    parameterPanel = (
      <CubePanel
        cube={modifier.farField.value}
        readOnly={!inEditMode || readOnly}
        setCube={(cube) => setShape((newOp) => {
          newOp.farField = { case: 'cube', value: cube };
        })}
      />
    );
  } else if (modifier?.farField.case === 'cylinder') {
    shapeType = 'cylinder';
    parameterPanel = (
      <CylinderPanel
        cylinder={modifier.farField.value}
        readOnly={!inEditMode || readOnly}
        setCylinder={
          (cylinder) => setShape((newOp) => {
            newOp.farField = { case: 'cylinder', value: cylinder };
          })
        }
      />
    );
  }

  const applyScale = (scale: number) => {
    setShape((mod) => scaleModifier(scale, mod));
  };

  // Saves the edit modifier as the current modifier and create a request to update the geometry.
  const onGeoModShapeSave = async () => {
    if (!editModifier) {
      return;
    }

    assert(!!rpc.clientGeometry, 'Missing geometry RPC client');
    assert(!!geometryId, 'Missing geometry ID');
    const feature = featureFromModifier(editModifier, node.id, node.name, isFarfield);
    const modType = createOrUpdateFeature(geometryState, node.id);
    const modification = new geometrypb.Modification({
      feature,
      modType: modType === geometrypb.Modification_ModificationType.CREATE_FEATURE ?
        geometrypb.Modification_ModificationType.CREATE_FEATURE :
        geometrypb.Modification_ModificationType.UPDATE_FEATURE,
    });
    const req = new geometryservicepb.ModifyGeometryRequest({
      requestId: random.string(32),
      geometryId,
      modification,
    });
    rpc.clientGeometry.modifyGeometry(req).catch((err) => addRpcError(`Server error ${err}`, err));

    // Focus out of the panel, to avoid weird rerenderings when calling setSelection.
    setSelectedFeature(DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE);

    // Imposter updates are triggered by setting the state inside ParaviewManager.
    setEditModifier(null);

    // Remove the focus on the panel, the geometry being displayed may not be linked to the
    // modification.
    setSelection([]);
  };

  const errors = validateFarfield(editModifier);

  // Remove the far field whenever the component unmounts. Also, add the far field when the
  // component mounts.
  useEffect(() => {
    if (editModifier) {
      const farFieldOptions = getFarFieldOptions(editModifier, true);
      lcvHandler.queueDisplayFunction('add far field', (display) => {
        display.workspace?.addFarField(farFieldOptions).catch((error) => console.warn(error));
      });
    }
    return () => {
      lcvHandler.queueDisplayFunction('remove far field', (display) => {
        display.workspace?.removeFarField().catch((error) => console.warn(error));
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={commonClasses.properties}>
      <EditModificationMessage nodeId={node.id} />
      {isFarfield && (
        <Form.LabeledInput
          help=""
          label="Shape">
          <RadioButtonGroup
            disabled={!inEditMode}
            kind="secondary"
            name="shapes"
            onChange={onShapeChange}
            options={shapeOptions}
            value={shapeValue}
          />
        </Form.LabeledInput>
      )}
      {parameterPanel}
      {!(shapeType === 'sphere' || shapeType === 'halfSphere') && (
        <Form.LabeledInput
          help="Scale the Parameters Up or Down"
          label="Scale">
          <ScaleButtons
            disabled={!inEditMode}
            onClick={applyScale}
            scaleValues={[0.5, 2]}
          />
        </Form.LabeledInput>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          justifyContent: 'center',
          marginTop: '10px',
        }}>
        {errors.map((error) => (
          <SectionMessage
            key={error}
            level="error"
            message={error}
            title="Error in shape settings"
          />
        ))}
      </div>
      <GeometryModificationPanelFooter
        featureId={node.id}
        onModificationSave={onGeoModShapeSave}
      />
    </div>
  );
};
