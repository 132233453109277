// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect } from 'react';

import * as flags from '../../flags';
import { CurrentView } from '../../lib/componentTypes/context';
import { ControlPanelMode } from '../../lib/componentTypes/controlPanel';
import { validExploration } from '../../lib/explorationUtils';
import { NodeTableType } from '../../lib/nodeTableUtil';
import { useIsEnabled } from '../../recoil/useExperimentConfig';
import useExplorationSet from '../../recoil/useExplorationSet';
import { useControlPanelMode } from '../../recoil/useProjectPage';
import { useWorkflowMap } from '../../recoil/workflowState';
import { useCurrentView } from '../../state/internal/global/currentView';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { useCreateExploration } from '../hooks/exploration/useCreateExploration';
import { DataTabs } from '../layout/Tabs';
import { TabConfig } from '../layout/Tabs/useDraggableTabs';

// Draw several buttons for switching between modes in the right pane.
const RightPaneSwitcher = () => {
  const [mode, setMode] = useControlPanelMode();
  const { isTreeModal, setSelection, setActiveNodeTable } = useSelectionContext();
  const { projectId, workflowId } = useProjectContext();
  const currentView = useCurrentView();
  const workflowReply = useWorkflowMap(projectId, [workflowId])[workflowId];
  const exploration = workflowReply?.config?.exploration;

  const sensitivityEnabled = useIsEnabled(flags.adjointSA);
  const [explorationSet] = useExplorationSet(projectId);
  const createExploration = useCreateExploration();

  // Disable the exploration button if there is no valid exploration to view in analysis mode.
  const explorationDisabled = (
    currentView === CurrentView.ANALYSIS && !validExploration(exploration)
  );

  const updateMode = useCallback((newMode: ControlPanelMode) => {
    setMode(newMode);
    setSelection([]);
    setActiveNodeTable({ type: NodeTableType.NONE });
  }, [setActiveNodeTable, setMode, setSelection]);

  // Switch out of exploration mode if it is disabled.
  useEffect(() => {
    if (mode === 'exploration' && explorationDisabled) {
      setMode('simulation');
    }
  }, [mode, setMode, explorationDisabled]);

  const onClickExploration = () => {
    if (!sensitivityEnabled && explorationSet.index < 0) {
      // if sensitivity is disabled and no exploration is selected, automatically create and set
      // grid search exploration
      createExploration('gridSearch');
    }
    updateMode('exploration');
  };

  const tabProps: TabConfig[] = [
    {
      disabled: isTreeModal,
      onClick: () => updateMode('simulation'),
      foreground: mode === 'simulation',
      id: 'simulation',
      label: 'Simulation',
      help: 'Simulation Settings',
    },
    {
      disabled: isTreeModal || explorationDisabled,
      onClick: onClickExploration,
      foreground: mode === 'exploration',
      id: 'exploration',
      label: 'Exploration',
      help: 'Exploration Settings',
    },
  ];

  return <DataTabs tabs={tabProps} />;
};

export default RightPaneSwitcher;
