// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useCallback, useEffect, useRef } from 'react';

import { Virtuoso as List } from 'react-virtuoso';

import { SIMULATION_TREE_DATA_LOCATOR, SimulationTreeNode } from '../../lib/simulationTree/node';
import useResizeObserver from '../../lib/useResizeObserver';
import { useSimulationTree } from '../../state/internal/tree/simulation';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { useTree } from '../hooks/useTree';

import { SimulationRowContainer } from './SimulationRowContainer';
import { useArrowKeyNav } from './useArrowKeyNav';

const useStyles = makeStyles(
  () => createStyles({
    root: {
      height: '100%',
      overflow: 'hidden',
    },
  }),
  { name: 'SimulationTreePanel' },
);

interface SimulationTreePanelProps {
  // The root node of the panel
  panelRoot: SimulationTreeNode;
}

/**
 * This panel displays a simulation tree containing the simulation settings.
 * Any node with children can be opened or closed. The children are displayed
 * if the node is opened.
 */
const SimulationTreePanel = (props: SimulationTreePanelProps) => {
  const classes = useStyles();

  const { projectId, workflowId, jobId } = useProjectContext();
  const { selectedNodeIds } = useSelectionContext();
  const simulationTree = useSimulationTree(projectId, workflowId, jobId);
  const { rowProps, maybeUpdateRowsOpened, listRef } = useTree(props.panelRoot);

  const domRef = useRef<HTMLDivElement>(null);
  const domSize = useResizeObserver(domRef, { name: 'simTreePanel' });
  const maxHeight = Math.max(100, domSize.height);

  // Listen to arrow keys for navigating in the simtree with keyboard shortcuts
  useArrowKeyNav(simulationTree, rowProps, listRef);

  const renderRow = useCallback((index, row) => (
    <SimulationRowContainer {...row} />
  ), []);

  useEffect(() => {
    maybeUpdateRowsOpened(simulationTree, selectedNodeIds);
  }, [selectedNodeIds, maybeUpdateRowsOpened, simulationTree]);

  if (!simulationTree) {
    return (
      <></>
    );
  }

  return (
    <div className={classes.root} data-locator={SIMULATION_TREE_DATA_LOCATOR} ref={domRef}>
      <List
        data={rowProps}
        itemContent={renderRow}
        ref={listRef}
        style={{ width: domSize.width, height: maxHeight }}
      />
    </div>
  );
};

export default SimulationTreePanel;
