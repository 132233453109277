// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement } from 'react';

import * as flags from '../../flags';
import { CommonMenuItem } from '../../lib/componentTypes/menu';
import { generateDefaultShape, getFeatureInitialName, volumeNodeIdsToCadIds } from '../../lib/geometryUtils';
import { Logger } from '../../lib/observability/logs';
import * as random from '../../lib/random';
import * as rpc from '../../lib/rpc';
import { addRpcError } from '../../lib/transientNotification';
import * as geometryservicepb from '../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import * as booleanpb from '../../proto/cad/boolean_pb';
import * as shapepb from '../../proto/cad/shape_pb';
import * as geometrypb from '../../proto/geometry/geometry_pb';
import { useIsGeometryServerActive } from '../../recoil/geometry/geometryServerStatus';
import { DEFAULT_SELECTED_FEATURE, DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE, SelectedFeature, useGeometrySelectedFeature, useGeometryState, useLatestTessellationValue, useSetGeometryState } from '../../recoil/geometry/geometryState';
import { initializeNewNode, useSetNewNodes } from '../../recoil/nodeSession';
import { useEnabledExperiments } from '../../recoil/useExperimentConfig';
import { useStaticVolumes } from '../../recoil/volumes';
import Dropdown from '../Dropdown';
import { FloatingGroup } from '../Pane/FloatingGroup';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { PatternType, defaultPattern } from '../treePanel/propPanel/geometry/GeometryModificationPattern';
import { TransfType, defaultTransf } from '../treePanel/propPanel/geometry/GeometryModificationTransform';

import { ToolbarButton } from './ToolbarButton';

// Toolbar height in pixels.
export const TOOLBAR_HEIGHT = 36;

const logger = new Logger('GeometryToolbar');

function globalDisabledReason(
  selectedFeature: SelectedFeature,
  readOnly: boolean,
  isGeoServerActive: boolean,
) {
  if (readOnly) {
    return 'Cannot edit geometries in shared projects';
  }
  if (!isGeoServerActive) {
    return 'Cannot edit geometry while geometry server is busy.';
  }
  if (selectedFeature !== DEFAULT_SELECTED_FEATURE &&
    selectedFeature !== DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE) {
    return 'Already editing a feature';
  }
  return undefined;
}

// The toolbar appears at the top of the main panel.
const GeometryToolbar = () => {
  const { projectId, geometryId, readOnly } = useProjectContext();
  const geoState = useGeometryState(projectId, geometryId);
  const [selectedFeature] = useGeometrySelectedFeature(geometryId);
  const setGeometryState = useSetGeometryState(projectId, geometryId);

  const { setSelection, setScrollTo, selectedNodeIds } = useSelectionContext();
  const setNewNodes = useSetNewNodes();
  const isGeoServerActive = useIsGeometryServerActive(geometryId);
  const updateSelection = (id: string) => {
    setNewNodes((nodes: any) => [...nodes, initializeNewNode(id)]);
    setSelection([id]);
    setScrollTo({ node: id });
  };
  const staticVolumes = useStaticVolumes(projectId);
  const cadMetadata = geoState?.cadMetadata;
  const latestTessellation = useLatestTessellationValue(geometryId);
  const experimentConfig = useEnabledExperiments();
  const allowDiscrete = experimentConfig.includes(flags.interactiveGeometryDiscrete);

  const getBodies = () => {
    if (cadMetadata) {
      return volumeNodeIdsToCadIds(selectedNodeIds, staticVolumes, cadMetadata);
    }
    return [];
  };

  const createNewModification = (operation: geometrypb.Feature['operation']) => {
    // We make this async so that we can get the latest tessellation before creating the new
    // modification, then call the inner async function to create the modification.
    const asyncInner = async () => {
      // If the current geometry state isn't from the latest tessellation, we need to get the
      // latest tessellation before creating a new modification.
      if (
        !latestTessellation?.cadMetadata?.equals(geoState?.cadMetadata) ||
        !latestTessellation.lcnMeta?.equals(geoState?.metadata)
      ) {
        const req = new geometryservicepb.LatestTessellationRequest({
          geometryId,
        });
        // The subscription part will handle the return value from here, we don't retry yet.
        try {
          await rpc.clientGeometry!.latestTessellation(req);
        } catch (error) {
          addRpcError('Failed to update the tessellation', error);
        }
      }

      if (operation.case === 'create') {
        generateDefaultShape(geoState!.cadMetadata, operation.value as geometrypb.Create);
      } else if (operation.case === 'farfield') {
        generateDefaultShape(
          geoState!.cadMetadata,
          (operation.value as geometrypb.Farfield).create!,
        );
      }
      const id = random.string(32);
      setGeometryState((oldGeometryState) => {
        if (oldGeometryState === undefined) {
          return oldGeometryState;
        }
        const newFeature = new geometrypb.Feature({
          id,
          operation,
        });
        newFeature.featureName = getFeatureInitialName(
          newFeature,
          oldGeometryState.geometryFeatures,
        );
        const newGeometryState = { ...oldGeometryState };
        newGeometryState.geometryFeatures.push(newFeature);

        // If the operation is something that takes bodies as input, prepopulate it with the
        // currently selected volumes, if any exist.
        switch (newFeature.operation.case) {
          case 'delete':
            newFeature.operation.value.ids = getBodies();
            break;
          case 'transform':
          case 'pattern':
            newFeature.operation.value.body = getBodies();
            break;
          case 'boolean': {
            const bool = newFeature.operation.value.op.value;
            if (bool?.bodies) {
              bool.bodies = getBodies();
            }
          }
            break;
          default:
          // none
        }
        return newGeometryState;
      });
      updateSelection(id);
    };
    asyncInner().catch((err) => {
      logger.error(err);
    });
  };

  const createNewShapeOrFarfield = (create: geometrypb.Create, isFarfield: boolean) => {
    if (isFarfield) {
      return createNewModification({
        case: 'farfield',
        value: new geometrypb.Farfield({
          create,
        }),
      });
    }
    return createNewModification({
      case: 'create',
      value: create,
    });
  };

  const disabledReason = globalDisabledReason(selectedFeature, readOnly, isGeoServerActive);
  const disabled = disabledReason !== undefined;

  // IGEO TODO: All create ops return the same shape template. Populate the correct shape on each.
  // Right now, if a mod has a shape defined, it is read only, so we can't do it yet.
  const primitiveMenuItems: CommonMenuItem[] = [
    {
      disabled,
      disabledReason,
      label: 'Cylinder',
      startIcon: { name: 'cylinder', maxHeight: 14 },
      onClick: () => {
        createNewShapeOrFarfield(
          new geometrypb.Create({
            shape: {
              case: 'cylinder',
              value: new shapepb.Cylinder(),
            },
          }),
          false,
        );
      },
    },
    {
      disabled,
      disabledReason,
      label: 'Box',
      startIcon: { name: 'cubeSolid', maxHeight: 14 },
      onClick: () => {
        createNewShapeOrFarfield(
          new geometrypb.Create({
            shape: {
              case: 'box',
              value: new shapepb.Cube(),
            },
          }),
          false,
        );
      },
    },
    {
      disabled,
      disabledReason,
      label: 'Sphere',
      startIcon: { name: 'sphere', maxHeight: 14 },
      onClick: () => {
        createNewShapeOrFarfield(
          new geometrypb.Create({
            shape: {
              case: 'sphere',
              value: new shapepb.Sphere(),
            },
          }),
          false,
        );
      },
    },
    {
      disabled,
      disabledReason,
      label: 'Half Sphere',
      startIcon: { name: 'halfSphere', maxHeight: 14 },
      onClick: () => {
        createNewShapeOrFarfield(
          new geometrypb.Create({
            shape: {
              case: 'halfSphere',
              value: new shapepb.HalfSphere(),
            },
          }),
          false,
        );
      },
    },
    { separator: true },
    {
      disabled,
      disabledReason,
      label: 'CAD',
      startIcon: { name: 'plus', maxHeight: 12 },
      onClick: () => {
        createNewModification({
          case: 'import',
          value: new geometrypb.Import({
            scaling: 1,
          }),
        });
      },
    },
  ];

  const booleanMenuItems: CommonMenuItem[] = [
    {
      disabled,
      disabledReason,
      label: 'Unite',
      startIcon: { name: 'geometryUnion', maxHeight: 14 },
      onClick: () => {
        createNewModification({
          case: 'boolean',
          value: new geometrypb.Boolean({
            op: {
              case: 'regUnion',
              value: new booleanpb.RegularUnion(),
            },
          }),
        });
      },
    },
    {
      disabled,
      disabledReason,
      label: 'Subtract',
      startIcon: { name: 'geometrySubtract', maxHeight: 14 },
      onClick: () => {
        createNewModification({
          case: 'boolean',
          value: new geometrypb.Boolean({
            op: {
              case: 'regSubtraction',
              value: new booleanpb.RegularSubtraction(),
            },
          }),
        });
      },
    },
    {
      disabled,
      disabledReason,
      label: 'Intersect',
      startIcon: { name: 'geometryIntersect', maxHeight: 14 },
      onClick: () => {
        createNewModification({
          case: 'boolean',
          value: new geometrypb.Boolean({
            op: {
              case: 'regIntersection',
              value: new booleanpb.RegularIntersection(),
            },
          }),
        });
      },
    },
    {
      disabled,
      disabledReason,
      label: 'Chop',
      startIcon: { name: 'geometryXor', maxHeight: 14 },
      onClick: () => {
        createNewModification({
          case: 'boolean',
          value: new geometrypb.Boolean({
            op: {
              case: 'regChop',
              value: new booleanpb.RegularChop(),
            },
          }),
        });
      },
    },
  ];

  const transformationMenuItems: CommonMenuItem[] = [
    {
      disabled,
      disabledReason,
      label: 'Mirror',
      startIcon: { name: 'mirroredTriangles', maxHeight: 14 },
      onClick: () => {
        createNewModification({ case: 'transform', value: defaultTransf(TransfType.MIRROR) });
      },
    },
    {
      disabled,
      disabledReason,
      label: 'Rotate',
      startIcon: { name: 'rotate', maxHeight: 14 },
      onClick: () => {
        createNewModification({ case: 'transform', value: defaultTransf(TransfType.ROTATION) });
      },
    },
    {
      disabled,
      disabledReason,
      label: 'Scale',
      startIcon: { name: 'geometryScale', maxHeight: 14 },
      onClick: () => {
        createNewModification({ case: 'transform', value: defaultTransf(TransfType.SCALING) });
      },
    },
    {
      disabled,
      disabledReason,
      label: 'Translate',
      startIcon: { name: 'arrowUpDownLeftRight', maxHeight: 14 },
      onClick: () => {
        createNewModification({ case: 'transform', value: defaultTransf(TransfType.TRANSLATION) });
      },
    },
    { separator: true },
    {
      disabled,
      disabledReason,
      label: 'Circular Pattern',
      startIcon: { name: 'patternCircular', maxHeight: 14 },
      onClick: () => {
        createNewModification({ case: 'pattern', value: defaultPattern(PatternType.CIRCULAR) });
      },
    },
    {
      disabled,
      disabledReason,
      label: 'Linear Pattern',
      startIcon: { name: 'patternLinear', maxHeight: 14 },
      onClick: () => {
        createNewModification({ case: 'pattern', value: defaultPattern(PatternType.LINEAR) });
      },
    },
  ];

  const mainButtons: ReactElement[] = [
    <Dropdown
      key="primitiveOpsDropdown"
      maxWidth={300}
      menuItems={primitiveMenuItems}
      position="below-right"
      toggle={(
        <ToolbarButton
          disabled={disabled}
          dropdown
          icon={{ name: 'cubePlus', maxHeight: 14 }}
          key="primitiveOpsButton"
          locator="toolbar-add"
          onClick={() => { }}
          title={disabled ? disabledReason : 'Add'}
        />
      )}
    />,
    <ToolbarButton
      disabled={disabled}
      icon={{ name: 'farfield', maxHeight: 14 }}
      key="farfield"
      locator="toolbar-farfield"
      onClick={() => {
        createNewShapeOrFarfield(
          new geometrypb.Create({
            shape: {
              case: 'sphere',
              value: new shapepb.Sphere(),
            },
          }),
          true,
        );
      }}
      title={disabled ? disabledReason : 'Farfield'}
    />,
    <ToolbarButton
      disabled={disabled}
      icon={{ name: 'cubeDash', maxHeight: 14 }}
      key="cubeDash"
      locator="toolbar-delete"
      onClick={() => {
        createNewModification({
          case: 'delete',
          value: new geometrypb.Delete(),
        });
      }}
      title={disabled ? disabledReason : 'Delete'}
    />,
    <Dropdown
      key="booleanOpsDropdown"
      maxWidth={300}
      menuItems={booleanMenuItems}
      position="below-right"
      toggle={(
        <ToolbarButton
          disabled={disabled}
          dropdown
          icon={{ name: 'geometryBoolean', maxHeight: 13 }}
          key="booleanOpsButton"
          locator="toolbar-boolean"
          onClick={() => { }}
          title={disabled ? disabledReason : 'Boolean'}
        />
      )}
    />,
    <Dropdown
      key="transformOpsDropdown"
      maxWidth={300}
      menuItems={transformationMenuItems}
      position="below-right"
      toggle={(
        <ToolbarButton
          disabled={disabled}
          dropdown
          icon={{ name: 'cubeOutlineInvertedCircle', maxHeight: 14.5 }}
          key="transformOpsButton"
          locator="toolbar-transform"
          onClick={() => { }}
          title={disabled ? disabledReason : 'Transform'}
        />
      )}
    />,
    <ToolbarButton
      disabled={disabled}
      icon={{ name: 'doubleUniqueFrames', maxHeight: 14.5 }}
      key="doubleUniqueFrames"
      locator="toolbar-imprint"
      onClick={() => {
        createNewModification({
          case: 'imprint',
          value: new geometrypb.Imprint(),
        });
      }}
      title={disabled ? disabledReason : 'Imprint'}
    />,
  ];

  if (allowDiscrete) {
    mainButtons.push(
      <ToolbarButton
        disabled={disabled}
        icon={{ name: 'groupAction', maxHeight: 14 }}
        key="groupAction"
        locator="toolbar-shrinkwrap"
        onClick={() => {
          createNewModification({
            case: 'shrinkwrap',
            value: new geometrypb.Shrinkwrap(),
          });
        }}
        title={disabled ? disabledReason : 'Shrinkwrap'}
      />,
    );
  }

  const undoRedoButtons: ReactElement[] = [
    <ToolbarButton
      disabled={!geoState || geoState.nUndosAvailable <= 0 || !isGeoServerActive || disabled}
      icon={{ name: 'undo', maxHeight: 11.6 }}
      key="help"
      locator="toolbar-help"
      onClick={() => {
        const req = new geometryservicepb.ModifyGeometryRequest({
          geometryId,
          modification: new geometrypb.Modification({
            modType: geometrypb.Modification_ModificationType.UNDO,
          }),
        });
        rpc.clientGeometry!.modifyGeometry(req).catch((err) => {
          logger.error(err);
        });
      }}
      title={disabled ? disabledReason : `Undo`}
    />,
    <ToolbarButton
      disabled={!geoState || geoState.nRedosAvailable <= 0 || !isGeoServerActive || disabled}
      icon={{ name: 'redo', maxHeight: 11.6 }}
      key="redo"
      locator="toolbar-help-redo"
      onClick={() => {
        const req = new geometryservicepb.ModifyGeometryRequest({
          geometryId,
          modification: new geometrypb.Modification({
            modType: geometrypb.Modification_ModificationType.REDO,
          }),
        });
        rpc.clientGeometry!.modifyGeometry(req).catch((err) => {
          logger.error(err);
        });
      }}
      title={disabled ? disabledReason : `Redo`}
    />,
  ];

  return (
    <>
      <FloatingGroup>{mainButtons}</FloatingGroup>
      <FloatingGroup>{undoRedoButtons}</FloatingGroup>
    </>
  );
};

export default GeometryToolbar;
