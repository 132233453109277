// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.

import { atom, atomFamily, selectorFamily, useRecoilState, useSetRecoilState } from 'recoil';

import { plotNodesFixture } from '../lib/fixtures';
import * as persist from '../lib/persist';
import { MONITOR_PLOT_NODE_ID } from '../lib/plot';
import { syncProjectStateEffect } from '../lib/recoilSync';
import { EMPTY_UINT8_ARRAY } from '../lib/stringarray';
import { isTestingEnv } from '../lib/testing/utils';
import * as plotpb from '../proto/plots/plots_pb';

const plotNodesKey = 'plotNodes';

const DEFAULT_MONITOR_PLOT = new plotpb.PlotSettings({
  id: MONITOR_PLOT_NODE_ID,
  name: 'Monitor Plot',
  plot: { case: 'monitorPlot', value: new plotpb.PlotSettings_MonitorPlot() },
});

const DEFAULT_PLOTS = new plotpb.Plots({ plots: [DEFAULT_MONITOR_PLOT] });

function serialize(val: plotpb.Plots): Uint8Array {
  return (val ? val.toBinary() : EMPTY_UINT8_ARRAY);
}

function deserialize(val: Uint8Array): plotpb.Plots {
  return (val.length ?
    plotpb.Plots.fromBinary(val) :
    DEFAULT_PLOTS);
}

export const plotNodesSelectorRpc = selectorFamily<plotpb.Plots, string>({
  key: `${plotNodesKey}/rpc`,
  get: (projectId: string) => () => (
    persist.getProjectState(projectId, [plotNodesKey], deserialize)
  ),
  dangerouslyAllowMutability: true,
});

const plotNodesSelectorTesting = selectorFamily<plotpb.Plots, string>({
  key: 'plotNodesSelector',
  get: () => plotNodesFixture,
  dangerouslyAllowMutability: true,
});

const plotNodesSelector = isTestingEnv() ? plotNodesSelectorTesting : plotNodesSelectorRpc;

export const plotNodesState = atomFamily<plotpb.Plots, string>({
  key: plotNodesKey,
  default: plotNodesSelector,
  effects: (projectId: string) => [
    syncProjectStateEffect(projectId, plotNodesKey, deserialize, serialize),
  ],
  dangerouslyAllowMutability: true,
});

export const usePlotNodes = (projectId: string) => (
  useRecoilState(plotNodesState(projectId))
);

export const useSetPlotNodes = (projectId: string) => (
  useSetRecoilState(plotNodesState(projectId))
);

const currentlySelectedPlot = atom<plotpb.PlotSettings>({
  key: 'currentlySelectedPlot',
  default: DEFAULT_MONITOR_PLOT,
  dangerouslyAllowMutability: true,
});

export const useCurrentlySelectedPlot = () => (
  useRecoilState(currentlySelectedPlot)
);

export const useSetCurrentlySelectedPlot = () => (
  useSetRecoilState(currentlySelectedPlot)
);
