// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { useUserCanEdit } from '../../lib/projectRoles';
import { NodeType, SimulationTreeNode } from '../../lib/simulationTree/node';
import useProjectMetadata from '../../recoil/useProjectMetadata';
import { IconButton } from '../Button/IconButton';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { DeleteMotionFrameDialog } from '../dialog/DeleteMotionFrame';
import { useNodeDeletion } from '../hooks/useNodeDeletion';
import { TrashIcon } from '../svg/TrashIcon';

const SimulationTreeDeleteButton = () => {
  const { projectId } = useProjectContext();
  const { isTreeModal, selectedNode } = useSelectionContext();

  const nodeDeletion = useNodeDeletion();
  const projectMetadata = useProjectMetadata(projectId);
  const userCanEdit = useUserCanEdit(projectMetadata?.summary);

  const deleteNode = async (node: SimulationTreeNode) => {
    const { type, id } = node;

    switch (type) {
      case NodeType.CAMERA:
        return nodeDeletion.deleteCameraNode(id);
      case NodeType.EXPLORATION_VARIABLE:
        return nodeDeletion.deleteExplorationVariableNode(id);
      case NodeType.FAR_FIELD:
        return nodeDeletion.deleteFarfieldNode();
      case NodeType.FILTER:
        return nodeDeletion.deleteFilterNode(id);
      case NodeType.GEOMETRY:
        return nodeDeletion.deleteGeometryNode();
      case NodeType.MATERIAL_FLUID:
      case NodeType.MATERIAL_SOLID:
        return nodeDeletion.deleteMaterial(id);
      case NodeType.MESH:
        return nodeDeletion.deleteMeshNode();
      case NodeType.MONITOR_PLANE:
        return nodeDeletion.deleteMonitorPlaneNode(id);
      case NodeType.MOTION_FRAME:
        return nodeDeletion.deleteMotionFrameNode(id);
      case NodeType.OUTPUT:
        return nodeDeletion.deleteOutputNode(id);
      case NodeType.PARTICLE_GROUP:
        return nodeDeletion.deleteParticleGroupNode(id);
      case NodeType.PHYSICAL_BEHAVIOR:
        return nodeDeletion.deletePhysicalBehaviorNode(id);
      case NodeType.PHYSICS_FLUID:
      case NodeType.PHYSICS_HEAT:
        return nodeDeletion.deletePhysicsNode(id);
      case NodeType.PHYSICS_FLUID_BOUNDARY_CONDITION:
        return nodeDeletion.deleteBoundaryNode(id);
      case NodeType.PHYSICS_HEAT_BOUNDARY_CONDITION:
        return nodeDeletion.deleteBoundaryNode(id);
      case NodeType.PHYSICS_PERIODIC_PAIR:
        return nodeDeletion.deletePeriodicBoundNode(id);
      case NodeType.PHYSICS_SLIDING_INTERFACE:
        return nodeDeletion.deleteSlidingInterfaceNode(id);
      case NodeType.PHYSICS_HEAT_HEAT_SOURCE:
        return nodeDeletion.deleteHeatSourceNode(id);
      case NodeType.PHYSICS_MULTI_INTERFACE:
        return nodeDeletion.deleteMultiphysicsInterfaceNode(id);
      case NodeType.PLOT:
        return nodeDeletion.deletePlotNode(id);
      case NodeType.POROUS_MODEL:
        return nodeDeletion.deletePorousModelNode(id);
      case NodeType.PROBE_POINT:
        return nodeDeletion.deleteProbePointNode(id);
      case NodeType.SURFACE_GROUP:
        return nodeDeletion.deleteSurfaceGroupNode(id);
      case NodeType.REFINEMENT_REGION:
        return nodeDeletion.deleteRefinementRegionNode(id);
      default:
        return false;
    }
  };

  // If the selected node can be deleted, clicking on the delete button will
  // remove it.
  const handleDelete = async () => {
    if (selectedNode) {
      if (await deleteNode(selectedNode)) {
        nodeDeletion.postDeleteNodeIds([selectedNode.id]);
      }
    }
  };

  // Whether or not the given node can be deleted.
  const canDelete = (
    !isTreeModal &&
    selectedNode &&
    nodeDeletion.canDelete(selectedNode.type, selectedNode.id)
  );

  return (
    <div>
      {(userCanEdit || selectedNode?.type === NodeType.FILTER) && (
        <IconButton
          disabled={!canDelete}
          name="delete-tree-node-button"
          onClick={handleDelete}>
          <TrashIcon maxHeight={12} />
        </IconButton>
      )}
      <DeleteMotionFrameDialog />
    </div>
  );
};

export default SimulationTreeDeleteButton;
