// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { SimulationRowProps } from '../../../../lib/componentTypes/simulationTree';
import { IconSpec, TreeRow } from '../../TreeRow';

const PRIMARY_ICON: IconSpec = { name: 'cubeOutline' };

export const SizeTreeRow = (props: SimulationRowProps) => (
  <TreeRow
    {...props}
    primaryIcon={PRIMARY_ICON}
  />
);
