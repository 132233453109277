// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { useMemo } from 'react';

import { MultipleChoiceParam } from '../../../../ProtoDescriptor';
import { ParamName, paramDesc } from '../../../../SimulationParamDescriptor';
import assert from '../../../../lib/assert';
import { SelectOption } from '../../../../lib/componentTypes/form';
import { NodeTableType } from '../../../../lib/nodeTableUtil';
import { findSlidingInterfaceById } from '../../../../lib/slidingInterfaceUtils';
import { wordsToList } from '../../../../lib/text';
import { getVolumeIdsFromSurfaces, volumeIdsToNames } from '../../../../lib/volumeUtils';
import * as simulationpb from '../../../../proto/client/simulation_pb';
import { useEntityGroupMap } from '../../../../recoil/entityGroupState';
import { useStaticVolumes } from '../../../../recoil/volumes';
import { DataSelect } from '../../../Form/DataSelect';
import LabeledInput from '../../../Form/LabeledInput';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import Divider from '../../../Theme/Divider';
import { useCommonTreePropsStyles } from '../../../Theme/commonStyles';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { useSimulationConfig } from '../../../hooks/useSimulationConfig';
import { useVolumeNodes } from '../../../hooks/useVolumeNodes';
import { SectionMessage } from '../../../notification/SectionMessage';
import NodeTable from '../../NodeTable';
import PropertiesSection from '../../PropertiesSection';

function getInterfaceMethodOptions(
  selectedValue?: simulationpb.InterfaceType,
): SelectOption<simulationpb.InterfaceType>[] {
  return (paramDesc[ParamName.InterfaceType] as MultipleChoiceParam).choices.map((choice) => ({
    name: choice.text,
    tooltip: choice.help,
    value: choice.enumNumber,
    selected: choice.enumNumber === selectedValue,
  }));
}

interface InterfaceSideSurfacesProps {
  sideA?: boolean;
  slidingInterface: simulationpb.SlidingInterfaces;
  nodeIdToScope: (surfaceId: string) => string;
  readOnly?: boolean;
}

const InterfaceSideSurfaces = (props: InterfaceSideSurfacesProps) => {
  const { slidingInterface, nodeIdToScope, readOnly, sideA = false } = props;

  const surfaces = sideA ? slidingInterface.slidingA : slidingInterface.slidingB;

  const sideLabel = sideA ? 'A' : 'B';

  return (
    <>
      <NodeTable
        editable={!readOnly}
        nodeIds={surfaces}
        nodeIdToScope={nodeIdToScope}
        tableId={`motion-interface-side-${sideLabel}`}
        tableType={
          sideA ?
            NodeTableType.SLIDING_INTERFACE_SURFACES_A :
            NodeTableType.SLIDING_INTERFACE_SURFACES_B
        }
        title="Surfaces"
      />
    </>
  );
};

export const PhysicsSlidingInterfacePropPanel = () => {
  // Context
  const { selectedNode: node, activeNodeTable } = useSelectionContext();
  assert(!!node, 'No selected sliding interface row');
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();

  // Recoil state
  const staticVolumes = useStaticVolumes(projectId);
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);

  // Custom hooks
  const { surfaceIdToVolumeScope } = useVolumeNodes();

  // Other variables
  const propsClasses = useCommonTreePropsStyles();
  const { simParam, saveParam } = useSimulationConfig();
  const slidingInterface = useMemo(
    () => findSlidingInterfaceById(simParam, node.id),
    [node.id, simParam],
  );
  assert(!!slidingInterface, 'No selected sliding interface');

  const interfaceId = slidingInterface.slidingInterfaceId;
  const sideA = slidingInterface.slidingA;
  const sideB = slidingInterface.slidingB;

  // Form data
  const methodOptions = getInterfaceMethodOptions(slidingInterface.interfaceType);
  const volumesA = getVolumeIdsFromSurfaces(sideA || [], staticVolumes);
  const volumesB = getVolumeIdsFromSurfaces(sideB || [], staticVolumes);

  // Handlers
  const handleChangeMethod = (value: simulationpb.InterfaceType) => {
    saveParam((newParam) => {
      const newInterface = findSlidingInterfaceById(newParam, interfaceId);
      if (newInterface) {
        newInterface.interfaceType = value;
      }
    });
  };

  return (
    <div>
      <PropertiesSection>
        <CollapsibleNodePanel
          heading="Interface"
          nodeId={node.id}
          panelName="interface">
          <LabeledInput
            label="Method">
            <DataSelect
              asBlock
              disabled={readOnly}
              onChange={handleChangeMethod}
              options={methodOptions}
              size="small"
            />
          </LabeledInput>
        </CollapsibleNodePanel>
      </PropertiesSection>
      <Divider />
      <PropertiesSection>
        {(volumesA.length > 1 || volumesB.length > 1) && (
          <div className={propsClasses.sectionMessages}>
            <SectionMessage
              level="warning"
              message="Each interface side can only hold surfaces that belong to the same volume."
            />
          </div>
        )}
        <CollapsibleNodePanel
          disabled={activeNodeTable.type === NodeTableType.SLIDING_INTERFACE_SURFACES_A}
          heading="Side A"
          nodeId={node.id}
          panelName="sideA">
          <div className={propsClasses.sectionDescription}>
            Select all surfaces that represent one side of the interface
          </div>
          <InterfaceSideSurfaces
            nodeIdToScope={surfaceIdToVolumeScope}
            readOnly={readOnly}
            sideA
            slidingInterface={slidingInterface}
          />
          {!!volumesA.length && (
            <div className={propsClasses.nodeTableFooter}>
              Connected Volumes: {wordsToList(volumeIdsToNames(volumesA, entityGroupMap))}
            </div>
          )}
        </CollapsibleNodePanel>
      </PropertiesSection>
      <Divider />
      <PropertiesSection>
        <CollapsibleNodePanel
          disabled={activeNodeTable.type === NodeTableType.SLIDING_INTERFACE_SURFACES_B}
          heading="Side B"
          nodeId={node.id}
          panelName="sideB">
          <div className={propsClasses.sectionDescription}>
            Select all surfaces that represent the other side of the interface
          </div>
          <InterfaceSideSurfaces
            nodeIdToScope={surfaceIdToVolumeScope}
            readOnly={readOnly}
            slidingInterface={slidingInterface}
          />
          {!!volumesB.length && (
            <div className={propsClasses.nodeTableFooter}>
              Connected Volumes: {wordsToList(volumeIdsToNames(volumesB, entityGroupMap))}
            </div>
          )}
        </CollapsibleNodePanel>
      </PropertiesSection>
    </div>
  );
};
