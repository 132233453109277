// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import { useMemo } from 'react';

import assert from '../../lib/assert';
import { expandGroups } from '../../lib/entityGroupUtils';
import { volumeNodeIdsToCadIds } from '../../lib/geometryUtils';
import { Logger } from '../../lib/observability/logs';
import * as random from '../../lib/random';
import * as rpc from '../../lib/rpc';
import { NodeType } from '../../lib/simulationTree/node';
import { addRpcError } from '../../lib/transientNotification';
import * as geometryservicepb from '../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import * as geometrypb from '../../proto/geometry/geometry_pb';
import { useEntityGroupData } from '../../recoil/entityGroupState';
import { useGeometryServerStatus } from '../../recoil/geometry/geometryServerStatus';
import { DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE, GeometryState, useGeometrySelectedFeature, useGeometryState, useSetGeometryState } from '../../recoil/geometry/geometryState';
import { useGeometryTags } from '../../recoil/geometry/geometryTagsState';
import { useSelectedGeometry } from '../../recoil/selectedGeometry';
import { useCadMetadata } from '../../recoil/useCadMetadata';
import { useStaticVolumes } from '../../recoil/volumes';
import { useIsGeometryView } from '../../state/internal/global/currentView';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';

import { useGetNodeFromAnyTree } from './useTree';

const logger = new Logger('useInteractiveGeometry');

/*
  * Returns a reason why the delete button should be disabled. If the delete button should not be
  * disabled, returns undefined.
*/
function disableDeleteReason(
  readOnly: boolean,
  featureIndex: number | undefined,
  geometryServerStatus: string,
) {
  if (readOnly) {
    return 'Cannot delete features in read-only mode';
  }
  if (featureIndex === 0) {
    return 'Cannot delete initial geometry import';
  }
  if (geometryServerStatus === 'busy') {
    return 'Cannot delete features while the server is processing';
  }
  return undefined;
}

export const useDeleteGeometryModification = (rightClickNodeId?: string) => {
  const { projectId, geometryId, readOnly } = useProjectContext();
  const { selectedNode: node, setSelection } = useSelectionContext();

  const geoState = useGeometryState(projectId, geometryId);
  const setGeoState = useSetGeometryState(projectId, geometryId);
  const [geometryServerStatus, setGeometryServerStatus] = useGeometryServerStatus(geometryId);
  const [, setSelectedFeature] = useGeometrySelectedFeature(geometryId);

  // When right clicking, the selected node may be null or a different node altogether. For the
  // right click context menu, we pass in the id of the feature we want to delete.
  const featureId = rightClickNodeId || node?.id;

  const featureIndex = useMemo(() => (
    geoState?.geometryFeatures.findIndex((item) => item.id === featureId)
  ), [geoState?.geometryFeatures, featureId]);
  const disabledDeleteReason = disableDeleteReason(readOnly, featureIndex, geometryServerStatus);

  const deleteRow = async () => {
    if (!featureId) {
      return;
    }
    // First step is to always delete the feature from the client's state. We will decide later
    // what to do on the server-side.
    setGeoState((oldGeoState) => {
      if (oldGeoState === undefined) {
        return undefined;
      }
      return {
        ...oldGeoState,
        geometryFeatures: oldGeoState.geometryFeatures.filter(({ id }) => id !== featureId),
      };
    });

    // Update the selected feature, so that the feature manager does not end up with a stale
    // reference to the latest feature. See LC-21271.
    setSelectedFeature(DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE);
    // The server returns the latest tessellation so we need to reset the selection state so that
    // we are consistent with the tessellation being sent back.
    setSelection([]);

    // If the server is aware of the feature we are deleting, we need to request a deletion to
    // the server.
    const acked = geoState?.ackModifications.has(featureId);
    if (acked) {
      setGeometryServerStatus('busy');
      const feature = new geometrypb.Feature({
        id: featureId,
      });
      const req = new geometryservicepb.ModifyGeometryRequest({
        geometryId,
        modification: new geometrypb.Modification({
          modType: geometrypb.Modification_ModificationType.DELETE_FEATURE,
          feature,
        }),
      });
      try {
        await rpc.clientGeometry!.modifyGeometry(req).catch((error) => logger.error(error));
      } catch (error) {
        addRpcError('Failed to delete geometry modification', error);
      }
    }
  };

  return { deleteRow, disabledDeleteReason };
};

/**
 * Returns true if the geometry in the setup tab is up to date with the latest geometry version.
 * */
export const useIsSetupGeometryUpToDate = () => {
  const { projectId, geometryId } = useProjectContext();

  const geoState = useGeometryState(projectId, geometryId);
  const [selectedGeometry] = useSelectedGeometry(projectId);

  const existingGeoVersionId = selectedGeometry?.geometryVersionId;
  const lastEntry = geoState?.geometryHistory[geoState.geometryHistory.length - 1];
  const geometryVersionId = lastEntry?.historyEntry?.geometryVersionNewId;

  return !!existingGeoVersionId && geometryVersionId === existingGeoVersionId;
};

/**
 * Returns a hook to be called when renaming a geometry feature and a boolean indicating whether
 * the feature renaming is disabled.
 */
export const useRenameGeometryFeature = () => {
  const { projectId, geometryId, readOnly } = useProjectContext();
  const geoState = useGeometryState(projectId, geometryId);
  const setGeoState = useSetGeometryState(projectId, geometryId);
  const [geometryServerStatus, setGeometryServerStatus] = useGeometryServerStatus(geometryId);
  const isFeatureRenameDisabled = readOnly || geometryServerStatus === 'busy';

  const renameGeometryFeature = (featureId: string, newName: string) => {
    if (!geoState) {
      return;
    }

    // Start by updating our local state with the new name.
    setGeoState((oldGeoState) => {
      if (!oldGeoState) {
        return undefined;
      }
      return {
        ...oldGeoState,
        geometryFeatures: oldGeoState.geometryFeatures.map((feature) => {
          if (feature.id === featureId) {
            return {
              ...feature,
              featureName: newName,
            };
          }
          return feature;
        }),
      } as GeometryState;
    });

    // If this feature has not been sent to the server yet, we don't need to send a request. This
    // can happen if users try to rename the feature before clicking on apply.
    if (!geoState.ackModifications.has(featureId)) {
      return;
    }

    setGeometryServerStatus('busy');
    // Request a rename to the server.
    const req = new geometryservicepb.ModifyGeometryRequest({
      geometryId,
      modification: new geometrypb.Modification({
        modType: geometrypb.Modification_ModificationType.RENAME_FEATURE,
        feature: new geometrypb.Feature({
          id: featureId,
          featureName: newName,
        }),
      }),
    });
    req.requestId = random.string(32);
    rpc.clientGeometry?.modifyGeometry(req).catch((err) => {
      logger.error('Failed to rename geometry modification', err);
    });
  };

  return { renameGeometryFeature, isFeatureRenameDisabled };
};

export const useTagsInteractiveGeometry = () => {
  const { projectId, geometryId, readOnly, jobId, workflowId } = useProjectContext();
  const geoState = useGeometryState(projectId, geometryId);
  const [geometryServerStatus, setGeometryServerStatus] = useGeometryServerStatus(geometryId);
  const geometryTags = useGeometryTags(projectId);
  const entityGroupData = useEntityGroupData(projectId, workflowId, jobId);
  const isGeometryView = useIsGeometryView();
  const getNodeFromAnyTree = useGetNodeFromAnyTree();
  const isTagRenameDisabled = readOnly || geometryServerStatus === 'busy' || !isGeometryView;
  const isCreateTagDisabled = isTagRenameDisabled;
  const isRemoveTagDisabled = isTagRenameDisabled;
  const [cadMetadata] = useCadMetadata(projectId);
  const staticVolumes = useStaticVolumes(projectId);

  const renameTag = async (nodeId: string, newName: string) => {
    if (!geoState) {
      return;
    }

    assert(geometryTags.isTagId(nodeId), 'Expected a tag ID');

    const oldName = geometryTags.tagNameFromId(nodeId);
    assert(!!oldName, 'Expected a tag name');
    setGeometryServerStatus('busy');
    const req = new geometryservicepb.ModifyGeometryRequest({
      geometryId,
      requestId: random.string(32),
      modification: new geometrypb.Modification({
        modType: geometrypb.Modification_ModificationType.RENAME_TAG,
        renameTag: new geometrypb.RenameTag({
          newName,
          oldName,
        }),
      }),
    });
    await rpc.clientGeometry?.modifyGeometry(req).catch((err) => {
      addRpcError('Failed to rename tag', err);
      logger.error('Failed to rename geometry modification', err);
    });
  };

  const createTag = async (tagName: string, nodeIds: string[]) => {
    if (!geoState) {
      return;
    }

    const ids = expandGroups(entityGroupData.leafMap)(nodeIds);
    const faceIds = ids.map((surface) => {
      const node = getNodeFromAnyTree(surface);
      if (!node) {
        return undefined;
      }

      if (node.type === NodeType.SURFACE) {
        const surfaceSplit = surface.split('/bound/BC_');
        assert(surfaceSplit.length === 2, 'Expected a surface name');
        return Number(BigInt(surfaceSplit[1]));
      }
      return undefined;
    }).filter((id) => id !== undefined) as number[];

    const bodyIds = ids.map((surface) => {
      const node = getNodeFromAnyTree(surface);
      if (!node) {
        return undefined;
      }

      if (node.type === NodeType.VOLUME) {
        return Number(volumeNodeIdsToCadIds([surface], staticVolumes, cadMetadata)[0]);
      }
      return undefined;
    }).filter((id) => id !== undefined) as number[];

    setGeometryServerStatus('busy');
    // Request a rename to the server.
    const req = new geometryservicepb.ModifyGeometryRequest({
      requestId: random.string(32),
      geometryId,
      modification: new geometrypb.Modification({
        modType: geometrypb.Modification_ModificationType.CREATE_TAG,
        createOrUpdateTag: new geometrypb.CreateOrUpdateTag({
          name: tagName,
          faces: faceIds,
          bodies: bodyIds,
        }),
      }),
    });
    await rpc.clientGeometry?.modifyGeometry(req).catch((err) => {
      addRpcError('Failed to create tag', err);
      logger.error('Failed to rename geometry modification', err);
    });
  };

  const removeTags = async (nodeIds: string[]) => {
    if (!geoState) {
      return;
    }

    setGeometryServerStatus('busy');
    const req = () => {
      assert(nodeIds.length > 0, 'Expected at least one tag ID');
      assert(geometryTags.isTagId(nodeIds[0]), 'Expected a tag ID');
      if (nodeIds.length === 1) {
        const oldName = geometryTags.tagNameFromId(nodeIds[0]);
        return new geometryservicepb.ModifyGeometryRequest({
          geometryId,
          requestId: random.string(32),
          modification: new geometrypb.Modification({
            modType: geometrypb.Modification_ModificationType.DELETE_TAG,
            deleteTag: new geometrypb.DeleteTag({
              name: oldName,
            }),
          }),
        });
      }
      const tagNames = nodeIds.map((id) => {
        assert(geometryTags.isTagId(id), 'Expected a tag ID');
        return geometryTags.tagNameFromId(id)!;
      });
      return new geometryservicepb.ModifyGeometryRequest({
        geometryId,
        requestId: random.string(32),
        modification: new geometrypb.Modification({
          modType: geometrypb.Modification_ModificationType.DELETE_TAGS,
          deleteTags: new geometrypb.DeleteTags({
            names: tagNames,
          }),
        }),
      });
    };

    await rpc.clientGeometry?.modifyGeometry(req()).catch((err) => {
      addRpcError('Failed to remove tag', err);
      logger.error('Failed to rename geometry modification', err);
    });
  };

  return {
    renameTag,
    isTagRenameDisabled,
    createTag,
    isCreateTagDisabled,
    removeTag: removeTags,
    isRemoveTagDisabled,
  };
};
