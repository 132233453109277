// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { getQuantityTags } from '../../../../../QuantityDescriptor';
import { NodeTableType } from '../../../../../lib/nodeTableUtil';
import { calcForceOptions, calcIntegralOptions, getOutputQuantity } from '../../../../../lib/outputNodeUtils';
import { useOutput } from '../../../../../model/hooks/useOutput';
import * as feoutputpb from '../../../../../proto/frontend/output/output_pb';
import { QuantityTag } from '../../../../../proto/quantity/quantity_options_pb';
import { DataSelect } from '../../../../Form/DataSelect';
import LabeledInput from '../../../../Form/LabeledInput';
import { usePorousForceOutput } from '../../../../hooks/output/usePorousForceOutput';
import { SectionMessage } from '../../../../notification/SectionMessage';
import NodeTable from '../../../NodeTable';

const { CALCULATION_DIFFERENCE } = feoutputpb.CalculationType;

interface OutputSurfacesProps {
  outputNode: feoutputpb.OutputNode;
  projectId: string;
  idBase: string;
}

export function OutputSurfaces(props: OutputSurfacesProps) {
  const { idBase, outputNode, projectId } = props;

  const quantity = getOutputQuantity(outputNode);
  if (!quantity) {
    throw Error('No quantity found.');
  }
  const { updateOutputNode } = useOutput(projectId, outputNode.id);

  const titleBase = 'Surfaces and Groups';
  const isDifference = (outputNode.calcType === CALCULATION_DIFFERENCE);
  const titleA = isDifference ? `IN ${titleBase}` : titleBase;

  const {
    showWarning,
    addAllMissingSurfaces,
    dismissWarning,
    warningText,
  } = usePorousForceOutput(outputNode, idBase);

  const readOnlyCalcType = getQuantityTags(quantity).includes(QuantityTag.TAG_DROP);

  return (
    <>
      <NodeTable
        editable
        nodeIds={outputNode.inSurfaces}
        tableId={`output-${idBase}-in`}
        tableType={NodeTableType.IN_SURFACES}
        title={titleA}
        warning={showWarning}
      />
      {showWarning && (
        <div style={{ marginTop: '8px' }}>
          <SectionMessage
            level="warning"
            links={[
              { label: 'Add All', onClick: addAllMissingSurfaces },
            ]}
            onDismiss={dismissWarning}>
            {warningText}
          </SectionMessage>
        </div>
      )}
      {isDifference && (
        <NodeTable
          editable
          nodeIds={outputNode.outSurfaces}
          tableId={`output-${idBase}-out`}
          tableType={NodeTableType.OUT_SURFACES}
          title={`OUT ${titleBase}`}
        />
      )}
      <LabeledInput
        help="Select the calculation type for multiple surfaces"
        label="Calculation">
        <DataSelect
          asBlock
          disabled={readOnlyCalcType}
          onChange={(newType) => updateOutputNode((newOutput) => {
            newOutput.calcType = newType;
            if (newType !== feoutputpb.CalculationType.CALCULATION_DIFFERENCE) {
              newOutput.outSurfaces = [];
            }
          })}
          options={
            outputNode.nodeProps.case === 'force' ?
              calcForceOptions(false, outputNode.calcType) :
              calcIntegralOptions(false, outputNode.calcType)
          }
          readOnly={readOnlyCalcType}
          size="small"
        />
      </LabeledInput>
    </>
  );
}
