// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement } from 'react';

import { getAdValue, newAdFloat } from '../../../../lib/adUtils';
import { validateGtZeroInt, validateGteOne, validateGteZero } from '../../../../lib/inputValidationUtils';
import newInt from '../../../../lib/intUtils';
import { BOUNDARY_ID, COMMON_START_ICON, adaptationBoundaryHeading } from '../../../../lib/mesh';
import { NodeTableType } from '../../../../lib/nodeTableUtil';
import { fromBigInt } from '../../../../lib/number';
import { DEFAULT_ADAPTATION_BL } from '../../../../lib/paramDefaults/boundaryLayerProfileState';
import { SelectionAction } from '../../../../lib/selectionUtils';
import { getOrCreateAdaptiveMeshRefinement } from '../../../../lib/simulationParamUtils';
import { defaultAdaptationBoundaryLayerParams } from '../../../../lib/simulationUtils';
import { wordsToList } from '../../../../lib/text';
import { BoundaryLayerProfile } from '../../../../proto/client/simulation_pb';
import * as meshgenerationpb from '../../../../proto/meshgeneration/meshgeneration_pb';
import { QuantityType } from '../../../../proto/quantity/quantity_pb';
import { WarningLocation, useMeshValidator } from '../../../../recoil/useMeshValidator';
import { useSetMeshMultiPart } from '../../../../recoil/useMeshingMultiPart';
import { pushConfirmation, useSetConfirmations } from '../../../../state/internal/dialog/confirmations';
import { ActionButton } from '../../../Button/ActionButton';
import { IconButton } from '../../../Button/IconButton';
import Form from '../../../Form';
import { NumberField } from '../../../Form/NumberField';
import { CollapsibleNodePanel } from '../../../Panel/CollapsibleNodePanel';
import Divider from '../../../Theme/Divider';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';
import { useSimulationConfig } from '../../../hooks/useSimulationConfig';
import { SectionMessage } from '../../../notification/SectionMessage';
import { ArrowUpRightIcon } from '../../../svg/ArrowUpRightIcon';
import { ResetIcon } from '../../../svg/ResetIcon';
import { TrashIcon } from '../../../svg/TrashIcon';
import NodeTable from '../../NodeTable';
import PropertiesSection from '../../PropertiesSection';
import { CustomCount } from '../shared/CustomCount';

interface SelectionChange {
  selection: string[],
  type: meshgenerationpb.MeshingMultiPart_BoundaryLayerParams_SelectionType,
  overlapIndices: number[],
}

interface MeshBoundaryParamsProps {
  // The index of the boundary layer params this is displaying.
  boundaryIndex: number;
  // If the panel contains inputs rather than constant values.
  isInput: boolean;
  // Set a pending selection that must be confirmed before it is applied.
  setPendingChange?: (selectionChange: SelectionChange) => void;
}

// A panel displaying a single BoundaryParams.
export const MeshAdaptationBoundaryParams = (props: MeshBoundaryParamsProps) => {
  const { boundaryIndex, isInput } = props;
  const { projectId, workflowId, jobId, readOnly } = useProjectContext();
  const { setSelection, setScrollTo, activeNodeTable } = useSelectionContext();
  const { simParam, saveParam } = useSimulationConfig();
  const boundary = simParam.adaptiveMeshRefinement?.boundaryLayerProfile[
    boundaryIndex
  ];

  const {
    disabledLevel,
    disabledReason,
    warningLocations,
  } = useMeshValidator(projectId, workflowId, jobId, readOnly);

  const showWarning = isInput && warningLocations.includes(WarningLocation.BL_SIZE);

  if (!boundary) {
    return null;
  }
  if (isInput && !props.setPendingChange) {
    throw new Error('setPendingChange expected for an input.');
  }
  const updateBoundary = (updateFunc: (params: BoundaryLayerProfile) => void) => {
    saveParam((newParam) => {
      const amr = getOrCreateAdaptiveMeshRefinement(newParam).clone();
      updateFunc(amr.boundaryLayerProfile[boundaryIndex]);
      newParam.adaptiveMeshRefinement = amr;
      return newParam;
    });
  };

  const isDefault = (boundaryIndex === 0);
  const headerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (isDefault) {
      // The default has a reset button that resets everything.
      saveParam((newParam) => {
        const amr = getOrCreateAdaptiveMeshRefinement(newParam).clone();
        amr.boundaryLayerProfile = [defaultAdaptationBoundaryLayerParams(simParam)];
        newParam.adaptiveMeshRefinement = amr;
        return newParam;
      });
    } else {
      // The other sections have a trash button that delete this particular boundary layer.
      saveParam((newParam) => {
        const amr = getOrCreateAdaptiveMeshRefinement(newParam).clone();
        const newBoundaryList = amr.boundaryLayerProfile?.splice(boundaryIndex, 1) || [];
        amr.boundaryLayerProfile = newBoundaryList;
        newParam.adaptiveMeshRefinement = amr;
        return newParam;
      });
    }
  };
  const editClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setSelection([BOUNDARY_ID]);
    setScrollTo({ node: BOUNDARY_ID });
  };
  const headerButton = isInput ? (
    <IconButton
      disabled={readOnly}
      onClick={headerClick}>
      {isDefault ? (
        <ResetIcon maxHeight={13} />
      ) : (
        <TrashIcon maxHeight={13} />
      )}
    </IconButton>
  ) : (
    <ActionButton kind="minimal" onClick={editClick} size="small">
      {readOnly ? 'View' : 'Edit'}
      <ArrowUpRightIcon maxHeight={9} />
    </ActionButton>
  );

  const showNodeTable = isInput;

  return (
    <PropertiesSection>
      <CollapsibleNodePanel
        disabled={activeNodeTable.type === NodeTableType.MESHING_ADAPTATION_BL}
        headerRight={headerButton}
        heading={adaptationBoundaryHeading(boundaryIndex)}
        nodeId={`${BOUNDARY_ID}-${boundaryIndex}`}
        panelName="main">
        <Form.LabeledInput label="Number of Layers">
          <NumberField
            asBlock
            disabled={readOnly}
            isInput={isInput}
            onCommit={(newValue: number) => updateBoundary((params: BoundaryLayerProfile) => {
              params.nLayers = newInt(newValue);
            })}
            readOnly={readOnly}
            validate={validateGtZeroInt}
            value={boundary.nLayers ? fromBigInt(boundary.nLayers.value) : null}
          />
        </Form.LabeledInput>
        <Form.LabeledInput label="Initial Size">
          <NumberField
            asBlock
            disabled={readOnly}
            faultType={warningLocations.includes(WarningLocation.BL_SIZE) ?
              'warning' : undefined}
            isInput={isInput}
            onCommit={(newValue: number) => updateBoundary((params: BoundaryLayerProfile) => {
              params.initialSize = newAdFloat(newValue);
            })}
            quantity={QuantityType.LENGTH}
            readOnly={readOnly}
            validate={validateGteZero}
            value={getAdValue(boundary.initialSize) ?? null}
          />
        </Form.LabeledInput>
        {showWarning && (
          <div style={{ marginTop: '8px' }}>
            <SectionMessage level={disabledLevel}>
              {disabledReason}
            </SectionMessage>
          </div>
        )}
        <Form.LabeledInput label="Growth Rate">
          <NumberField
            asBlock
            disabled={readOnly}
            isInput={isInput}
            onCommit={(newValue: number) => updateBoundary((params: BoundaryLayerProfile) => {
              params.growthRate = newAdFloat(newValue);
            })}
            readOnly={readOnly}
            validate={validateGteOne}
            value={getAdValue(boundary.growthRate) ?? null}
          />
        </Form.LabeledInput>
        {showNodeTable && (
          <div style={{ paddingTop: '8px' }}>
            <NodeTable
              editable={!readOnly}
              formGroups
              nodeIds={boundary.surfaces}
              tableId={`meshing-bl-${boundaryIndex}`}
              tableIndex={boundaryIndex}
              tableType={NodeTableType.MESHING_ADAPTATION_BL}
              title="Surfaces"
            />
          </div>
        )}
        {!isInput && (
          <CustomCount
            count={simParam.adaptiveMeshRefinement!.boundaryLayerProfile.length - 1}
          />
        )}
      </CollapsibleNodePanel>
    </PropertiesSection>
  );
};

// A panel for displaying the mesh boundary layer parameters.
export const AdaptationBoundaryPropPanel = () => {
  const { projectId, readOnly } = useProjectContext();
  const setMeshMultiPart = useSetMeshMultiPart(projectId);
  const { modifySelection } = useSelectionContext();
  const setConfirmStack = useSetConfirmations();
  const { simParam, saveParam } = useSimulationConfig();
  const amr = getOrCreateAdaptiveMeshRefinement(simParam);
  const boundaryLayersList = amr.boundaryLayerProfile;

  const confirmChange = (pendingChange: SelectionChange) => {
    const headings = pendingChange.overlapIndices.map((idx) => adaptationBoundaryHeading(idx));
    pushConfirmation(setConfirmStack, {
      onContinue: () => {
        setMeshMultiPart((oldMeshMultiPart) => {
          const newMeshMultiPart = oldMeshMultiPart!.clone();
          newMeshMultiPart.blParams[0].selection = pendingChange.type;
          return newMeshMultiPart;
        });
        modifySelection({
          action: SelectionAction.OVERWRITE_EXCLUDE,
          modificationIds: pendingChange.selection,
          nodeTableOverride: { type: NodeTableType.MESHING_BL, index: 0 },
          updateHighlighting: false,
        });
      },
      subtitle: `This will remove some surfaces from ${wordsToList(headings)}.
        Do you wish to continue?`,
      title: 'Confirm',
    });
  };

  const addCustomMeshSize = () => {
    saveParam((param) => {
      const newBL = DEFAULT_ADAPTATION_BL.clone();
      getOrCreateAdaptiveMeshRefinement(param).boundaryLayerProfile.push(newBL);
      return param;
    });
  };

  const boundaryList: ReactElement[] = [];
  for (let i = 0; i < boundaryLayersList.length; i += 1) {
    boundaryList.push(
      <MeshAdaptationBoundaryParams
        boundaryIndex={i}
        isInput
        key={`vol-${i}`}
        setPendingChange={confirmChange}
      />,
    );
    boundaryList.push(<Divider key={`div-${i}`} />);
  }
  return (
    <div>
      {boundaryList}
      <PropertiesSection>
        <ActionButton
          disabled={readOnly}
          kind="secondary"
          onClick={addCustomMeshSize}
          size="small"
          startIcon={COMMON_START_ICON}>
          Custom Boundary Layer
        </ActionButton>
      </PropertiesSection>
    </div>
  );
};
