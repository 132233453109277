// Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import { SimulationRowProps } from '../../../lib/componentTypes/simulationTree';
import { useGeometryContacts } from '../../../recoil/geometryContactsState';
import { useProjectContext } from '../../context/ProjectContext';
import { useSelectionContext } from '../../context/SelectionManager';
import { ComputeGeometryContacts } from '../ComputeGeometryContacts';
import { IconSpec, TreeRow } from '../TreeRow';

const PRIMARY_ICON: IconSpec = { name: 'verticalPlanesFilledOutlined' };

export const GeometryContactContainerTreeRow = (props: SimulationRowProps) => {
  // We don't allow to compute contacts in readOnly mode (i.e. in projects with read only mode or
  // in the simulation tabs). Also, we do not allow rerunning the contacts operator if a previous
  // contact operation failed.
  const { projectId, readOnly } = useProjectContext();
  const { isTreeModal } = useSelectionContext();

  const contacts = useGeometryContacts(projectId);
  const canRunGeometryContacts = (
    !contacts.operationError &&
    !isTreeModal &&
    !readOnly &&
    !contacts.found
  );

  return (
    <TreeRow
      {...props}
      addControl={canRunGeometryContacts ? <ComputeGeometryContacts /> : undefined}
      earlyAccess
      primaryIcon={PRIMARY_ICON}
    />
  );
};
