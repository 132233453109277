// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/lcstatus/details/geometry/geometry.proto (package luminary.proto.lcstatus.details.geometry, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Vector3 } from "../../../base/base_pb.js";

/**
 * Geometric information about a vertex.
 *
 * @generated from message luminary.proto.lcstatus.details.geometry.Vertex
 */
export const Vertex = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcstatus.details.geometry.Vertex",
  () => [
    { no: 1, name: "adjacent_surfaces", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "coords", kind: "message", T: Vector3 },
  ],
);

/**
 * Geometric information about an edge.
 *
 * @generated from message luminary.proto.lcstatus.details.geometry.Edge
 */
export const Edge = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcstatus.details.geometry.Edge",
  () => [
    { no: 1, name: "adjacent_surfaces", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * Details for code GEO_VERTEX_DUPLICATE
 *
 * @generated from message luminary.proto.lcstatus.details.geometry.GeoVertexDuplicateDetails
 */
export const GeoVertexDuplicateDetails = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcstatus.details.geometry.GeoVertexDuplicateDetails",
  () => [
    { no: 1, name: "vertex_1", kind: "message", T: Vertex },
    { no: 2, name: "vertex_2", kind: "message", T: Vertex },
  ],
);

/**
 * Details for code GEO_EDGE_UNMESHABLE
 *
 * @generated from message luminary.proto.lcstatus.details.geometry.GeoEdgeUnmeshableDetails
 */
export const GeoEdgeUnmeshableDetails = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcstatus.details.geometry.GeoEdgeUnmeshableDetails",
  () => [
    { no: 1, name: "edge", kind: "message", T: Edge },
  ],
);

/**
 * Details for code GEO_EDGE_NOT_SMOOTH
 *
 * @generated from message luminary.proto.lcstatus.details.geometry.GeoEdgeNotSmoothDetails
 */
export const GeoEdgeNotSmoothDetails = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcstatus.details.geometry.GeoEdgeNotSmoothDetails",
  () => [
    { no: 1, name: "edge", kind: "message", T: Edge },
  ],
);

/**
 * Details for code GEO_EDGE_LARGE_TOLERANCE
 *
 * @generated from message luminary.proto.lcstatus.details.geometry.GeoEdgeLargeToleranceDetails
 */
export const GeoEdgeLargeToleranceDetails = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcstatus.details.geometry.GeoEdgeLargeToleranceDetails",
  () => [
    { no: 1, name: "edge", kind: "message", T: Edge },
    { no: 2, name: "tolerance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * Details for code GEO_FACE_UNMESHABLE
 *
 * @generated from message luminary.proto.lcstatus.details.geometry.GeoFaceUnmeshableDetails
 */
export const GeoFaceUnmeshableDetails = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcstatus.details.geometry.GeoFaceUnmeshableDetails",
  () => [
    { no: 1, name: "surface_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Details for code GEO_FACE_EDGES_TOO_CLOSE
 *
 * @generated from message luminary.proto.lcstatus.details.geometry.GeoFaceEdgesTooCloseDetails
 */
export const GeoFaceEdgesTooCloseDetails = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcstatus.details.geometry.GeoFaceEdgesTooCloseDetails",
  () => [
    { no: 1, name: "surface_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "aspect_ratio", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * Details for code GEO_FACE_EDGE_TOO_SMALL
 *
 * @generated from message luminary.proto.lcstatus.details.geometry.GeoFaceEdgeTooSmallDetails
 */
export const GeoFaceEdgeTooSmallDetails = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcstatus.details.geometry.GeoFaceEdgeTooSmallDetails",
  () => [
    { no: 1, name: "surface_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Details for code GEO_FACE_FACE_INTERSECTION
 *
 * @generated from message luminary.proto.lcstatus.details.geometry.GeoFaceFaceIntersectionDetails
 */
export const GeoFaceFaceIntersectionDetails = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcstatus.details.geometry.GeoFaceFaceIntersectionDetails",
  () => [
    { no: 1, name: "surface_1_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "surface_2_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Details for code GEO_FACE_SELF_INTERSECTION
 *
 * @generated from message luminary.proto.lcstatus.details.geometry.GeoFaceSelfIntersectionDetails
 */
export const GeoFaceSelfIntersectionDetails = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcstatus.details.geometry.GeoFaceSelfIntersectionDetails",
  () => [
    { no: 1, name: "surface_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Details for code GEO_FACE_NOT_SMOOTH
 *
 * @generated from message luminary.proto.lcstatus.details.geometry.GeoFaceNotSmoothDetails
 */
export const GeoFaceNotSmoothDetails = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcstatus.details.geometry.GeoFaceNotSmoothDetails",
  () => [
    { no: 1, name: "surface_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Details for code GEO_FACE_LARGE_TOLERANCE
 *
 * @generated from message luminary.proto.lcstatus.details.geometry.GeoFaceLargeToleranceDetails
 */
export const GeoFaceLargeToleranceDetails = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcstatus.details.geometry.GeoFaceLargeToleranceDetails",
  () => [
    { no: 1, name: "surface_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tolerance", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * Details for code GEO_FACE_EDGE_CROSS
 *
 * @generated from message luminary.proto.lcstatus.details.geometry.GeoFaceEdgeCrossDetails
 */
export const GeoFaceEdgeCrossDetails = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcstatus.details.geometry.GeoFaceEdgeCrossDetails",
  () => [
    { no: 1, name: "surface_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "edge_1", kind: "message", T: Edge },
    { no: 3, name: "edge_2", kind: "message", T: Edge },
  ],
);

/**
 * Details for code GEO_FACE_NEEDS_IMPRINT
 *
 * @generated from message luminary.proto.lcstatus.details.geometry.GeoFaceNeedsImprintDetails
 */
export const GeoFaceNeedsImprintDetails = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcstatus.details.geometry.GeoFaceNeedsImprintDetails",
  () => [
    { no: 1, name: "surface_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Details for code GEO_CONTACT_NEEDS_IMPRINT
 *
 * @generated from message luminary.proto.lcstatus.details.geometry.GeoContactNeedsImprintDetails
 */
export const GeoContactNeedsImprintDetails = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcstatus.details.geometry.GeoContactNeedsImprintDetails",
  () => [
    { no: 1, name: "geometry_contact_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Details for code GEO_VOLUME_OPEN
 *
 * @generated from message luminary.proto.lcstatus.details.geometry.GeoVolumeOpenDetails
 */
export const GeoVolumeOpenDetails = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcstatus.details.geometry.GeoVolumeOpenDetails",
  () => [
    { no: 1, name: "volumes_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * Details for code GEO_VOLUME_NON_MANIFOLD
 *
 * @generated from message luminary.proto.lcstatus.details.geometry.GeoVolumeNonManifoldDetails
 */
export const GeoVolumeNonManifoldDetails = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcstatus.details.geometry.GeoVolumeNonManifoldDetails",
  () => [
    { no: 1, name: "volume_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * Details for code GEO_VOLUME_UNMESHABLE
 *
 * @generated from message luminary.proto.lcstatus.details.geometry.GeoVolumeUnmeshableDetails
 */
export const GeoVolumeUnmeshableDetails = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcstatus.details.geometry.GeoVolumeUnmeshableDetails",
  () => [
    { no: 1, name: "volume_id", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * @generated from message luminary.proto.lcstatus.details.geometry.GeoFeatureUnknownErrorDetails
 */
export const GeoFeatureUnknownErrorDetails = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcstatus.details.geometry.GeoFeatureUnknownErrorDetails",
  () => [
    { no: 1, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.lcstatus.details.geometry.GeoWorkerInvalidRequestDetails
 */
export const GeoWorkerInvalidRequestDetails = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.lcstatus.details.geometry.GeoWorkerInvalidRequestDetails",
  () => [
    { no: 1, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

