// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file proto/frontend/frontend.proto (package luminary.proto.frontend, syntax proto3)
/* eslint-disable */
// @ts-nocheck

// This file defines the RPC protocol between the jobmaster and frontend
// clients. This service is run using grpc-web protocol.
//
// See //doc/backend.md for more details.
//
// Credentials: every request requires a credential to be provided via the
// "authorization" header, set as part of the gRPC metadata.  The value should
// be "Bearer <token>", where <token> is a base64-encoded oauth token from auth0.
// It is recommended to use an interceptor to avoid having to repetitively set
// this value.
//
// For HTTP requests, the metadata must also present the
// "frontend-client-version" header in order to verify that the client schema
// version is compatible with the one used in the server.
//

import { Any, proto3, Timestamp } from "@bufbuild/protobuf";
import { AdVector3, Checksum, JobStatus, Status } from "../base/base_pb.js";
import { Checkpoint, Config, JobIncarnationStat } from "../workflow/workflow_pb.js";
import { PodConfig } from "../podconfig/podconfig_pb.js";
import { MeshType } from "../upload/upload_pb.js";
import { MeshFileMetadata } from "../lcn/lcmesh_pb.js";
import { Param, SurfaceName } from "../fvm/param_pb.js";
import { SimulationParam } from "../client/simulation_pb.js";
import { File } from "../file/file_pb.js";
import { IntegrationDirection, SeedPlacementParams, SeedPlacementType } from "../vis/vis_pb.js";
import { Values } from "../exploration/exploration_pb.js";
import { ClusterConfig, NodeReservation, NodeReservationState, ResourceUnit, UserReservation } from "../clusterconfig/clusterconfig_pb.js";
import { KeyInfo } from "../kms/kms_pb.js";
import { PlatformAdminUser } from "../platformadmin/platformadmin_pb.js";
import { Output, Result, StoppingCondition, StoppingConditionStatus } from "../output/output_pb.js";
import { ReferenceValues } from "../output/reference_values_pb.js";
import { ValidateExpressionResponse } from "../analyzer/analyzer_pb.js";
import { MeshingMultiPart, UserGeometry, UserGeometryMod, UserMeshingParams } from "../meshgeneration/meshgeneration_pb.js";
import { CadMetadata } from "../cadmetadata/cadmetadata_pb.js";
import { LCStatus } from "../lcstatus/lcstatus_pb.js";
import { RectilinearTable } from "../table/table_pb.js";

/**
 * @generated from enum luminary.proto.frontend.MeshConversionStatus
 */
export const MeshConversionStatus = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.frontend.MeshConversionStatus",
  [
    {no: 0, name: "NOT_REQUIRED"},
    {no: 2, name: "IN_PROGRESS"},
    {no: 3, name: "COMPLETE"},
    {no: 4, name: "FAILED"},
  ],
);

/**
 * @generated from enum luminary.proto.frontend.UserStatus
 */
export const UserStatus = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.frontend.UserStatus",
  [
    {no: 0, name: "INVALID"},
    {no: 1, name: "ENABLED"},
    {no: 2, name: "DISABLED"},
    {no: 3, name: "PENDING_REGISTRATION"},
    {no: 4, name: "PENDING_REACCEPT_TERMS"},
  ],
);

/**
 * @generated from enum luminary.proto.frontend.AccountStatus
 */
export const AccountStatus = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.frontend.AccountStatus",
  [
    {no: 0, name: "INVALID_STATUS"},
    {no: 1, name: "ACCOUNT_ACTIVE"},
    {no: 2, name: "ACCOUNT_DISABLED"},
  ],
);

/**
 * @generated from enum luminary.proto.frontend.CameraAccess
 */
export const CameraAccess = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.frontend.CameraAccess",
  [
    {no: 0, name: "INVALID_ACCESS"},
    {no: 1, name: "LOCAL"},
    {no: 2, name: "GLOBAL"},
  ],
);

/**
 * @generated from enum luminary.proto.frontend.WorkOrderType
 */
export const WorkOrderType = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.frontend.WorkOrderType",
  [
    {no: 0, name: "CHECK_GEOMETRY"},
    {no: 1, name: "GET_MESH"},
    {no: 2, name: "GET_GEOMETRY"},
    {no: 3, name: "COMPUTE_GEOMETRY_CONTACTS"},
  ],
);

/**
 * @generated from message luminary.proto.frontend.ListProjectsReply
 */
export const ListProjectsReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ListProjectsReply",
  () => [
    { no: 1, name: "project", kind: "message", T: ProjectSummary, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.SampleProject
 */
export const SampleProject = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.SampleProject",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "project_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "project_description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "documentation_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ListSampleProjectsReply
 */
export const ListSampleProjectsReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ListSampleProjectsReply",
  () => [
    { no: 1, name: "sample_projects", kind: "message", T: SampleProject, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.PublishSampleProjectRequest
 */
export const PublishSampleProjectRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.PublishSampleProjectRequest",
  () => [
    { no: 2, name: "sample_project", kind: "message", T: SampleProject },
  ],
);

/**
 * @generated from message luminary.proto.frontend.RemoveSampleProjectRequest
 */
export const RemoveSampleProjectRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.RemoveSampleProjectRequest",
  () => [
    { no: 2, name: "sample_project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetProjectRequest
 */
export const GetProjectRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetProjectRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetProjectReply
 */
export const GetProjectReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetProjectReply",
  () => [
    { no: 1, name: "summary", kind: "message", T: ProjectSummary },
    { no: 17, name: "workflow", kind: "message", T: WorkflowMetadata, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.WorkflowMetadata
 */
export const WorkflowMetadata = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.WorkflowMetadata",
  () => [
    { no: 1, name: "workflow_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "creation_time", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "update_time", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "status", kind: "message", T: JobStatus },
    { no: 5, name: "config", kind: "message", T: Config },
    { no: 6, name: "pod_config", kind: "message", T: PodConfig },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ProjectSummary
 */
export const ProjectSummary = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ProjectSummary",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "creation_time", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "update_time", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "owner", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "acl", kind: "message", T: ProjectAclEntry, repeated: true },
    { no: 8, name: "storage_bytes", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "compute_milli_credits", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.FileMetadata
 */
export const FileMetadata = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.FileMetadata",
  () => [
    { no: 1, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "file_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "file_type_seq", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "size", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 16, name: "checksum", kind: "message", T: Checksum },
    { no: 17, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.NewProjectRequest
 */
export const NewProjectRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.NewProjectRequest",
  () => [
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "nonce", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ProjectDescriptor
 */
export const ProjectDescriptor = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ProjectDescriptor",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.NewProjectReply
 */
export const NewProjectReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.NewProjectReply",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_project", kind: "message", T: ProjectDescriptor },
  ],
);

/**
 * @generated from message luminary.proto.frontend.CopyProjectParams
 */
export const CopyProjectParams = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.CopyProjectParams",
  () => [
    { no: 1, name: "source_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.CopyProjectRequest
 */
export const CopyProjectRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.CopyProjectRequest",
  () => [
    { no: 1, name: "params", kind: "message", T: CopyProjectParams },
  ],
);

/**
 * @generated from message luminary.proto.frontend.CopyProjectReply
 */
export const CopyProjectReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.CopyProjectReply",
  () => [
    { no: 1, name: "created_project", kind: "message", T: ProjectDescriptor },
  ],
);

/**
 * @generated from message luminary.proto.frontend.DeleteProjectRequest
 */
export const DeleteProjectRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.DeleteProjectRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.EditProjectRequest
 */
export const EditProjectRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.EditProjectRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * A subject can be either a user or SUPPORT
 * In the future, other subject types such as team or group or account may be supported.
 *
 * @generated from message luminary.proto.frontend.Subject
 */
export const Subject = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.Subject",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(Subject_Type) },
  ],
);

/**
 * Used to disambiguate "id" string which may be a user email
 * or account ID.
 *
 * @generated from enum luminary.proto.frontend.Subject.Type
 */
export const Subject_Type = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.frontend.Subject.Type",
  [
    {no: 0, name: "INVALID"},
    {no: 3, name: "LC_USER"},
    {no: 4, name: "SUPPORT"},
  ],
);

/**
 * @generated from message luminary.proto.frontend.ProjectAclEntry
 */
export const ProjectAclEntry = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ProjectAclEntry",
  () => [
    { no: 1, name: "subject", kind: "message", T: Subject },
    { no: 3, name: "role", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.UpdateProjectPermissionRequest
 */
export const UpdateProjectPermissionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.UpdateProjectPermissionRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "acl_update", kind: "message", T: ProjectAclEntry, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.NewWorkflowRequest
 */
export const NewWorkflowRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.NewWorkflowRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "config", kind: "message", T: Config },
  ],
);

/**
 * @generated from message luminary.proto.frontend.NewWorkflowReply
 */
export const NewWorkflowReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.NewWorkflowReply",
  () => [
    { no: 1, name: "workflow_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "job_id", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.SuspendWorkflowRequest
 */
export const SuspendWorkflowRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.SuspendWorkflowRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "workflow_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ResumeWorkflowRequest
 */
export const ResumeWorkflowRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ResumeWorkflowRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "workflow_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.DeleteWorkflowRequest
 */
export const DeleteWorkflowRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.DeleteWorkflowRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "workflow_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.DropWorkOrderRequest
 */
export const DropWorkOrderRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.DropWorkOrderRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "work_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.MeshConversionStatusRequest
 */
export const MeshConversionStatusRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.MeshConversionStatusRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "scaling", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "disconnect", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "mesh_type", kind: "enum", T: proto3.getEnumType(MeshType) },
    { no: 8, name: "do_not_read_zones_openfoam", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.MeshConversionStatusReply
 */
export const MeshConversionStatusReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.MeshConversionStatusReply",
  () => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(MeshConversionStatus) },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "typ" },
    { no: 3, name: "event", kind: "message", T: MeshConversionStatusReply_Event, oneof: "typ" },
    { no: 4, name: "err", kind: "message", T: Status, oneof: "typ" },
  ],
);

/**
 * @generated from message luminary.proto.frontend.MeshConversionStatusReply.Event
 */
export const MeshConversionStatusReply_Event = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.MeshConversionStatusReply.Event",
  () => [
    { no: 1, name: "event", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "progress_fraction", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
  {localName: "MeshConversionStatusReply_Event"},
);

/**
 * (a) The following fields remain constant for a given file, even during
 * multi-part uploads.
 *
 * @generated from message luminary.proto.frontend.UploadFileRequest
 */
export const UploadFileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.UploadFileRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "size", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 7, name: "checksum", kind: "message", T: Checksum },
    { no: 8, name: "start_offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 9, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 10, name: "scaling", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 11, name: "disconnect", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "mesh_type", kind: "enum", T: proto3.getEnumType(MeshType) },
    { no: 13, name: "do_not_read_zones_openfoam", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.UploadFileReply
 */
export const UploadFileReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.UploadFileReply",
  () => [
    { no: 1, name: "limit_offset", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "complete", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "conversion", kind: "enum", T: proto3.getEnumType(MeshConversionStatus) },
  ],
);

/**
 * @generated from message luminary.proto.frontend.UploadUrlRequest
 */
export const UploadUrlRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.UploadUrlRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "scaling", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "disconnect", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "mesh_type", kind: "enum", T: proto3.getEnumType(MeshType) },
    { no: 8, name: "do_not_read_zones_openfoam", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.UploadUrlReply
 */
export const UploadUrlReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.UploadUrlReply",
  () => [
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "conversion", kind: "enum", T: proto3.getEnumType(MeshConversionStatus) },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ValidateWorkflowConfigRequest
 */
export const ValidateWorkflowConfigRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ValidateWorkflowConfigRequest",
  () => [
    { no: 2, name: "config", kind: "message", T: Config },
    { no: 3, name: "mesh_meta", kind: "message", T: MeshFileMetadata },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ValidateWorkflowConfigReply
 */
export const ValidateWorkflowConfigReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ValidateWorkflowConfigReply",
  () => [
    { no: 1, name: "error", kind: "message", T: Status },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ReadFileIndexRequest
 */
export const ReadFileIndexRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ReadFileIndexRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ReadFileIndexReply
 */
export const ReadFileIndexReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ReadFileIndexReply",
  () => [
    { no: 3, name: "internal_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "metadata", kind: "message", T: Any, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ValidateParamRequest
 */
export const ValidateParamRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ValidateParamRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "fvm_param", kind: "message", T: Param },
    { no: 4, name: "simulation_param", kind: "message", T: SimulationParam },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ValidateParamReply
 */
export const ValidateParamReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ValidateParamReply",
  () => [
    { no: 1, name: "error_msg", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.VisMultiSliceRequest
 */
export const VisMultiSliceRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.VisMultiSliceRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "n_slices", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "start", kind: "message", T: AdVector3 },
    { no: 6, name: "end", kind: "message", T: AdVector3 },
    { no: 7, name: "project_vectors", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.VisMultiSliceReply
 */
export const VisMultiSliceReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.VisMultiSliceReply",
  () => [
    { no: 1, name: "result", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "request", kind: "message", T: VisMultiSliceRequest },
  ],
);

/**
 * @generated from message luminary.proto.frontend.VisPipelineRequest
 */
export const VisPipelineRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.VisPipelineRequest",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "workspace_state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "requested_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.VisPipelineReply
 */
export const VisPipelineReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.VisPipelineReply",
  () => [
    { no: 1, name: "filter_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "meta_files", kind: "message", T: File, repeated: true },
    { no: 3, name: "data_files", kind: "message", T: File, repeated: true },
    { no: 4, name: "request", kind: "message", T: VisPipelineRequest },
  ],
);

/**
 * @generated from message luminary.proto.frontend.VisProgressInfo
 */
export const VisProgressInfo = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.VisProgressInfo",
  () => [
    { no: 1, name: "progress", kind: "message", T: ProgressInfo },
    { no: 2, name: "filter_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.VisPipelineStreamReply
 */
export const VisPipelineStreamReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.VisPipelineStreamReply",
  () => [
    { no: 1, name: "urls_reply", kind: "message", T: VisPipelineReply },
    { no: 2, name: "progress", kind: "message", T: VisProgressInfo, repeated: true },
    { no: 3, name: "completed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.VisStreamlinesRequest
 */
export const VisStreamlinesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.VisStreamlinesRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "seed_placement_type", kind: "enum", T: proto3.getEnumType(SeedPlacementType) },
    { no: 5, name: "integration_direction", kind: "enum", T: proto3.getEnumType(IntegrationDirection) },
    { no: 6, name: "data_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "seed_placement_params", kind: "message", T: SeedPlacementParams },
    { no: 8, name: "maximum_length", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.VisStreamlinesReply
 */
export const VisStreamlinesReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.VisStreamlinesReply",
  () => [
    { no: 1, name: "result", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "request", kind: "message", T: VisStreamlinesRequest },
  ],
);

/**
 * @generated from message luminary.proto.frontend.VisSurfaceLICRequest
 */
export const VisSurfaceLICRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.VisSurfaceLICRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "seed_placement_type", kind: "enum", T: proto3.getEnumType(SeedPlacementType) },
    { no: 5, name: "integration_direction", kind: "enum", T: proto3.getEnumType(IntegrationDirection) },
    { no: 6, name: "data_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "seed_placement_params", kind: "message", T: SeedPlacementParams },
    { no: 8, name: "maximum_length", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 9, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.VisSurfaceLICReply
 */
export const VisSurfaceLICReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.VisSurfaceLICReply",
  () => [
    { no: 1, name: "result", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "request", kind: "message", T: VisSurfaceLICRequest },
  ],
);

/**
 * @generated from message luminary.proto.frontend.RenderDataParameters
 */
export const RenderDataParameters = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.RenderDataParameters",
  () => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "extra", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.RenderDataUrlRequest
 */
export const RenderDataUrlRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.RenderDataUrlRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "params", kind: "message", T: RenderDataParameters },
  ],
);

/**
 * @generated from message luminary.proto.frontend.RenderDataUrlReply
 */
export const RenderDataUrlReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.RenderDataUrlReply",
  () => [
    { no: 2, name: "raw_data", kind: "message", T: File },
    { no: 3, name: "metadata", kind: "message", T: File },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ProtoToJsonRequest
 */
export const ProtoToJsonRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ProtoToJsonRequest",
  () => [
    { no: 2, name: "data", kind: "message", T: Any },
    { no: 3, name: "use_stable_api", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ProtoToJsonReply
 */
export const ProtoToJsonReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ProtoToJsonReply",
  () => [
    { no: 1, name: "json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.FvmParamToJSONRequest
 */
export const FvmParamToJSONRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.FvmParamToJSONRequest",
  () => [
    { no: 2, name: "fvm_param", kind: "message", T: Param },
  ],
);

/**
 * @generated from message luminary.proto.frontend.FvmParamToJSONReply
 */
export const FvmParamToJSONReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.FvmParamToJSONReply",
  () => [
    { no: 1, name: "json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.SimulationParamToJSONRequest
 */
export const SimulationParamToJSONRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.SimulationParamToJSONRequest",
  () => [
    { no: 1, name: "simulation_param", kind: "message", T: SimulationParam },
  ],
);

/**
 * @generated from message luminary.proto.frontend.SimulationParamToJSONReply
 */
export const SimulationParamToJSONReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.SimulationParamToJSONReply",
  () => [
    { no: 1, name: "json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.JsonToProtoRequest
 */
export const JsonToProtoRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.JsonToProtoRequest",
  () => [
    { no: 3, name: "json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GrpcStatus
 */
export const GrpcStatus = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GrpcStatus",
  () => [
    { no: 1, name: "code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "details", kind: "message", T: Any, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.JsonToProtoReply
 */
export const JsonToProtoReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.JsonToProtoReply",
  () => [
    { no: 1, name: "data", kind: "message", T: Any },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetWorkflowsRequest
 */
export const GetWorkflowsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetWorkflowsRequest",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "workflow_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetWorkflowsReply
 */
export const GetWorkflowsReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetWorkflowsReply",
  () => [
    { no: 1, name: "workflows_replies", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: GetWorkflowReply} },
    { no: 2, name: "workflows_errors", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: GrpcStatus} },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetWorkflowRequest
 */
export const GetWorkflowRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetWorkflowRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "workflow_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetWorkflowReply
 */
export const GetWorkflowReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetWorkflowReply",
  () => [
    { no: 1, name: "config", kind: "message", T: Config },
    { no: 2, name: "pod_config", kind: "message", T: PodConfig },
    { no: 3, name: "checkpoint", kind: "message", T: Checkpoint },
    { no: 4, name: "status", kind: "message", T: JobStatus },
    { no: 5, name: "job", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: GetWorkflowReply_Job} },
    { no: 6, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetWorkflowReply.SchedulerEvent
 */
export const GetWorkflowReply_SchedulerEvent = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetWorkflowReply.SchedulerEvent",
  () => [
    { no: 1, name: "job_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "typ" },
    { no: 3, name: "error", kind: "message", T: Status, oneof: "typ" },
  ],
  {localName: "GetWorkflowReply_SchedulerEvent"},
);

/**
 * Per-job status.
 *
 * @generated from message luminary.proto.frontend.GetWorkflowReply.Job
 */
export const GetWorkflowReply_Job = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetWorkflowReply.Job",
  () => [
    { no: 1, name: "job_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "job_incarnation", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "last_incarnation_stat", kind: "message", T: JobIncarnationStat },
    { no: 2, name: "creation_time", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "update_time", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "status", kind: "message", T: JobStatus },
    { no: 5, name: "exploration_values", kind: "message", T: Values },
    { no: 6, name: "last_solution", kind: "message", T: Solution },
    { no: 9, name: "last_scheduler_event", kind: "message", T: GetWorkflowReply_SchedulerEvent },
    { no: 13, name: "run_time", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "credits", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 14, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "latest_iter", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 16, name: "latest_time", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
  {localName: "GetWorkflowReply_Job"},
);

/**
 * Unique identifier for a job.
 *
 * @generated from message luminary.proto.frontend.JobSpec
 */
export const JobSpec = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.JobSpec",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "workflow_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "job_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetJobRequest
 */
export const GetJobRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetJobRequest",
  () => [
    { no: 2, name: "job_spec", kind: "message", T: JobSpec },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetJobReply
 */
export const GetJobReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetJobReply",
  () => [
    { no: 1, name: "incremental_update", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "solution", kind: "message", T: Solution, repeated: true },
    { no: 3, name: "last_incarnation", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "last_incarnation_stat", kind: "message", T: JobIncarnationStat },
    { no: 5, name: "latest_iter", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 6, name: "latest_time", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * The solution type stores a copy of SQL solution row.
 *
 * @generated from message luminary.proto.frontend.Solution
 */
export const Solution = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.Solution",
  () => [
    { no: 6, name: "iter", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "time", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.JobConfigRequest
 */
export const JobConfigRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.JobConfigRequest",
  () => [
    { no: 2, name: "job_spec", kind: "message", T: JobSpec },
  ],
);

/**
 * @generated from message luminary.proto.frontend.JobConfigReply
 */
export const JobConfigReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.JobConfigReply",
  () => [
    { no: 2, name: "config", kind: "message", T: Config },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ServerInfoReply
 */
export const ServerInfoReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ServerInfoReply",
  () => [
    { no: 1, name: "jobmaster_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "fvm_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "paraview_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "meshconverter_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "page_version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "jobmaster_git_commit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "cluster_config", kind: "message", T: ClusterConfig },
    { no: 9, name: "reservation", kind: "message", T: UserReservation, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.AccountInfoReply
 */
export const AccountInfoReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.AccountInfoReply",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user", kind: "message", T: AccountInfoReply_UserInfo, repeated: true },
    { no: 5, name: "using_default_kms_key", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "kms_key", kind: "message", T: KeyInfo, repeated: true },
    { no: 6, name: "has_security_info", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "created_time", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "billing", kind: "message", T: AccountInfoReply_BillingInfo },
    { no: 12, name: "accountStatus", kind: "enum", T: proto3.getEnumType(AccountStatus) },
  ],
);

/**
 * One user on the account.
 *
 * @generated from message luminary.proto.frontend.AccountInfoReply.UserInfo
 */
export const AccountInfoReply_UserInfo = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.AccountInfoReply.UserInfo",
  () => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "given_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "family_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "status", kind: "enum", T: proto3.getEnumType(UserStatus) },
    { no: 6, name: "account_role", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "lc_user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "login_info", kind: "message", T: AccountInfoReply_LoginInfo, repeated: true },
    { no: 9, name: "email_notification", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "platform_admin", kind: "message", T: PlatformAdminUser },
  ],
  {localName: "AccountInfoReply_UserInfo"},
);

/**
 * @generated from message luminary.proto.frontend.AccountInfoReply.LoginInfo
 */
export const AccountInfoReply_LoginInfo = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.AccountInfoReply.LoginInfo",
  () => [
    { no: 1, name: "login_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
  {localName: "AccountInfoReply_LoginInfo"},
);

/**
 * @generated from message luminary.proto.frontend.AccountInfoReply.Invoice
 */
export const AccountInfoReply_Invoice = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.AccountInfoReply.Invoice",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "date", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "due_date", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "starting_bal", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "ending_bal", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "total_charges", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "amount_due", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "pdf_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "AccountInfoReply_Invoice"},
);

/**
 * @generated from message luminary.proto.frontend.AccountInfoReply.BillingInfo
 */
export const AccountInfoReply_BillingInfo = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.AccountInfoReply.BillingInfo",
  () => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "balance", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "invoice", kind: "message", T: AccountInfoReply_Invoice, repeated: true },
  ],
  {localName: "AccountInfoReply_BillingInfo"},
);

/**
 * @generated from message luminary.proto.frontend.Domain
 */
export const Domain = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.Domain",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.DomainEntry
 */
export const DomainEntry = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.DomainEntry",
  () => [
    { no: 1, name: "name", kind: "message", T: Domain },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.AllowedDomainsRequest
 */
export const AllowedDomainsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.AllowedDomainsRequest",
  [],
);

/**
 * @generated from message luminary.proto.frontend.AllowedDomainsReply
 */
export const AllowedDomainsReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.AllowedDomainsReply",
  () => [
    { no: 1, name: "domains", kind: "message", T: DomainEntry, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.DeleteInviteDomainRequest
 */
export const DeleteInviteDomainRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.DeleteInviteDomainRequest",
  () => [
    { no: 2, name: "domain", kind: "message", T: DomainEntry },
  ],
);

/**
 * @generated from message luminary.proto.frontend.AddInviteDomainRequest
 */
export const AddInviteDomainRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.AddInviteDomainRequest",
  () => [
    { no: 2, name: "domain", kind: "message", T: Domain },
  ],
);

/**
 * @generated from message luminary.proto.frontend.AllowAnyInviteDomainRequest
 */
export const AllowAnyInviteDomainRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.AllowAnyInviteDomainRequest",
  () => [
    { no: 2, name: "allow_any_domain_invite", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.AnyDomainInviteAllowedRequest
 */
export const AnyDomainInviteAllowedRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.AnyDomainInviteAllowedRequest",
  [],
);

/**
 * @generated from message luminary.proto.frontend.AnyDomainInviteAllowedReply
 */
export const AnyDomainInviteAllowedReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.AnyDomainInviteAllowedReply",
  () => [
    { no: 1, name: "allow_any_domain_invite", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.SetEmailNotificationsPreferenceRequest
 */
export const SetEmailNotificationsPreferenceRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.SetEmailNotificationsPreferenceRequest",
  () => [
    { no: 2, name: "send_email_notifications", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * One instance of resource use.  Derived from a project_resource_usage row.
 *
 * @generated from message luminary.proto.frontend.ResourceUsage
 */
export const ResourceUsage = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ResourceUsage",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "time", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "price", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "product_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "type", kind: "enum", T: proto3.getEnumType(ResourceUnit) },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ResourceUsageRequest
 */
export const ResourceUsageRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ResourceUsageRequest",
  () => [
    { no: 6, name: "start_time", kind: "message", T: Timestamp },
    { no: 7, name: "end_time", kind: "message", T: Timestamp },
    { no: 4, name: "account_level_usage_data", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "limit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ResourceUsageReply
 */
export const ResourceUsageReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ResourceUsageReply",
  () => [
    { no: 1, name: "usage", kind: "message", T: ResourceUsage, repeated: true },
    { no: 2, name: "next_time", kind: "message", T: Timestamp },
  ],
);

/**
 * One instance of resource use.  Derived from a project_resource_usage row.
 *
 * @generated from message luminary.proto.frontend.ResourceUsageDetail
 */
export const ResourceUsageDetail = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ResourceUsageDetail",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "project_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "time", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "credits", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "n_unit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "product_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "type", kind: "enum", T: proto3.getEnumType(ResourceUnit) },
    { no: 9, name: "simulation_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "job_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "geometry_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "geometry_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ResourceUsageDetailRequest
 */
export const ResourceUsageDetailRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ResourceUsageDetailRequest",
  () => [
    { no: 5, name: "start_time", kind: "message", T: Timestamp },
    { no: 6, name: "end_time", kind: "message", T: Timestamp },
    { no: 3, name: "account_level_usage_data", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "limit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ResourceUsageDetailReply
 */
export const ResourceUsageDetailReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ResourceUsageDetailReply",
  () => [
    { no: 1, name: "usage", kind: "message", T: ResourceUsageDetail, repeated: true },
    { no: 2, name: "next_time", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message luminary.proto.frontend.CreditsRemaining
 */
export const CreditsRemaining = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.CreditsRemaining",
  () => [
    { no: 1, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "updateTimeUnixSeconds", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * Empty request. Fields may be added in the future.
 *
 * @generated from message luminary.proto.frontend.CreditsRemainingRequest
 */
export const CreditsRemainingRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.CreditsRemainingRequest",
  [],
);

/**
 * @generated from message luminary.proto.frontend.CreditsRemainingReply
 */
export const CreditsRemainingReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.CreditsRemainingReply",
  () => [
    { no: 1, name: "creditsRemaining", kind: "message", T: CreditsRemaining },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ReservedNodesReply
 */
export const ReservedNodesReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ReservedNodesReply",
  () => [
    { no: 3, name: "reservation", kind: "message", T: NodeReservationState, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ReserveNodesRequest
 */
export const ReserveNodesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ReserveNodesRequest",
  () => [
    { no: 3, name: "reservation", kind: "message", T: NodeReservation, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.SetSessionStateRequest
 */
export const SetSessionStateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.SetSessionStateRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "value", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 6, name: "timestamp", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message luminary.proto.frontend.SessionStateRequest
 */
export const SessionStateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.SessionStateRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.SessionStateReply
 */
export const SessionStateReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.SessionStateReply",
  () => [
    { no: 2, name: "value", kind: "scalar", T: 12 /* ScalarType.BYTES */, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.SessionStateEntry
 */
export const SessionStateEntry = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.SessionStateEntry",
  () => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 12 /* ScalarType.BYTES */, opt: true },
    { no: 3, name: "timestamp", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message luminary.proto.frontend.SessionStateStreamReply
 */
export const SessionStateStreamReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.SessionStateStreamReply",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "entry", kind: "message", T: SessionStateEntry, repeated: true },
    { no: 3, name: "initial_response", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.RefreshSharedStateRequest
 */
export const RefreshSharedStateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.RefreshSharedStateRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * ComputeOutputRequest is identical to analyzer.ComputeOutputRequest (other
 * than workflow_id)
 *
 * @generated from message luminary.proto.frontend.ComputeOutputRequest
 */
export const ComputeOutputRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ComputeOutputRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "workflow_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "job_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "outputs", kind: "message", T: Output, repeated: true },
    { no: 7, name: "all_iterations", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "begin_iter", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "end_iter", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "reference_values", kind: "message", T: ReferenceValues },
    { no: 15, name: "use_cache", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.OutputRequest
 */
export const OutputRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.OutputRequest",
  () => [
    { no: 1, name: "job_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "output", kind: "message", T: Output },
    { no: 3, name: "reference_values", kind: "message", T: ReferenceValues },
  ],
);

/**
 * Batched version of ComputeOutputRequest that can simulatenously process
 * outputs from different jobs
 *
 * @generated from message luminary.proto.frontend.ComputeOutputBatchRequest
 */
export const ComputeOutputBatchRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ComputeOutputBatchRequest",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "output_requests", kind: "message", T: OutputRequest, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ComputeOutputBatchReply
 */
export const ComputeOutputBatchReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ComputeOutputBatchReply",
  () => [
    { no: 1, name: "output", kind: "message", T: Output, repeated: true },
    { no: 2, name: "result", kind: "message", T: Result, repeated: true },
  ],
);

/**
 * ComputeOutputReply is identical to analyzer.ComputeOutputReply
 *
 * @generated from message luminary.proto.frontend.ComputeOutputReply
 */
export const ComputeOutputReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ComputeOutputReply",
  () => [
    { no: 5, name: "output", kind: "message", T: Output, repeated: true },
    { no: 6, name: "result", kind: "message", T: Result, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ExportSolutionRequest
 */
export const ExportSolutionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ExportSolutionRequest",
  () => [
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "surface_name", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: SurfaceName} },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ExportSolutionReply
 */
export const ExportSolutionReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ExportSolutionReply",
  () => [
    { no: 4, name: "file", kind: "message", T: File },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ExportMovieRequest
 */
export const ExportMovieRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ExportMovieRequest",
  () => [
    { no: 2, name: "urls", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "camera", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "view", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ExportMovieReply
 */
export const ExportMovieReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ExportMovieReply",
  () => [
    { no: 1, name: "size", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * ExportExplorationRequest is nearly identical to analyzer.ComputeOutputRequest
 * (no job_id)
 *
 * @generated from message luminary.proto.frontend.ExportExplorationRequest
 */
export const ExportExplorationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ExportExplorationRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "workflow_id", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 12, name: "outputs", kind: "message", T: Output, repeated: true },
    { no: 5, name: "all_iterations", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "begin_iter", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "end_iter", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "reference_values", kind: "message", T: ReferenceValues },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ExportExplorationReply
 */
export const ExportExplorationReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ExportExplorationReply",
  () => [
    { no: 1, name: "payload", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.StoppingConditionRequest
 */
export const StoppingConditionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.StoppingConditionRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "job_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "condition", kind: "message", T: StoppingCondition, repeated: true },
    { no: 7, name: "reference_values", kind: "message", T: ReferenceValues },
  ],
);

/**
 * @generated from message luminary.proto.frontend.StoppingConditionReply
 */
export const StoppingConditionReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.StoppingConditionReply",
  () => [
    { no: 1, name: "status", kind: "message", T: StoppingConditionStatus },
    { no: 2, name: "condition", kind: "message", T: StoppingCondition, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ValidateExpressionRequest
 */
export const ValidateExpressionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ValidateExpressionRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "expression", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ValidateExpressionReply
 */
export const ValidateExpressionReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ValidateExpressionReply",
  () => [
    { no: 3, name: "response", kind: "message", T: ValidateExpressionResponse, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.SetKMSRequest
 */
export const SetKMSRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.SetKMSRequest",
  () => [
    { no: 2, name: "key_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "rotation_period", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "manual", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "rotate_now", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ExperimentConfigRequest
 */
export const ExperimentConfigRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ExperimentConfigRequest",
  [],
);

/**
 * @generated from message luminary.proto.frontend.Experiment
 */
export const Experiment = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.Experiment",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "ui_tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ExperimentConfigReply
 */
export const ExperimentConfigReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ExperimentConfigReply",
  () => [
    { no: 1, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "experiments", kind: "map", K: 4 /* ScalarType.UINT64 */, V: {kind: "message", T: Experiment} },
  ],
);

/**
 * ProgressInfo contains information about a request's execution progress.
 *
 * @generated from message luminary.proto.frontend.ProgressInfo
 */
export const ProgressInfo = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ProgressInfo",
  () => [
    { no: 1, name: "completed_units", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "total_units", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "details", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "indeterminate", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.CheckGetGeometryContactsDependenciesRequest
 */
export const CheckGetGeometryContactsDependenciesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.CheckGetGeometryContactsDependenciesRequest",
  () => [
    { no: 1, name: "request", kind: "message", T: GetGeometryContactsRequest },
  ],
);

/**
 * @generated from message luminary.proto.frontend.CheckGetGeometryContactsDependenciesReply
 */
export const CheckGetGeometryContactsDependenciesReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.CheckGetGeometryContactsDependenciesReply",
  () => [
    { no: 1, name: "all_found", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetGeometryRequest
 */
export const GetGeometryRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetGeometryRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "user_geo", kind: "message", T: UserGeometry },
    { no: 4, name: "user_geo_mod", kind: "message", T: UserGeometryMod },
    { no: 5, name: "geometry_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "geometry_version_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetGeometryReply
 */
export const GetGeometryReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetGeometryReply",
  () => [
    { no: 2, name: "start", kind: "message", T: GetGeometryReply_Start, oneof: "typ" },
    { no: 3, name: "progress", kind: "message", T: ProgressInfo, oneof: "typ" },
    { no: 4, name: "preview", kind: "message", T: GetGeometryReply_Preview, oneof: "typ" },
    { no: 1, name: "done", kind: "message", T: GetGeometryReply_Done, oneof: "typ" },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetGeometryReply.Start
 */
export const GetGeometryReply_Start = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetGeometryReply.Start",
  () => [
    { no: 1, name: "work_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "GetGeometryReply_Start"},
);

/**
 * @generated from message luminary.proto.frontend.GetGeometryReply.Done
 */
export const GetGeometryReply_Done = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetGeometryReply.Done",
  () => [
    { no: 1, name: "surface_geo_mesh_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "metadata", kind: "message", T: CadMetadata },
    { no: 3, name: "file_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "issues", kind: "message", T: LCStatus, repeated: true },
    { no: 5, name: "interactive_geo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "GetGeometryReply_Done"},
);

/**
 * @generated from message luminary.proto.frontend.GetGeometryReply.Preview
 */
export const GetGeometryReply_Preview = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetGeometryReply.Preview",
  () => [
    { no: 1, name: "surface_geo_mesh_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "GetGeometryReply_Preview"},
);

/**
 * @generated from message luminary.proto.frontend.CheckGetGeometryDependenciesRequest
 */
export const CheckGetGeometryDependenciesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.CheckGetGeometryDependenciesRequest",
  () => [
    { no: 1, name: "request", kind: "message", T: GetGeometryRequest },
  ],
);

/**
 * @generated from message luminary.proto.frontend.CheckGetGeometryDependenciesReply
 */
export const CheckGetGeometryDependenciesReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.CheckGetGeometryDependenciesReply",
  () => [
    { no: 1, name: "all_found", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetGeometryContactsRequest
 */
export const GetGeometryContactsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetGeometryContactsRequest",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_geo", kind: "message", T: UserGeometry },
    { no: 3, name: "user_geo_mod", kind: "message", T: UserGeometryMod },
    { no: 4, name: "geometry_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "geometry_version_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetGeometryContactsReply
 */
export const GetGeometryContactsReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetGeometryContactsReply",
  () => [
    { no: 1, name: "found", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "operation_error", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "geo_contacts", kind: "message", T: GetGeometryContactsReply_GeoContact, repeated: true },
    { no: 4, name: "issues", kind: "message", T: LCStatus, repeated: true },
  ],
);

/**
 * The contact information parsed from the output of the operator.
 *
 * @generated from message luminary.proto.frontend.GetGeometryContactsReply.GeoContact
 */
export const GetGeometryContactsReply_GeoContact = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetGeometryContactsReply.GeoContact",
  () => [
    { no: 1, name: "side_a", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "side_b", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "GetGeometryContactsReply_GeoContact"},
);

/**
 * @generated from message luminary.proto.frontend.ComputeGeometryContactsRequest
 */
export const ComputeGeometryContactsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ComputeGeometryContactsRequest",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_geo", kind: "message", T: UserGeometry },
    { no: 3, name: "user_geo_mod", kind: "message", T: UserGeometryMod },
    { no: 4, name: "geometry_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "geometry_version_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ComputeGeometryContactsReply
 */
export const ComputeGeometryContactsReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ComputeGeometryContactsReply",
  () => [
    { no: 2, name: "start", kind: "message", T: ComputeGeometryContactsReply_Start, oneof: "typ" },
    { no: 3, name: "progress", kind: "message", T: ProgressInfo, oneof: "typ" },
    { no: 1, name: "done", kind: "message", T: ComputeGeometryContactsReply_Done, oneof: "typ" },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ComputeGeometryContactsReply.Start
 */
export const ComputeGeometryContactsReply_Start = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ComputeGeometryContactsReply.Start",
  () => [
    { no: 1, name: "work_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "ComputeGeometryContactsReply_Start"},
);

/**
 * @generated from message luminary.proto.frontend.ComputeGeometryContactsReply.Done
 */
export const ComputeGeometryContactsReply_Done = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ComputeGeometryContactsReply.Done",
  () => [
    { no: 1, name: "ok", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
  {localName: "ComputeGeometryContactsReply_Done"},
);

/**
 * @generated from message luminary.proto.frontend.CheckGeometryRequest
 */
export const CheckGeometryRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.CheckGeometryRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "user_geo", kind: "message", T: UserGeometry },
    { no: 4, name: "user_geo_mod", kind: "message", T: UserGeometryMod },
    { no: 5, name: "geometry_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "geometry_version_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.CheckGeometryReply
 */
export const CheckGeometryReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.CheckGeometryReply",
  () => [
    { no: 2, name: "start", kind: "message", T: CheckGeometryReply_Start, oneof: "typ" },
    { no: 3, name: "progress", kind: "message", T: ProgressInfo, oneof: "typ" },
    { no: 1, name: "done", kind: "message", T: CheckGeometryReply_Done, oneof: "typ" },
  ],
);

/**
 * @generated from message luminary.proto.frontend.CheckGeometryReply.Start
 */
export const CheckGeometryReply_Start = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.CheckGeometryReply.Start",
  () => [
    { no: 1, name: "work_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "CheckGeometryReply_Start"},
);

/**
 * @generated from message luminary.proto.frontend.CheckGeometryReply.Done
 */
export const CheckGeometryReply_Done = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.CheckGeometryReply.Done",
  () => [
    { no: 1, name: "ok", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "issues", kind: "message", T: LCStatus, repeated: true },
  ],
  {localName: "CheckGeometryReply_Done"},
);

/**
 * @generated from message luminary.proto.frontend.CheckGetMeshDependenciesRequest
 */
export const CheckGetMeshDependenciesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.CheckGetMeshDependenciesRequest",
  () => [
    { no: 1, name: "request", kind: "message", T: GetMeshRequest },
  ],
);

/**
 * @generated from message luminary.proto.frontend.CheckGetMeshDependenciesReply
 */
export const CheckGetMeshDependenciesReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.CheckGetMeshDependenciesReply",
  () => [
    { no: 1, name: "all_found", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetMeshRequest
 */
export const GetMeshRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetMeshRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "user_geo", kind: "message", T: UserGeometry },
    { no: 5, name: "user_geo_mod", kind: "message", T: UserGeometryMod },
    { no: 4, name: "meshing_params", kind: "message", T: UserMeshingParams },
    { no: 6, name: "meshing_multiple_part", kind: "message", T: MeshingMultiPart },
    { no: 8, name: "geometry_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "geometry_version_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetMeshReply
 */
export const GetMeshReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetMeshReply",
  () => [
    { no: 2, name: "start", kind: "message", T: GetMeshReply_Start, oneof: "typ" },
    { no: 3, name: "progress", kind: "message", T: ProgressInfo, oneof: "typ" },
    { no: 1, name: "done", kind: "message", T: GetMeshReply_Done, oneof: "typ" },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetMeshReply.Start
 */
export const GetMeshReply_Start = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetMeshReply.Start",
  () => [
    { no: 1, name: "work_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "mesh_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "GetMeshReply_Start"},
);

/**
 * @generated from message luminary.proto.frontend.GetMeshReply.Done
 */
export const GetMeshReply_Done = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetMeshReply.Done",
  () => [
    { no: 1, name: "mesh_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "mesh_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "GetMeshReply_Done"},
);

/**
 * This message mostly mirrors the API mesh resource
 * (proto/api/v0/luminarycloud/mesh/mesh.proto), but contains additional
 * internal-only fields that are not exposed in the external API.
 *
 * @generated from message luminary.proto.frontend.Mesh
 */
export const Mesh = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.Mesh",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "create_time", kind: "message", T: Timestamp },
    { no: 4, name: "status", kind: "enum", T: proto3.getEnumType(Mesh_MeshStatus) },
    { no: 5, name: "mesh_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "mesh_origin", kind: "enum", T: proto3.getEnumType(Mesh_MeshOrigin) },
  ],
);

/**
 * @generated from enum luminary.proto.frontend.Mesh.MeshStatus
 */
export const Mesh_MeshStatus = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.frontend.Mesh.MeshStatus",
  [
    {no: 0, name: "MESH_STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "MESH_STATUS_CREATING", localName: "CREATING"},
    {no: 2, name: "MESH_STATUS_COMPLETED", localName: "COMPLETED"},
    {no: 3, name: "MESH_STATUS_FAILED", localName: "FAILED"},
  ],
);

/**
 * DO NOT PERSIST/STORE ANYWHERE, for informational purposes only. Logic used
 * to determine the origin and the possible enum values may change at any time
 * without guarantees.
 *
 * @generated from enum luminary.proto.frontend.Mesh.MeshOrigin
 */
export const Mesh_MeshOrigin = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.frontend.Mesh.MeshOrigin",
  [
    {no: 0, name: "MESH_ORIGIN_UNKNOWN", localName: "UNKNOWN"},
    {no: 1, name: "MESH_ORIGIN_CONVERTED", localName: "CONVERTED"},
    {no: 2, name: "MESH_ORIGIN_USER_GENERATED", localName: "USER_GENERATED"},
    {no: 3, name: "MESH_ORIGIN_MINIMAL_MESH", localName: "MINIMAL_MESH"},
    {no: 4, name: "MESH_ORIGIN_ADAPTED_MESH", localName: "ADAPTED_MESH"},
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetMeshInfoRequest
 */
export const GetMeshInfoRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetMeshInfoRequest",
  () => [
    { no: 1, name: "mesh_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetMeshInfoReply
 */
export const GetMeshInfoReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetMeshInfoReply",
  () => [
    { no: 1, name: "mesh", kind: "message", T: Mesh },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ListMeshesRequest
 */
export const ListMeshesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ListMeshesRequest",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ListMeshesReply
 */
export const ListMeshesReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ListMeshesReply",
  () => [
    { no: 1, name: "meshes", kind: "message", T: Mesh, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.UpdateMeshRequest
 */
export const UpdateMeshRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.UpdateMeshRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.UpdateMeshReply
 */
export const UpdateMeshReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.UpdateMeshReply",
  () => [
    { no: 1, name: "mesh", kind: "message", T: Mesh },
  ],
);

/**
 * @generated from message luminary.proto.frontend.DeleteMeshRequest
 */
export const DeleteMeshRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.DeleteMeshRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetMeshMetadataRequest
 */
export const GetMeshMetadataRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetMeshMetadataRequest",
  () => [
    { no: 1, name: "mesh_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetMeshMetadataReply
 */
export const GetMeshMetadataReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetMeshMetadataReply",
  () => [
    { no: 1, name: "mesh_meta", kind: "message", T: MeshFileMetadata },
  ],
);

/**
 * @generated from message luminary.proto.frontend.UploadMeshRequest
 */
export const UploadMeshRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.UploadMeshRequest",
  () => [
    { no: 1, name: "uploadFileReq", kind: "message", T: UploadFileRequest },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "upload_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.UploadMeshReply
 */
export const UploadMeshReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.UploadMeshReply",
  () => [
    { no: 1, name: "mesh", kind: "message", T: Mesh },
    { no: 2, name: "upload_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetMeshGenerationParamsRequest
 */
export const GetMeshGenerationParamsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetMeshGenerationParamsRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "mesh_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "mesh_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetMeshGenerationParamsReply
 */
export const GetMeshGenerationParamsReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetMeshGenerationParamsReply",
  () => [
    { no: 1, name: "meshing_params", kind: "message", T: UserMeshingParams },
    { no: 2, name: "meshing_multiple_part", kind: "message", T: MeshingMultiPart },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ImportC81Request
 */
export const ImportC81Request = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ImportC81Request",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ImportC81Reply
 */
export const ImportC81Reply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ImportC81Reply",
  () => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetRectilinearTableRequest
 */
export const GetRectilinearTableRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetRectilinearTableRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetRectilinearTableReply
 */
export const GetRectilinearTableReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetRectilinearTableReply",
  () => [
    { no: 1, name: "table", kind: "message", T: RectilinearTable },
  ],
);

/**
 * @generated from message luminary.proto.frontend.CreateUserRequest
 */
export const CreateUserRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.CreateUserRequest",
  () => [
    { no: 2, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "role", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "userActive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "new_user_log_in", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.DeleteMFARequest
 */
export const DeleteMFARequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.DeleteMFARequest",
  () => [
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.DeletePasswordRequest
 */
export const DeletePasswordRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.DeletePasswordRequest",
  () => [
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "new_login", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ResetPasswordRequest
 */
export const ResetPasswordRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ResetPasswordRequest",
  () => [
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.SetPasswordRequest
 */
export const SetPasswordRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.SetPasswordRequest",
  () => [
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "ticketId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.UseTicketRequest
 */
export const UseTicketRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.UseTicketRequest",
  () => [
    { no: 2, name: "ticketId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ResendActivationEmailRequest
 */
export const ResendActivationEmailRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ResendActivationEmailRequest",
  () => [
    { no: 2, name: "ticketId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.LoginTokenRequest
 */
export const LoginTokenRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.LoginTokenRequest",
  () => [
    { no: 2, name: "mfaToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "idToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "otp_authenticator_type", kind: "message", T: LoginTokenRequest_OtpAuthenticatorType, oneof: "authenticator_type" },
    { no: 9, name: "oob_authenticator_type", kind: "message", T: LoginTokenRequest_OobAuthenticatorType, oneof: "authenticator_type" },
  ],
);

/**
 * OtpAuthenticatorType should be used only for the OTP authenticator type
 * i.e. when following:
 * - https://auth0.com/docs/secure/multi-factor-authentication/authenticate-using-ropg-flow-with-mfa/enroll-and-challenge-otp-authenticators
 *
 * @generated from message luminary.proto.frontend.LoginTokenRequest.OtpAuthenticatorType
 */
export const LoginTokenRequest_OtpAuthenticatorType = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.LoginTokenRequest.OtpAuthenticatorType",
  () => [
    { no: 5, name: "otp_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "LoginTokenRequest_OtpAuthenticatorType"},
);

/**
 * OobAuthenticatorType should be used for SMS, Voice, Email and Push notification authenticator types
 * i.e. when following:
 * - https://auth0.com/docs/secure/multi-factor-authentication/authenticate-using-ropg-flow-with-mfa/enroll-challenge-sms-voice-authenticators
 * or in the future if we support email/push:
 * - https://auth0.com/docs/secure/multi-factor-authentication/authenticate-using-ropg-flow-with-mfa/enroll-and-challenge-push-authenticators
 * - https://auth0.com/docs/secure/multi-factor-authentication/authenticate-using-ropg-flow-with-mfa/enroll-and-challenge-email-authenticators
 *
 * @generated from message luminary.proto.frontend.LoginTokenRequest.OobAuthenticatorType
 */
export const LoginTokenRequest_OobAuthenticatorType = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.LoginTokenRequest.OobAuthenticatorType",
  () => [
    { no: 6, name: "oob_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "binding_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "LoginTokenRequest_OobAuthenticatorType"},
);

/**
 * @generated from message luminary.proto.frontend.LoginMfaRecoveryCodeRequest
 */
export const LoginMfaRecoveryCodeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.LoginMfaRecoveryCodeRequest",
  () => [
    { no: 2, name: "mfa_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "recovery_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * LoginMfaRecoveryCodeReply will send the new login token
 * and a new recoverycode to be used by the user
 *
 * @generated from message luminary.proto.frontend.LoginMfaRecoveryCodeReply
 */
export const LoginMfaRecoveryCodeReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.LoginMfaRecoveryCodeReply",
  () => [
    { no: 1, name: "login_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "new_recovery_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "expiration", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.LoginTokenReply
 */
export const LoginTokenReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.LoginTokenReply",
  () => [
    { no: 1, name: "loginToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "refreshToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "expiration", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.LoginCodeTokenRequest
 */
export const LoginCodeTokenRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.LoginCodeTokenRequest",
  () => [
    { no: 2, name: "authorization_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "redirect_uri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.LoginCodeTokenReply
 */
export const LoginCodeTokenReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.LoginCodeTokenReply",
  () => [
    { no: 1, name: "access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "refresh_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "expiration", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ValidateUserInviteRequest
 */
export const ValidateUserInviteRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ValidateUserInviteRequest",
  () => [
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "new_user_log_in", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ValidateUserInviteReply
 */
export const ValidateUserInviteReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ValidateUserInviteReply",
  () => [
    { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(UserStatus) },
  ],
);

/**
 * @generated from message luminary.proto.frontend.UpdateUserRequest
 */
export const UpdateUserRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.UpdateUserRequest",
  () => [
    { no: 2, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "role", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "lc_user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.DeleteUserRequest
 */
export const DeleteUserRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.DeleteUserRequest",
  () => [
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.AcceptTermsRequest
 */
export const AcceptTermsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.AcceptTermsRequest",
  () => [
    { no: 2, name: "is_new_user", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.AcceptTermsV2Request
 */
export const AcceptTermsV2Request = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.AcceptTermsV2Request",
  () => [
    { no: 2, name: "ticketId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ListCamerasRequest
 */
export const ListCamerasRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ListCamerasRequest",
  () => [
    { no: 2, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.CameraInfo
 */
export const CameraInfo = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.CameraInfo",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "create_time", kind: "message", T: Timestamp },
    { no: 3, name: "camera_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "camera_access", kind: "enum", T: proto3.getEnumType(CameraAccess) },
  ],
);

/**
 * @generated from message luminary.proto.frontend.ListCamerasReply
 */
export const ListCamerasReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.ListCamerasReply",
  () => [
    { no: 1, name: "cameras", kind: "message", T: CameraInfo, repeated: true },
  ],
);

/**
 * @generated from message luminary.proto.frontend.AddCameraReply
 */
export const AddCameraReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.AddCameraReply",
  () => [
    { no: 1, name: "camera", kind: "message", T: CameraInfo },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetCameraRequest
 */
export const GetCameraRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetCameraRequest",
  () => [
    { no: 2, name: "camera_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.GetCameraReply
 */
export const GetCameraReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.GetCameraReply",
  () => [
    { no: 1, name: "camera_json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.AddCameraRequest
 */
export const AddCameraRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.AddCameraRequest",
  () => [
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "camera_json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "camera_access", kind: "enum", T: proto3.getEnumType(CameraAccess) },
  ],
);

/**
 * @generated from message luminary.proto.frontend.DeleteCameraRequest
 */
export const DeleteCameraRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.DeleteCameraRequest",
  () => [
    { no: 3, name: "camera_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.UpdateCameraInfoRequest
 */
export const UpdateCameraInfoRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.UpdateCameraInfoRequest",
  () => [
    { no: 2, name: "camera_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "camera_access", kind: "enum", T: proto3.getEnumType(CameraAccess) },
  ],
);

/**
 * @generated from message luminary.proto.frontend.RenameWorkflowRequest
 */
export const RenameWorkflowRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.RenameWorkflowRequest",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "workflow_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message luminary.proto.frontend.RenameJobRequest
 */
export const RenameJobRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.RenameJobRequest",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "job_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * The camera state of the LCVis arcball camera. The payload is encoded as JSON.
 *
 * @generated from message luminary.proto.frontend.LCVisCameraState
 */
export const LCVisCameraState = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.LCVisCameraState",
  () => [
    { no: 1, name: "json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Tracks the visibility of each surface.
 *
 * @generated from message luminary.proto.frontend.LCVisVisibilityMap
 */
export const LCVisVisibilityMap = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.LCVisVisibilityMap",
  () => [
    { no: 1, name: "entries", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 8 /* ScalarType.BOOL */} },
  ],
);

/**
 * @generated from message luminary.proto.frontend.PendingWorkOrder
 */
export const PendingWorkOrder = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.PendingWorkOrder",
  () => [
    { no: 1, name: "work_order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "get_geometry", kind: "message", T: GetGeometryRequest, oneof: "typ" },
    { no: 3, name: "check_geometry", kind: "message", T: CheckGeometryRequest, oneof: "typ" },
    { no: 4, name: "get_mesh", kind: "message", T: GetMeshRequest, oneof: "typ" },
    { no: 6, name: "compute_geometry_contacts", kind: "message", T: ComputeGeometryContactsRequest, oneof: "typ" },
    { no: 5, name: "progress_info", kind: "message", T: ProgressInfo },
  ],
);

/**
 * @generated from message luminary.proto.frontend.PendingWorkOrders
 */
export const PendingWorkOrders = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.PendingWorkOrders",
  () => [
    { no: 1, name: "work_orders", kind: "map", K: 5 /* ScalarType.INT32 */, V: {kind: "message", T: PendingWorkOrder} },
  ],
);

/**
 * @generated from message luminary.proto.frontend.LoadToSetupBackendOperationsRequest
 */
export const LoadToSetupBackendOperationsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.LoadToSetupBackendOperationsRequest",
  () => [
    { no: 1, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "geometry_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "geometry_version_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "allow_lc_surface_tessellation", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "keep_geometrical_entities", kind: "enum", T: proto3.getEnumType(LoadToSetupBackendOperationsRequest_KeepGeometricalEntities) },
  ],
);

/**
 * Which geometrical entities to keep in the settings when loading the
 * new geometry.
 *
 * @generated from enum luminary.proto.frontend.LoadToSetupBackendOperationsRequest.KeepGeometricalEntities
 */
export const LoadToSetupBackendOperationsRequest_KeepGeometricalEntities = /*@__PURE__*/ proto3.makeEnum(
  "luminary.proto.frontend.LoadToSetupBackendOperationsRequest.KeepGeometricalEntities",
  [
    {no: 0, name: "DO_NOT_KEEP_GEOMETRICAL_ENTITIES"},
    {no: 1, name: "KEEP_TAG_GEOMETRICAL_ENTITIES"},
    {no: 2, name: "KEEP_ALL_GEOMETRICAL_ENTITIES"},
  ],
);

/**
 * @generated from message luminary.proto.frontend.LoadToSetupBackendOperationsReply
 */
export const LoadToSetupBackendOperationsReply = /*@__PURE__*/ proto3.makeMessageType(
  "luminary.proto.frontend.LoadToSetupBackendOperationsReply",
  [],
);

