// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.

import React, { useMemo, useState } from 'react';

import assert from '../lib/assert';
import { CommonMenuItem } from '../lib/componentTypes/menu';
import { EMPTY_VALUE } from '../lib/constants';
import { CsvWriter } from '../lib/csv';
import exportMovie from '../lib/exportMovie';
import { downloadFile, exportSolution, exportVolumeSolution } from '../lib/exportSolution';
import { formatNumber } from '../lib/number';
import { Logger } from '../lib/observability/logs';
import { extname, isLcSolnExtension } from '../lib/path';
import { useUserCanEdit } from '../lib/projectRoles';
import * as status from '../lib/status';
import { addError, addInfo } from '../lib/transientNotification';
import * as simulationpb from '../proto/client/simulation_pb';
import * as filepb from '../proto/file/file_pb';
import * as frontendpb from '../proto/frontend/frontend_pb';
import { useEntityGroupMap } from '../recoil/entityGroupState';
import { useJobState } from '../recoil/jobState';
import { useCameraPosition } from '../recoil/paraviewState';
import { useIsEnabled } from '../recoil/useExperimentConfig';
import useProjectMetadata from '../recoil/useProjectMetadata';

import { ActionButton } from './Button/ActionButton';
import Dropdown from './Dropdown';
import { DataConfig } from './OutputChart/OutputChart';
import { useParaviewContext } from './Paraview/ParaviewManager';
import { createStyles, makeStyles } from './Theme';
import { TimeBarSettings } from './TimeBar';
import { useProjectContext } from './context/ProjectContext';
import { TriangleIcon } from './svg/TriangleIcon';

const logger = new Logger('DownloadMenu');

const useStyles = makeStyles(
  () => createStyles({
    toggle: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
    },
  }),
  { name: 'DownloadMenu' },
);

function volumeSolutionFormId(jobId: string) {
  return `downloadVolumeSolutionForm-${jobId}`;
}

function surfaceSolutionFormId(jobId: string) {
  return `downloadSurfaceSolutionForm-${jobId}`;
}

// Downloads a given file using browser-native forms. The file must have a signed URL as download
// endpoint.
function downloadFileWithForm(file: filepb.File, formId: string) {
  assert(
    file.contents.case === 'signedUrl' && file.contents.value !== '',
    'File must have a signed URL to download',
  );
  // Parse the URL and its query parameters
  const url = new URL(file.contents.value);

  const form = document.getElementById(formId) as HTMLFormElement;

  // Set the form action to the URL without the query string.
  form.action = url.origin + url.pathname;

  // Remove all children of the form to avoid inserting query params of an old request.
  while (form.firstChild) {
    form.removeChild(form.firstChild);
  }

  // For each query parameter, create a hidden input field. We need this because forms remove query
  // parameters.
  url.searchParams.forEach((value, key) => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = value;
    form.appendChild(input);
  });

  form.submit();
}

// Export data from the output chart as a CSV, with one column for iteration/step, one column for
// time (transient only), and one column for each plotted value (one per DataConfig). Saves file
// through browser as "export.csv".
//
// Requires: data.length === dataConfig.length && data[0].length === iters.length If
// settings.isTransient is true, also requires time.length === iters.length
//
// TODO(bamo): right now, users can only export one named output at a time, since they can only
// export the contents of the chart.  But, in the future we would want to include the custom (or
// default) output name in the column headers to disambiguate multiple outputs of the same quantity
// (on different surfaces, for example).  But this whole implementation needs to be overhauled
// anyways, to be decoupled from what is shown in the chart, and probably moved to the backend, so
// this should be self-resolving.
function exportCsv(
  dataConfig: DataConfig[],
  data: number[][],
  positions: number[][],
  time: number[],
  settings: TimeBarSettings,
) {
  const headers: string[] = [];
  const PRECISION = 8;
  if (settings.xyChart) {
    dataConfig.forEach((dc) => {
      // For each element, we add both a X axis label and a quantity label to the header array
      headers.push(settings.xyChartHeader?.x || 'X Axis');
      headers.push(`${dc.name} ${settings.xyChartHeader?.y}`);
      // Category used to differentiate between intersection curves or breaks in
      // line sampling data.
      headers.push('Segment');
    });
  } else if (settings.isTransient) {
    headers.push('Step', 'Time (s)');
    dataConfig.forEach((dc) => headers.push(dc.name));
  } else {
    headers.push('Iteration');
    dataConfig.forEach((dc) => headers.push(dc.name));
  }
  const writer = new CsvWriter(headers);
  if (settings.xyChart) {
    // Get the longest element we have data for - this will determine the number of rows
    const maxLengthElement = Math.max(...positions.map((i) => i.length));

    // We currently have two types of xy plot data produced from vis. Intersection curves and line
    // probes. Both data types can be sampling different parts of the dataset. For example, consider
    // a plane with both forward and rear wings.  I can place a intersection curve so it intesects
    // both wings, creating two distinct curves. Similarly, we can do a line probe in the volume,
    // and some samples might be in empty space. To make this clear in the data, we will label
    // consecutive values as part of the same "segment".  This will allow users to interpret the
    // data. Previously, we exported (--,--) for the rows where there were no data or were inserted
    // on purpose to indicate a separate portion of an intesection curve. Plotting libraries don't
    // like this.
    let segment = 0;
    let lastValueNonEmpty = false;
    for (let i = 0; i < maxLengthElement; i += 1) {
      const row: string[] = [];
      for (let j = 0; j < dataConfig.length; j += 1) {
        if (positions[j][i] && data[j][i]) {
          lastValueNonEmpty = true;
          // First push coordinate value
          row.push(positions[j][i].toPrecision(PRECISION));
          // Then push data value
          row.push(data[j][i].toPrecision(PRECISION));
          // push the segment this data belongs to.
          row.push(`${segment}`);
        } else if (lastValueNonEmpty) {
          // We can get repeated 'empty' values from line probes going through empty space. Only
          // label a new segment after seeing actual data.
          segment += 1;
          lastValueNonEmpty = false;
        }
      }
      try {
        if (row.length) {
          writer.addRow(row);
        }
      } catch (err) {
        addError(err.message);
      }
    }
  } else if (positions.length === 1) {
    // If it's an iterations based chart, positions has only one set of values
    const iters = positions[0];
    for (let i = 0; i < iters.length; i += 1) {
      const row: string[] = [`${iters[i]}`];
      if (settings.isTransient) {
        row.push(time[i].toPrecision(PRECISION));
      }
      for (let j = 0; j < dataConfig.length; j += 1) {
        if (data[j][i]) {
          row.push(data[j][i].toPrecision(PRECISION));
        } else {
          row.push(EMPTY_VALUE);
        }
      }
      try {
        writer.addRow(row);
      } catch (err) {
        addError(err.message);
      }
    }
  }
  writer.saveFile('export.csv');
}

interface DownloadMenuProps {
  param: simulationpb.SimulationParam | null;

  // Info for output chart data export
  dataConfig: DataConfig[];
  data: number[][];
  positions: number[][];
  time: number[];
  settings: TimeBarSettings;
}

// A menu for downloading either output chart data or solution data.
const DownloadMenu = (props: DownloadMenuProps) => {
  // == Props
  const { data, dataConfig, param, positions, settings, time } = props;

  // == Contexts
  const { projectId, workflowId, jobId } = useProjectContext();
  const {
    paraviewProjectId,
    paraviewActiveUrl,
    paraviewMeshMetadata,
    viewState,
  } = useParaviewContext();

  // == Shared state
  const [cameraPosition] = useCameraPosition(
    paraviewProjectId,
    paraviewActiveUrl,
    paraviewMeshMetadata,
  );
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  // TODO(LC-6960, LC-9921) Disabling movie maker until we update the moviemaker implementation.
  // Change experiment config to 'movie_maker' to re-enable this code path.
  const isMovieMakerEnabled = useIsEnabled('movie_maker-disabled');
  const jobState = useJobState(projectId, workflowId, jobId);
  const projectMetadata = useProjectMetadata(projectId);
  const userCanEdit = useUserCanEdit(projectMetadata?.summary);

  // == Local state
  const [ongoingGraphExport, setOngoingGraphExport] = useState<boolean>(false);
  const [ongoingSolnExport, setOngoingSolnExport] = useState<boolean>(false);
  const [ongoingVolumeSolnExport, setOngoingVolumeSolnExport] = useState<boolean>(false);
  const [ongoingMovieExport, setOngoingMovieExport] = useState<boolean>(false);

  // == Functional hooks
  const classes = useStyles();

  // == Derived state
  const isLcSoln = isLcSolnExtension(extname(paraviewActiveUrl));
  const disableGraphExport = dataConfig.length === 0 || ongoingGraphExport;
  const disableAnyExport = !param || !paraviewActiveUrl || !isLcSoln;
  const disableSolnExport = disableAnyExport || ongoingSolnExport;
  const disableVolumeSolnExport = disableAnyExport || ongoingVolumeSolnExport;
  const disableMovieExport = disableAnyExport || ongoingMovieExport;
  const volumeDownloadDisabledReason = useMemo(() => {
    if (!userCanEdit) {
      return 'Downloading volume data is disabled in view only projects';
    }
    if (ongoingVolumeSolnExport) {
      return 'Download already in progress';
    }
    return '';
  }, [userCanEdit, ongoingVolumeSolnExport]);

  const menuItems: CommonMenuItem[] = [
    {
      disabled: disableGraphExport,
      label: 'Download current chart data',
      onClick: () => {
        if (disableGraphExport) {
          return;
        }
        setOngoingGraphExport(true);
        exportCsv(dataConfig, data, positions, time, settings);
        setOngoingGraphExport(false);
      },
    },
    {
      // writer or higher role required for surface data download
      disabled: !userCanEdit || ongoingSolnExport,
      disabledReason: !userCanEdit ? 'Downloading surface data is disabled in view only projects' :
        'Download already in progress',
      label: 'Surface Data',
      onClick: async () => {
        if (disableSolnExport) {
          return;
        }
        setOngoingSolnExport(true);
        addInfo(
          'Download will begin automatically in the background in a few moments. ' +
          'Please do not navigate away from this page.',
          'Surface data download initiated',
        );

        try {
          const file = await exportSolution(paraviewActiveUrl, entityGroupMap);
          if (file.contents.case !== 'signedUrl') {
            // Use the streamSaver codepath because we need authorization headers to access the
            // fetch endpoint. This codepath tends to crash the browser if the file is too large.
            await downloadFile(file);
          } else {
            downloadFileWithForm(file, surfaceSolutionFormId(jobId));
          }
        } catch (err) {
          addError(`exportSolution: ${status.stringifyError(err)}`);
        } finally {
          setOngoingSolnExport(false);
        }
      },
    },
    {
      disabled: !!volumeDownloadDisabledReason,
      disabledReason: volumeDownloadDisabledReason,
      label: `Volume Data${settings.isTransient ?
        ` (Time ${formatNumber(settings.steps[settings.currentIndex])})` : ''}`,
      onClick: async () => {
        if (disableVolumeSolnExport) {
          return;
        }
        setOngoingVolumeSolnExport(true);
        addInfo(
          'Download will begin automatically in the background in a few moments. ' +
          'Please do not navigate away from this page.',
          'Volume data download initiated',
        );

        try {
          const iteration = settings.isTransient ?
            settings.iters[settings.currentIndex] : undefined;
          const file = await exportVolumeSolution(jobId, iteration);
          if (file.contents.case !== 'signedUrl') {
            // Use the streamSaver codepath because we need authorization headers to access the
            // fetch endpoint. This codepath tends to crash the browser if the file is too large.
            await downloadFile(file);
          } else {
            downloadFileWithForm(file, volumeSolutionFormId(jobId));
          }
        } catch (err) {
          addError(`exportVolumeSolution: ${status.stringifyError(err)}`);
        } finally {
          setOngoingVolumeSolnExport(false);
        }
      },
    },
  ];

  if (isMovieMakerEnabled) {
    menuItems.push(
      {
        disabled: disableMovieExport || !viewState,
        label: 'Export Movie',
        onClick: () => {
          if (disableMovieExport) {
            return;
          }

          let viewJson = '';
          let cameraJson = '';
          if (viewState) {
            viewJson = JSON.stringify(viewState);
          }
          if (cameraPosition) {
            cameraJson = JSON.stringify(cameraPosition);
          }
          logger.info(viewJson);
          const urls: string[] = [];
          jobState?.solutions.forEach((value: frontendpb.Solution) => {
            // The solutions array is the size of the total number of iterations, but there are only
            // actual urls for iterations that have an output. We have to iterate through all of
            // them and figure out which ones are non-empty.
            //
            // TODO(matt): we might want to disallow this menu item if the simulation is still
            // running, although we can initally allow it.
            const { url } = value;
            if (url && url.length > 0) {
              urls.push(url);
            }
          });
          setOngoingMovieExport(true);
          exportMovie(urls, cameraJson, viewJson).catch((err) => {
            addError(`exportMovie: ${status.stringifyError(err)}`);
          }).finally(() => {
            setOngoingMovieExport(false);
          });
        },
      },
    );
  }

  return (
    // Using some native forms in order to start downloads in the background without using
    // streamSaver.
    <>
      <form action="" id={volumeSolutionFormId(jobId)} method="get" style={{ display: 'none' }} />
      <form action="" id={surfaceSolutionFormId(jobId)} method="get" style={{ display: 'none' }} />
      <Dropdown
        menuItems={menuItems}
        position="below-left"
        toggle={(
          <ActionButton kind="minimal">
            <div className={classes.toggle}>
              <span>Download</span>
              <TriangleIcon height={5} width={10} />
            </div>
          </ActionButton>
        )}
      />
    </>
  );
};

export default DownloadMenu;
