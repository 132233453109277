// Copyright 2020-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { CurrentView } from '../../lib/componentTypes/context';
import { colors } from '../../lib/designSystem';
import { EntityGroupMap } from '../../lib/entityGroupMap';
import {
  SUPPORTED_ERROR_CODES,
  geomHealthIndexToNodeId,
  geomHealthNodeIdtoIndex,
  getDescription,
  getGeomHealthSubtitle,
  getNodeIds,
  getTitle,
} from '../../lib/geometryHealthUtils';
import { zoomAndMakeOthersTransparent } from '../../lib/lcvis/api';
import { geometryLink } from '../../lib/navigation';
import { useUserCanEdit } from '../../lib/projectRoles';
import { SelectionAction } from '../../lib/selectionUtils';
import { plural } from '../../lib/text';
import * as codespb from '../../proto/lcstatus/codes_pb';
import * as lcstatuspb from '../../proto/lcstatus/lcstatus_pb';
import * as levelspb from '../../proto/lcstatus/levels_pb';
import { useEntityGroupMap } from '../../recoil/entityGroupState';
import { useGeometryHealth } from '../../recoil/geometryHealth';
import { useSetTransparencySettings } from '../../recoil/lcvis/transparencySettings';
import { useIsGeometryPending } from '../../recoil/pendingWorkOrders';
import { useSelectedGeometry } from '../../recoil/selectedGeometry';
import { useProjectMetadataValue } from '../../recoil/useProjectMetadata';
import { useCurrentView } from '../../state/internal/global/currentView';
import { ActionButton } from '../Button/ActionButton';
import { SummaryPanel } from '../Panel/SummaryPanel';
import { createStyles, makeStyles } from '../Theme';
import { useProjectContext } from '../context/ProjectContext';
import { useSelectionContext } from '../context/SelectionManager';
import { CollapsibleCard } from '../layout/CollapsibleCard';

import MeshImportDialogCommon from './MeshImportDialogCommon';

const useStyles = makeStyles(
  () => createStyles({
    actionButtons: {
      display: 'flex',
      gap: '8px',
    },
    groupHeading: {
      padding: '12px 12px 6px 12px',
      fontWeight: 600,
    },
    headerRight: {
      color: colors.lowEmphasisText,
      padding: '0 12px',
    },
    issuesContainer: {
      overflowY: 'scroll',
      scrollbarWidth: 'none',
      maxHeight: '60vh',
      // When using ctrl + click we do not want to select the div text
      userSelect: 'none',
    },
  }),
  { name: 'GeometryHealth' },
);

// The time in ms after which a success geometry health card should be dismissed automatically.
const SUCCESS_AUTO_DISMISS = 5000;

// An issue with attached information for sorting it.
interface sortableIssue {
  status: lcstatuspb.LCStatus,
  ordering: [number, number, string],
}

// The issue are sorted based on three values in order of importance: First, the level,
// second the code, third the nodeIds.
function createSortableIssue(status: lcstatuspb.LCStatus): sortableIssue {
  const codeIndex = SUPPORTED_ERROR_CODES.indexOf(status.code);
  const nodeIds = getNodeIds(status).join(' ');
  return { status, ordering: [-status.level, codeIndex, nodeIds] };
}

// A comparison function used for sorting. Returns negative if A should appear before B. Returns
// positive if A should appear after B. Goes through the ordering data in sequence with the earliest
// entries being the most important.
function compareIssues(issueA: sortableIssue, issueB: sortableIssue) {
  for (let i = 0; i < 3; i += 1) {
    if (issueA.ordering[i] < issueB.ordering[i]) {
      return -1;
    }
    if (issueA.ordering[i] > issueB.ordering[i]) {
      return 1;
    }
  }
  return 0;
}

export interface GeometryHealthCardProps {
  // The issues to display.
  issues: lcstatuspb.LCStatus[];
  // Whether or not check geometry returned successfully.
  ok: boolean;
  // Called when dismiss is pressed.
  dismissCard: () => void;
  // Called when re-upload is pressed.
  reUpload: () => void;
  entityGroupMap: EntityGroupMap;
  // If the user cannot edit, we are in view only mode.
  userCanEdit?: boolean;
}

// A card displaying any issues related to geometry health and actions that can be performed.
// Contains just the UI elements with the control logic passed in.
export const GeometryHealthCard = (props: GeometryHealthCardProps) => {
  // == Props
  const {
    dismissCard,
    issues,
    ok,
    reUpload,
  } = props;

  // == Contexts
  const { projectId } = useProjectContext();
  const { selectedNodeIds, modifySelection, setScrollTo, isTreeModal } = useSelectionContext();

  // == Recoil
  const isGeometryPending = useIsGeometryPending(projectId);
  const [selectedGeometry] = useSelectedGeometry(projectId);
  const navigate = useNavigate();
  const setTransparencySettings = useSetTransparencySettings();

  // == Data
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const isIgeoProject = !!selectedGeometry.geometryId;

  // An array of selected indices. Computed from the value of selectedNodeIds.
  const selectedCards = useMemo(() => {
    const geomHealthNodeIds = selectedNodeIds.filter(
      (nodeId) => (geomHealthNodeIdtoIndex(nodeId) >= 0),
    );
    return geomHealthNodeIds.length ?
      geomHealthNodeIds.map((nodeId) => geomHealthNodeIdtoIndex(nodeId)) : [];
  }, [selectedNodeIds]);
  const classes = useStyles();

  // Count the number of errors and warnings.
  const count = issues.reduce((acc, issue) => {
    switch (issue.level) {
      case levelspb.Level.ERROR:
        acc.errors += 1;
        break;
      case levelspb.Level.WARN:
        acc.warnings += 1;
        break;
      default:
        break;
    }
    return acc;
  }, { errors: 0, warnings: 0 });

  // Creates a subtitle based on the number of errors and warnings.
  const hasErrors = (count.errors > 0);
  const hasWarnings = (count.warnings > 0);
  const subtitle = getGeomHealthSubtitle(hasWarnings, hasErrors, ok, isIgeoProject);

  const filteredIssues = issues.filter(
    (issue) => (SUPPORTED_ERROR_CODES.includes(issue.code)),
  );
  const sortedIssues = filteredIssues.map((issue) => createSortableIssue(issue));
  sortedIssues.sort(compareIssues);

  const errorCodeCount = new Map<codespb.Code, number>();

  // Create a series of rows describing each issue.
  const issueRows: ReactElement[] = [];
  sortedIssues.forEach((sortableIssue, index) => {
    const issue = sortableIssue.status;
    const onClick = (event: any) => {
      // If some NodeTable/NodeSubselect is active, we shouldn't modify the selection on click.
      // In this case, the click handler in NodeTable/NodeSubselect's should exit its edit mode.
      if (isTreeModal) {
        return;
      }
      const nodeIds = getNodeIds(issue);
      const action = (event.ctrlKey || event.metaKey) ?
        SelectionAction.ADD : SelectionAction.OVERWRITE;
      // Add a nodeId for the selected card.
      nodeIds.push(geomHealthIndexToNodeId(index));
      modifySelection({ action, modificationIds: nodeIds });
      if (nodeIds.length > 0) {
        setScrollTo({ node: nodeIds[0], fast: true });
      }
      zoomAndMakeOthersTransparent(nodeIds, setTransparencySettings);
    };
    const code = issue.code;
    const newCount = (errorCodeCount.get(code) || 0) + 1;
    errorCodeCount.set(code, newCount);
    const isSelected = selectedCards.includes(index);
    const isWarning = (issue.level === levelspb.Level.WARN);
    const iconName = isWarning ? 'warning' : 'diskExclamation';
    const iconColor = isWarning ? colors.yellow500 : colors.red500;
    const iconTooltip = isWarning ?
      'Warnings can impact mesh quality.' :
      'Errors must be fixed before proceeding.';
    let title = getTitle(issue);
    if (newCount > 1) {
      title += ` ${newCount}`;
    }
    issueRows.push(
      <CollapsibleCard
        iconColor={iconColor}
        iconName={iconName}
        iconTooltip={iconTooltip}
        key={title}
        onClick={onClick}
        selected={isSelected}
        title={title}>
        {getDescription(issue, props.entityGroupMap)}
      </CollapsibleCard>,
    );
  });

  // A header appears on the right when the card is collapsed.
  const numIssues = count.errors + count.warnings;
  let headerRight;
  if (collapsed && numIssues > 0) {
    headerRight = (
      <div className={classes.headerRight}>
        {`${numIssues} issue${plural(numIssues)}`}
      </div>
    );
  }

  return (
    <SummaryPanel
      collapsed={collapsed}
      headerRight={headerRight}
      heading="Geometry Health"
      onToggle={() => setCollapsed(!collapsed)}
      summary={(
        <>
          {subtitle}
          <div className={classes.actionButtons}>
            {(!ok || hasWarnings) && props.userCanEdit && (
              <ActionButton
                disabled={isGeometryPending}
                kind={!ok ? 'primary' : 'secondary'}
                onClick={isIgeoProject ? () => navigate(geometryLink(projectId)) : reUpload}
                size="small">
                {isIgeoProject ? 'Edit Geometry' : 'Re-upload File'}
              </ActionButton>
            )}
            {ok && hasWarnings && props.userCanEdit && (
              <ActionButton
                onClick={dismissCard}
                size="small">
                Dismiss
              </ActionButton>
            )}
          </div>
        </>
      )}>
      <div className={classes.issuesContainer}>
        {issueRows}
      </div>
    </SummaryPanel>
  );
};

export interface GeometryHealthProps { }

// A card for display the geometry health. This specifies the control logic and passes it into
// GeometryHealthCard.
const GeometryHealth = (props: GeometryHealthProps) => {
  const { projectId, workflowId, jobId } = useProjectContext();
  const currentView = useCurrentView();
  const [geometryHealth, setGeometryHealth] = useGeometryHealth(projectId);
  const entityGroupMap = useEntityGroupMap(projectId, workflowId, jobId);
  const autoDismissTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const isGettingGeometry = useIsGeometryPending(projectId);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isDialogOpen && isGettingGeometry) {
      setIsDialogOpen(false);
    }
  }, [isGettingGeometry, isDialogOpen, setIsDialogOpen]);

  const projectMetadata = useProjectMetadataValue(projectId || '');
  const userCanEdit = useUserCanEdit(projectMetadata?.summary);

  // Close the dialog when there is no geometry health card. This indicates that a new file was
  // imported successfully.
  useEffect(() => {
    if (!geometryHealth && isDialogOpen) {
      setIsDialogOpen(false);
    }
  }, [geometryHealth, isDialogOpen, setIsDialogOpen]);

  // Dismiss the geometry health card.
  const dismissCard = useCallback(() => {
    setGeometryHealth(null);
  }, [setGeometryHealth]);

  // Automatically dismiss a card with no issues after a short period of time.
  const issues = geometryHealth?.issues || [];
  const issueLength = issues.length;
  useEffect(() => {
    if (issueLength === 0 && geometryHealth && geometryHealth.ok) {
      autoDismissTimeout.current = setTimeout(() => {
        if (issueLength === 0 && geometryHealth) {
          dismissCard();
        }
      }, SUCCESS_AUTO_DISMISS);
    }
    return (() => {
      if (autoDismissTimeout.current) {
        clearTimeout(autoDismissTimeout.current);
      }
    });
  }, [dismissCard, geometryHealth, issueLength]);

  if (geometryHealth && currentView === CurrentView.SETUP) {
    return (
      <>
        <MeshImportDialogCommon
          onClose={() => setIsDialogOpen(false)}
          open={isDialogOpen}
        />
        <GeometryHealthCard
          dismissCard={dismissCard}
          entityGroupMap={entityGroupMap}
          issues={issues}
          ok={geometryHealth.ok}
          reUpload={() => setIsDialogOpen(true)}
          userCanEdit={userCanEdit}
        />
      </>
    );
  }
  return null;
};

export default GeometryHealth;
