// Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved.
import React, { CSSProperties, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import cx from 'classnames';

import { FaultType, FormControlSize } from '../../../lib/componentTypes/form';
import { isUnmodifiedEnterKey } from '../../../lib/event';
import { upperFirst } from '../../../lib/text';

import './TextInput.scss';

/** Implements text box inputs according to the Figma mocks at
 * https://www.figma.com/file/8rNGeZ9HiJ4Zl6pVaEk5Lq/Luminary-Component-Library?node-id=141%3A2815
 */

type InputLikeElement = HTMLInputElement | HTMLTextAreaElement;

/**
 * Return true if and only if the input's contents are completely selected, as determined by
 * comparing the selection length to a value length
 * @param inputEl
 * @param value
 * @returns
 */
function isInputWhollySelected(inputEl: InputLikeElement | null, value: string) {
  if (!value.length || !inputEl) {
    return false;
  }
  if (inputEl.nodeName.toLowerCase() !== 'input') {
    // Always return false for textareas to match native behavior
    return false;
  }

  // This could be empty in some browsers, like FF, which has an old bug where
  // document.getSelection() doesn't work for inputs.
  const docSelection = document.getSelection()?.toString();
  if (docSelection?.length === value.length) {
    return true;
  }

  // When document.getSelection is empty, look instead at the selection start/end values on the
  // input element.
  const input = inputEl ? inputEl as HTMLInputElement : null;
  const start = Number(input?.selectionStart);
  const end = Number(input?.selectionEnd);
  if (!Number.isNaN(start) && !Number.isNaN(end)) {
    if (Math.abs(end - start) === value.length) {
      return true;
    }
  }
  return false;
}

export interface TextInputProps {
  // Value in the textbox
  value?: string;
  // Placeholder text
  placeholder?: string;
  // Called when input value changes
  onChange?: (value: string) => void;
  // Called when input value is commited (by hitting Enter or blurring the input)
  onCommit?: (value: string) => void;
  // Optional focus event handler
  onFocus?: () => void;
  // Optional blur event handler
  onBlur?: () => void;
  /**
   * Optionally prevent the input from committing changes on blur
   *
   * This may be useful if the user is required to click on something while this is in focus
   */
  disableCommitOnBlur?: boolean;
  // Optional 'name' attribute for use in forms
  name?: string;
  // Optionally disable the input
  disabled?: boolean;
  // Set a custom input type like password
  type?: 'text' | 'password';
  // Optionally place input in readOnly mode
  readOnly?: boolean;

  // *** The autoFocus prop should only rarely be used. ***
  // It can reduce usability and accessibility for users. [jsx-a11y/no-autofocus]
  // But we allow it in some special cases.
  autoFocus?: boolean;

  // Choose an input size (see Figma)
  size?: FormControlSize;
  // Sets a fault type to engender different border colors
  faultType?: FaultType;
  // Forces root node to have a block layout (vs. the default inline layout)
  asBlock?: boolean;
  // Optional data-locator attribute to apply to the underlying <input /> or <textarea />
  dataLocator?: string;
  // Prevent sending the input to logrocket
  dataPrivate?: boolean;
  // Control whether and how entered text is automatically capitalized
  autoCapitalize?: 'off' | 'none' | 'on' | 'sentences' | 'words' | 'characters';
  // Control automated assistance in filling out form field values
  autoComplete?: 'off' | 'on';
  // (non-standard, Safari-only) control whether or not entered text is
  // auto-corrected
  autoCorrect?: 'off' | 'on';
  // Control whether entered text is checked for spelling (when true, text may
  // have squiggly red underlines to indicated errors)
  spellCheck?: 'false' | 'true';

  // Uses a <textarea /> element instead of <input type="text" />
  multiline?: boolean;
  // Default number of rows (applies only when using <textarea>)
  rows?: number;
  // Default number of columns (applies only when using <textarea>)
  cols?: number;
  // Control whether a <textarea> is resizable and how
  resize?: 'both' | 'horizontal' | 'vertical' | 'none';
  // Specify justification of text in input
  justify?: 'start' | 'center' | 'end';

  // Content (string, DOM nodes, React nodes, etc.) to place at the beginning or
  // end of the input box. Add a stopPropagation() call on the event to prevent
  // the TextInput focusing when the adornment is clicked.
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  // Optionally include a custom center-aligned button node to be placed at the end of the input
  adornmentButton?: ReactNode;

  // Optionally handle keyboard event when Enter key is pressed
  onEnter?: React.KeyboardEventHandler;
  // Optionally include a custom handler for the paste event.
  onPaste?: React.ClipboardEventHandler;

  // Whether to submit the form when the Enter key is pressed
  submitOnEnter?: boolean;
}

export const TextInput = (props: TextInputProps) => {
  const {
    adornmentButton,
    asBlock,
    autoCapitalize = 'off',
    autoComplete = 'off',
    autoCorrect = 'off',
    autoFocus,
    cols,
    dataLocator,
    dataPrivate,
    disabled,
    disableCommitOnBlur,
    endAdornment,
    faultType,
    justify = 'start',
    multiline,
    name = '',
    onBlur,
    onChange,
    onCommit,
    onEnter,
    onFocus,
    onPaste,
    type,
    placeholder,
    readOnly,
    resize = 'none',
    rows = 2,
    size = 'medium',
    spellCheck = 'false',
    startAdornment,
    value = '',
  } = props;

  const [inputValue, setInputValue] = useState(value);
  const focused = useRef(false);
  const inputEl = useRef(null);

  // LC-20955
  // Because of the closure created by the unMount useEffect below, the data used during unmount
  // becomes stale and old data gets committed. To avoid this, we use refs to store the latest
  // values outside of the closure
  const lastCommittedValueRef = useRef(value);
  const onBlurRef = useRef(onBlur);
  const onChangeRef = useRef(onChange);
  const onCommitRef = useRef(onCommit);
  const disabledCommitOnBlur = useRef(disableCommitOnBlur);
  onBlurRef.current = onBlur;
  onChangeRef.current = onChange;
  onCommitRef.current = onCommit;
  disabledCommitOnBlur.current = disableCommitOnBlur;

  const updateValue = useCallback((newValue: string, commit = false) => {
    const doChange = (newValue !== inputValue);
    const doCommit = (newValue !== lastCommittedValueRef.current) && commit;
    if (doChange) {
      setInputValue(newValue);
      onChangeRef.current?.(newValue);
    }
    if (doCommit) {
      lastCommittedValueRef.current = newValue;
      onCommitRef.current?.(newValue);
    }
  }, [inputValue]);

  useEffect(() => {
    updateValue(value);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const inputOnBlur = (newValue: string) => {
    // a ref is used here so that the value is not stale during unmount
    updateValue(newValue, !disabledCommitOnBlur.current);
    focused.current = false;
    onBlurRef.current?.();
  };

  useEffect(() => {
    // During clean up, the inputEl.current has already unmounted so it returns null
    // Thus, the reference must be captured during mount
    // https://legacy.reactjs.org/blog/2020/08/10/react-v17-rc.html#potential-issues
    const inputInstance = inputEl.current;
    return () => {
      // Avoid dependencies in this useEffect by manually blurring the input/textarea to trigger
      // updateValue()
      if (inputInstance && focused.current) {
        // Only blur the input if it is still focused as commiting all changes could be expensive
        // and cause a lot of rerenders
        const instance = inputInstance as InputLikeElement;
        inputOnBlur(instance.value);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // HTML attributes common to both <input /> and <textarea /> elements
  const commonAttrs = {
    'aria-label': name,
    autoCapitalize,
    autoComplete,
    autoCorrect,
    'data-locator': dataLocator,
    disabled,
    name,
    onBlur: () => {
      inputOnBlur(inputValue);
    },
    onChange: (event: React.ChangeEvent) => {
      updateValue((event.target as InputLikeElement).value, !focused.current);
    },
    onFocus: () => {
      // At the moment of focus, the input/textarea might be selected (e.g. when the user tabbed
      // into the input instead of clicking it).  Later, the value, which may have been formatted
      // prior to focus, will revert to a raw value for more precise editing.  (See NumberInput
      // which wraps this component.)  If the raw and formatted values differ when this replacement
      // happens, any selection will be lost.  Track selection state here before values are swapped,
      // so that we may reset it after the value is replaced with the raw value
      window.requestAnimationFrame(() => {
        // Calculate reselect inside an animation frame request; otherwise, it might be too early to
        // detect selected text in some browsers
        const reselect = isInputWhollySelected(inputEl.current, value);
        lastCommittedValueRef.current = inputValue;
        focused.current = true;
        onFocus?.();
        window.requestAnimationFrame(() => {
          // Reselect input's content in another animation frame request, which will be after the
          // value has been replaced and a repaint has occurred.
          if (inputEl.current && reselect) {
            (inputEl.current as InputLikeElement).select();
          }
        });
      });
    },
    onPaste,
    placeholder,
    readOnly,
    ref: inputEl,
    spellCheck,
    value: inputValue,
  };

  const setInputFocused = () => {
    if (inputEl.current) {
      (inputEl.current as InputLikeElement).focus();
    }
  };

  return (
    <div
      className={cx(
        'textInput',
        size,
        faultType ? `fault${upperFirst(faultType)}` : '',
        {
          asBlock,
          disabled,
          multiline,
          readOnly,
        },
      )}
      data-private={dataPrivate}
      style={{
        '--input-justify': justify,
      } as CSSProperties}>
      <div
        className="container"
        onClick={setInputFocused}
        onKeyDown={() => { }}
        role="none">
        {startAdornment && !multiline && (
          <div className="adornment">{startAdornment}</div>
        )}
        {multiline ?
          (
            <textarea
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={autoFocus}
              {...commonAttrs}
              cols={cols}
              onKeyDown={(event) => {
                if (isUnmodifiedEnterKey(event) && props.submitOnEnter) {
                  event.preventDefault();
                  onEnter?.(event);
                }
              }}
              onKeyUp={(event) => {
                if (isUnmodifiedEnterKey(event) && props.submitOnEnter) {
                  updateValue((event.target as InputLikeElement).value, true);
                }
              }}
              rows={rows}
              style={{ resize }}
            />
          ) :
          (
            <input
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={autoFocus}
              {...commonAttrs}
              onKeyDown={(event) => {
                if (isUnmodifiedEnterKey(event)) {
                  onEnter?.(event);
                }
              }}
              onKeyUp={(event) => {
                if (isUnmodifiedEnterKey(event)) {
                  updateValue((event.target as InputLikeElement).value, true);
                }
              }}
              type={type || 'text'}
            />
          )}
        {endAdornment && (
          <div className="adornment">{endAdornment}</div>
        )}
        {adornmentButton && (
          <div className="adornmentButton">{adornmentButton}</div>
        )}
      </div>
    </div>
  );
};
