// Copyright 2024 Luminary Cloud, Inc. All Rights Reserved.
import React from 'react';

import assert from '../../../../lib/assert';
import * as random from '../../../../lib/random';
import * as rpc from '../../../../lib/rpc';
import { addRpcError } from '../../../../lib/transientNotification';
import * as geometryservicepb from '../../../../proto/api/v0/luminarycloud/geometry/geometry_pb';
import * as geometrypb from '../../../../proto/geometry/geometry_pb';
import { DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE, createOrUpdateFeature, useGeometrySelectedFeature, useGeometryState } from '../../../../recoil/geometry/geometryState';
import { useCommonTreePropsStyles } from '../../../Theme/commonStyles';
import { useProjectContext } from '../../../context/ProjectContext';
import { useSelectionContext } from '../../../context/SelectionManager';

import GeometryModificationPanelFooter from './GeometryModificationPanelFooter';
import { EditModificationMessage } from './GeometryModificationShared';

const panelTextOptions = {
  successful: 'Shrinkwrap was successful.',
  failed: 'Shrinkwrap failed.',
  pending: 'All bodies will be shrinkwrapped.',
};

// Handles shrinkwrap geometry modifications.
export const GeometryModificationShrinkwrapPropPanel = () => {
  // == Contexts
  const { projectId, geometryId } = useProjectContext();
  const { selectedNode: node, setSelection } = useSelectionContext();
  assert(!!node, 'No selected geometry modification shrinkwrap row');

  // == Recoil
  const geometryState = useGeometryState(projectId, geometryId);
  const [, setSelectedFeature] = useGeometrySelectedFeature(geometryId);

  // == Hooks
  const commonClasses = useCommonTreePropsStyles();

  // == Derived data
  const mod = geometryState?.geometryFeatures.find((feature) => feature.id === node.id);
  assert(!!mod, 'No selected geometry modification shrinkwrap');
  const isModAccepted = geometryState?.ackModifications.has(node.id);
  const isModFailed = geometryState?.featureIssuesServer.some(
    (issue) => issue.featureId === node.id,
  );

  let panelText = panelTextOptions.pending;

  if (isModAccepted) {
    if (isModFailed) {
      // If the mod has an issue, show failed message.
      panelText = panelTextOptions.failed;
    } else {
      // If the modification was accepted, show the successfull message.
      panelText = panelTextOptions.successful;
    }
  }

  const onShrinkwrapSave = async () => {
    const feature = new geometrypb.Feature({
      operation: {
        case: 'shrinkwrap',
        value: mod.operation.value as geometrypb.Shrinkwrap,
      },
      id: node.id,
      featureName: mod.featureName,
    });
    const req = new geometryservicepb.ModifyGeometryRequest({
      requestId: random.string(32),
      geometryId,
      modification: new geometrypb.Modification({
        modType: createOrUpdateFeature(geometryState, node.id),
        feature,
      }),
    });
    rpc.clientGeometry!.modifyGeometry(req).catch((err) => (
      addRpcError(`Server error ${err}`, err)
    ));

    // Focus out of the panel, to avoid weird rerenderings when calling setSelection.
    setSelectedFeature(DEFAULT_SELECTED_FEATURE_IGNORE_UPDATE);
    setSelection([]);
  };

  return (
    <div className={commonClasses.properties}>
      <EditModificationMessage nodeId={node.id} />
      <div
        style={{ margin: '0 0 14px', fontSize: 13, fontWeight: 600 }}>
        {panelText}
      </div>

      <GeometryModificationPanelFooter
        featureId={node.id}
        onModificationSave={onShrinkwrapSave}
      />
    </div>
  );
};
